import React, { useState, createContext } from "react";

export const VideoContext = createContext([]);

export const VideoProvider = ({ children }) => {
  const [chapterVideoData, setChapterVideoData] = useState(null);
  const [sideMenuVideoData, setSideMenuVideoData] = useState(null);

  const value = {
    chapterVideoData,
    setChapterVideoData,
    sideMenuVideoData,
    setSideMenuVideoData,
  };

  return (
    <VideoContext.Provider value={value}>{children}</VideoContext.Provider>
  );
};

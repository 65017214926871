import axios from 'axios';
import { url } from 'config/urlConfig';

const tokenFromStorage =
  localStorage.getItem('token') || sessionStorage.getItem('token');

const headerParams = {
  apiKey: process.env.REACT_APP_API_KEY,
  token: tokenFromStorage,
};

export const getTopics = ({ queryParams }) =>
  axios.get(`/topics`, {
    headers: headerParams,
    params: { ...queryParams },
  });

export const getTopic = ({ queryParams, topicId }) =>
  axios.get(`/topics/${topicId}`, {
    headers: headerParams,
    params: { ...queryParams },
  });

export const getTopicalBigIdea = ({ queryParams, topicId }) =>
  axios.get(`/topics/${topicId}/bigIdea`, {
    headers: headerParams,
    params: { ...queryParams },
  });

export const getStubs = ({ queryParams }) =>
  axios.get(`/stubs`, {
    headers: headerParams,
    params: { ...queryParams },
  });

export const getStub = ({ queryParams, stubsId }) =>
  axios.get(`/stubs/${stubsId}`, {
    headers: headerParams,
    params: { ...queryParams },
  });

export const updateNewStubs = () =>
  axios.put(`/user/newStubs-flag`, {
    headers: headerParams,
  });

export const markStubAsRead = ({ stubsId, bodyParams }) =>
  axios.put(
    `/stubs/${stubsId}`,
    { ...bodyParams },
    {
      headers: headerParams,
    },
  );

export const getStubContent = ({ queryParams, stubsId }) =>
  axios.get(`/stubs/${stubsId}/content`, {
    headers: headerParams,
    params: { ...queryParams },
  });

export const getStubsBigIdea = ({ queryParams, stubsBigIdeaId }) =>
  axios.get(`/stubsBigIdea/${stubsBigIdeaId}`, {
    headers: headerParams,
    params: { ...queryParams },
  });

export const useHandleTopicsBack = ({ history, topicSlug, stubSlug }) => {
  const topicId = JSON.parse(sessionStorage.getItem('topicId'));

  const handleBack = () => {
    if (topicSlug && stubSlug) {
      navigateTopics({
        navigate: history.push,
        type: 'hub',
        topicSlug,
        topicId,
      });
    } else if (topicSlug && !stubSlug) {
      navigateTopics({
        navigate: history.push,
      });
    } else {
      navigateTopics({
        navigate: history.push,
      });
    }
  };

  return handleBack;
};

export const navigateTopics = ({
  navigate,
  type,
  topicSlug,
  stubSlug,
  params,
  state,
}) => {
  const navigateUrlByType = type => {
    switch (type) {
      case 'topics' || null:
        return `${url.TOPICS}`;
      case 'hub':
        return `${url.TOPICSHUB}/${topicSlug}`;
      case 'stub':
        return `${url.TOPICSHUB}/${topicSlug}/page/${stubSlug}`;
      default:
        return `${url.TOPICS}`;
    }
  };

  // Constructing URL with query parameters
  const navigateUrl = navigateUrlByType(type);
  const queryParams = new URLSearchParams(params).toString();
  const finalUrl = queryParams ? `${navigateUrl}?${queryParams}` : navigateUrl;

  if (!type || !navigateUrl) return navigate(navigateUrlByType('topics'));

  return navigate(finalUrl, { state });
};

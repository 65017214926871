export function calculateDayDifference(startDate, endDate) {
  // Parse the input date strings into Date objects
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);

  // Calculate the time difference in milliseconds
  const timeDifference = endDateObj - startDateObj;

  // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
  const daysDifference = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

  return daysDifference;
}

import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { FormHeader, FormError, FormFooter } from "./AuthComponents";
import { resendEmailVerification } from "./authUtils";

const SignupVerification = ({ history, location }) => {
  const searchParams = new URLSearchParams(location.search);

  const [message, setMessage] = useState(null);
  const [startCounter, setStartCounter] = useState(false);
  const [disableCounter, setDisableCounter] = useState(false);
  const [seconds, setSeconds] = useState(60);

  const email = searchParams.get("email");
  const decodeEmail = decodeURI(email);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const submitForm = async () => {
    const bodyFormData = { email: decodeEmail };

    try {
      const res = await resendEmailVerification(bodyFormData);

      setMessage({
        text: res?.data?.message,
        isSuccess: true,
      });
    } catch (err) {
      console.log({ err });
      setMessage({
        text: err?.response?.data?.message,
        isSuccess: false,
      });
    }

    setStartCounter(true);
  };

  useEffect(() => {
    if (startCounter === true) {
      setDisableCounter(true);

      let myInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }
        if (seconds === 0) {
          setSeconds(60);
          setStartCounter(false);
          setDisableCounter(false);
          setMessage("");
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
  }, [startCounter, seconds]);

  useEffect(() => {
    setStartCounter(true);
  }, []);

  return (
    <div className="right-content-space">
      <div className="form-content">
        <FormHeader
          type="verification"
          title="Complete your sign up"
          backButton
          backButtonClick={() => history.goBack()}
        />

        <p className="form-subheader">
          A verification email has been sent to {decodeEmail}. Please click on
          the link in the email to activate your account.
        </p>

        <form>
          <FormFooter
            text="Didn't receive your email?"
            className={`verification ${!isMobile && "no-underline"}`}
            buttonText={`Resend Now. (${seconds}s)`}
            buttonClick={!disableCounter && submitForm}
            buttonDisabled={disableCounter}
          />

          <FormError message={message?.text} isSuccess={message?.isSuccess} />
        </form>
      </div>
    </div>
  );
};

export default withRouter(SignupVerification);

import { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { url } from 'config/urlConfig';
import { UserContext } from 'context/UserContext';
import { capitalize } from 'shared/helper/helper';
import {
  updateUser,
  createChild,
  submitAnswer,
  completeChildTest,
  AssignChapters,
} from 'routes/ProfileTest/ProfileTestUtils';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import Popup from 'shared/components/Popup/Popup';
import { WarningOutlined } from '@material-ui/icons';

// Internal use only [DEV]
const SkipSignupPopup = ({ children, type }) => {
  const history = useHistory();

  const { user, setUser } = useContext(UserContext);

  const selectedIncompleteChildProfile = JSON.parse(
    sessionStorage.getItem('selectedIncompleteChildProfile'),
  );
  const childProfile = selectedIncompleteChildProfile;

  const [showSkipSignupModal, setShowSkipSignupModal] = useState(false);
  const [isSkipSignupLoading, setIsSkipSignupLoading] = useState(false);
  const [isSecureKey, setIsSecureKey] = useState(false);

  // Toggle skip signup modal
  const toggleSkipSignupModal = () => {
    setShowSkipSignupModal(prev => !prev);
  };

  const getUserName = ({ userEmail }) => {
    const filteredEmailName = userEmail?.split('@')[0];

    // Check for the presence of '-', '_', '.', or '+'
    const dashIndex = filteredEmailName?.indexOf('-');
    const underscoreIndex = filteredEmailName?.indexOf('_');
    const dotIndex = filteredEmailName?.indexOf('.');
    const plusIndex = filteredEmailName?.indexOf('+');

    let firstName, lastName;

    // Choose the splitting character based on the first one found
    if (dashIndex !== -1) {
      firstName = filteredEmailName?.substring(0, dashIndex);
      lastName = filteredEmailName?.substring(dashIndex + 1);
    } else if (underscoreIndex !== -1) {
      firstName = filteredEmailName?.substring(0, underscoreIndex);
      lastName = filteredEmailName?.substring(underscoreIndex + 1);
    } else if (dotIndex !== -1) {
      firstName = filteredEmailName?.substring(0, dotIndex);
      lastName = filteredEmailName?.substring(dotIndex + 1);
    } else if (plusIndex !== -1) {
      firstName = filteredEmailName?.substring(0, plusIndex);
      lastName = filteredEmailName?.substring(plusIndex + 1);
    } else {
      // If none of the characters is found, consider the entire username as the first name
      firstName = filteredEmailName;
    }

    const userName = capitalize(firstName + ' ' + (lastName ? lastName : ''));

    return { userName, firstName, lastName };
  };

  const handleProceed = async () => {
    setIsSkipSignupLoading(true);

    let res;
    switch (type) {
      case 'parent-test':
        res = await handleSubmitParentTest();
        if (res) {
          history.push(`${url.CHILD_TEST_PROFILE}`);
          setIsSkipSignupLoading(false);
        }
        break;
      case 'child-test':
        res = await handleSubmitChildTest({ sampleChild: childProfile });
        if (res) {
          history.push('/welcome?test-completed=true');
          setIsSkipSignupLoading(false);
        }
        break;
      case 'signup':
        res = await handleCompleteSignupProgress();
        if (res) {
          history.push('/welcome?test-completed=true');
          setIsSkipSignupLoading(false);
        }
        break;
      default:
        res = await handleCompleteSignupProgress();
        if (res) {
          history.push('/welcome?test-completed=true');
          setIsSkipSignupLoading(false);
        }
    }

    toggleSkipSignupModal();
  };

  const handleCancel = () => {
    toggleSkipSignupModal();
  };

  const handleCreateProfiles = async () => {
    const userEmail = user?.email;

    const { userName, firstName } = getUserName({ userEmail });

    const userProfileData = {
      ...user,
      name: userName.substring(0, 12),
      birthday: '01/01/1991',
      colour: 'blue',
      parent: 'father',
      shape: 'circle',
    };

    const childProfileData = {
      name: `${firstName.substring(0, 6)} Child`,
      date_of_birth: '02/02/2002',
      gender: 'M',
      colour: 'purple',
      shape: 'triangle',
    };

    try {
      const parentRes = await updateUser(userProfileData);

      if (parentRes) {
        setUser(userProfileData);
        const sampleChildRes = await createChild(childProfileData);

        return sampleChildRes;
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: useSkipSignup.js:85 ~ handleCreateProfiles ~ err:',
        err?.response,
      );
    }

    // Return undefined if an error occurs
    return undefined;
  };

  const handleSubmitParentTest = async () => {
    const exampleTestAnswers = Array.from({ length: 60 }, (_, i) => ({
      number: i + 1,
      value: Math.floor(Math.random() * 5) + 1,
    }));

    try {
      const res = await submitAnswer({
        type: 'parent',
        id: user.id,
        answers: exampleTestAnswers,
      });

      return res;
    } catch (err) {
      console.log(
        '🚀 ~ file: useSkipSignup.js:44 ~ handleSubmitParentAnswer ~ err:',
        err?.response,
      );
    }

    return undefined;
  };

  const handleSubmitChildTest = async ({ sampleChild }) => {
    const sampleChildId = sampleChild?.id;

    const exampleTestAnswers = Array.from({ length: 60 }, (_, i) => ({
      number: i + 1,
      value: Math.floor(Math.random() * 5) + 1,
    }));

    try {
      const res = await completeChildTest({
        type: 'child',
        id: sampleChildId,
        answers: exampleTestAnswers,
      }).catch(err => {
        console.log('🚀 ~ handleSubmitChildTest ~ err:', err);
      });
      const data = res.data?.data;

      if (!_.isEmpty(data)) {
        // handleAssignChapter
        try {
          const chapterRes = await AssignChapters({
            child_id: sampleChildId,
          });

          sessionStorage.setItem(
            'selectedRelative',
            JSON.stringify(sampleChild),
          );
          return chapterRes;
        } catch (err) {
          console.log(
            '🚀 ~ file: useSkipSignup.js:68 ~ handleAssignChapter ~ err:',
            err?.response,
          );
        }
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: useSkipSignup.js:143 ~ handleSubmitChildTest ~ err:',
        err?.response,
      );
    }

    return undefined;
  };

  const handleCompleteSignupProgress = async () => {
    try {
      const profileRes = await handleCreateProfiles();

      if (profileRes) {
        const parentTestRes = await handleSubmitParentTest();

        if (parentTestRes) {
          const sampleChild = profileRes?.data?.data;
          await handleSubmitChildTest({ sampleChild });

          return parentTestRes;
        }
      }
    } catch (error) {
      console.error('Error in handleCompleteSignupProgress:', error);
    }

    return undefined;
  };

  useEffect(() => {
    const env = process.env.REACT_APP_STAGE;
    const isEnvDev = _.isEqual(env, 'dev');

    function handleKeyDown(event) {
      if (event.shiftKey && event.code === 'KeyS' && isEnvDev) {
        if (!isSecureKey) {
          setIsSecureKey(true);
        } else {
          setIsSecureKey(false);
        }
      } else if (isSecureKey && event.code === 'KeyS' && isEnvDev) {
        toggleSkipSignupModal();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isSecureKey]);

  useEffect(() => {
    return () => {
      setShowSkipSignupModal(false);
    };
  }, []);

  if (showSkipSignupModal) {
    return (
      <Popup
        icon={
          <WarningOutlined
            style={{
              color: 'darkred',
              width: '120px',
              height: '100%',
            }}
          />
        }
        header={
          <>
            Skip Signup Cheat <br /> <span>[DEV Testing Only]</span>
          </>
        }
        description={
          !isSkipSignupLoading ? (
            <>
              Press 'Proceed' to skip the signup process <br />
              <br /> <strong>CJ: Please use wisely</strong>
            </>
          ) : (
            <LoadingIndicator loadingText="Skipping signup process..." />
          )
        }
        callToActions={[
          {
            type: 'solid',
            children: 'Proceed',
            onClick: handleProceed,
          },
          {
            type: 'outline',
            children: 'Cancel',
            onClick: handleCancel,
          },
        ]}
        setShowModal={setShowSkipSignupModal}>
        {children}
      </Popup>
    );
  }

  return <>{children}</>;
};

export default SkipSignupPopup;

import Lottie from 'react-lottie';
import animationData from 'assets/animation/loading_animation.json';
import './LoadingIndicator.scss';

const lottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const LoadingIndicator = ({ loadingText, isLoaderOnly }) => {
  if (isLoaderOnly) {
    return (
      <div id="loader">
        <Lottie
          options={{ ...lottieOptions, animationData: animationData }}
          width="200px"
          height="140px"
        />
        <h2>{loadingText ? loadingText : 'Loading...'}</h2>
      </div>
    );
  }

  return (
    <div id="loading-indicator">
      <div className="loading-container">
        <Lottie
          options={{ ...lottieOptions, animationData: animationData }}
          width="300px"
          height="140px"
        />
        <h2>{loadingText ? loadingText : 'Loading...'}</h2>
      </div>
    </div>
  );
};

export default LoadingIndicator;

import { useContext, useState } from 'react';
import _ from 'lodash';
import {
  trackingAction,
  TRACKING_PURCHASES,
  GA_EVENT_CATOGARY,
  GA_PURCHASE_JOURNEY_TYPE,
} from 'shared/function/Tracking';
import { makePayment, WALLET_TYPES } from './CheckoutUtils';
import useProductList from './useProductList';
import { UserContext } from 'context/UserContext';

const usePaymentData = ({ calculationResult, setOrderId }) => {
  const [paymentData, setPaymentData] = useState(null);
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  const { selectedProduct } = useProductList();
  const selectedProductData = JSON.parse(
    sessionStorage.getItem('selectedProduct'),
  );

  const productData = selectedProduct || selectedProductData;

  const productId = productData?.id;
  const productType = productData?.product_id;

  const isPurchaseChildProfile = productType?.toLowerCase().includes('child');

  const { user } = useContext(UserContext);

  const productCurrency = localStorage
    .getItem('countryCurrency')
    ?.toLowerCase();

  const resetPaymentData = () => {
    setPaymentData(null);
    setIsPaymentLoading(false);
  };

  const requestPaymentFromAPI = async paymentMethod => {
    try {
      setIsPaymentLoading(true);
      const discountCodeId = calculationResult?.discount_code_id;

      const paymentType = _.isEqual(paymentMethod, 'paypal')
        ? 'Paypal'
        : 'Stripe';

      const wallet = Object.values(WALLET_TYPES).includes(paymentMethod)
        ? paymentMethod.toLowerCase()
        : null;

      const body = {
        discount_code_id: discountCodeId,
        payment_type: paymentType,
        currency: productCurrency,
        order_items: [
          {
            product_id: productId,
            quantity: 1,
          },
        ],
        ...(_.isEqual(paymentMethod, 'paynow') ? { is_paynow: 1 } : {}),
        ...(wallet ? { wallet } : {}),
      };

      const res = await makePayment(body);

      const { data } = res.data;
      const orderId = data.id;

      setOrderId(orderId);
      setPaymentData(data);
      setIsPaymentLoading(false);

      trackingAction({
        category: GA_EVENT_CATOGARY.ecommerce,
        action: isPurchaseChildProfile
          ? TRACKING_PURCHASES.web_purchase_child_profile
          : TRACKING_PURCHASES.web_purchase_premium_access,
        ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.begin_checkout,
        ecommerceEventData: {
          orderId,
          productData,
        },
        extraData: {
          user_id: user?.id,
        },
      });

      return data;
    } catch (err) {
      setIsPaymentLoading(false);
      console.log(err?.response);
    }
  };

  return {
    paymentData,
    requestPaymentFromAPI,
    resetPaymentData,
    isPaymentLoading,
  };
};

export default usePaymentData;

import axios from 'axios';

export const getQuestion = ({ type, id }) =>
  axios.get(`/quiz?type=${type}&child_id=${id}`);
export const submitAnswer = body => axios.post('/quiz/submit-answer', body);
export const reSubmitAnswer = body => axios.post('/quiz/resubmit-answer', body);
export const getUserData = () => axios.get('/user');
export const AssignChapters = body =>
  axios.post('/books/assign-chapters', body);
export const reAssignChapters = body => axios.post('/books/reassign-chapters');
export const getRelative = () => axios.get('/relative');

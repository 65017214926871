import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import parse from 'html-react-parser';
import { getPolicy, handleSettingBack } from '../SettingModuleUtils';
import { ChevronLeft } from '@material-ui/icons';
import './Policy-Terms.scss';

const Policy = ({ history }) => {
  const location = useLocation();

  const [policyContent, setPolicyContent] = useState('');

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  useEffect(() => {
    getPolicyData();
  }, []);

  const getPolicyData = async () => {
    const response = await getPolicy();
    const { content } = response.data.data;
    setPolicyContent(content);
  };

  return (
    <div id="settings-policy-terms">
      <div className="settings-mobile-header">
        <span
          onClick={() =>
            handleSettingBack({
              history,
              location,
              type: 'tab',
              isMobile,
            })
          }>
          <ChevronLeft /> Back
        </span>
        <h1>Privacy Policy</h1>
      </div>
      <div className="parse-container">{parse(policyContent)}</div>
    </div>
  );
};

export default withRouter(Policy);

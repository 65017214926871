export const TRACKING_GENERAL = {
  navigate_spotlight: 'navigate_spotlight',
  navigate_snapshot: 'navigate_snapshot',
  navigate_playbooks: 'navigate_playbooks',
  navigate_journey: 'navigate_journey',
  navigate_mailbox: 'navigate_mailbox',
  navigate_settings: 'navigate_settings',
};

export const TRACKING_SPOTLIGHT = {
  spotlight_explore: 'spotlight_explore',
  spotlight_share: 'spotlight_share',
  spotlight_manage: 'spotlight_manage',
};

export const TRACKING_SNAPSHOT = {
  snapshot_graph_purpose: 'snapshot_graph_purpose',
  snapshot_graph_energy: 'snapshot_graph_energy',
  snapshot_graph_affirmation: 'snapshot_graph_affirmation',
  snapshot_graph_knowledge: 'snapshot_graph_knowledge',
  snapshot_graph_sustainability: 'snapshot_graph_sustainability',
  snapshot_video_preview: 'snapshot_video_preview',
  snapshot_expand_chapter: 'snapshot_expand_chapter',
  snapshot_explore: 'snapshot_explore',
};

export const TRACKING_PLAYBOOKS = {
  playbooks_add_child: 'playbooks_add_child',
  playbooks_select_child: 'playbooks_select_child',
  playbooks_new_child: 'playbooks_new_child',
  register_new_child: 'register_new_child',
  child_test_next_questions: 'child_test_next_questions',
  section_menu_scroll_to_domain: 'section_menu_scroll_to_domain',
  section_menu_play_intro_video: 'section_menu_play_intro_video',
  section_menu_select_domain: 'section_menu_select_domain',
  section_menu_play_ending_video: 'section_menu_play_ending_video',
  chapter_menu_tooltip: 'chapter_menu_tooltip',
  chapter_menu_play_intro_video: 'chapter_menu_play_intro_video',
  chapter_menu_select_chapter: 'chapter_menu_select_chapter',
  chapter_menu_play_ending_video: 'chapter_menu_play_ending_video',
  chapter_menu_back: 'chapter_menu_back',
};

export const TRACKING_TOPICS = {
  topics_select_topic: 'topics_select_topic',
  stub_explore: 'stub_explore',
};

export const TRACKING_PURCHASES = {
  session_start: 'session_start',
  view_premium_upgrade: 'view_premium_upgrade',
  view_child_profile: 'view_child_profile',
  checkout_premium_upgrade: 'checkout_premium_upgrade',
  checkout_child_profile: 'checkout_child_profile',
  checkout_premium_extend: 'checkout_premium_extend',
  iap_child_profile: 'iap_child_profile',
  iap_child_profile_purchase_attempt_with_limited_premium:
    'iap_child_profile_purchase_attempt_with_limited_premium',
  iap_child_profile_success: 'iap_child_profile_success',
  iap_child_profile_failed: 'iap_child_profile_failed',
  iap_premium_access: 'iap_premium_access',
  iap_premium_access_success: 'iap_premium_access_success',
  iap_premium_access_failed: 'iap_premium_access_failed',
  web_purchase_child_profile: 'web_purchase_child_profile',
  web_purchase_child_profile_purchase_attempt_with_limited_premium:
    'web_purchase_child_profile_purchase_attempt_with_limited_premium',
  web_purchase_child_profile_success: 'web_purchase_child_profile_success',
  web_purchase_child_profile_failed: 'web_purchase_child_profile_failed',
  web_purchase_child_profile_cancelled: 'web_purchase_child_profile_cancelled',
  web_purchase_premium_access: 'web_purchase_premium_access',
  web_purchase_premium_access_success: 'web_purchase_premium_access_success',
  web_purchase_premium_access_failed: 'web_purchase_premium_access_failed',
  web_purchase_premium_access_cancelled:
    'web_purchase_premium_access_cancelled',
};

export const TRACKING_TESTS = {
  start_parent_test: 'start_parent_test',
  finish_parent_test: 'finish_parent_test',
  start_child_test: 'start_child_test',
  finish_child_test: 'finish_child_test',
};

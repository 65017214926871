import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { isIOS } from 'react-device-detect';
import SmartBanner from 'react-smartbanner';
import _ from 'lodash';
import { TESTING_GENERAL_ID } from 'shared/const/TestAutomationIds';

const NotificationBanner = () => {
  const path = new URL(window.location.href).pathname;

  const isLogin = path === '/login';

  const isAuthExcludeLogin =
    path === '/sign-up' ||
    path === '/forgot-password' ||
    path === '/reset-password' ||
    path === '/signup' ||
    path === '/signup-verification';

  const notificationBanner = sessionStorage.getItem('notification_banner');

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const isBannerActive = _.isEqual(notificationBanner, 'active');

  const handleClose = () => {
    sessionStorage.setItem('notification_banner', 'inActive');
  };

  useEffect(() => {
    if (!notificationBanner) {
      if (isLogin) {
        window.location.reload();
      }
      sessionStorage.setItem('notification_banner', 'active');
    }
  }, [notificationBanner, isLogin]);

  useEffect(() => {
    const addDataTestIdToCloseButton = () => {
      // Find the close button element by class name
      const closeButton = document.querySelector('.smartbanner-close');

      // Check if the close button element exists
      if (closeButton) {
        // Add the data-test-id attribute
        closeButton.setAttribute(
          'data-test-id',
          TESTING_GENERAL_ID.SMARTBANNER_CLOSE_BUTTON,
        );
      }
    };

    const timeoutId = setTimeout(() => {
      addDataTestIdToCloseButton();
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [notificationBanner]);

  if (!isBannerActive || !isMobileView || isAuthExcludeLogin) return null;

  return (
    <div id="react-smartbanner">
      <SmartBanner
        title="Connected Playbook"
        daysHidden={0}
        daysReminder={0}
        position={'top'}
        force={isIOS ? 'ios' : 'android'}
        url={{
          ios: 'https://apps.apple.com/us/app/connected-playbook/id1619967091',
          android:
            'https://play.google.com/store/apps/details?id=com.connected.playbook&pli=1',
        }}
        storeText={{
          android: 'In Google Play Store',
          ios: 'On the App Store',
        }}
        ignoreCookies={true} // added prop to prevent storing cookies
        onClose={() => handleClose()}
      />
    </div>
  );
};

export default NotificationBanner;


import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import { UserContext } from 'context/UserContext';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';
import smoothscroll from 'smoothscroll-polyfill';
import { url } from 'config/urlConfig';
import { progressColor } from 'shared/const';
import { capitalize } from 'shared/helper/helper';
import { trackingAction, TRACKING_TESTS } from 'shared/function/Tracking';
import {
  getQuestion,
  submitAnswer,
  completeChildTest,
  AssignChapters,
  getRelative,
  reSubmitAnswer,
} from '../ProfileTestUtils';
import { navigatePlaybooks } from 'routes/Books/BooksUtils';
import { getUserData, getBooks } from '../../Books/api';

// components/assets
import Head from 'shared/components/Head.js';
import Result from './Result';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import SkipSignupPopup from 'shared/components/SkipSignupPopup/SkipSignupPopup';
import { EndTestPrompt } from './TestComponents';
import { ButtonSolid } from 'shared/components/Button/Button';
import { ReactComponent as ChevronRight } from 'assets/home/chevron-right.svg';
import { ReactComponent as InfoIcon } from 'assets/info-icon.svg';
import UserIcon from 'shared/components/UserIcon';
import './Test.scss';

const Test = ({ history, location, match }) => {
  const { types } = match.params;

  const params = new URLSearchParams(location.search);
  const addChild = params.get('addchild');

  const { tempUser, setTempUser, user, setUserRelatives, setUser } =
    useContext(UserContext);

  const [testTime, setTestTime] = useState({});

  const [questionArray, setQuestionArray] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [questions2, setQuestions2] = useState([]);
  const [questions3, setQuestions3] = useState([]);
  const [questions4, setQuestions4] = useState([]);
  const [questions5, setQuestions5] = useState([]);
  const [questions6, setQuestions6] = useState([]);

  const [section1Complete, setSection1Complete] = useState(false);
  const [section2Complete, setSection2Complete] = useState(false);
  const [section3Complete, setSection3Complete] = useState(false);
  const [section4Complete, setSection4Complete] = useState(false);
  const [section5Complete, setSection5Complete] = useState(false);

  const [openSection1, setOpenSection1] = useState(true);
  const [openSection2, setOpenSection2] = useState(false);
  const [openSection3, setOpenSection3] = useState(false);
  const [openSection4, setOpenSection4] = useState(false);
  const [openSection5, setOpenSection5] = useState(false);
  const [openSection6, setOpenSection6] = useState(false);

  const [countIndex, setCountIndex] = useState(0);

  const [counter, setCounter] = useState(0);
  const [answerArray, setAnswerArray] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [type, setType] = useState(types);
  const [showEndTestPopup, setShowEndTestPopup] = useState(false);

  const mobileSize = useMediaQuery({ query: '(max-width: 425px)' });
  const tabletSize = useMediaQuery({ query: '(max-width: 768px)' });
  const customSize = useMediaQuery({ query: '(max-width: 500px)' });

  const selectedIncompleteChildProfile = JSON.parse(
    sessionStorage.getItem('selectedIncompleteChildProfile'),
  );

  const childProfile = selectedIncompleteChildProfile;
  const childProfileId = childProfile?.id;
  const childProfileName = childProfile?.name;
  const childProfileColour = childProfile?.colour;

  smoothscroll.polyfill();

  const scrollToBottom = () => {
    if (mobileSize) {
      return window.scrollTo({
        top: document.body.scrollHeight - 100,
        left: 0,
        behavior: 'smooth',
      });
    }
    window.scrollTo({
      top: document.body.scrollHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const selectAnswer = async ({ value, number }) => {
    const tempAnswerCollection = [...answerArray];
    const answerIndex = number - 1;
    tempAnswerCollection[answerIndex] = { number, value };

    setAnswerArray(tempAnswerCollection);
    setCounter(tempAnswerCollection.length);

    if (openSection1) {
      onChangeQuestion(number);
    }
    if (openSection2) {
      onChangeQuestion2(number);
    }

    if (openSection3) {
      onChangeQuestion3(number);
    }

    if (openSection4) {
      onChangeQuestion4(number);
    }

    if (openSection5) {
      onChangeQuestion5(number);
    }

    if (openSection6) {
      onChangeQuestion6(number);
    }
    if (tempAnswerCollection.filter(el => el).length === 10) {
      setSection1Complete(true);
    }

    if (tempAnswerCollection.filter(el => el).length === 20) {
      setSection2Complete(true);
    }

    if (tempAnswerCollection.filter(el => el).length === 30) {
      setSection3Complete(true);
    }

    if (tempAnswerCollection.filter(el => el).length === 40) {
      setSection4Complete(true);
    }

    if (tempAnswerCollection.filter(el => el).length === 50) {
      setSection5Complete(true);
    }

    if (tempAnswerCollection.filter(el => el).length === questionArray.length) {
      setIsFormCompleted(true);
    } else {
      setIsFormCompleted(false);
    }
  };

  const handleSubmitAnswerParent = async () => {
    try {
      const res = await submitAnswer({
        type,
        id: user.id,
        answers: answerArray,
      });
      const { data } = res.data;

      if (!_.isEmpty(data)) {
        setAnswerArray([]);
        setIsSubmitting(false);
        setIsFormCompleted(false);

        trackingAction({
          action: TRACKING_TESTS.finish_parent_test,
          extraData: {
            user_id: user?.id,
          },
        });
      }
    } catch (err) {
      const { message } = err;
      console.log('handleSubmitAnsweParent ===> error', message);
    }
  };

  const handleSubmitAnswerChildandAssignChapter = async () => {
    try {
      const res = await completeChildTest({
        type,
        id: childProfileId,
        answers: answerArray,
      });
      const { data } = res?.data;
      const childTestTaken = data?.parent?.child_test_taken;
      const parentTestTaken = data?.parent?.parent_test_taken;

      if (!_.isEmpty(data)) {
        setAnswerArray([]);
        trackingAction({
          action: TRACKING_TESTS.finish_child_test,
          extraData: { relative_id: data?.id, user_id: user?.id },
        });

        if (childTestTaken === 1 && parentTestTaken === 1) {
          handleAssignChapter();
        } else {
          handleResubmitAnswer();
        }
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: Test.js:522 ~ handleSubmitAnswerChildandAssignChapter ~ err:',
        err?.response,
      );
      const { message } = err;
      console.log(
        'handleSubmitAnswerChildandAssignChapter ===> error',
        message,
      );
    }
  };

  const handleAssignChapter = async () => {
    try {
      await AssignChapters({
        child_id: childProfileId,
      }).catch(err => {
        console.log('🚀 ~ handleAssignChapter ~ err:', err?.response);
      });
      getRelativeData();
      sessionStorage.setItem('selectedRelative', JSON.stringify(childProfile));
    } catch (err) {
      const { message } = err;
      console.log('handleAssignChapter  ===> error', message);
      getRelativeData();
    }
  };

  const handleResubmitAnswer = async () => {
    try {
      const res = await reSubmitAnswer({
        child_id: childProfileId,
      });
      const { data } = res.data;
      const childTestTaken = data.parent.child_test_taken;
      const parentTestTaken = data.parent.parent_test_taken;

      if (childTestTaken === 1 && parentTestTaken === 1) {
        handleAssignChapter();
      } else {
        getRelativeData();
      }
    } catch (err) {
      const { message } = err;
      console.log('handleResubmitAnswer  ===> error', message);
      getRelativeData();
    }
  };

  const getRelativeData = async () => {
    try {
      const res = await getRelative();
      const relativeData = res?.data?.data;

      setIsSubmitting(false);
      setIsFormCompleted(false);
      window.scrollTo(0, 0);
      setType('child-result');
      setUserRelatives(relativeData);
    } catch (err) {
      const { message } = err;
      console.log('getRelativeData  ===> error', message);
    }
  };

  const handleNext = async () => {
    if (type === 'child') {
      setIsSubmitting(true);

      handleSubmitAnswerChildandAssignChapter();
    }
    if (type === 'child-result') {
      setIsSubmitting(false);
      const userRes = await getUserData();
      const userData = userRes?.data?.data;

      setUser({
        ...user,
        first_child: userData?.first_child,
        child_test_taken: userData.child_test_taken,
        parent_test_taken: userData.parent_test_taken,
        all_books_generated: userData.all_books_generated,
        all_books_occupied: userData?.all_books_occupied === 0 ? 0 : 1,
      });

      const bookRes = await getBooks();
      const bookData = bookRes?.data?.data;
      const book = bookData?.filter(
        book => book.child_id === childProfileId,
      )[0];
      const bookId = book?.id;

      if (
        userData.parent_test_taken === 1 &&
        userData.child_test_taken === 1 &&
        userData.all_books_generated === 1
      ) {
        navigatePlaybooks({
          navigate: history.push,
          type: 'section-menu',
          bookId,
        });
      } else {
        history.push(
          `/lobby-area/${
            userData.parent_test_taken === 0 || userData.child_test_taken === 0
              ? 'result-error'
              : 'generate-book-error'
          }`,
        );
      }
    }
    if (type === 'parent') {
      setIsSubmitting(true);

      handleSubmitAnswerParent();

      history.push('/profile-test-child');
      window.scrollTo(0, 0);
    }
  };

  const onChangeQuestion = number => {
    const isAnswered =
      answerArray.filter(answer => answer.number === number).length > 0;
    const index = answerArray.filter(el => el).length + 1;
    const q = questions;

    if (!isAnswered) {
      if (counter !== index && counter < index && index < 10) {
        q.push(questionArray[index]);
        setQuestions(q);
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
    }
  };

  const onChangeQuestion2 = number => {
    const isAnswered =
      answerArray.filter(answer => answer.number === number).length > 0;
    const index = answerArray.filter(el => el).length + 1;
    const q = questions2;

    if (!isAnswered) {
      if (counter !== index && counter < index && index < 20) {
        q.push(questionArray[index]);
        setQuestions2(q);
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
    }
  };

  const onChangeQuestion3 = number => {
    const isAnswered =
      answerArray.filter(answer => answer.number === number).length > 0;
    const index = answerArray.filter(el => el).length + 1;
    const q = questions3;

    if (!isAnswered) {
      if (counter !== index && counter < index && index < 30) {
        q.push(questionArray[index]);
        setQuestions3(q);
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
    }
  };

  const onChangeQuestion4 = number => {
    const isAnswered =
      answerArray.filter(answer => answer.number === number).length > 0;
    const index = answerArray.filter(el => el).length + 1;
    const q = questions4;

    if (!isAnswered) {
      if (counter !== index && counter < index && index < 40) {
        q.push(questionArray[index]);
        setQuestions4(q);
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
    }
  };

  const onChangeQuestion5 = number => {
    const isAnswered =
      answerArray.filter(answer => answer.number === number).length > 0;
    const index = answerArray.filter(el => el).length + 1;
    const q = questions5;

    if (!isAnswered) {
      if (counter !== index && counter < index && index < 50) {
        q.push(questionArray[index]);
        setQuestions5(q);
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
    }
  };

  const onChangeQuestion6 = number => {
    const isAnswered =
      answerArray.filter(answer => answer.number === number).length > 0;
    const index = answerArray.filter(el => el).length + 1;
    const q = questions6;

    if (!isAnswered) {
      if (counter !== index && counter < index && index < 60) {
        q.push(questionArray[index]);
        setQuestions6(q);
        setTimeout(() => {
          scrollToBottom();
        }, 100);
      }
    }
  };

  useEffect(() => {
    if (match.url === `/test/${types}`) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }

    if (match.url === `/test/${types}?addchild=true`) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (type === 'parent') {
      if (user.parent_test_taken === 1) {
        history.push(`${url.HOME}`);
      } else if (_.isEmpty(tempUser)) {
        history.push('/profile-test');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, user.parent_test_taken, tempUser]);

  useEffect(() => {
    if (type === 'child') {
      if (user.all_books_occupied === 1) {
        history.push(`${url.HOME}`);
      } else if (_.isEmpty(childProfile)) {
        history.push(
          `/profile-test-child${addChild ? '?addchild=true' : null}`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, user.child_test_taken, childProfile]);

  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted

    const getQuestionFromAPI = async () => {
      try {
        const result = await getQuestion({
          type,
          id: type === 'child' ? childProfileId : user.id,
        });

        // Check if the component is still mounted before updating state
        if (isMounted) {
          setQuestionArray(result.data.data);

          if (type === 'child') {
            setTestTime({ ...testTime, child_test_time: moment().format() });
          }
          if (type === 'parent') {
            setTestTime({ ...testTime, parent_test_time: moment().format() });
          }

          // Update state only if the component is mounted
          const question = [];
          question.push(result.data.data[0]);
          setQuestions(question);

          const question2 = [];
          question2.push(result.data.data[10]);
          setQuestions2(question2);

          const question3 = [];
          question3.push(result.data.data[20]);
          setQuestions3(question3);

          const question4 = [];
          question4.push(result.data.data[30]);
          setQuestions4(question4);

          const question5 = [];
          question5.push(result.data.data[40]);
          setQuestions5(question5);

          const question6 = [];
          question6.push(result.data.data[50]);
          setQuestions6(question6);
        }
      } catch (err) {
        console.log('🚀 ~ getQuestionFromAPI ~ err:', err?.response);
      }
    };

    if (type === 'child' || type === 'parent') {
      getQuestionFromAPI();
    }

    // Cleanup function to set the mounted flag to false when the component unmounts
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, user]);

  const radioButtonClass = colour => {
    switch (colour) {
      case 'blue':
        return 'radio-blue';
      case 'pink':
        return 'radio-pink';
      case 'green':
        return 'radio-green';
      case 'purple':
        return 'radio-purple';
      default:
        return 'radio-pink';
    }
  };

  const renderQuestion = (question, index) => {
    const QnsToNotAppearDivider =
      question.id === '1' ||
      question.id === '11' ||
      question.id === '21' ||
      question.id === '31' ||
      question.id === '41' ||
      question.id === '51';

    return (
      <div key={question.id}>
        {!QnsToNotAppearDivider && (
          <div className="divider-container">
            <div
              className="divider"
              style={{
                height: 5,
                backgroundColor: '#F0F0F0',
                width: '100%',
                borderRadius: 8,
              }}
            />
          </div>
        )}
        <div
          className="single-question"
          style={{
            opacity: !_.isEmpty(answerArray[index + countIndex]) ? '0.4' : '1',
          }}>
          <div className="question-container">
            {!customSize && (
              <p className="left-label">{question.lowest_value_statement}</p>
            )}

            <div className="radio-btn-container">
              <div className="tour-test-left">
                <div
                  className={`${radioButtonClass(
                    type === 'parent' ? user.colour : childProfileColour,
                  )} ${
                    answerArray[index + countIndex] &&
                    answerArray[index + countIndex].value === '1'
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() =>
                    !isSubmitting &&
                    selectAnswer({ value: '1', number: question.id })
                  }></div>
                <div
                  className={`${radioButtonClass(
                    type === 'parent' ? user.colour : childProfileColour,
                  )}  ${
                    answerArray[index + countIndex] &&
                    answerArray[index + countIndex].value === '2'
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() =>
                    !isSubmitting &&
                    selectAnswer({ value: '2', number: question.id })
                  }></div>
              </div>

              <div className="center-radio-button-container">
                <div
                  className={`${radioButtonClass(
                    type === 'parent' ? user.colour : childProfileColour,
                  )} ${
                    answerArray[index + countIndex] &&
                    answerArray[index + countIndex].value === '3'
                      ? 'selected'
                      : 'tour-test-center'
                  }`}
                  onClick={() =>
                    !isSubmitting &&
                    selectAnswer({ value: '3', number: question.id })
                  }></div>
              </div>

              <div className="tour-test-right">
                <div
                  className={`${radioButtonClass(
                    type === 'parent' ? user.colour : childProfileColour,
                  )} ${
                    answerArray[index + countIndex] &&
                    answerArray[index + countIndex].value === '4'
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() =>
                    !isSubmitting &&
                    selectAnswer({ value: '4', number: question.id })
                  }></div>
                <div
                  className={`${radioButtonClass(
                    type === 'parent' ? user.colour : childProfileColour,
                  )} ${
                    answerArray[index + countIndex] &&
                    answerArray[index + countIndex].value === '5'
                      ? 'selected'
                      : ''
                  }`}
                  onClick={() =>
                    !isSubmitting &&
                    selectAnswer({ value: '5', number: question.id })
                  }></div>
              </div>
            </div>

            {!customSize && (
              <p className="right-label">{question.highest_value_statement}</p>
            )}
          </div>

          {customSize && (
            <div className="labels">
              <p className="left-label">{question.lowest_value_statement}</p>
              <div className="middle-space" />
              <p className="right-label">{question.highest_value_statement}</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const counterIndex = chapter => {
    switch (chapter) {
      case 'section1':
        return 0;
      case 'section2':
        return 10;
      case 'section3':
        return 20;
      case 'section4':
        return 30;
      case 'section5':
        return 40;
      case 'section6':
        return 50;
      case 'section7':
        return 60;
      default:
        return 0;
    }
  };

  //flip the bar colour
  const progressBarColour = colour => {
    switch (colour) {
      case 'blue':
        return '#66C0D9';
      case 'pink':
        return '#FF7E9B';
      case 'green':
        return '#98CC68';
      case 'purple':
        return '#D573F2';
      default:
        return '#66C0D9';
    }
  };

  const openQns2 = () => {
    setTimeout(() => {
      setOpenSection1(false);
      setCountIndex(counterIndex('section2'));
      window.scrollTo(0, 0);
    }, 1000);

    setTimeout(() => {
      setOpenSection2(true);
    }, 1500);
  };

  const openQns3 = () => {
    setTimeout(() => {
      setOpenSection2(false);
      setCountIndex(counterIndex('section3'));
      window.scrollTo(0, 0);
    }, 1000);

    setTimeout(() => {
      setOpenSection3(true);
    }, 1500);
  };

  const openQns4 = () => {
    setTimeout(() => {
      setOpenSection3(false);
      setCountIndex(counterIndex('section4'));
      window.scrollTo(0, 0);
    }, 1000);

    setTimeout(() => {
      setOpenSection4(true);
    }, 1500);
  };

  const openQns5 = () => {
    setTimeout(() => {
      setOpenSection4(false);
      setCountIndex(counterIndex('section5'));
      window.scrollTo(0, 0);
    }, 1000);

    setTimeout(() => {
      setOpenSection5(true);
    }, 1500);
  };

  const openQns6 = () => {
    setTimeout(() => {
      setOpenSection5(false);
      setCountIndex(counterIndex('section6'));
      window.scrollTo(0, 0);
    }, 1000);

    setTimeout(() => {
      setOpenSection6(true);
    }, 1500);
  };

  return (
    <SkipSignupPopup type={types === 'parent' ? 'parent-test' : 'child-test'}>
      <div className={`test-page ${type}`}>
        <Head
          title={
            type === ('parent' || 'parent-result')
              ? ` ${_.isEmpty(user) ? 'Parent Test' : 'Connected'} | ${
                  _.isEmpty(user) ? 'Connected' : 'Parent Test'
                }`
              : ` ${_.isEmpty(user) ? 'Child Test' : 'Connected'} | ${
                  _.isEmpty(user) ? 'Connected' : 'Child Test'
                }`
          }
        />

        {showEndTestPopup && (
          <EndTestPrompt
            history={history}
            type={type}
            isAddChild={addChild}
            setTempUser={setTempUser}
            setShowEndTestPopup={setShowEndTestPopup}
          />
        )}

        <div
          className={
            !tabletSize
              ? `grid-desktop-container`
              : `grid-mobile-container ${
                  type === 'child-result' && 'test-success'
                }`
          }>
          {!tabletSize && (
            <div
              className="back"
              style={{
                display: type !== 'child' && type !== 'parent' && 'none',
              }}
              onClick={() => setShowEndTestPopup(true)}>
              <ChevronRight /> Back
            </div>
          )}

          {(type === 'child' || type === 'parent') && (
            <div className="progress-container">
              <div id="progress-block">
                <div id="progress">
                  <div
                    style={{
                      width: '100%',
                    }}>
                    {tabletSize && (
                      <div
                        className="back"
                        onClick={() => setShowEndTestPopup(true)}>
                        <ChevronRight /> Back
                      </div>
                    )}

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        paddingTop: tabletSize && '30px',
                      }}>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '0 0 40px',
                        }}>
                        <UserIcon
                          style={{ width: customSize && '38px' }}
                          relative={type === 'parent' ? user : childProfile}
                        />

                        <h1 className="section-title">
                          {type === 'child'
                            ? `${capitalize(childProfileName)}'s Profile`
                            : 'Your profile'}
                        </h1>

                        <Tooltip
                          button={<InfoIcon className="test-info-icon" />}
                          position="bottom"
                          text={
                            <div className="test-tooltip-text">
                              <p>Remember:</p>

                              <ul>
                                <li>There are no wrong answers</li>
                                <li>Don't overthink it</li>
                                <li>
                                  Set aside enough time to complete both
                                  profiles
                                </li>
                              </ul>
                            </div>
                          }
                        />
                      </div>

                      <div className="progress-container">
                        <div
                          className="progress"
                          style={{
                            backgroundColor:
                              type === 'parent'
                                ? progressColor[user.colour]
                                : progressColor[childProfileColour],
                          }}>
                          {/* change into question progress */}
                          <div
                            className="progress-bar"
                            style={{
                              width: (counter * 100) / 60 + '%',
                              backgroundColor:
                                type === 'parent'
                                  ? progressBarColour(user.colour)
                                  : progressBarColour(childProfileColour),
                            }}
                          />
                        </div>

                        <p
                          style={{
                            color:
                              type === 'parent'
                                ? progressBarColour(user.colour)
                                : progressBarColour(childProfileColour),
                          }}>
                          {counter}/60
                        </p>
                      </div>
                    </div>

                    <div></div>
                  </div>
                </div>
              </div>

              {openSection1 && (
                <>
                  <div id="question-list">
                    {questions.length > 0 &&
                      questions.map((singleQuestion, index) => {
                        return renderQuestion(singleQuestion, index);
                      })}
                  </div>

                  <ButtonSolid
                    className="next-button"
                    type="button"
                    disabled={!section1Complete}
                    onClick={() => openQns2()}
                    children="Next"
                  />
                </>
              )}

              {openSection2 && (
                <>
                  <div id="question-list">
                    {questions2.length > 0 &&
                      questions2.map((singleQuestion, index) => {
                        return renderQuestion(singleQuestion, index);
                      })}
                  </div>

                  <ButtonSolid
                    className="next-button"
                    type="button"
                    disabled={!section2Complete}
                    onClick={() => openQns3()}
                    children="Next"
                  />
                </>
              )}

              {openSection3 && (
                <>
                  <div id="question-list">
                    {questions3.length > 0 &&
                      questions3.map((singleQuestion, index) => {
                        return renderQuestion(singleQuestion, index);
                      })}
                  </div>

                  <ButtonSolid
                    className="next-button"
                    type="button"
                    disabled={!section3Complete}
                    onClick={() => openQns4()}
                    children="Next"
                  />
                </>
              )}

              {openSection4 && (
                <>
                  <div id="question-list">
                    {questions4.length > 0 &&
                      questions4.map((singleQuestion, index) => {
                        return renderQuestion(singleQuestion, index);
                      })}
                  </div>

                  <ButtonSolid
                    className="next-button"
                    type="button"
                    disabled={!section4Complete}
                    onClick={() => openQns5()}
                    children="Next"
                  />
                </>
              )}

              {openSection5 && (
                <>
                  <div id="question-list">
                    {questions5.length > 0 &&
                      questions5.map((singleQuestion, index) => {
                        return renderQuestion(singleQuestion, index);
                      })}
                  </div>

                  <ButtonSolid
                    className="next-button"
                    type="button"
                    disabled={!section5Complete}
                    onClick={() => openQns6()}
                    children="Next"
                  />
                </>
              )}

              {openSection6 && (
                <>
                  <div id="question-list">
                    {questions6.length > 0 &&
                      questions6.map((singleQuestion, index) => {
                        return renderQuestion(singleQuestion, index);
                      })}
                  </div>

                  <ButtonSolid
                    className="next-button"
                    type="button"
                    disabled={!isFormCompleted || isSubmitting}
                    onClick={handleNext}
                    children={
                      isSubmitting
                        ? 'Generating...'
                        : type === 'parent'
                        ? `Proceed with my child’s profile`
                        : `Generate my Playbook`
                    }
                  />
                </>
              )}
            </div>
          )}
        </div>

        {type !== 'child' && type !== 'parent' && (
          <Result type={type} next={handleNext} />
        )}
      </div>
    </SkipSignupPopup>
  );
};

export default Test;

import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import moment from 'moment';
import { UserContext } from 'context/UserContext';
import { url } from 'config/urlConfig';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import downloadFile from 'shared/function/downloadFile';
import { encryptParams } from 'shared/helper/helper';
import {
  trackingAction,
  TRACKING_PURCHASES,
  GA_EVENT_CATOGARY,
  GA_PURCHASE_JOURNEY_TYPE,
} from 'shared/function/Tracking';
import {
  HISTORY_MODES,
  PAYMENT_METHOD_TYPES,
  GIFTCARD_CAMPAIGN_TYPES,
} from 'shared/const';
import {
  updateUserInfo,
  useDetectPreviousPath,
  getUserTransactionHistory,
  getPdfReceipt,
  handleSettingBack,
} from '../SettingModuleUtils';
import useProductList from 'routes/Checkout/useProductList';
import { listProducts } from 'routes/Checkout/CheckoutUtils';

// components/assets
import { ButtonSolid, ButtonText } from 'shared/components/Button/Button';
import { BackButton, TabBar } from './SettingsComponent';
import { FormError } from 'routes/Auth/AuthComponents';
import PasswordField from 'shared/components/PasswordField/PasswordField';
import DeleteAccountModal from './DeleteAccountModal';
import { ReactComponent as Checked } from 'assets/general/checked-plain-icon.svg';
import AccessImgDemoWeb from 'assets/settings/settings-account-demo-access-web.png';
import AccessImgDemoMob from 'assets/settings/settings-account-demo-access-mob.png';
import AccessImgPremiumWeb from 'assets/settings/settings-account-premium-access-web.png';
import AccessImgPremiumMob from 'assets/settings/settings-account-premium-access-mob.png';
import AccessTransactionIcon from 'assets/settings/settings-account-access-transaction-icon.png';
import './Account.scss';

const Account = ({ history }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const toTab = params.get('tab');
  const isToTabDetails = _.isEqual(toTab, 'details');

  const tabs = [
    {
      label: 'Access',
      value: 'access',
      type: 'left',
      isSelected: isToTabDetails ? false : true,
    },
    {
      label: 'Details',
      value: 'details',
      type: 'right',
      isSelected: isToTabDetails ? true : false,
    },
  ];

  const [accountItems, setAccountItems] = useState(tabs);
  const [selectedAccountItem, setSelectedAccountItem] = useState(
    isToTabDetails ? 'details' : 'access',
  );
  const [transactionHistories, setTransactionHistories] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);
  const [productList, setProductList] = useState([]);

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const handleShowTabContent = ({ item }) => {
    const updatedAccounts = accountItems.map(account => {
      if (account.value === item.value) {
        return { ...account, isSelected: true };
      } else if (account.isSelected) {
        return { ...account, isSelected: false };
      }
      return account;
    });

    setAccountItems(updatedAccounts);
    setSelectedAccountItem(item.value);

    setErrorMessage(null);
    setSuccessMessage(null);
  };

  const getUserTransactionHistoryData = async () => {
    try {
      const res = await getUserTransactionHistory();

      const transactionHistoryData = res?.data?.data;
      setTransactionHistories(transactionHistoryData);
    } catch (err) {
      console.log(
        '🚀 ~ file: Account.js:82 ~ getUserTransactionHistoryData ~ err:',
        err?.response,
      );
    }
  };

  const getProducts = async () => {
    try {
      const res = await listProducts();
      const productListData = res?.data?.data;
      setProductList(productListData);
    } catch (err) {
      console.log('🚀 ~ file: Account.js:82 ~ getProducts ~ err:', err);
    }
  };

  useEffect(() => {
    getUserTransactionHistoryData();
    getProducts();
  }, []);

  return (
    <div id="settings-account">
      <div className="user-form">
        {isMobile && (
          <BackButton
            title="Settings"
            handleClick={() =>
              handleSettingBack({ history, location, type: 'tab', isMobile })
            }
          />
        )}
        <h1 className="header">Manage Account</h1>

        <div className="settings-account-container">
          <TabBar
            items={accountItems}
            handleShowTabContent={handleShowTabContent}
          />

          {selectedAccountItem && (
            <TabContent
              history={history}
              location={location}
              selectedAccountItem={selectedAccountItem}
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              setOpenDeleteAccountModal={setOpenDeleteAccountModal}
              transactionHistories={transactionHistories}
              productList={productList}
            />
          )}
        </div>
      </div>

      {openDeleteAccountModal && (
        <DeleteAccountModal
          history={history}
          setOpenModal={setOpenDeleteAccountModal}
        />
      )}
    </div>
  );
};

const TabContent = ({
  history,
  location,
  selectedAccountItem,
  successMessage,
  setSuccessMessage,
  errorMessage,
  setErrorMessage,
  setOpenDeleteAccountModal,
  transactionHistories,
  productList,
}) => {
  const { user, setUser } = useContext(UserContext);

  const { userPremiumEndDate, isUserPremiumPermanent, isUserDemo } =
    useUserPremiumAccess();

  const { previousPath } = useDetectPreviousPath({
    location,
  });

  const { selectedProduct } = useProductList();

  const defaultFormValue = {
    email: user.email,
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const [formValue, setFormValue] = useState(defaultFormValue);
  const [submitDisable, setSubmitDisable] = useState(true);

  const isTabAccess = _.isEqual(selectedAccountItem, 'access');
  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const subscriptionEndDate = moment(userPremiumEndDate).format('DD MMMM YYYY');

  const accessImg = isUserDemo
    ? (isMobileView && AccessImgDemoMob) || AccessImgDemoWeb
    : (isMobileView && AccessImgPremiumMob) || AccessImgPremiumWeb;

  const handlePremiumAccess = async () => {
    if (isUserDemo) {
      trackingAction({
        category: GA_EVENT_CATOGARY.ecommerce,
        action: TRACKING_PURCHASES.view_premium_upgrade,
        ecommerceEventType: 'view_item',
        ecommerceEventData: {},
        extraData: {
          user_id: user?.id,
        },
      });

      history.push(`${url.UPGRADE_PREMIUM}?from=${previousPath}`);
    } else {
      trackingAction({
        category: GA_EVENT_CATOGARY.ecommerce,
        action: TRACKING_PURCHASES.view_child_profile,
        ecommerceEventType: 'view_item',
        ecommerceEventData: {},
        extraData: {
          user_id: user?.id,
        },
      });

      history.push(`${url.ADD_CHILD}?from=${previousPath}`);
    }
  };

  const handleDownloadReceipt = async ({ selectedTransaction }) => {
    const transactionOrderId = selectedTransaction?.order_id;
    const transactionDate = moment(
      selectedTransaction?.created_at,
      'YYYY-MM-DD',
    ).format('YYYYMMDD');

    const receiptFileName = `Receipt_Connected_${transactionDate}`;

    try {
      const res = await getPdfReceipt({ orderId: transactionOrderId });
      const pdfContent = res?.data;

      downloadFile({
        source: pdfContent,
        fileName: receiptFileName,
      });
    } catch (err) {
      console.log('🚀 ~ handleDownloadReceipt ~ err:', err?.response);
    }
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  const submitForm = async e => {
    e.preventDefault();

    setSuccessMessage('');
    setErrorMessage(null);
    const encryptedOldPassword = encryptParams(formValue.oldPassword);
    const encryptedNewPassword = encryptParams(formValue.newPassword);
    const encryptedCfmPassword = encryptParams(formValue.confirmPassword);

    const valueToSubmit = {
      email: formValue.email,
      old_password: encryptedOldPassword,
      new_password: encryptedNewPassword,
      confirm_new_password: encryptedCfmPassword,
    };

    try {
      await updateUserInfo({ ...user, ...valueToSubmit });
      setUser({ ...user, ...valueToSubmit });

      setSubmitDisable(true);
      setSuccessMessage('Changes successfully saved');

      // Reset formValue to default values
      setFormValue(defaultFormValue);
    } catch (err) {
      const { message } = err.response.data;

      setErrorMessage({
        message: message,
        type: 'error',
      });
    }
  };

  const getPaymentMethodType = data => {
    const { payment_method_type: paymentMethodType, mode } = data;

    if (paymentMethodType) {
      const processedType =
        PAYMENT_METHOD_TYPES[paymentMethodType.toUpperCase()];

      if (processedType) {
        return processedType;
      }
    }

    return mode;
  };

  const getMode = data => {
    const { mode } = data;

    switch (mode.toUpperCase()) {
      // Referral
      case HISTORY_MODES.MODE_REFERRER:
        return 'Referrer Bonus';
      case HISTORY_MODES.MODE_REFERRED:
        return 'Referral Bonus';
      // Expiry log
      case HISTORY_MODES.MODE_REACTIVATION:
        return 'Premium access reactivated';
      case HISTORY_MODES.MODE_EXPIRY:
        return 'Premium access expired';
      case HISTORY_MODES.MODE_GIFT:
        return 'Premium access gifted';
      // Payments
      case HISTORY_MODES.MODE_STRIPE:
        return getPaymentMethodType(data);
      case HISTORY_MODES.MODE_PAYPAL:
        return PAYMENT_METHOD_TYPES.PAYPAL;
      case HISTORY_MODES.MODE_ANDROID:
      case HISTORY_MODES.MODE_IOS:
        return 'In app purchase';
      case HISTORY_MODES.MODE_PREMIUM_ACCESS_CODE:
      case HISTORY_MODES.MODE_GIFT_CODE:
        return 'Code redemption';
      case HISTORY_MODES.MODE_USER_CREATION:
        return 'Account Created';
      default:
        return mode;
    }
  };

  const getProduct = data => {
    const { product_id: productId } = data;

    if (productId && productList.length > 0) {
      const foundProduct = productList.find(
        product => product.id === productId,
      );

      if (foundProduct) {
        return foundProduct.name;
      }
    }

    return null;
  };

  const getNumberOfDays = data => {
    const { number_of_days: numberOfDays, remarks } = data;

    // Under very specific conditions, the remarks will be returned
    // Remarks cannot normally be entered by the Admins and has to be done via the Backend
    if (remarks && remarks.length > 0) {
      return remarks;
    }

    if (numberOfDays && numberOfDays > 0) {
      return `${numberOfDays} days Premium access`;
    }
    return null;
  };

  const getGiftCodeItem = data => {
    const {
      giftcard_type: giftCardType,
      number_of_days: numberOfDays,
      book_quantity: bookQuantity,
      remarks,
    } = data;

    const playbookItem =
      productList.length > 0
        ? productList.find(item => item.type === 'PLAYBOOK')
        : null;

    // Under very specific conditions, the remarks will be returned
    // Remarks cannot normally be entered by the Admins and has to be done via the Backend
    if (remarks && remarks.length > 0) {
      return remarks;
    }

    if (giftCardType === GIFTCARD_CAMPAIGN_TYPES.CHILD) {
      if (playbookItem) {
        return `${bookQuantity > 1 ? `${bookQuantity}x ` : ''}${
          playbookItem.name
        }`;
      }
    }

    if (giftCardType === GIFTCARD_CAMPAIGN_TYPES.PREMIUM_ACCESS_AND_CHILD) {
      if (playbookItem) {
        return `${bookQuantity > 1 ? `${bookQuantity}x ` : ''}${
          playbookItem.name
        } + ${numberOfDays} ${
          numberOfDays > 1 ? 'days' : 'day'
        } Premium access`;
      }
    }

    if (
      giftCardType === GIFTCARD_CAMPAIGN_TYPES.SINGLE_USE_PREMIUM_ACCESS ||
      giftCardType === GIFTCARD_CAMPAIGN_TYPES.MULTI_USE_PREMIUM_ACCESS
    ) {
      if (playbookItem) {
        return `${numberOfDays} ${
          numberOfDays > 1 ? 'days' : 'day'
        } Premium access`;
      }
    }

    return null;
  };

  useEffect(() => {
    const { oldPassword, newPassword, confirmPassword } = formValue;

    const passwordRE =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W|_]).{8,}$/;

    if (oldPassword || newPassword || confirmPassword) {
      setSubmitDisable(true);

      if (!oldPassword) {
        setErrorMessage({
          message: 'Current password is required',
          type: 'password-old',
        });
      } else if (oldPassword && !passwordRE.test(oldPassword)) {
        setErrorMessage({
          message: 'The password format is invalid',
          type: 'password-old',
        });
      } else if (newPassword && !passwordRE.test(newPassword)) {
        setErrorMessage({
          message: 'The password format is invalid',
          type: 'password',
        });
      } else if (!confirmPassword) {
        setSubmitDisable(true);
      } else if (
        newPassword &&
        confirmPassword &&
        newPassword !== confirmPassword
      ) {
        setErrorMessage({
          message: 'Both passwords should match',
          type: 'password-match',
        });
      } else {
        setSubmitDisable(false);
      }
    } else {
      setSubmitDisable(true);
      setErrorMessage(null);
    }
  }, [formValue, setErrorMessage]);

  const renderDescription = data => {
    const { mode } = data;

    switch (mode.toUpperCase()) {
      // Those with product attached
      case HISTORY_MODES.MODE_STRIPE:
      case HISTORY_MODES.MODE_PAYPAL:
      case HISTORY_MODES.MODE_ANDROID:
      case HISTORY_MODES.MODE_IOS:
        return getProduct(data);
      // Those with number of days attached
      case HISTORY_MODES.MODE_PREMIUM_ACCESS_CODE:
      case HISTORY_MODES.MODE_GIFT:
      case HISTORY_MODES.MODE_REFERRER:
      case HISTORY_MODES.MODE_REFERRED:
        return getNumberOfDays(data);
      // Those that revert to basic access
      case HISTORY_MODES.MODE_EXPIRY:
      case HISTORY_MODES.MODE_USER_CREATION:
        return 'Basic Access';
      // Those that don't have anything
      case HISTORY_MODES.MODE_GIFT_CODE:
        return getGiftCodeItem(data);
      case HISTORY_MODES.MODE_REACTIVATION:
      default:
        return null;
    }
  };

  const TransactionHistoryItem = ({ transaction }) => {
    const mode = getMode(transaction);
    const description = renderDescription(transaction);

    const transactionDate = moment(transaction?.created_at).format(
      'DD MMMM YYYY',
    );
    const transactionOrderId = transaction?.order_id;

    return (
      <div className="transaction-history-item">
        <div className="transaction-history-item-content">
          <p className="transaction-history-item-date">
            {transactionDate} - {mode}
          </p>
          {description && description.length > 0 && (
            <p className="transaction-history-item-access">{description}</p>
          )}
        </div>

        {transactionOrderId && mode !== 'In app purchase' && (
          <img
            className="transaction-history-item-icon"
            src={AccessTransactionIcon}
            alt="Transaction History"
            onClick={() =>
              handleDownloadReceipt({ selectedTransaction: transaction })
            }
          />
        )}
      </div>
    );
  };

  const handleCheckoutPremiumExtend = () => {
    trackingAction({
      category: GA_EVENT_CATOGARY.ecommerce,
      action: TRACKING_PURCHASES.view_premium_upgrade,
      ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.view_item,
      ecommerceEventData: { productData: selectedProduct },
      extraData: {
        user_id: user?.id,
      },
    });

    history.push(`${url.UPGRADE_PREMIUM}?from=${previousPath}`);
  };

  return (
    <div className="settings-account-form">
      {isTabAccess ? (
        <>
          {isUserDemo ? (
            <div
              className={`settings-account-access-content demo
              }`}>
              <img
                className="access-content-img"
                src={accessImg}
                alt="Access"
              />

              <div className="settings-account-access-description">
                <h3 className="access-content-text">
                  {!isMobileView
                    ? 'You are currently not subscribed to Connected'
                    : 'You are currently on Basic mode'}
                </h3>
                <ButtonSolid
                  className="submit-btn account access"
                  children="Upgrade to Premium"
                  onClick={() => handlePremiumAccess()}
                />
              </div>
            </div>
          ) : (
            <div className={`settings-account-access-content premium`}>
              {isUserPremiumPermanent ? (
                <p className="access-content-text">
                  You are entitled to a lifetime access to The Connected
                  Playbook!
                </p>
              ) : (
                <p className="access-content-text">
                  Your Premium access expires on {subscriptionEndDate}.
                </p>
              )}

              <div className="access-content-img-container">
                <img
                  className="access-content-img premium"
                  src={accessImg}
                  alt="Access"
                />
              </div>

              {!isUserPremiumPermanent && (
                <ButtonSolid
                  className="submit-btn account access"
                  children="Extend Premium access"
                  onClick={() => handleCheckoutPremiumExtend()}
                />
              )}

              <div className="access-content-transaction-history">
                <h3 className="transaction-history-label">
                  Transaction History
                </h3>

                {transactionHistories?.map(transaction => {
                  return (
                    <div className="transaction-history-item-container">
                      <TransactionHistoryItem transaction={transaction} />
                      <div className="transaction-history-item-divider" />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="settings-account-details-content">
          <div className="account-form-group">
            <label>Email Address</label>
            <input
              className="email"
              name="email"
              type="text"
              placeholder="Email"
              value={formValue.email}
              onChange={handleChange}
              disabled
            />
          </div>

          <div className="account-form-group">
            <label>Change Password</label>

            <PasswordField
              label="Current Password"
              name="oldPassword"
              value={formValue.oldPassword}
              handleChange={handleChange}
              isError={errorMessage?.type === 'password-old'}
              error={errorMessage?.message}
            />

            <PasswordField
              label="New Password"
              name="newPassword"
              value={formValue.newPassword}
              handleChange={handleChange}
              isError={errorMessage?.type === 'password'}
              error={errorMessage?.message}
            />

            <PasswordField
              label="Confirm New Password"
              name="confirmPassword"
              value={formValue.confirmPassword}
              handleChange={handleChange}
              confirmPassword
              isError={errorMessage?.type === 'password-match'}
              error={errorMessage?.message}
            />
          </div>
          {successMessage && (
            <p className="success-message">
              <Checked /> {successMessage}
            </p>
          )}
          <FormError
            message={errorMessage?.type === 'error' && errorMessage?.message}
          />

          <ButtonSolid
            className="submit-btn account"
            onClick={submitForm}
            disabled={submitDisable}
            children="Save Changes"
          />

          <ButtonText
            className="delete-btn"
            onClick={() => setOpenDeleteAccountModal(true)}
            children="Delete Account"
          />
        </div>
      )}
    </div>
  );
};

export default withRouter(Account);

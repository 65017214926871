import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import { UserContext } from 'context/UserContext';
import { url } from 'config/urlConfig';
import { colorObjectV2 } from 'shared/const';
import { capitalize } from 'shared/helper/helper';
import { trackingAction, TRACKING_TESTS } from 'shared/function/Tracking';
import { updateUser } from 'routes/ProfileTest/ProfileTestUtils';

// components/assets
import Head from 'shared/components/Head.js';
import { ButtonSolid } from 'shared/components/Button/Button';
import { ReactComponent as IconCircle } from 'assets/icons/icon_circle.svg';
import { ReactComponent as IconTriangle } from 'assets/icons/icon_triangle.svg';
import { ReactComponent as IconSquare } from 'assets/icons/icon_square.svg';
import { ReactComponent as IconDiamond } from 'assets/icons/icon_diamond.svg';
import { ReactComponent as Checked } from 'assets/general/checked-plain-icon.svg';
import { Check } from '@material-ui/icons';

const ParentProfileScreen = ({
  history,
  formValueParent,
  setFormValueParent,
}) => {
  const { setTempUser, user, setUser } = useContext(UserContext);

  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const submitForm = async () => {
    const capitalizeName = capitalize(formValueParent.name);
    const newFormValue = { ...formValueParent, name: capitalizeName };

    try {
      await updateUser(newFormValue);
      setUser({ ...user, ...newFormValue });
      setTempUser({ ...newFormValue });
      setIsSubmitting(true);
      setSuccess('Account details submitted.');

      trackingAction({
        action: TRACKING_TESTS.start_parent_test,
        extraData: {
          user_id: user?.id,
        },
      });

      setTimeout(() => {
        history.push(`/test-loading`);
      }, 1000);
    } catch (err) {
      const { message } = err.response.data;
      setError(message);
    }
  };

  const handleChange = e => {
    const { value, name } = e.target;

    if (name === 'birthday' && value === 'DD/MM/YYYY') {
      return; // Exit early if the date format is invalid
    }

    setFormValueParent({ ...formValueParent, [name]: value });
    setError('');
  };

  const colorShape = (value, color) => {
    switch (value) {
      case 'square':
        return <IconSquare style={{ fill: colorObjectV2[color] }} />;
      case 'circle':
        return <IconCircle style={{ fill: colorObjectV2[color] }} />;
      case 'triangle':
        return <IconTriangle style={{ fill: colorObjectV2[color] }} />;
      case 'diamond':
        return <IconDiamond style={{ fill: colorObjectV2[color] }} />;
      default:
        return <IconCircle style={{ fill: colorObjectV2[color] }} />;
    }
  };

  const OnClickShape = shape => {
    setFormValueParent({ ...formValueParent, shape: shape });
    setError('');
  };

  const OnClickColour = color => {
    setFormValueParent({ ...formValueParent, colour: color });
    setError('');
  };

  useEffect(() => {
    if (user.parent_answer_saved === 1 || user.parent_test_taken === 1) {
      history.push(`${url.HOME}`);
    }
  }, [user.parent_answer_saved, user.parent_test_taken, history]);

  useEffect(() => {
    const validate = () => {
      let emptyCount = 0;
      Object.entries(formValueParent).forEach(item => {
        if (!item[1]) emptyCount++;
      });

      if (emptyCount > 0) {
        setIsFormCompleted(false);
      } else {
        setIsFormCompleted(true);
      }
    };
    validate();
  }, [formValueParent]);

  return (
    <div className="right-content signup">
      <Head
        title={
          _.isEmpty(user)
            ? 'Parent Profile | Connected'
            : 'Connnected | Parent Profile'
        }
      />

      <div className="auth-form-container">
        <div className="form">
          <div className="form-group-name">
            <label>Name</label>
            <input
              name="name"
              type="text"
              maxlength="20"
              value={formValueParent.name}
              onChange={handleChange}
              placeholder="Your Name"
              style={{ textTransform: 'capitalize' }}
            />
          </div>
          <div className="form-group-inline">
            <div className="form-group">
              <label>I'm a</label>
              <div className="selection-switch-container">
                {Object.entries({ father: 'Dad', mother: 'Mom' }).map(item => (
                  <div
                    key={item[0]}
                    className={`selection ${
                      formValueParent?.parent === item[0] ? 'selected' : ''
                    }`}
                    onClick={() =>
                      setFormValueParent({
                        ...formValueParent,
                        parent: item[0],
                      })
                    }>
                    {item[1]}
                  </div>
                ))}
              </div>
            </div>
            <div className="form-group">
              <label>Born on</label>
              <InputMask
                name="birthday"
                mask="99/99/9999"
                maskPlaceholder="DD/MM/YYYY"
                onChange={handleChange}
                value={formValueParent.birthday}
                placeholder="DD/MM/YYYY"
              />
            </div>
          </div>

          <div className="form-group">
            <label style={{ marginBottom: '12px' }}>Choose a shape</label>
            <div className="shape-container">
              <div
                className={`single-shape ${
                  formValueParent.shape === 'circle' ? 'selected' : ''
                }`}
                onClick={() => OnClickShape('circle')}>
                <IconCircle
                  style={{
                    fill:
                      formValueParent.colour === ''
                        ? '#e8e8e8'
                        : colorObjectV2[formValueParent.colour],
                    opacity: formValueParent.colour === '' && '1',
                    cursor: 'default',
                  }}
                />{' '}
                <Checked className="selected-icon" />
              </div>
              <div
                className={`single-shape ${
                  formValueParent.shape === 'triangle' ? 'selected' : ''
                }`}
                onClick={() => OnClickShape('triangle')}>
                <IconTriangle
                  style={{
                    fill:
                      formValueParent.colour === ''
                        ? '#e8e8e8'
                        : colorObjectV2[formValueParent.colour],
                    opacity: formValueParent.colour === '' && '1',
                    cursor: 'default',
                  }}
                />
                <Checked className="selected-icon-triangle" />
              </div>
              <div
                className={`single-shape ${
                  formValueParent.shape === 'diamond' ? 'selected' : ''
                }`}
                onClick={() => OnClickShape('diamond')}>
                <IconDiamond
                  style={{
                    fill:
                      formValueParent.colour === ''
                        ? '#e8e8e8'
                        : colorObjectV2[formValueParent.colour],
                    opacity: formValueParent.colour === '' && '1',
                    cursor: 'default',
                  }}
                />
                <Checked className="selected-icon" />
              </div>
              <div
                className={`single-shape ${
                  formValueParent.shape === 'square' ? 'selected' : ''
                }`}
                onClick={() => OnClickShape('square')}>
                <IconSquare
                  style={{
                    fill:
                      formValueParent.colour === ''
                        ? '#e8e8e8'
                        : colorObjectV2[formValueParent.colour],
                    opacity: formValueParent.colour === '' && '1',
                    cursor: 'default',
                  }}
                />
                <Checked className="selected-icon" />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label style={{ margin: '12px 0px' }}>Choose a colour</label>
            <div className="color-container">
              <div
                className={`single-color ${
                  formValueParent.colour === 'blue' ? 'selected' : ''
                }`}
                onClick={() => OnClickColour('blue')}>
                {colorShape(formValueParent.shape, 'blue')}{' '}
                <Checked
                  className={`${
                    formValueParent.shape === 'triangle'
                      ? 'selected-icon-triangle'
                      : 'selected-icon'
                  }`}
                />
              </div>
              <div
                className={`single-color ${
                  formValueParent.colour === 'purple' ? 'selected' : ''
                }`}
                onClick={() => OnClickColour('purple')}>
                {colorShape(formValueParent.shape, 'purple')}
                <Checked
                  className={`${
                    formValueParent.shape === 'triangle'
                      ? 'selected-icon-triangle'
                      : 'selected-icon'
                  }`}
                />
              </div>
              <div
                className={`single-color ${
                  formValueParent.colour === 'pink' ? 'selected' : ''
                }`}
                onClick={() => OnClickColour('pink')}>
                {colorShape(formValueParent.shape, 'pink')}
                <Checked
                  className={`${
                    formValueParent.shape === 'triangle'
                      ? 'selected-icon-triangle'
                      : 'selected-icon'
                  }`}
                />
              </div>
              <div
                className={`single-color ${
                  formValueParent.colour === 'green' ? 'selected' : ''
                }`}
                onClick={() => OnClickColour('green')}>
                {colorShape(formValueParent.shape, 'green')}
                <Checked
                  className={`${
                    formValueParent.shape === 'triangle'
                      ? 'selected-icon-triangle'
                      : 'selected-icon'
                  }`}
                />
              </div>
            </div>
          </div>

          {error !== '' && (
            <div className="error-container">
              <p>{error}</p>
            </div>
          )}

          {success !== '' && (
            <div className="success-container">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Check style={{ marginRight: '20px' }} />
                <p> {success}</p>
              </div>
            </div>
          )}

          <ButtonSolid
            onClick={submitForm}
            className="submit"
            disabled={!isFormCompleted || isSubmitting}
            children={isSubmitting ? 'Submitting...' : 'Start your profile'}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ParentProfileScreen);

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { url } from 'config/urlConfig';
import { TESTING_GENERAL_ID } from 'shared/const/TestAutomationIds';
import { navigatePlaybooks } from 'routes/Books/BooksUtils';

// components/assets
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import { ButtonSolid } from 'shared/components/Button/Button';
import { ReactComponent as CloseNormal } from 'assets/general/close-normal-icon.svg';
import { ReactComponent as CloseStrong } from 'assets/general/close-strong-icon.svg';
import NoAccessIcon from 'assets/general/no-access-icon.png';
import './NoAccessModal.scss';

const NoAccessModal = ({
  history,
  isAddChild,
  isPreviewBtn,
  handleClose,
  className,
  ...otherProps
}) => {
  const previewChapterResponseData = JSON.parse(
    sessionStorage.getItem('previewChapterResponseData'),
  );
  const { previewChapterData, childs } = previewChapterResponseData;

  const location = useLocation();
  const path = location.pathname;

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const mainTitle = isAddChild
    ? 'To add an additional child profile:'
    : 'This is a Premium feature';

  const subTitle = isAddChild ? (
    <p className="subtitle-top">
      Upgrade to Premium to access your Playbook, and for the option to add more
      child profiles.
    </p>
  ) : (
    <>
      <p className="subtitle-top">You are currently in Basic mode.</p>
      <p className="subtitle-bottom">
        Upgrade to or preview your Premium content.
      </p>
    </>
  );

  const handleUpgrade = () => {
    history.push(`${url.UPGRADE_PREMIUM}?from=${path?.substring(1)}`);
  };

  const handlePreview = () => {
    const bookId = previewChapterData?.book_id;
    const sectionId = previewChapterData?.section_id;
    const chapterId = previewChapterData?.chapter_id;

    navigatePlaybooks({
      navigate: history.push,
      type: 'chapter',
      domainSlug: 'purpose',
      chapterSlug: 'preparedness',
      bookId,
      sectionId,
      chapterId,
    });

    sessionStorage.setItem(
      'selectedRelative',
      JSON.stringify(childs && childs[0]),
    );
  };

  const Buttons = () => {
    const buttons = isAddChild
      ? [
          {
            className: 'upgrade-button',
            children: 'Manage Premium Access',
            onClick: handleUpgrade,
          },
        ]
      : isPreviewBtn
      ? [
          {
            className: 'upgrade-button',
            children: 'Upgrade',
            onClick: handleUpgrade,
            testingAutoId: 'premium-upgradebtn',
          },
          {
            className: 'preview-button',
            children: 'Preview',
            onClick: handlePreview,
            testingAutoId: 'premium-previewbtn',
          },
        ]
      : [
          {
            className: 'upgrade-button',
            children: 'Upgrade',
            onClick: handleUpgrade,
            testingAutoId: 'premium-upgradebtn',
          },
        ];

    return (
      <div
        className={`no-access-modal-button-container ${
          isAddChild && 'add-child'
        }`}>
        {buttons?.map(item => {
          return (
            <ButtonSolid
              className={item?.className}
              onClick={item?.onClick}
              children={item?.children}
              data-test-id={item?.testingAutoId}
            />
          );
        })}
      </div>
    );
  };

  return (
    <div id="no-access">
      <div
        className={`no-access-modal ${className}`}
        onClick={() => handleClose(false)}
        {...otherProps}>
        <div className="no-access-close-container">
          {isMobileView && (
            <CloseStrong
              data-test-id={TESTING_GENERAL_ID.NO_ACCESS_POPUP_CLOSE_BUTTON}
              className="no-access-close-btn"
              onClick={() => handleClose(false)}
            />
          )}

          {!isMobileView && (
            <CloseNormal
              data-test-id={TESTING_GENERAL_ID.NO_ACCESS_POPUP_CLOSE_BUTTON}
              className="no-access-close-btn"
              onClick={() => handleClose(false)}
            />
          )}

          <div
            className="no-access-modal-container"
            onClick={e => e.stopPropagation()}>
            <div className="no-access-popup-container">
              <div className="no-access-popup-content-container image-container">
                <img src={NoAccessIcon} alt="Lock" />
              </div>
            </div>

            {previewChapterData ? (
              <div className="no-access-sub-content-container">
                <h2>{mainTitle}</h2>
                {subTitle}

                <Buttons />
              </div>
            ) : (
              <div className="no-access-sub-content-container">
                <LoadingIndicator isLoaderOnly />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

NoAccessModal.defaultProps = {
  handleBuy: () => {},
  handleClose: () => {},
};
export default NoAccessModal;

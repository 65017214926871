import React, { useState, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './PasswordField.scss';

// asset
import { ReactComponent as Visibility } from 'assets/auth/feather-eye-on-icon.svg';
import { ReactComponent as VisibilityOff } from 'assets/auth/feather-eye-off-icon.svg';
import { ErrorOutline } from '@material-ui/icons';

const PasswordField = ({
  name,
  label,
  value,
  handleChange,
  confirmPassword,
  setIsShowPasswordRule,
  isError,
  error,
  ...otherProps
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [showRule, setShowRule] = useState(false);
  const nodeRef = useRef();

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const renderPasswordRule = () => {
    // if (confirmPassword) {
    //     return null
    // }

    return (
      <>
        <h2>Your password should include</h2>
        <ul>
          <li>At least 1 uppercase alphabet</li>
          <li>At least 1 lowercase alphabet</li>
          <li>At least 1 number</li>
          <li>At least 1 special character</li>
        </ul>
      </>
    );
  };

  useEffect(() => {
    if (setIsShowPasswordRule) {
      if (showRule && !confirmPassword) setIsShowPasswordRule(true);
      else setIsShowPasswordRule(false);
    }
  }, [showRule, confirmPassword, setIsShowPasswordRule]);

  return (
    <>
      <div className="form-group password" {...otherProps}>
        <input
          className={`${isError ? 'error' : ''}`}
          type={passwordShown ? 'text' : 'password'}
          autoComplete="off"
          name={name}
          placeholder={label}
          value={value}
          onChange={e => handleChange(e)}
          onBlur={() => setShowRule(false)}
          onFocus={() => setShowRule(true)}
        />

        <button
          type="button"
          className={`password-reveal-btn ${isError && 'error'}`}
          onClick={togglePasswordVisiblity}>
          {passwordShown ? <Visibility /> : <VisibilityOff />}
        </button>

        {isError && <ErrorOutline className={`input-error-icon password`} />}

        {!confirmPassword && (
          <CSSTransition
            nodeRef={nodeRef}
            in={showRule}
            timeout={0}
            classNames="display"
            unmountOnExit
            appear>
            <div
              className={`password-rule-container ${isError && 'error'}`}
              ref={nodeRef}>
              {renderPasswordRule()}
            </div>
          </CSSTransition>
        )}
      </div>

      {isError && <p className="input-error">{error}</p>}
    </>
  );
};

export default PasswordField;

import { useEffect } from "react";

const useStickyHeader = ({ headerSelector, otherItemSelector }) => {
  useEffect(() => {
    const updateHeaderPosition = () => {
      const headerElement = document.querySelector(headerSelector);
      const otherItemElement = document.querySelector(otherItemSelector);

      if (headerElement && otherItemElement) {
        const rect = otherItemElement.getBoundingClientRect();
        const topOffset = rect.top;

        if (topOffset <= 0) {
          headerElement.style.top = "0";
        } else {
          headerElement.style.top = `${topOffset}px`;
        }
      }
    };

    window.addEventListener("scroll", updateHeaderPosition);

    return () => {
      window.removeEventListener("scroll", updateHeaderPosition);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerSelector, otherItemSelector]);
};

export default useStickyHeader;

import useStickyHeader from 'shared/hooks/useStickyHeader';
import { ReactComponent as ChevronRight } from 'assets/home/chevron-right.svg';
import './TopicsComponent.scss';

export const TopicsWrapper = ({
  backBtnText,
  backBtnNavigation,
  children,
  hideMainNavigation,
  ...otherProps
}) => {
  useStickyHeader({
    headerSelector: '.back-wrapper',
    otherItemSelector: '#react-smartbanner',
  });

  return (
    <div
      id="topics-wrapper"
      className={`${hideMainNavigation && 'hide-main-navigation'}`}
      {...otherProps}>
      <div
        className={`wrapper-container ${
          hideMainNavigation && 'hide-main-navigation'
        }`}>
        <div className="back-wrapper">
          <div className="back-btn">
            <ChevronRight className="back-icon" onClick={backBtnNavigation} />{' '}
            <div className="back-text" onClick={backBtnNavigation}>
              {' '}
              {backBtnText}
            </div>
          </div>
        </div>

        <div className="content-wrapper">{children}</div>
      </div>
    </div>
  );
};

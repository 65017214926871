import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const NotFound = () => {
  const history = useHistory();

  useEffect(() => {
    history.replace("/");
  }, [history]);

  return null;

  // return (
  //   <div>
  //     <h1>404 - Not Found!</h1>
  //   </div>
  // );
};

export default NotFound;

import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { UserContext } from 'context/UserContext';
import { encryptParams } from 'shared/helper/helper';
import PasswordField from 'shared/components/PasswordField';
import { ButtonSolid } from 'shared/components/Button/Button';
import { FormHeader, FormInput, FormError } from './AuthComponents';
import { resetPassword } from './authUtils';

const ResetPassword = ({ history }) => {
  const [formValue, setFormValue] = useState({
    resetCode: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState('');
  const [submitDisable, setSubmitDisable] = useState(true);
  const [isShowPasswordRule, setIsShowPasswordRule] = useState(false);
  const { setNotification } = useContext(UserContext);

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const submitForm = async e => {
    e.preventDefault();
    const { resetCode, password } = formValue;
    const encryptedPassword = encryptParams(password);

    const bodyFormData = new FormData();
    bodyFormData.append('reset_code', resetCode);
    bodyFormData.append('password', encryptedPassword);

    try {
      const response = await resetPassword(bodyFormData);
      if (response) {
        setNotification({
          title: 'All done!',
          message: 'Login again with your new password.',
          btnLabel: 'Done',
          btnColor: 'primary',
          showSecondaryClose: false,
          primaryFunction: () => {
            history.push('/login');
          },
        });
      }
    } catch (err) {
      if (err) {
        const errMessage = err?.response?.data?.message;

        if (errMessage?.includes('code')) {
          setError({ message: err?.response?.data?.message, type: 'code' });
        } else {
          setError({
            message: err?.response?.data?.message,
            type: 'error',
          });
        }
      }
    }
  };

  const handleChange = e => {
    const { value, name } = e.target;
    setFormValue({ ...formValue, [name]: value });
    setError('');
  };

  useEffect(() => {
    const { resetCode, password, validatePassword } = formValue;
    const passwordRE =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W|_]).{8,}$/;

    if (resetCode || password || validatePassword) {
      setSubmitDisable(true);

      if (resetCode === '') {
        setError({
          message: 'Reset Code is required',
          type: 'code',
        });
      } else if (password && !passwordRE.test(password)) {
        setError({
          message: 'The password format is invalid',
          type: 'password',
        });
      } else if (
        password &&
        validatePassword &&
        password !== validatePassword
      ) {
        setError({
          message: 'Both passwords should match',
          type: 'password-match',
        });
      } else {
        setSubmitDisable(false);
      }
    } else {
      setSubmitDisable(true);
      setError(null);
    }
  }, [formValue]);

  return (
    <div
      className={`right-content-space ${
        isShowPasswordRule && 'password-rule'
      }`}>
      <div className="form-content">
        <FormHeader
          title="Forgot password"
          desc={
            isMobile &&
            'If the email you have entered is registered to an account, a reset link will be sent to it.'
          }
          backButton
          backButtonClick={() => history.goBack()}
        />

        <form onSubmit={submitForm}>
          <div className="form-group">
            <FormInput
              type="text"
              autoComplete="off"
              name="resetCode"
              onChange={handleChange}
              placeholder="Reset code"
              isError={error?.type === 'code'}
              error={error?.message}
            />

            <PasswordField
              label="Set new password"
              name="password"
              handleChange={handleChange}
              setIsShowPasswordRule={setIsShowPasswordRule}
              isError={error?.type === 'password'}
              error={error?.message}
            />

            <PasswordField
              label="Retype new password"
              name="validatePassword"
              handleChange={handleChange}
              confirmPassword
              setIsShowPasswordRule={setIsShowPasswordRule}
              isError={error?.type === 'password-match'}
              error={error?.message}
            />
          </div>

          <FormError message={error?.type === 'error' && error?.message} />

          <ButtonSolid
            type="submit"
            className="submit"
            children="Submit"
            disabled={submitDisable}
          />
        </form>
      </div>
    </div>
  );
};

export default withRouter(ResetPassword);

import { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { UserContext } from 'context/UserContext';
import useScrollDetector from 'shared/hooks/useScrollDetector';
import { getBooks } from 'routes/Books/api';
import { getRelative } from 'routes/Auth/authUtils';
import _ from 'lodash';

const useChildPicker = () => {
  const { user } = useContext(UserContext);

  const { isScrollingDown } = useScrollDetector();

  const selectedRelative = JSON.parse(
    sessionStorage.getItem('selectedRelative'),
  );

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const [childBooks, setChildBooks] = useState([]);
  const [emptyBooks, setEmptyBooks] = useState([]);
  const [selectedChildOption, setSelectedChildOption] =
    useState(selectedRelative);

  const [showPopup, setShowPopup] = useState(false);
  const [isChildPickerLoading, setIsChildPickerLoading] = useState(false);

  const childs = [...childBooks, ...emptyBooks];
  const firstChildData = childBooks?.length !== 0 && childBooks[0];

  const isAnyProfileIncompleted =
    childs &&
    childs?.length !== 0 &&
    childs?.some(child => {
      return !child?.name || child?.test_completed === 0;
    });

  const updateSelectedChildOption = newSelectedChildOption => {
    setSelectedChildOption(newSelectedChildOption);
    sessionStorage.setItem(
      'selectedRelative',
      JSON.stringify(newSelectedChildOption),
    );
    window.location.reload();
  };

  const updateChildBooks = () => {
    getChildsData();
  };

  const getChildsData = async () => {
    if (!_.isEmpty(user) && childBooks?.length === 0) {
      try {
        const res = await getRelative();
        const childData = res?.data?.data;

        setChildBooks(childData);
      } catch (err) {
        console.log('🚀 ~ getChildData ~ err:', err?.response);
      }
    }
  };

  const getBooksData = async () => {
    try {
      const res = await getBooks();
      const booksData = res?.data?.data;

      const filteredBooksData = booksData?.filter(book => {
        const isChildProfileCompleted = book?.child_name;

        if (isChildProfileCompleted) {
          return false;
        }

        return true;
      });

      setEmptyBooks(filteredBooksData);
    } catch (err) {
      console.log('🚀 ~ getBooksData ~ err:', err?.response);
    }
  };

  useEffect(() => {
    setIsChildPickerLoading(true);

    Promise.all([getBooksData(), getChildsData()]).then(() => {
      setIsChildPickerLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isMobile && isScrollingDown) setShowPopup(false);
  }, [isMobile, isScrollingDown]);

  useEffect(() => {
    const selectedChild = childBooks?.find(child => {
      return child?.id === selectedChildOption?.id;
    });

    const isSelectedChildIncompletedProfile =
      selectedChild?.test_completed === 0;

    if (!selectedChildOption) {
      setSelectedChildOption(firstChildData);
      sessionStorage.setItem(
        'selectedRelative',
        JSON.stringify(firstChildData),
      );
    }

    if (isSelectedChildIncompletedProfile) {
      setSelectedChildOption(firstChildData);
      sessionStorage.setItem(
        'selectedRelative',
        JSON.stringify(firstChildData),
      );
    }
  }, [selectedChildOption, firstChildData, childBooks]);

  return {
    user,
    childs,
    childBooks,
    updateChildBooks,
    firstChild: firstChildData,
    selectedChildOption,
    setSelectedChildOption,
    updateSelectedChildOption,
    isAnyProfileIncompleted,
    isChildPickerLoading,
    showPopup,
    setShowPopup,
    childPickerData: {
      user,
      childs,
      selectedChildOption,
      updateSelectedChildOption,
      isAnyProfileIncompleted,
      showPopup,
      setShowPopup,
    },
  };
};

export default useChildPicker;

import { goAnchorTop } from 'shared/function/goAnchor';
import { ReactComponent as ArrowTopIcon } from 'assets/general/arrow-up-grey-icon-wbox.svg';
import './BackToTopButton.scss';

const BackToTopButton = ({ position }) => {
  const onBackToTop = () => {
    goAnchorTop();
  };

  return (
    <div id="back-to-top-button" className={position}>
      <ArrowTopIcon className="arrow-up-icon" onClick={() => onBackToTop()} />
    </div>
  );
};

export default BackToTopButton;

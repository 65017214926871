import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { UserContext } from 'context/UserContext';
import { url } from 'config/urlConfig';
import { getTopicalBigIdea } from './TopicsUtils';
import { ButtonSolid } from 'shared/components/Button/Button';
import { TopicsWrapper } from './TopicsComponent';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import './TopicsTopicalBigIdea.scss';

const TopicsTopicalBigIdea = () => {
  const history = useHistory();

  const { topicId } = useParams();

  const { selectedRelative: contextSelectedRelative } = useContext(UserContext);

  const sessionSelectedRelative = JSON.parse(
    sessionStorage.getItem('selectedRelative'),
  );
  const selectedRelative = contextSelectedRelative || sessionSelectedRelative;

  const [topicalBigIdeaData, setTopicalBigIdeaData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const bigIdeaId = topicalBigIdeaData?.id;
  const bigIdeaDescription =
    topicalBigIdeaData?.big_idea_expanded_description &&
    parse(topicalBigIdeaData?.big_idea_expanded_description);
  const bigIdeaImage = topicalBigIdeaData?.big_idea_image;

  const handleBack = () => {
    history.push(`${url.TOPICSHUB}/${bigIdeaId}`);
  };

  const getTopicalBigIdeaData = async () => {
    setIsLoading(true);

    try {
      const relativeId = selectedRelative?.id;
      const queryParams = { relativeId: relativeId };

      const res = await getTopicalBigIdea({ queryParams, topicId });
      const topicalBigIdeaData = res?.data?.data;

      setTopicalBigIdeaData(topicalBigIdeaData);
      setIsLoading(false);
    } catch (err) {
      if (err)
        console.log(
          '🚀 ~ file: JourneyTopicalHub.js:80 ~ getTopicalBigIdeaData ~ err:',
          err,
        );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTopicalBigIdeaData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoadingIndicator />;

  return (
    <TopicsWrapper
      backBtnText="Back"
      backBtnNavigation={() => handleBack()}
      hideMainNavigation={true}>
      <div id="journey-topical-big-idea">
        <div className="big-idea-content">
          <div className="idea-description-wrapper">
            <p className="content-description">{bigIdeaDescription}</p>
          </div>

          <h2 className="content-header">Principles explored:</h2>

          <div className="idea-image-wrapper">
            <div className="content-image-container">
              <img src={bigIdeaImage} alt="Big Idea" />
            </div>
          </div>

          <ButtonSolid
            className="back-btn"
            onClick={() => handleBack()}
            children="Back"
          />
        </div>
      </div>
    </TopicsWrapper>
  );
};

export default TopicsTopicalBigIdea;

import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import axios from 'axios';
import { UserContext } from 'context/UserContext';
import { capitalize } from 'shared/helper/helper';

// components/assets
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import { ReactComponent as IconCircle } from 'assets/icons/icon_circle.svg';
import { ReactComponent as IconTriangle } from 'assets/icons/icon_triangle.svg';
import { ReactComponent as IconSquare } from 'assets/icons/icon_square.svg';
import { ReactComponent as IconDiamond } from 'assets/icons/icon_diamond.svg';
import { ReactComponent as Checked } from 'assets/general/checked-plain-icon.svg';
import { colorObjectV2 } from 'shared/const';
import { updateChild } from '../SettingModuleUtils';
import './ChildSettings.scss';

const ChildSettings = ({
  childId,
  successMessage,
  setSuccessMessage,
  errorMessage,
  setErrorMessage,
  updateChildBooks,
}) => {
  const { setSelectedRelative, userRelatives, setUserRelatives } =
    useContext(UserContext);

  const [disableBtn, setDisableBtn] = useState(true);
  const [formValue, setFormValue] = useState(null);

  const formColour = formValue?.colour?.toLowerCase();
  const isLoading = !formValue;

  const handleChange = e => {
    const { name, value } = e.target;
    const parentName = formValue?.formValue;
    const email = formValue?.email;
    const birthday = formValue?.birthday;

    setSuccessMessage(null);
    setErrorMessage(null);

    if (email === '' || parentName === '' || birthday === '' || value === '') {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }

    setFormValue({ ...formValue, [name]: value });
    setDisableBtn(false);
  };

  const submitForm = async () => {
    setSuccessMessage(null);
    setErrorMessage(null);
    const gender = formValue?.gender === 'female' ? 'F' : 'M';

    const newFormValue = {
      ...formValue,
      name: capitalize(formValue?.name),
      gender,
      date_of_birth: formValue?.birthday,
    };

    try {
      await updateChild(formValue?.id, newFormValue);
      setFormValue(newFormValue);

      const tempUserRelatives = [...userRelatives];
      const relIndex = _.findIndex(
        userRelatives,
        user => user.id === formValue?.id,
      );

      const tempRelative = {
        ...userRelatives[relIndex],
        ...formValue,
        birthday: formValue?.birthday,
      };

      tempUserRelatives[relIndex] = tempRelative;

      setSuccessMessage('Changes successfully saved');
      setUserRelatives(tempUserRelatives);

      setSelectedRelative(tempRelative);
      sessionStorage.setItem('selectedRelative', JSON.stringify(tempRelative));
      updateChildBooks();
    } catch (error) {
      const { message } = error.response.data;
      setErrorMessage(message);
    }
  };

  const getChildData = async () => {
    try {
      // TODO:: Please refactor this
      const { data } = await axios.get(`/relative/${childId}`);
      const { data: childData, message, response_code: responseCode } = data;
      if (responseCode === 'CED000') {
        const { id, name, gender, shape, colour, birthday, guided_mode } =
          childData;

        setFormValue({
          id,
          name,
          gender,
          shape,
          colour,
          birthday,
          guided_mode,
        });
      } else {
        throw new Error(message || 'Something went wrong.');
      }
    } catch (err) {
      console.log('🚀 ~ getChildData ~ err:', err?.response);
      window.alert(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    // Get updated info from API with the id
    getChildData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childId]);

  const SelectionSwitch = ({ items, label }) => {
    return (
      <div className="selection-switch-container">
        {Object.entries(items).map(item => (
          <div
            key={item[0]}
            className={`selection ${
              formValue?.gender === item[0] ? 'selected' : ''
            }`}
            onClick={() => {
              setFormValue({ ...formValue, gender: item[0] });
              setDisableBtn(false);
            }}>
            {item[1]}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={`user-account-container container-1 ${
        isLoading ? 'loading' : null
      }`}>
      {isLoading ? (
        <LoadingIndicator isLoaderOnly />
      ) : (
        <div className="user-form">
          <div className="form-group">
            <label>Child's Name</label>
            <input
              name="name"
              type="text"
              maxlength="12"
              placeholder="Name"
              value={formValue?.name}
              onChange={handleChange}
              onSelect={handleChange}
            />
          </div>
          <div className="form-group-inline">
            <div className="form-group">
              <label>My child is a</label>
              <SelectionSwitch
                items={{ male: 'Boy', female: 'Girl' }}
                label="parent"
              />
            </div>
            <div className="form-group">
              <label>Born on</label>
              <InputMask
                name="birthday"
                mask="99/99/9999"
                maskPlaceholder="DD/MM/YYYY"
                onChange={handleChange}
                value={formValue?.birthday}
                placeholder="DD/MM/YYYY"
              />
            </div>
          </div>

          <div className="form-group">
            <div className="color-container">
              {Object.entries(colorObjectV2).map(color => {
                return (
                  <div
                    key={color[0]}
                    style={{ backgroundColor: color[1] }}
                    onClick={() => {
                      setFormValue({ ...formValue, colour: color[0] });
                      setDisableBtn(false);
                    }}
                    className={`single-color ${
                      color[0] === formColour ? 'selected' : ''
                    }`}>
                    <Checked className="selected-icon" />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="form-group">
            <div className="shape-container">
              <div
                className={`single-shape ${
                  formValue?.shape === 'circle' ? 'selected' : ''
                }`}
                onClick={() => {
                  setFormValue({ ...formValue, shape: 'circle' });
                  setDisableBtn(false);
                }}>
                <IconCircle
                  style={{
                    fill: colorObjectV2[formColour],
                  }}
                />
              </div>
              <div
                className={`single-shape ${
                  formValue?.shape === 'triangle' ? 'selected' : ''
                }`}
                onClick={() => {
                  setFormValue({ ...formValue, shape: 'triangle' });
                  setDisableBtn(false);
                }}>
                <IconTriangle style={{ fill: colorObjectV2[formColour] }} />
              </div>
              <div
                className={`single-shape ${
                  formValue?.shape === 'diamond' ? 'selected' : ''
                }`}
                onClick={() => {
                  setFormValue({ ...formValue, shape: 'diamond' });
                  setDisableBtn(false);
                }}>
                <IconDiamond style={{ fill: colorObjectV2[formColour] }} />
              </div>
              <div
                className={`single-shape ${
                  formValue?.shape === 'square' ? 'selected' : ''
                }`}
                onClick={() => {
                  setFormValue({ ...formValue, shape: 'square' });
                  setDisableBtn(false);
                }}>
                <IconSquare style={{ fill: colorObjectV2[formColour] }} />
              </div>
            </div>
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && (
            <p className="success-message">
              <Checked /> {successMessage}
            </p>
          )}
          <button
            className="submit-btn"
            onClick={submitForm}
            disabled={disableBtn}>
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default withRouter(ChildSettings);

import React, { useState, useContext, useEffect } from 'react';
import { ToastContext } from 'context/ToastContext';
import { UserContext } from 'context/UserContext';
import { withRouter } from 'react-router-dom';
import { capitalize } from 'shared/helper/helper';
import { prices } from 'shared/const';
import { trackingAction, TRACKING_PURCHASES } from 'shared/function/Tracking';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { navigatePayment, updateUserPaymentStatus } from './CheckoutUtils';
import { ButtonSolid } from 'shared/components/Button/Button';
import useProductList from './useProductList';

const CardForm = ({
  paymentData,
  history,
  location,
  discountCode,
  isDiscountCodeValid,
  discountInfo,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const { setToast } = useContext(ToastContext);

  const { user, setUser, cartItem, setCartItem } = useContext(UserContext);

  const { selectedProduct } = useProductList();

  const [status, setStatus] = useState('Pay');
  const [cardHolderName, setCardHolderName] = useState(null);
  const [isCardNoValid, setIsCardNoValid] = useState(false);
  const [isCardInputFocused, setIsCardInputFocused] = useState(false);

  const [disableButton, setDisableButton] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const params = new URLSearchParams(location.search);
  const addChild = params.get('addchild') === 'true';
  const unlockplaybook = params.get('unlockplaybook') === 'true';

  useState(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 1000);
  });

  const trackGtmEvent = (event, data) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
      user_id: user.id,
      [event]: {
        value: data,
      },
    });
  };

  const navigateUserPayment = () => {
    navigatePayment({
      history,
      addChild,
      unlockplaybook,
      discountCode,
      isDiscountCodeValid,
      discountInfo,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setDisableButton(true);
    setErrorMessage('');
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    setStatus('Processing payment...');

    // Confirm the payment on the client
    const { error: stripeError, paymentIntent } =
      await stripe.confirmCardPayment(paymentData?.client_secret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

    if (stripeError) {
      setErrorMessage(stripeError.message);
      setStatus('Pay');
      setDisableButton(false);
      return;
    }

    setStatus(capitalize(paymentIntent.status));

    const STATUS = {
      SUCCESS: 'Success',
      FAIL: 'Failed',
    };

    let body = {};

    // TODO
    const totalPrice = addChild ? prices.addChild : prices.subscription;
    trackGtmEvent('purchase', totalPrice);

    const orderId = paymentData.id;

    body = {
      payment_status: 'Success',
      intent_id: paymentIntent.id,
    };

    setCartItem([body, ...cartItem]);

    updateUserPaymentStatus({
      orderId,
      selectedProduct,
      status: STATUS.SUCCESS,
      paymentIntentId: paymentIntent?.id,
      navigatePayment: navigateUserPayment,
      setToast,
      user,
      setUser,
      unlockplaybook,
    });
  };

  const handleInputFocus = () => {
    setIsCardInputFocused(true);
  };

  const handleInputBlur = () => {
    setIsCardInputFocused(false);
  };

  useEffect(() => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const cardElement = elements.getElement(CardElement);

    cardElement.on('change', event => {
      const isCardNoValid = event?.complete;

      if (isCardNoValid) setIsCardNoValid(true);
      else setIsCardNoValid(false);
    });
  }, [elements, stripe]);

  useEffect(() => {
    if (isCardNoValid && cardHolderName) setDisableButton(false);
    else setDisableButton(true);
  }, [cardHolderName, isCardNoValid]);

  // Track "payment failed" action when there are error from Stripe
  useEffect(() => {
    const productType = selectedProduct?.product_id;
    const isPurchaseChildProfile = productType?.toLowerCase().includes('child');

    const orderId = paymentData?.id;
    const productId = selectedProduct?.id;

    if (errorMessage) {
      if (isPurchaseChildProfile) {
        trackingAction({
          action: TRACKING_PURCHASES.web_purchase_child_profile_failed,
          extraData: {
            order_id: orderId,
            product_id: productId,
            user_id: user?.id,
          },
        });
      } else {
        trackingAction({
          action: TRACKING_PURCHASES.web_purchase_premium_access_failed,
          extraData: {
            order_id: orderId,
            product_id: productId,
            user_id: user?.id,
          },
        });
      }
    }
  }, [errorMessage, selectedProduct, paymentData]);

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <CardElement
          id="card-element"
          className={`${isCardInputFocused ? 'focus' : 'none'}`}
          options={{ hidePostalCode: true }}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <input
          id="card-holder-name"
          type="name"
          value={cardHolderName}
          placeholder="Name"
          onChange={e => setCardHolderName(e.target.value)}
        />

        {showButton && (
          <ButtonSolid
            type="submit"
            disabled={disableButton}
            children={status}
          />
        )}
      </form>

      {errorMessage !== '' && (
        <div className="stripe-error">{errorMessage}</div>
      )}
    </>
  );
};

export default withRouter(CardForm);

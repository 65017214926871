import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Lottie from 'react-lottie';
import _ from 'lodash';
import { url } from 'config/urlConfig';
import { UserContext } from 'context/UserContext';
import { capitalize } from 'shared/helper/helper';
import { getReferrals } from 'routes/ReferralSystem/ReferralUtils';

// components/assets
import { ButtonSolid } from 'shared/components/Button/Button';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import animationData from 'assets/child-result.json';
import './Welcome.scss';

const Welcome = () => {
  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const isTestCompleted = _.isEqual(params.get('test-completed'), 'true');

  const { user, setUser, userRelatives } = useContext(UserContext);

  const [isReferredResult, setIsReferredResult] = useState(false);
  const [isValidatingReferredResult, setIsValidatingReferredResult] =
    useState(false);

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const firstChildName = capitalize(userRelatives[0]?.name);

  const handleDiveIn = () => {
    const isAddChild = userRelatives?.length >= 2;

    if (isAddChild) {
      history.push(`${url.PLAYBOOKSECTION}`);
    } else {
      // Landing Onboard
      history.push(`${url.PLAYBOOKSNAPSHOT}`);
    }
  };

  const validateReferredResult = async () => {
    setIsReferredResult(false);
    setIsValidatingReferredResult(true);

    const queryParams = { type: 'referred' };

    try {
      const res = await getReferrals({ queryParams });
      const data = res?.data?.data;
      const isDataEmpty = data?.length === 0;

      if (res) {
        setIsValidatingReferredResult(false);

        if (!isDataEmpty) {
          setIsReferredResult(true);
        }
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: Result.js:72 ~ validateReferredResult ~ err:',
        err?.response,
      );

      if (err) {
        setIsReferredResult(false);
        setIsValidatingReferredResult(false);
      }
    }
  };

  useEffect(() => {
    validateReferredResult();
  }, []);

  useEffect(() => {
    const isCompletingSignup = userRelatives?.length < 2;

    // Only update user if conditions are met and user data has changed
    if (
      user &&
      userRelatives &&
      isCompletingSignup &&
      (user.parent_test_taken !== 1 ||
        user.child_test_taken !== 1 ||
        user.parent_answer_saved !== 1 ||
        user.child_answer_saved !== 1)
    ) {
      setUser({
        ...user,
        parent_test_taken: 1,
        child_test_taken: 1,
        parent_answer_saved: 1,
        child_answer_saved: 1,
      });
    }
  }, [userRelatives, user, setUser]);

  useEffect(() => {
    if (!isTestCompleted) history.push('/spotlight');
  }, [isTestCompleted, history]);

  if (isValidatingReferredResult) return <LoadingIndicator />;

  return (
    <div id="welcome-screen">
      <div className="welcome-content">
        <div className="welcome-img-wrapper">
          <Lottie options={lottieOptions} />
        </div>

        <div className="welcome-content-wrapper">
          <h1 className="welcome-header">
            {isReferredResult
              ? 'Score! You’ve received 7 days of Premium access.'
              : 'Your Profiles are ready!'}
          </h1>

          {isReferredResult && (
            <p className="welcome-description">
              Get started with customised personality insights for you and{' '}
              {firstChildName} in your Playbook now!
            </p>
          )}

          <ButtonSolid
            className="dive-in-btn"
            type="button"
            onClick={() => handleDiveIn()}
            children="Dive in"
          />
        </div>
      </div>
    </div>
  );
};

export default Welcome;

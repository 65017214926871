import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { UserContext } from 'context/UserContext';
import UserIcon from 'shared/components/UserIcon';
import parse from 'html-react-parser';
import { capitalize, convertULToArray } from 'shared/helper/helper';
import { plotGraphResult } from 'shared/const';
import { getGraphTableColour } from '../PeaksSnapshot/peaksSnapshotUtils';
import './PrimerModule.scss';

const PrimerModule = ({ body, selectedRelative, LockOverlay }) => {
  const mobileSize = useMediaQuery({ query: '(max-width: 500px)' });
  const {
    description,
    parent_description,
    parent_header_description,
    child_description,
    child_header_description,
    left_label,
    middle_label,
    right_label,
    parent_score,
    child_score,
  } = body;
  const { user } = useContext(UserContext);

  const [activeData, setActiveData] = useState({
    user: 'parent',
    headerDescription: parent_header_description,
    description: convertULToArray(parent_description),
  });

  const childName = capitalize(selectedRelative?.name);
  const childColour = selectedRelative?.colour;
  const userColour = user?.colour;

  const { leftLabel, middleLabel, rightLabel } = getGraphTableColour({
    parentScore: parent_score,
    childScore: child_score,
    userColour: userColour,
    childColour: childColour,
  });

  const switchData = user => {
    if (user === 'parent')
      setActiveData({
        user,
        headerDescription: parent_header_description,
        description: convertULToArray(parent_description),
      });

    if (user === 'child')
      setActiveData({
        user,
        headerDescription: child_header_description,
        description: convertULToArray(child_description),
      });
  };

  return (
    <section className="module primer">
      <div className="chapter-primer-title">
        <h2>PEAKS Insights: </h2>
        <h1>
          <UserIcon
            relative={user}
            style={mobileSize ? { width: '20px', height: '20px' } : null}
          />
          You &amp;
          <UserIcon
            relative={selectedRelative}
            style={mobileSize ? { width: '20px', height: '20px' } : null}
          />
          {childName}
        </h1>
      </div>

      <div className="primer-content">
        <div className="primer-top-container">
          <div className="bar-graph">
            <UserIcon
              relative={user}
              style={{ left: plotGraphResult(parent_score) + '%' }}
            />
            <UserIcon
              key={selectedRelative.id}
              relative={selectedRelative}
              style={{ left: plotGraphResult(child_score) + '%' }}
            />
            <div className="graph-label">
              <table>
                <tr>
                  <td
                    style={{
                      background: leftLabel ? leftLabel[0] : '',
                      color: leftLabel ? leftLabel[1] : '',
                    }}>
                    <p>{left_label}</p>
                  </td>
                  <td
                    style={{
                      background: middleLabel ? middleLabel[0] : '',
                      color: middleLabel ? middleLabel[1] : '',
                    }}>
                    <p>{middle_label}</p>
                  </td>
                  <td
                    style={{
                      background: rightLabel ? rightLabel[0] : '',
                      color: rightLabel ? rightLabel[1] : '',
                    }}>
                    <p>{right_label}</p>
                  </td>
                </tr>
              </table>
            </div>
            <span className="bar"></span>
          </div>
        </div>

        {/* PEAKS Score bar */}
        <div className="primer-description">
          {description && parse(description)}
        </div>

        <div className="primer-parent-child-point">
          <ul className="selector">
            <li
              onClick={() => switchData('parent')}
              className={`${activeData.user === 'parent' ? 'active' : ''}`}>
              <UserIcon
                relative={user}
                style={mobileSize ? { width: '20px', height: '20px' } : null}
              />{' '}
              <h3>You</h3>
            </li>
            <li
              onClick={() => switchData('child')}
              className={`${activeData.user === 'child' ? 'active' : ''}`}>
              <UserIcon
                relative={selectedRelative}
                style={mobileSize ? { width: '20px', height: '20px' } : null}
              />{' '}
              <h3>{childName}</h3>
            </li>
          </ul>

          <div className="primer-parent-child-content">
            <div className="list-header">
              {activeData.headerDescription !== null &&
                parse(activeData.headerDescription)}
            </div>
            {activeData.description.map((item, index) => (
              <div key={index} className="single-point">
                <div className="icon-container">
                  <UserIcon
                    relative={
                      activeData.user === 'parent' ? user : selectedRelative
                    }
                  />
                </div>
                <p>{item}</p>
              </div>
            ))}
          </div>
        </div>

        <LockOverlay />
      </div>
    </section>
  );
};

export default withRouter(PrimerModule);

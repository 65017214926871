import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Head from 'shared/components/Head.js';
import { ButtonSolid } from 'shared/components/Button/Button';
import { sendContact, handleSettingBack } from '../SettingModuleUtils';
import './Contact.scss';

// icon
import { ChevronLeft } from '@material-ui/icons';
import { ReactComponent as Close } from 'assets/general/close-code.svg';
import { ReactComponent as Checked } from 'assets/general/checked-plain-icon-code.svg';

const ContactSettings = ({ history }) => {
  const location = useLocation();

  const [docHeight, setDocHeight] = useState(document.body.clientHeight);
  const [formValue, setFormValue] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisable, setSubmitDisable] = useState(true);

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  useEffect(() => {
    setDocHeight(window.document.body.offsetHeight);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.document.body.offsetHeight]);

  useEffect(() => {
    const { name, email, message } = formValue;
    const emailRE =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (name !== '' && email !== '' && message !== '') {
      setSubmitDisable(true);

      if (!emailRE.test(email)) {
        setError('Please enter a valid email address');
      } else {
        setSubmitDisable(false);
      }
    } else setSubmitDisable(true);
  }, [formValue]);

  const handleChange = e => {
    setError('');
    setSuccess('');
    const { value, name } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await sendContact(formValue);
      setSuccess('Thanks for your message! We’ll be in touch shortly.');
      setFormValue({ name: '', email: '', message: '' });
    } catch (err) {
      const { data } = err.response;
      setError(data.message);
    }
  };

  return (
    <>
      <div id="settings-contact">
        <div className="settings-mobile-header">
          <span
            onClick={() =>
              handleSettingBack({
                history,
                location,
                type: 'tab',
                isMobile,
              })
            }>
            <ChevronLeft /> Back
          </span>
          <h1>Contact Us</h1>
        </div>
        <div className="contact-page">
          <Head title="Contact Us | Connected" />

          <div className="container-contact">
            <div className="form-group">
              <label>Name:</label>
              <input
                type="text"
                name="name"
                placeholder="Type your name here"
                value={formValue.name}
                onChange={e => handleChange(e)}
              />
            </div>

            <div className="form-group">
              <label>Email:</label>
              <input
                type="text"
                name="email"
                placeholder="Type your email here"
                value={formValue.email}
                onChange={e => handleChange(e)}
              />
            </div>

            <div className="form-group">
              <label>Message:</label>
              <textarea
                placeholder="Type your message here"
                name="message"
                rows="10"
                value={formValue.message}
                onChange={e => handleChange(e)}
              />
            </div>

            {error !== '' && (
              <div className="error-container">
                <div>
                  <Close />
                  <p>{error}</p>
                </div>
              </div>
            )}

            {success !== '' && (
              <div className="success-container">
                <div>
                  <Checked fill="#FFFFFF" />
                  <p>{success}</p>
                </div>
              </div>
            )}

            <ButtonSolid
              className="submit"
              disabled={submitDisable}
              onClick={handleSubmit}
              children="Submit"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ContactSettings);

import React, { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { trackingAction, TRACKING_PLAYBOOKS } from 'shared/function/Tracking';
import getChapterImages from 'assets/books/chapter/getChapterImages';
import getSnapshotImages from 'assets/snapshot/getSnapshotImages';

// components/assets
import { ButtonSolid } from 'shared/components/Button/Button';
import snapshotWebImg from 'assets/books/section-snapshot-web.png';
import snapshotMobImg from 'assets/books/section-snapshot-mob.png';
import introImg from 'assets/books/section-intro-web.jpg';
import purposeWebCompletedImg from 'assets/books/section-purpose-web-completed.png';
import purposeWebImg from 'assets/books/section-purpose-web.jpg';
import purposeMobImg from 'assets/books/section-purpose-mob.jpg';
import energyWebCompletedImg from 'assets/books/section-energy-web-completed.png';
import energyWebImg from 'assets/books/section-energy-web.jpg';
import energyMobImg from 'assets/books/section-energy-mob.jpg';
import affirmationWebCompletedImg from 'assets/books/section-affirmation-web-completed.png';
import affirmationWebImg from 'assets/books/section-affirmation-web.jpg';
import affirmationMobImg from 'assets/books/section-affirmation-mob.jpg';
import knowledgeWebCompletedImg from 'assets/books/section-knowledge-web-completed.png';
import knowledgeWebImg from 'assets/books/section-knowledge-web.jpg';
import knowledgeMobImg from 'assets/books/section-knowledge-mob.jpg';
import sustainabilityWebCompletedImg from 'assets/books/section-sustainability-web-completed.png';
import sustainabilityWebImg from 'assets/books/section-sustainability-web.jpg';
import sustainabilityMobImg from 'assets/books/section-sustainability-mob.jpg';
import endingImg from 'assets/books/section-ending-web.jpg';
import { ReactComponent as UpDownIcon } from 'assets/up-down-icon.svg';
import './BooksComponent.scss';
import { UserContext } from 'context/UserContext';

export const BookCard = ({
  screen,
  type,
  handleClick,
  leftContent,
  rightContent,
  ...otherProps
}) => {
  const isScreenChapterMenu = _.isEqual(screen, 'chapter-menu');
  const isTypeVideo = _.isEqual(type, 'video');
  const isTypeSnapshot = _.isEqual(type, 'snapshot');

  return (
    <div
      id="book-card"
      className={`${isScreenChapterMenu ? 'chapter' : ''} book-card`}
      onClick={handleClick}
      {...otherProps}>
      <div className="left-container">{leftContent}</div>
      <div
        className={`right-container  ${isScreenChapterMenu && 'chapter'} ${
          isTypeVideo ? 'video' : ''
        } ${isTypeSnapshot ? 'snapshot' : ''} `}>
        {rightContent}
      </div>
    </div>
  );
};

export const SectionMenuCompletedImage = ({ section, ...otherProps }) => {
  const getSectionImage = ({ section }) => {
    switch (section) {
      case 'Purpose':
        return purposeWebCompletedImg;
      case 'Energy':
        return energyWebCompletedImg;
      case 'Affirmation':
        return affirmationWebCompletedImg;
      case 'Knowledge':
        return knowledgeWebCompletedImg;
      case 'Sustainability':
        return sustainabilityWebCompletedImg;
      case 'Intro':
        return introImg;
      case 'Ending':
        return endingImg;
      default:
        return purposeWebImg;
    }
  };

  const img = getSectionImage({ section });

  if (!img) return null;

  return (
    <img
      className={`completed-state ${section?.toLowerCase()}`}
      src={img}
      alt="section"
      height="50%"
      {...otherProps}
    />
  );
};

export const SectionMenuImageWeb = ({ section, className, ...otherProps }) => {
  const getSectionImage = ({ section }) => {
    switch (section) {
      case 'Snapshot':
        return snapshotWebImg;
      case 'Intro':
        return introImg;
      case 'Purpose':
        return purposeWebImg;
      case 'Energy':
        return energyWebImg;
      case 'Affirmation':
        return affirmationWebImg;
      case 'Knowledge':
        return knowledgeWebImg;
      case 'Sustainability':
        return sustainabilityWebImg;
      case 'Ending':
        return endingImg;
      default:
        return purposeWebImg;
    }
  };

  const img = getSectionImage({ section });

  if (!img) return null;

  return (
    <img
      className={className}
      src={img}
      alt="section"
      height="50%"
      {...otherProps}
    />
  );
};

export const SectionMenuImageMob = ({ section, ...otherProps }) => {
  const getSectionImage = ({ section }) => {
    switch (section) {
      case 'Snapshot':
        return snapshotMobImg;
      case 'Intro':
        return introImg;
      case 'Purpose':
        return purposeMobImg;
      case 'Energy':
        return energyMobImg;
      case 'Affirmation':
        return affirmationMobImg;
      case 'Knowledge':
        return knowledgeMobImg;
      case 'Sustainability':
        return sustainabilityMobImg;
      case 'Ending':
        return endingImg;
      default:
        return purposeWebImg;
    }
  };

  const img = getSectionImage({ section });

  if (!img) return null;

  return <img src={img} alt="section" height="50%" {...otherProps} />;
};

export const ChapterMenuImage = ({ code, ...otherProps }) => {
  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });
  const imageDir = isMobileView ? 'mob' : 'web';

  const { chapterImages } = getChapterImages(imageDir);

  const chapterImage = chapterImages.find(item => {
    if (item?.label?.toLowerCase() === code?.toLowerCase()) {
      return true;
    }

    return false;
  });

  if (!chapterImage) return null;

  return (
    <div id="chapter-image-container" {...otherProps}>
      <img src={chapterImage?.jpg} alt={chapterImage?.label} />
    </div>
  );
};

export const SnapshotCardImage = ({ code, ...otherProps }) => {
  const snapshotImages = getSnapshotImages();

  const snapshotImage = snapshotImages.find(item => {
    if (item?.label?.toLowerCase() === code?.toLowerCase()) {
      return true;
    }

    return false;
  });

  if (!snapshotImage) return null;

  return (
    <div id="chapter-image-container" {...otherProps}>
      <img src={snapshotImage?.jpg} alt={snapshotImage?.label} />
    </div>
  );
};

export const ChapterVideoImage = ({ type, code, ...otherProps }) => {
  const isVideoIntro = _.isEqual(type, 'intro');

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });
  const imageDir = isMobileView ? 'mob' : 'web';

  const { videoImages } = getChapterImages(imageDir);

  const introImage = videoImages.find(item => {
    const label = item?.label?.toLowerCase();
    const chapterCode = code?.toLowerCase()?.charAt(0);

    if (label.includes('intro') && label?.charAt(0) === chapterCode) {
      return true;
    }

    return false;
  });

  const endingImage = videoImages.find(item => {
    const label = item?.label?.toLowerCase();
    const chapterCode = code?.toLowerCase()?.charAt(0);

    if (label.includes('ending') && label?.charAt(0) === chapterCode) {
      return true;
    }

    return false;
  });

  if (!introImage || !endingImage) return null;

  return (
    <div id="chapter-image-container" {...otherProps}>
      {isVideoIntro ? (
        <img
          className="chapter-image"
          src={introImage?.jpg}
          alt={introImage?.label}
        />
      ) : (
        <img
          className="chapter-image"
          src={endingImage?.jpg}
          alt={endingImage?.label}
        />
      )}
    </div>
  );
};

export const SectionMenuSidebar = ({
  titleNames,
  moduleItems,
  selectedRelative,
  activeIdx,
  setActiveIdx,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const relativeName = selectedRelative?.name;

  const handleModalAction = () => {
    setOpenModal(!openModal);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const SideBarMenu = () => {
    return (
      <div
        id="section-menu-side-bar"
        className={openModal && 'active'}
        onClick={handleModalAction}>
        <h4 className="menu-text">{titleNames[activeIdx]}</h4>
        <UpDownIcon className="menu-icon" />
      </div>
    );
  };

  const SideBarModal = () => {
    return (
      <div id="mobile-side-bar-modal" onClick={handleModalClose}>
        <div className="modal-outer-container">
          <SideBarMenu />

          <div className="modal-inner-container">
            <div className="modal-header">
              <div className="header">
                <h3>Section Menu</h3>
              </div>

              <div className="sub-header">
                <h3>You & {relativeName}</h3>
              </div>
            </div>

            <SideBarItems
              titleNames={titleNames}
              moduleItems={moduleItems}
              activeIdx={activeIdx + 1}
              setActiveIdx={setActiveIdx}
              handleModalAction={handleModalAction}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!openModal && <SideBarMenu />}
      {openModal && <SideBarModal />}
    </>
  );
};

const SideBarItems = ({
  moduleItems,
  activeIdx,
  setActiveIdx,
  handleModalAction,
  titleNames,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const { user } = useContext(UserContext);

  return (
    <div id="module-title-items">
      {titleNames?.map((title, idx) => {
        const itemIdx = idx + 1;

        const clickable = idx < moduleItems?.length;
        const id = clickable ? moduleItems[idx]?.id : {};

        const handleAction = () => {
          const excludeFirstLastItem = index =>
            index > 0 && index < titleNames.length - 1;

          setTimeout(() => {
            isMobile ? goAnchorMobile(id) : goAnchor(id);
          }, 100);

          setActiveIdx(idx);

          if (idx !== activeIdx && excludeFirstLastItem(idx)) {
            const activeDomainData = moduleItems[idx];
            const domainId = activeDomainData?.domain_id;
            const sectionId = activeDomainData?.id;

            if (activeDomainData)
              trackingAction({
                action: TRACKING_PLAYBOOKS.section_menu_scroll_to_domain,
                extraData: {
                  domain_id: domainId,
                  section_id: sectionId,
                  user_id: user?.id,
                },
              });
          }

          if (isMobile) {
            handleModalAction();
          }
        };

        return (
          <button
            className={`module-item-btn ${activeIdx === itemIdx && 'active'} ${
              clickable ? 'clickable' : ''
            }`}
            onClick={clickable ? handleAction : null}>
            <p>
              {itemIdx}.<span>{title}</span>
            </p>
          </button>
        );
      })}
    </div>
  );
};

function goAnchor(anchorId) {
  const targetElement = document.getElementById(anchorId);
  if (targetElement) {
    const topOffset =
      targetElement.getBoundingClientRect().top + window.pageYOffset;

    let offset;
    if (window.innerWidth < 600) {
      offset = 0.08;
    } else if (window.innerWidth <= 768) {
      offset = 0.12;
    } else if (window.innerWidth <= 1024) {
      offset = 0.22;
    } else {
      offset = 0.25;
    }

    if (window.innerWidth >= 1900) {
      offset += 0.05;
    }

    if (window.innerHeight >= 820) {
      offset -= 0.05;
    }

    window.scrollTo({
      top: topOffset - window.innerHeight * offset,
      behavior: 'smooth',
    });
  }
}

function goAnchorMobile(anchorId) {
  const targetElement = document.getElementById(anchorId);

  if (targetElement) {
    const topOffset = targetElement.offsetTop;
    const offset = getOffset();

    window.scrollTo({
      top: topOffset - window.innerHeight * offset,
      behavior: 'smooth',
    });
  }
}

function getOffset() {
  let offset;
  if (window.innerWidth < 600) {
    offset = +0.2;
  } else if (window.innerWidth <= 768) {
    offset = 0;
  } else if (window.innerWidth <= 1024) {
    offset = -0.02;
  } else {
    offset = -0.01;
  }

  if (window.innerWidth >= 1900) {
    offset += 0.05;
  }

  if (window.innerHeight >= 820) {
    offset -= 0.06;
  } else {
    offset -= 0.08;
  }

  return offset;
}

import React, { useContext } from 'react'
import _ from 'lodash'
import { ReactComponent as BookmarkIconFilled } from 'assets/bookmark-filled.svg'
import { ReactComponent as HeadspaceIcon } from 'assets/headspace.svg'
import { UserContext } from 'context/UserContext'
import './Notification.scss'

const Notification = (props) => {
    const { notification, setNotification } = useContext(UserContext)

    if (!_.isEmpty(notification)) {
        const { title, message, btnLabel, btnColor, secondaryColor, primaryFunction, secondaryFunction, showSecondaryClose, secondaryLabel, children, icon } = notification

        const renderButton = () => {
            if (showSecondaryClose) {
                return (
                    <div className='btn-container'>
                        <button className={btnColor} onClick={() => {
                            _.isFunction(primaryFunction) && primaryFunction()
                            setNotification({})
                        }}>{btnLabel || 'Close'}</button>
                        <button className={secondaryColor} onClick={() => {
                            _.isFunction(secondaryFunction) && secondaryFunction()
                            setNotification({})
                        }}>{secondaryLabel || 'Close'}</button>
                    </div>
                )
            }

            return (
                <div className='btn-container'>
                    <button className={btnColor} onClick={() => {
                        _.isFunction(primaryFunction) && primaryFunction()
                        setNotification({})
                    }}>{btnLabel || 'Close'}</button>
                </div>
            )
        }

        return (
            <div className='notification-cover'>
                <div className='popup'>
                    <div className='content-container'>
                        <div className='icon-wrap'>
                            {icon === 'bookmark' && <BookmarkIconFilled style={{ opacity: '.5' }} />}
                            {icon === 'headspace' && <HeadspaceIcon style={{ fill: 'red' }} />}
                        </div>
                        <h1>{title}</h1>
                        <p>{message}</p>
                        {_.isFunction(children) && children()}
                    </div>
                    {renderButton()}
                </div>
            </div>
        )
    } else {
        return null
    }

}

export default Notification
import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useMainNavDetector } from 'routes/MainNavigation/MainNavigationUtils';

const useScrollDetector = () => {
  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const { isMainNav } = useMainNavDetector();

  const [isScrolling, setIsScrolling] = useState(false);
  const [scrollDirection, setScrollDirection] = useState(null);
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [isNotAtTop, setIsNotAtTop] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);
  // scrolledDown state variable is set to true when the user scrolls down and reset to false when the user scrolls up
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  const isScrollingUp = isScrolling && scrollDirection === 'up';
  const isScrollingDown = isScrolling && scrollDirection === 'down';

  useEffect(() => {
    let timer = null;

    const handleWheel = () => {
      setIsScrolling(true);
      clearTimeout(timer);

      const currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition > prevScrollPosition) {
        setScrollDirection('down');
        setIsScrolledDown(true); // Set scrolledDown to true when scrolling down
      } else if (currentScrollPosition < prevScrollPosition) {
        setScrollDirection('up');
        setIsNotAtTop(currentScrollPosition > 0);
        setIsScrolledDown(false); // Reset scrolledDown to false when scrolling up
      } else {
        setIsNotAtTop(currentScrollPosition > 0);
      }

      setPrevScrollPosition(currentScrollPosition);

      // Detect if scrolled to the bottom
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollableHeight = documentHeight - windowHeight;

      setIsAtBottom(
        (isMobileView && isMainNav
          ? currentScrollPosition + 100
          : currentScrollPosition + 1) >= scrollableHeight,
      );

      timer = setTimeout(() => {
        setIsScrolling(false);
      }, 200); // Set a threshold time to determine when scrolling has stopped
    };

    window.addEventListener('scroll', handleWheel);

    return () => {
      window.removeEventListener('scroll', handleWheel);
    };
  }, [prevScrollPosition, isMobileView, isMainNav]);

  return {
    isScrolling,
    isScrollingUp,
    isScrollingDown,
    isScrolledDown,
    isNotAtTop,
    isAtBottom,
  };
};

export default useScrollDetector;

import React, { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { UserContext } from 'context/UserContext';
import { NoAccessContext } from 'context/NoAccessContext.js';
import { url } from 'config/urlConfig';
import { TESTING_PLAYBOOK_ID } from 'shared/const/TestAutomationIds';
import { getPeaksSnapshot, getBooks } from './api';
import { goAnchorFacet } from './peaksSnapshotUtils.js';
import useChildPicker from 'shared/hooks/useChildPicker';

// components/assets
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import StickyHeader from 'shared/components/StickyHeader/StickyHeader';
import ChildPicker from 'shared/components/ChildPicker/ChildPicker';
import ChildFilter from 'shared/components/ChildFilter/ChildFilter';
import NoAccessModal from 'routes/ManagePremiumAccess/NoAccessModal';
import PeaksSnapshotGraph from './PeaksSnapshotGraph.js';
import PeaksSnapshotDetails from './PeaksSnapshotDetails';
import { Tooltip } from './PeaksSnapshotComponents';
import { ReactComponent as ChevronRight } from 'assets/general/chevron-right.svg';
import './PeaksSnapshot.scss';

const PeaksSnapshot = ({ history }) => {
  const { user, userRelatives } = useContext(UserContext);

  const { noAccessOpen, setNoAccessOpen } = useContext(NoAccessContext);

  // trigger usePreviewChapter to make sure Landing Onboard getting data before render
  const previewChapterResponseData = JSON.parse(
    sessionStorage.getItem('previewChapterResponseData'),
  );
  const { previewChapterData } = previewChapterResponseData;

  const { childPickerData } = useChildPicker();

  const sessionSelectedRelative = JSON.parse(
    sessionStorage.getItem('selectedRelative'),
  );
  const selectedRelative = sessionSelectedRelative;
  const relativeId = selectedRelative?.id;

  const bookId =
    JSON.parse(sessionStorage.getItem('bookId')) || previewChapterData?.book_id;
  const chapterId =
    JSON.parse(sessionStorage.getItem('chapterId')) ||
    previewChapterData?.chapter_id;
  const domainSlug = JSON.parse(sessionStorage.getItem('domainSlug'));

  const isUserDemo = _.isEqual(user?.is_demo, 1);

  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(domainSlug || 'Purpose');
  const [selectedFacet, setSelectedFacet] = useState();
  const [selectedRelatives, setSelectedRelatives] = useState([]);
  const [newUserResult, setNewUserResult] = useState([]);
  const [bookList, setBookList] = useState([]);
  const [showAltView, setShowAltView] = useState(false);

  const isDesktopView = useMediaQuery({ query: '(min-width: 992px)' });
  const isTabletView = useMediaQuery({
    query: '(min-width: 732px) and (max-width: 991px)',
  });
  const isMobileView = useMediaQuery({ query: '(max-width: 731px)' });

  const handleBack = () => {
    history.push(`${url.PLAYBOOKSECTION}`);
  };

  const getResult = async () => {
    setIsLoading(true);

    try {
      const response = await getPeaksSnapshot(relativeId);
      const relativeResult = response.data.data;

      setNewUserResult(relativeResult);
      setIsLoading(false);
    } catch (err) {
      const { message } = err.response.data;
      console.log('🚀 ~ getResult ~ message:', message);
      setIsLoading(false);
    }
  };

  const getBookData = async () => {
    setIsLoading(true);

    try {
      const res = await getBooks();
      const result = res.data.data;
      const selectedBook = _.find(result && result, { id: bookId });
      setBookList(selectedBook);
      setIsLoading(false);
    } catch (err) {
      const { message } = err.response.data;
      console.log('🚀 ~ getBookData ~ message:', message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(newUserResult)) {
      const selectedObject = _.find(newUserResult, { name: selectedTab });
      const selected = selectedObject && selectedObject.facets[0];
      setSelectedFacet(selected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserResult]);

  useEffect(() => {
    Promise.all([getBookData(), getResult()]).then(() => {
      setSelectedRelatives([]);
    });

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId]);

  useEffect(() => {
    setNoAccessOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const chapterId = JSON.parse(sessionStorage.getItem('chapterId'));

    if (newUserResult && bookList && chapterId) {
      setTimeout(() => goAnchorFacet(chapterId), 100);
    }
  }, [newUserResult, bookList]);

  if (isLoading || previewChapterData === null) return <LoadingIndicator />;

  return (
    <div id="peaks-page">
      {!isLoading && (
        <div className="container-peaksnapshot">
          <div className="peaksnapshot-wrapper">
            <div className="peaks-header">
              {bookList && (
                <div className="header">
                  {!isMobileView && (
                    <div
                      className="back-btn"
                      data-test-id={TESTING_PLAYBOOK_ID.SNAPSHOT_BACK_BUTTON}
                      onClick={() => handleBack()}>
                      <ChevronRight className="back-icon" />
                      <h5 className="back-text">Playbook</h5>
                    </div>
                  )}

                  {isMobileView && (
                    <StickyHeader
                      isBackBtn
                      data-test-id={TESTING_PLAYBOOK_ID.SNAPSHOT_BACK_BUTTON}
                      backText="Playbook"
                      handleBack={handleBack}
                    />
                  )}

                  <ChildPicker childPickerData={childPickerData} disablePopup />
                </div>
              )}

              {bookList && (
                <div className="peaks-subheader">
                  <div className="peaks-subheader-left-content">
                    <h1>PEAKS Snapshot </h1>

                    {isMobileView && <Tooltip data={bookList} />}
                  </div>

                  <div className="subheader-info-icon-group">
                    {(isTabletView || isDesktopView) && (
                      <Tooltip data={bookList} />
                    )}

                    <div className="graph-switch-container">
                      <div
                        className={`graph-switch ${showAltView ? 'alt' : ''}`}
                        onClick={
                          selectedRelatives?.length > 0
                            ? null
                            : () => setShowAltView(!showAltView)
                        }
                        data-test-id="snapshot-switch"
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="peaks-graph-bar">
            {userRelatives.length > 1 && !isUserDemo && (
              <div
                className={`view-w-child-header ${isDesktopView && 'center'}`}>
                <ChildFilter
                  title="View with: "
                  userRelatives={userRelatives}
                  selectedChild={selectedRelative}
                  selectedChilds={selectedRelatives}
                  setSelectedChilds={setSelectedRelatives}
                  setShowAltView={setShowAltView}
                />
              </div>
            )}

            <div
              className={`graph-wrapper ${
                isUserDemo || userRelatives?.length < 2 ? 'demo' : ''
              }`}>
              <PeaksSnapshotGraph
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                setSelectedFacet={setSelectedFacet}
                childId={relativeId}
                newUserResult={newUserResult}
                showAltView={showAltView}
                selectedRelatives={selectedRelatives}
              />
            </div>
          </div>

          {selectedFacet && bookList && (
            <PeaksSnapshotDetails
              selectedTab={selectedTab}
              selectedFacet={selectedFacet}
              setSelectedFacet={setSelectedFacet}
              selectedRelatives={selectedRelatives}
              newUserResult={newUserResult}
              childName={bookList.child_name}
              paramChapterId={chapterId}
            />
          )}

          {noAccessOpen && (
            <NoAccessModal
              history={history}
              isPreviewBtn={true}
              handleClose={() => setNoAccessOpen(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PeaksSnapshot;

import React, { useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { facetColor } from 'shared/const';
import UserIcon from 'shared/components/UserIcon';
import parse from 'html-react-parser';
import useOutsideClickForTips from './useOutsideClickForTips';

const CardWDescription = ({ selectedTips: tips, facet, setSelectedTips }) => {
  const sessionSelectedRelative = JSON.parse(
    sessionStorage.getItem('selectedRelative'),
  );
  const selectedRelative = sessionSelectedRelative;

  const wrapperRef = useRef(null);
  const { title, category, content } = tips.body;
  const { name, shape, colour } = selectedRelative;

  const labelColor = facetColor(facet);

  const isDomainPurpose =
    facet === 'Preparedness' ||
    facet === 'Organisation' ||
    facet === 'Dutifulness' ||
    facet === 'Success Driven' ||
    facet === 'Focus';

  const renderDescriptionData = () => {
    return content.map(singleContent => {
      const { componentId, data, type } = singleContent;
      switch (type) {
        case 'EDITOR':
          return <div key={componentId}>{parse(data)}</div>;

        case 'IMAGE_COMPONENT':
          return <img key={componentId} src={data} alt={title} />;

        default:
          return null;
      }
    });
  };

  const resetSelectedTips = () => {
    setSelectedTips(null);
  };

  useOutsideClickForTips(wrapperRef, resetSelectedTips);

  return (
    <div className={`card-w-description ${!tips ? 'hidden' : ''}`}>
      {tips && (
        <>
          <div className="top-content">
            <div className="card-header">
              <div className="left">
                <UserIcon relative={{ colour, shape }} />
                <p>{name}</p>
                <span
                  style={{
                    color: labelColor,
                    borderColor: labelColor,
                  }}>
                  {facet}
                </span>
              </div>
            </div>

            <h2 className="card-content">{title}</h2>
            <div className="card-description">{renderDescriptionData()}</div>
          </div>

          <div
            className="footer-content modal"
            style={{
              backgroundColor: isDomainPurpose ? '#fee0ca' : `${labelColor}45`,
            }}>
            {category}
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(CardWDescription);

import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { useOnboardingStatus, useOnboardItems } from './OnboardingTourUtils';
import { navigatePlaybooks } from 'routes/Books/BooksUtils';
import { ButtonSolid } from '../Button/Button';
import LoadingIndicator from '../Loading/LoadingIndicator';
import './OnboardingTour.scss';

const OnboardingTour = ({ children, history, isLoading }) => {
  const path = history.location.pathname;

  const { isOnboard, isOnboardType, updateOnboard } = useOnboardingStatus({
    path,
  });

  const previewChapterResponseData = JSON.parse(
    sessionStorage.getItem('previewChapterResponseData'),
  );
  const { previewChapterData } = previewChapterResponseData;

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const onboardItems = useOnboardItems({ previewChapterData, isMobileView });

  const bookId = previewChapterData?.book_id;
  const chapterId = previewChapterData?.chapter_id;
  const sectionId = previewChapterData?.section_id;

  const handleOnClick = ({ type, navigation }) => {
    if (navigation) {
      if (navigation.includes('playbooks')) {
        navigatePlaybooks({
          navigate: history.push,
          type: 'chapter',
          domainSlug: 'purpose',
          chapterSlug: 'preparedness',
          bookId,
          sectionId,
          chapterId,
        });
      } else {
        history.replace(navigation);
      }
      updateOnboard({ type });
    } else {
      updateOnboard({ type });
    }
  };

  if (isOnboard) {
    if (isLoading || _.isEqual(onboardItems?.length, 0)) {
      return <LoadingIndicator />;
    }

    return (
      <div id="onboarding-tour-overlay-screen">
        {onboardItems?.map(item => {
          const type = item?.type;
          const headerTop = item?.headerTop;
          const headerBottom = item?.headerBottom;

          const subHeader = item?.subHeader;
          const isSubHeaderTop = _.isEqual(item?.SubHeaderPos, 'top');
          const isSubHeaderBottom = _.isEqual(item?.SubHeaderPos, 'bottom');
          const isDescriptionArray = _.isArray(item?.description);
          const description = item?.description;
          const subDescription = item?.subDescription;
          const buttons = item?.buttons;

          const isTypeTopics = _.isEqual(type, 'topics');

          if (_.isEqual(isOnboardType, type)) {
            return (
              <div className="onboarding-tour-overlay-content">
                <h1
                  className={`header-top ${
                    isSubHeaderBottom && 'subHeader-bottom'
                  }`}>
                  {headerTop}
                </h1>

                {headerBottom && (
                  <h2 className="header-bottom">{headerBottom}</h2>
                )}

                {isSubHeaderTop && <h2 className="subHeader">{subHeader}</h2>}

                <img
                  className={`content-image ${isTypeTopics && 'topics'}`}
                  src={item?.img}
                  alt={type}
                />

                <div className={`description-content ${type}`}>
                  {isSubHeaderBottom && (
                    <h2 className="subHeader bottom">{subHeader}</h2>
                  )}

                  {isDescriptionArray ? (
                    <ul className="description-list">
                      {description?.map(item => {
                        return <li>{item}</li>;
                      })}
                    </ul>
                  ) : (
                    <p className="description">{description}</p>
                  )}

                  <p className="sub-description">{subDescription}</p>
                </div>

                {buttons?.map(button => {
                  return (
                    <ButtonSolid
                      className={`onboarding-tour-button ${button?.type}`}
                      children={button?.title}
                      onClick={() =>
                        handleOnClick({
                          type: type,
                          navigation: button?.navigation,
                        })
                      }
                    />
                  );
                })}
              </div>
            );
          }

          return null;
        })}
      </div>
    );
  }

  return children;
};

export default OnboardingTour;

import React, { useState } from 'react';
import parse from 'html-react-parser';
import { domainColor } from 'shared/const';
import { TESTING_PLAYBOOK_ID } from 'shared/const/TestAutomationIds';
import { capitalize } from 'shared/helper/helper';
import Vimeo from '@u-wave/react-vimeo';
import { ReactComponent as Close } from 'assets/general/close.svg';

export const SectionVideoModal = ({ videoObject, setVideoObject }) => {
  const { title, url, type } = videoObject;

  const isIntroVideo = type === 'Welcome';

  const handleClose = () => {
    setVideoObject(null);
  };

  return (
    <div className="video-modal" onClick={() => handleClose()}>
      <div className="modal-container" onClick={e => e.stopPropagation()}>
        <div className="modal-head">
          <h1>{title}</h1>
          <Close
            data-test-id={
              isIntroVideo
                ? TESTING_PLAYBOOK_ID.SECTION_INTRO_VIDEO_CLOSE_BUTTON
                : null
            }
            onClick={() => handleClose()}
          />
        </div>

        <div className="video-container">
          <Vimeo video={url} width={680} height={420} autoplay={true} />
        </div>
      </div>
    </div>
  );
};

export const ChapterVideoModal = ({
  chapterVideoData,
  setChapterVideoData,
  childName,
}) => {
  const {
    videoType,
    chapterTitle,
    domainName,
    chapterList,
    getChapterList,
    chapterNumber,
    bookId,
    sectionId,
    videoUrl,
  } = chapterVideoData;

  const [showNext, setShowNext] = useState(false);

  const labelColor = domainColor(domainName);

  const handleNext = async () => {
    setChapterVideoData(null);

    // await updateVideoStatus(bookId, sectionId, {
    //   video_type: videoType === "intro" ? "intro" : "ending",
    //   video_is_watched: 1,
    // });
    // getChapterList();

    // let body = {};
    // if (videoType === "intro") {
    //   body = {
    //     getNext: 1,
    //     type: "Chapter",
    //     chapterId: chapterList[0].id,
    //     chapterNumber: chapterNumber + 1,
    //     bookId,
    //     index: 0,
    //   };
    // } else {
    //   body = {
    //     getNext: 1,
    //     type: "Video",
    //     sectionId,
    //     bookId,
    //     videoType: "Intro",
    //   };
    // }
    // updateLastRead(body);
  };

  // const handleNext = () => {
  //   // Create a new URLSearchParams object from the original search string, and remove all parameters except for 'sectionId'
  //   const newSearchParams = new URLSearchParams({
  //     ...location.searchParams,
  //     sectionId: selectedSectionId,
  //   });

  //   // Create a new location object with the modified search string
  //   const newLocation = {
  //     ...location,
  //     search: `?${newSearchParams.toString()}`,
  //   };

  //   // Use the history object to navigate to the new location
  //   history.push(newLocation);
  // };

  const handlePlayerEnd = () => {
    setShowNext(true);
  };

  return (
    <div
      className={`video-content-overlay ${chapterVideoData ? 'active' : ''}`}
      onClick={handleNext}>
      <div className="content-container" onClick={e => e.stopPropagation()}>
        <div className="head">
          <div className="left">
            <div className="label" style={{ backgroundColor: labelColor }}>
              {capitalize(domainName)}
            </div>

            {chapterTitle && (
              <div className="title">
                {parse(chapterTitle.replace('{[child]}', childName))}
              </div>
            )}
          </div>
          <div className="right">
            <Close onClick={handleNext} />
          </div>
        </div>

        <div className="content">
          <div className="video-container">
            <Vimeo
              video={videoUrl}
              width={680}
              height={420}
              onEnd={handlePlayerEnd}
              autoplay={true}
            />
          </div>

          {showNext && <button onClick={handleNext}>Next</button>}
        </div>
      </div>
    </div>
  );
};

import axios from 'axios';
import Account from './components/Account';
import Profiles from './components/Profiles';
import Spotlight from './components/Spotlight';
import ReferralSettings from 'routes/ReferralSystem/ReferralSettings';
import Terms from './components/Terms';
import Policy from './components/Policy';
import Contact from './components/Contact';
import ManagePremiumAccess from 'routes/ManagePremiumAccess/ManagePremiumAccess';
import UpgradePremium from 'routes/ManagePremiumAccess/UpgradePremium';
import AddChildProfile from 'routes/ManagePremiumAccess/AddChildProfile';

// user
export const getUser = body => axios.get('/user');

export const updateUser = body => axios.put('/user', body);

export const updateUserInfo = body => axios.put('/user/info', body);

export const getUserTransactionHistory = body => axios.get('/user/history');

export const getPdfReceipt = ({ orderId }) =>
  axios.get(`/orders/${orderId}/receipt`, { responseType: 'blob' });

export const getProductById = queryParams =>
  axios.get('/product/:productId', { params: { ...queryParams } });

// relative/child
export const getChildList = () => axios.get('/relative');

export const updateChild = (relativeId, body) =>
  axios.put(`/relative/${relativeId}`, body);

export const deleteChild = relativeId =>
  axios.delete(`/relative/${relativeId}`);

export const toggleGuidedMode = (relativeId, body) =>
  axios.put(`/relative/${relativeId}/toggle-guided`, body);

// notification
export const getNotifications = () => axios.get('/notification');

export const readNotifications = notificationId =>
  axios.get(`/notification/${notificationId}`);

export const deleteNotifications = notificationId =>
  axios.delete(`/notification/${notificationId}`);

export const getNotificationSettings = () =>
  axios.get('/spotlight-string/notification');

export const updateNotificationSettings = body =>
  axios.put('/spotlight-string/notification', body);

// terms/policy
export const getTerms = () => axios.get('/info?type=terms_and_conditions');

export const getPolicy = () => axios.get('/info?type=privacy_policy');

export const submitFeedback = body => axios.post(`/feedbacks`, body);

export const deleteCart = () => axios.delete('/carts');

export const sendContact = body => axios.post('/contact-us', body);

export const getSpotlightSettings = () =>
  axios.get('/spotlight-string/settings');

export const updateSpotlightSettings = body =>
  axios.put('/spotlight-string/settings', body);

export const deleteAccount = body => axios.delete('/user', { data: body });

export const SelectedSettingTab = ({ settingId }) => {
  switch (settingId) {
    case 'manage-premium':
      return (
        <div className="user-account-container container-1">
          <ManagePremiumAccess />
        </div>
      );
    case 'upgrade-premium':
      return (
        <div className="user-account-container container-1">
          <UpgradePremium />
        </div>
      );
    case 'add-child-profile':
      return (
        <div className="user-account-container container-1">
          <AddChildProfile />
        </div>
      );
    case 'account':
      return <Account />;
    case 'profiles':
      return <Profiles />;
    case 'spotlight':
      return <Spotlight />;
    case 'referral':
      return <ReferralSettings />;
    case 'terms':
      return <Terms />;
    case 'policy':
      return <Policy />;
    case 'contact':
      return <Contact />;
    default:
      return <Account />;
  }
};

export const useDetectPreviousPath = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const previousPath = params.get('from');

  return { previousPath };
};

export const handleSettingBack = ({ history, location, type, isMobile }) => {
  const params = new URLSearchParams(location.search);
  const previousPath = params.get('from');

  const isTab = type === 'tab';

  if (isMobile) {
    if (isTab) {
      history.push(`/settings?from=${previousPath}`);
    } else {
      history.push(`/${previousPath}`);
    }
  } else {
    history.push(`/${previousPath}`);
  }
};

export const truncateString = ({ string, maxLength }) => {
  if (string.length <= maxLength) {
    return string;
  } else {
    // Subtract maxLength to accommodate for the ellipsis
    return string.slice(0, maxLength - 3) + '...';
  }
};

export const truncateStringWithCondition = ({
  string,
  maxLength,
  isStringTakingTooMuchSpace,
}) => {
  if (isStringTakingTooMuchSpace) {
    return string.slice(0, maxLength - 3) + '...';
  } else {
    return string;
  }
};

export const isStringTakingTooMuchSpace = ({
  string,
  stringThreshold,
  fontSize,
  fontFamily,
}) => {
  const span = document.createElement('span');

  // Set the font size and font family to match the styling of the rendered text
  span.style.fontSize = fontSize;
  span.style.fontFamily = fontFamily;
  span.textContent = string;
  document.body.appendChild(span);

  // Measure the width of the span
  const width = span.offsetWidth;
  document.body.removeChild(span);

  // Compare against a threshold (e.g., 100 pixels)
  return width > stringThreshold;
};

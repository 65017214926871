import React, { useState, useContext } from 'react';
import InputMask from 'react-input-mask';
import { withRouter } from 'react-router-dom';

import { UserContext } from 'context/UserContext';
import { colorObjectV2 } from 'shared/const';
import { updateUser } from '../SettingModuleUtils';
import './UserSettings.scss';

// icon
import { ChevronRight } from '@material-ui/icons';
import { ReactComponent as Checked } from 'assets/general/checked-plain-icon.svg';
import { ReactComponent as Close } from 'assets/general/close.svg';
import { ReactComponent as IconCircle } from 'assets/icons/icon_circle.svg';
import { ReactComponent as IconTriangle } from 'assets/icons/icon_triangle.svg';
import { ReactComponent as IconSquare } from 'assets/icons/icon_square.svg';
import { ReactComponent as IconDiamond } from 'assets/icons/icon_diamond.svg';

const UserSettings = ({
  successMessage,
  setSuccessMessage,
  errorMessage,
  setErrorMessage,
}) => {
  const { user, setUser } = useContext(UserContext);

  const [formValue, setFormValue] = useState({
    name: user.name,
    email: user.email,
    parent: user.parent,
    shape: user.shape,
    colour: user.colour,
    birthday: user.birthday,
  });

  const [showForm, setShowForm] = useState(true);
  const [disableBtn, setDisableBtn] = useState(true);

  const formColour = formValue?.colour?.toLowerCase();

  const SelectionSwitch = ({ items, label }) => {
    return (
      <div className="selection-switch-container">
        {Object.entries(items).map(item => (
          <div
            key={item[0]}
            className={`selection ${
              formValue.parent === item[0] ? 'selected' : ''
            }`}
            onClick={() => {
              setFormValue({ ...formValue, parent: item[0] });
              setDisableBtn(false);
            }}>
            {item[1]}
          </div>
        ))}
      </div>
    );
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const { name: parentName, email, birthday } = formValue;
    setSuccessMessage(null);
    setErrorMessage(null);

    if (email === '' || parentName === '' || birthday === '' || value === '') {
      setDisableBtn(true);
    } else {
      setDisableBtn(false);
    }

    const updatedFormValue = { ...formValue, [name]: value };

    setDisableBtn(false);
    setFormValue(updatedFormValue);
  };

  const submitForm = async () => {
    setSuccessMessage(null);
    setErrorMessage(null);
    // const newFormValue = { ...formValue, birthday: moment(formValue.birthday, 'YYYY-MM-DD').format('DD/MM/YYYY') }
    const newFormValue = { ...formValue };

    try {
      const res = await updateUser(newFormValue);
      setUser({ ...user, ...newFormValue });

      if (res?.data?.message) {
        setSuccessMessage('Changes successfully saved');
      }

      // setShowForm(false)
      // setToast({
      //     type: 'success',
      //     message: 'Account details updated.'
      // })
    } catch (err) {
      const { message } = err.response.data;
      setErrorMessage(message);
      // setToast({
      //     type: 'error',
      //     message
      // })
    }
  };

  const renderForm = () => {
    return (
      <div className="user-form">
        <div className="form-group">
          <label>Name</label>
          <input
            name="name"
            type="text"
            maxlength="20"
            placeholder="Name"
            value={formValue.name}
            onChange={handleChange}
            onSelect={handleChange}
          />
        </div>
        <div className="form-group-inline">
          <div className="form-group">
            <label>I'm a</label>
            <SelectionSwitch
              items={{ father: 'Dad', mother: 'Mom' }}
              label="parent"
            />
          </div>
          <div className="form-group">
            <label>Born on</label>
            {/* <input name='birthday' type='date' placeholder='DD/MM/YYYY' value={formValue.birthday} onChange={handleChange} /> */}
            <InputMask
              name="birthday"
              mask="99/99/9999"
              maskPlaceholder="DD/MM/YYYY"
              onChange={handleChange}
              value={formValue.birthday}
              placeholder="DD/MM/YYYY"
            />
          </div>
        </div>
        {/* <div className='form-group'>
                    <label>Email</label>
                    <input name='email' type='text' placeholder='Email' value={formValue.email} onChange={handleChange} onSelect={handleChange} />
                </div> */}

        <div className="form-group">
          {/* <label>Choose a colour to represent this profile</label> */}
          <div className="color-container">
            {Object.entries(colorObjectV2).map(color => {
              return (
                <div
                  key={color[0]}
                  style={{ backgroundColor: color[1] }}
                  onClick={() => {
                    setFormValue({ ...formValue, colour: color[0] });
                    setDisableBtn(false);
                  }}
                  className={`single-color ${
                    color[0] === formColour ? 'selected' : ''
                  }`}>
                  <Checked className="selected-icon" />
                </div>
              );
            })}
          </div>
        </div>

        <div className="form-group">
          {/* <label>Choose a shape to represent this profile</label> */}
          <div className="shape-container">
            <div
              className={`single-shape ${
                formValue.shape === 'circle' ? 'selected' : ''
              }`}
              onClick={() => {
                setFormValue({ ...formValue, shape: 'circle' });
                setDisableBtn(false);
              }}>
              <IconCircle style={{ fill: colorObjectV2[formColour] }} />
            </div>
            <div
              className={`single-shape ${
                formValue.shape === 'triangle' ? 'selected' : ''
              }`}
              onClick={() => {
                setFormValue({ ...formValue, shape: 'triangle' });
                setDisableBtn(false);
              }}>
              <IconTriangle style={{ fill: colorObjectV2[formColour] }} />
            </div>
            <div
              className={`single-shape ${
                formValue.shape === 'diamond' ? 'selected' : ''
              }`}
              onClick={() => {
                setFormValue({ ...formValue, shape: 'diamond' });
                setDisableBtn(false);
              }}>
              <IconDiamond style={{ fill: colorObjectV2[formColour] }} />
            </div>
            <div
              className={`single-shape ${
                formValue.shape === 'square' ? 'selected' : ''
              }`}
              onClick={() => {
                setFormValue({ ...formValue, shape: 'square' });
                setDisableBtn(false);
              }}>
              <IconSquare style={{ fill: colorObjectV2[formColour] }} />
            </div>
          </div>
        </div>

        {successMessage && (
          <p className="success-message">
            <Checked /> {successMessage}
          </p>
        )}
        {errorMessage && (
          <p className="error-message">
            <Close /> {errorMessage}
          </p>
        )}
        <button
          className="submit-btn"
          onClick={submitForm}
          disabled={disableBtn}>
          Save Changes
        </button>
      </div>
    );
  };

  return (
    <div className="user-account-container container-1">
      {/* <div className="settings-mobile-header">
        <span onClick={() => history.push("/settings")}>
          <ChevronLeft /> Back
        </span>
        <h1>
          <UserIcon relative={user} /> You
        </h1>
      </div> */}

      {showForm ? (
        renderForm()
      ) : (
        <>
          <button className="edit" onClick={() => setShowForm(true)}>
            Edit your profile <ChevronRight />
          </button>
          {/* <h3>Reassessments</h3>
                    <button className='retest'>
                        <UserIcon relative={{ colour: user.colour, shape: user.shape }} />
                        Last test taken for yourself was 6 months ago. Click here to retake your Profile Test.
                    </button> */}
        </>
      )}
    </div>
  );
};

export default withRouter(UserSettings);

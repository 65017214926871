import { useState, useEffect, useContext } from 'react';
import { ToastContext } from 'context/ToastContext';
import { UserContext } from 'context/UserContext';
import { useStripe } from '@stripe/react-stripe-js';
import {
  navigatePayment,
  updateUserPaymentStatus,
  updatePaymentStatus,
} from './CheckoutUtils';

const usePaymentRequest = ({
  paymentData,
  selectedProduct,
  totalPrice,
  unlockplaybook,
  addChild,
  discountCode,
  isDiscountCodeValid,
  discountInfo,
  history,
}) => {
  const stripe = useStripe();

  const { setToast } = useContext(ToastContext);

  const { user, setUser } = useContext(UserContext);

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePaymentChecked, setCanMakePaymentChecked] = useState(false);

  const currency = localStorage.getItem('countryCurrency')?.toLowerCase();

  // payment request not accept decimal
  const formattedTotalPrice = totalPrice * 100;

  const navigateUserPayment = () => {
    navigatePayment({
      history,
      addChild,
      unlockplaybook,
      discountCode,
      isDiscountCodeValid,
      discountInfo,
    });
  };

  useEffect(() => {
    let isMounted = true;

    const initializePaymentRequest = async () => {
      try {
        const { name } = selectedProduct;
        const pr = stripe.paymentRequest({
          country: 'SG',
          currency: currency,
          total: {
            label: name,
            amount: formattedTotalPrice,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        if (pr) {
          // Check the availability of the Payment Request API.
          const result = await pr.canMakePayment();
          if (result && isMounted) {
            setPaymentRequest(pr);
          } else {
            // Handle the case where the Payment Request API is not available
            // This could mean the browser or device does not support it
            console.error('Payment Request API not available');
            setPaymentRequest(null);
          }
        }
      } catch (err) {
        // Handle any errors that occurred during the canMakePayment check
        console.error('Error checking Payment Request availability:', err);
        setCanMakePaymentChecked(true); // Set the flag to prevent further calls
      }
    };

    if (stripe && totalPrice && !canMakePaymentChecked && selectedProduct) {
      initializePaymentRequest();
    }

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, totalPrice, canMakePaymentChecked, selectedProduct]);

  useEffect(() => {
    if (paymentRequest && paymentData) {
      paymentRequest.on('paymentmethod', async ev => {
        try {
          const orderId = paymentData.id;

          // Confirm the PaymentIntent without handling potential next actions (yet).
          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(paymentData.client_secret, {
              payment_method: ev.paymentMethod.id,
            });

          if (confirmError) {
            // Report to the browser that the payment failed, prompting it to
            // re-show the payment interface, or show an error message and close
            // the payment interface.
            ev.complete('fail');

            console.log('Line 328 - confirmError');
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');

            const STATUS = {
              SUCCESS: 'Success',
              FAIL: 'Failed',
            };

            // Check if the PaymentIntent requires any actions and, if so, let Stripe.js
            // handle the flow. If using an API version older than "2019-02-11"
            // instead check for: paymentIntent.status === "requires_source_action".
            if (paymentIntent.status === 'requires_action') {
              // Let Stripe.js handle the rest of the payment flow.
              const { error } = await stripe.confirmCardPayment(
                paymentData.client_secret,
              );

              if (error) {
                // The payment failed -- ask your customer for a new payment method.
                await updatePaymentStatus(orderId, {
                  payment_status: STATUS.FAIL,
                });

                console.log('Line 359 - confirm card error');
              } else {
                // The payment has succeeded -- show a success message to your customer.
                updateUserPaymentStatus({
                  orderId,
                  selectedProduct,
                  status: STATUS.SUCCESS,
                  paymentIntentId: paymentIntent?.id,
                  navigatePayment: navigateUserPayment,
                  setToast,
                  user,
                  setUser,
                  unlockplaybook,
                });

                console.log('Line 372 - confirm card success');
              }
            } else {
              // The payment has succeeded -- show a success message to your customer.
              updateUserPaymentStatus({
                orderId,
                selectedProduct,
                status: STATUS.SUCCESS,
                paymentIntentId: paymentIntent?.id,
                navigatePayment: navigateUserPayment,
                setToast,
                user,
                setUser,
                unlockplaybook,
              });

              console.log('Line 386 - no required action - success');
            }
          }
        } catch (err) {
          console.log('Line 390 - try - catch err', err.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequest, paymentData]);

  return { paymentRequest };
};

export default usePaymentRequest;

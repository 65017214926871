import axios from 'axios';

export const getBooks = () => axios.get('books');
export const getSection = relativeId =>
  axios.get(`books/${relativeId}/sections`);
export const listChapters = (bookId, sectionId) =>
  axios.get(`books/${bookId}/sections/${sectionId}`);
export const getChapters = (bookId, chapterId) =>
  axios.get(`books/${bookId}/chapters/${chapterId}`);
export const updateReadStatus = (bookId, chapterId) =>
  axios.put(`books/${bookId}/chapters/${chapterId}`);
export const updateVideoStatus = (bookId, sectionId, body) =>
  axios.put(`books/${bookId}/sections/${sectionId}`, body);
export const getBooksVideo = () => axios.get('/books/videos');
export const listNavs = bookId => axios.get(`books/${bookId}/navs`);

// Hubs
export const getChapterHubs = queryParams =>
  axios.get('/chapter-hub', { params: { ...queryParams } });
export const getPlaybookCards = queryParams =>
  axios.get('/chapter-hub/playbook-cards', { params: { ...queryParams } });

export const saveTips = (relativeId, tipsId) =>
  axios.put(`/relative/${relativeId}/tip/${tipsId}/save`);
export const removeTips = (relativeId, tipsId) =>
  axios.delete(`/relative/${relativeId}/tip/${tipsId}/save`);

export const updateLastRead = body => axios.put(`/books/last-read`, body);
export const redeemBook = body => axios.post(`/books/redeem`, body);
export const claimPremiumAccess = body =>
  axios.post(`/premiumAccessCode/claim`, body);
export const updateBookCover = (bookId, body) =>
  axios.put(`books/${bookId}`, body);

export const updateFeedbackShow = (bookId, body) =>
  axios.put(`/books/${bookId}/feedback`, body);

export const feedbackAppear = body => axios.put(`/feedbacks/shown`, body);

export const getUserData = () => axios.get('/user');

//Toolkit
export const listUserToolkit = body => axios.post('/user-toolkit', body);
export const readMeToolkitList = (toolkitId, relativeId) =>
  axios.get(`/user-toolkit/${toolkitId}/relative/${relativeId}`);
export const listUserPathways = (toolkitId, relativeId) =>
  axios.get(`/user-toolkit/${toolkitId}/relative/${relativeId}/pathway`);
export const userPathwaysDetails = (toolkitId, relativeId, pathwayId) =>
  axios.get(
    `/user-toolkit/${toolkitId}/relative/${relativeId}/pathway/${pathwayId}`,
  );
export const updateUserPathways = (toolkitId, relativeId, pathwayId, body) =>
  axios.put(
    `/user-toolkit/${toolkitId}/relative/${relativeId}/pathway/${pathwayId}`,
    body,
  );
export const updateToolkitTips = body =>
  axios.put(`/user-toolkit/save-tips`, body);
export const listUserBonus = (toolkitId, relativeId) =>
  axios.get(`/user-toolkit/${toolkitId}/relative/${relativeId}/bonus`);
export const getUserBonus = (toolkitId, relativeId, bonusId) =>
  axios.get(
    `/user-toolkit/${toolkitId}/relative/${relativeId}/bonus/${bonusId}`,
  );

// Gift codes
export const validateGiftCode = body => axios.post('/giftCode/validate', body);
export const claimGiftCode = body => axios.post('/giftCode/claim', body);

import axios from 'axios';
import {
  trackingAction,
  TRACKING_PURCHASES,
  GA_EVENT_CATOGARY,
  GA_PURCHASE_JOURNEY_TYPE,
} from 'shared/function/Tracking';

export const validateDiscountCode = body => axios.post('/discount', body);

// Payment
export const makePayment = body => axios.post('/orders', body);

export const updatePaymentStatus = (orderId, body) =>
  axios.put(`/orders/${orderId}`, body);

export const listProducts = () => axios.get('/product?platform=WEB');

export const paymentOptions = {
  Card: { type: 'card', label: 'Debit/Credit Card' },
  Paypal: { type: 'paypal', label: 'Paypal' },
  Wallet: { type: 'wallet', label: 'Apple Pay/Google Pay' },
  Paynow: { type: 'paynow', label: 'PayNow' },
};

export const WALLET_TYPES = {
  APPLE_PAY: 'APPLE_PAY',
  GOOGLE_PAY: 'GOOGLE_PAY',
};

export const navigatePayment = ({
  history,
  addChild,
  unlockplaybook,
  discountCode,
  isDiscountCodeValid,
  discountInfo,
}) => {
  const discountPercentage = discountInfo?.percentage;

  const buildUrl = params => {
    let url = `/order-completed`;

    if (params.addchild) {
      url += `?addchild=true`;
    } else if (params.unlockplaybook) {
      url += `?unlockplaybook=true`;
    }

    if (discountCode && isDiscountCodeValid) {
      url += `&discountCode=${discountCode}`;
    }

    if (discountPercentage) {
      url += `&discountPercentage=${discountPercentage}`;
    }

    return url;
  };

  const navigationParams = {
    addchild: addChild,
    unlockplaybook: unlockplaybook,
  };

  const url = buildUrl(navigationParams);
  history.push(url);
};

export const updateUserPaymentStatus = async ({
  orderId,
  selectedProduct,
  status,
  paymentIntentId,
  navigatePayment,
  setToast,
  user,
  setUser,
  unlockplaybook,
}) => {
  const productId = selectedProduct?.id;
  const productType = selectedProduct?.product_id;
  const isPurchaseChildProfile = productType?.toLowerCase().includes('child');
  const isUnlockPlaybook = unlockplaybook;

  try {
    const res = await updatePaymentStatus(orderId, {
      payment_status: status,
      intent_id: paymentIntentId,
    });

    // API return data seems missing fields
    const updatePaymentData = res?.data?.data;
    const premiumStartData = updatePaymentData?.premium_start_date;
    const premiumEndData = updatePaymentData?.premium_end_date;

    // The payment status update was successful.
    if (updatePaymentData) {
      if (isUnlockPlaybook) {
        setUser({
          ...user,
          premium_start_date: premiumStartData,
          premium_end_date: premiumEndData,
          is_demo: 0,
          is_temp: 0,
        });
      } else {
        setUser({
          ...user,
          premium_start_date: premiumStartData,
          premium_end_date: premiumEndData,
        });
      }

      trackingAction({
        category: GA_EVENT_CATOGARY.ecommerce,
        action: isPurchaseChildProfile
          ? TRACKING_PURCHASES.web_purchase_child_profile_success
          : TRACKING_PURCHASES.web_purchase_premium_access_success,
        ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.purchase,
        ecommerceEventData: {
          orderId,
          productData: selectedProduct,
        },
        extraData: {
          user_id: user?.id,
        },
      });

      if (navigatePayment) {
        navigatePayment();
      }
    }
  } catch (error) {
    console.error('Error updating payment status:', error);

    setToast({
      type: 'error',
      message: 'Error updating payment status',
    });

    if (isPurchaseChildProfile) {
      trackingAction({
        action: TRACKING_PURCHASES.web_purchase_child_profile_failed,
        extraData: {
          order_id: orderId,
          product_id: productId,
          user_id: user?.id,
        },
      });
    } else {
      trackingAction({
        action: TRACKING_PURCHASES.web_purchase_premium_access_failed,
        extraData: {
          order_id: orderId,
          product_id: productId,
          user_id: user?.id,
        },
      });
    }
  }
};

import React, { useState, useEffect, createContext, createRef } from 'react';

export const UserContext = createContext([]);

export const userData = createRef();

export const UserProvider = ({ children }) => {
  let initialValue = {};

  const [user, setUser] = useState(initialValue);
  const [selectedRelative, setSelectedRelative] = useState({});
  const [userRelatives, setUserRelatives] = useState([]);
  const [userResult, setUserResult] = useState([]);
  const [activeHeadspace, setActiveHeadspace] = useState([]);
  const [notification, setNotification] = useState([]);
  const [tempUser, setTempUser] = useState(initialValue);
  const [tempChild, setTempChild] = useState(initialValue);
  const [groupFlag, setGroupFlag] = useState(false);

  // v3
  const [cartItem, setCartItem] = useState(
    JSON.parse(localStorage.getItem('cart')) || [],
  );
  const [isCartMenuOpen, setIsCartMenuOpen] = useState(false);
  const [settingFeedbackShow, setSettingFeedbackShow] = useState(
    JSON.parse(localStorage.getItem('feedback')) || false,
  );
  const [badgeCount, setBadgeCount] = useState(null);
  const [inTourId, setInTourId] = useState('');
  const [showCompletedTours, setShowCompletedTours] = useState(
    JSON.parse(localStorage.getItem('showCompletedTours') || false),
  );

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartItem));
  }, [cartItem]);

  useEffect(() => {
    localStorage.setItem('feedback', JSON.stringify(settingFeedbackShow));
  }, [settingFeedbackShow]);

  useEffect(() => {
    localStorage.setItem(
      'showCompletedTours',
      JSON.stringify(showCompletedTours),
    );
  }, [showCompletedTours]);

  useEffect(() => {
    if (user) {
      userData.current = user;
    }
  }, [user]);

  const value = {
    user,
    setUser,
    tempUser,
    setTempUser,
    tempChild,
    setTempChild,
    selectedRelative,
    setSelectedRelative,
    userResult,
    setUserResult,
    notification,
    setNotification,
    userRelatives,
    setUserRelatives,
    activeHeadspace,
    setActiveHeadspace,
    groupFlag,
    setGroupFlag,
    cartItem,
    setCartItem,
    isCartMenuOpen,
    setIsCartMenuOpen,
    settingFeedbackShow,
    setSettingFeedbackShow,
    badgeCount,
    setBadgeCount,
    inTourId,
    setInTourId,
    showCompletedTours,
    setShowCompletedTours,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

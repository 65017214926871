const { default: COUNTRY_LIST } = require('shared/const/CountryList');

export const getCountryData = countryCode => {
  // Always grab Singapore as a default country
  const defaultCountry = COUNTRY_LIST.find(
    country => country.countryCode === 'SG',
  );

  const foundCountry = COUNTRY_LIST.find(
    country => country.countryCode === countryCode,
  );

  if (foundCountry) {
    return foundCountry;
  }

  return defaultCountry;
};

export const getCurrencyCodeSymbol = currencyCode => {
  switch (currencyCode) {
    case 'MYR':
      return 'MYR ';
    case 'SGD':
    default:
      return '$';
  }
};

export function goAnchorTop({ contentElementId = null } = {}) {
  const offset = getOffset();
  const contentElement = document.querySelector(contentElementId);

  if (contentElement) {
    const containerTopOffset = contentElement.getBoundingClientRect().top;
    const targetOffset = containerTopOffset - offset;

    window.scrollTo({
      top: targetOffset,
      behavior: 'smooth',
    });
  } else {
    window.scrollTo({
      top: 0 - offset,
      behavior: 'smooth',
    });
  }
}

function getOffset() {
  let offset = 0;

  return offset;
}

import React from "react";
import parse from "html-react-parser";
import { useMediaQuery } from "react-responsive";
import "./ArticleModule.scss";

// icon
import { ReactComponent as QuoteLeftIcon } from "assets/books/quote-outline-left-icon.svg";
import { ReactComponent as QuoteRightIcon } from "assets/books/quote-outline-right-icon.svg";

const ArticleModule = ({ title, body, LockOverlay }) => {
  const mobileSize = useMediaQuery({ query: "(max-width: 500px)" });

  const renderComponent = (component) => {
    const { componentId, type, data } = component;

    if (type === "EDITOR") {
      return (
        <div className="text-line-height" key={componentId}>
          {data !== null && parse(data)}
        </div>
      );
    }
    if (type === "QUOTE") {
      return (
        <div className="quote" key={componentId}>
          <QuoteLeftIcon />
          <div style={{ marginLeft: mobileSize && "4px" }}>
            {data !== null && parse(data)}
          </div>
          <QuoteRightIcon />
        </div>
      );
    }
    if (type === "IMAGE_COMPONENT")
      return <img key={componentId} src={data} alt="" />;
  };

  const { components } = body;
  return (
    <section className="module article">
      <h1 className="article-title">{title}</h1>

      <div className="article-content">
        {components &&
          components.map((component) => renderComponent(component))}
        <LockOverlay />
      </div>
    </section>
  );
};

export default ArticleModule;

import axios from 'axios';
import { logToSentry } from 'shared/function/SentryUtils';

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;
const X_API_KEY = process.env.REACT_APP_API_KEY;
axios.defaults.baseURL = `${BASE_URL}/${API_VERSION}`;
axios.defaults.headers.common['x-api-key'] = X_API_KEY;

axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { config, data: errorData } = error.response;
    const { message } = errorData;
    const { baseURL, method, url, params, data: body } = config;
    const path = baseURL + url;

    logToSentry(method, path, params, body, message);

    return Promise.reject(error);
  },
);

let _referenceComponents = [];

// This is to reference the entire view port
// In this case, the view port will be based on the home screens content size
// Useful if you need the exact width
let viewPortReference = null;

/**
 * This sets the View / Div in an array so that it could be referenced later.
 *
 * Useful for getting the props of a specific div.
 * @param {Object} data
 */
export function setReferenceComponents(data) {
  const { id } = data;
  const found = _referenceComponents.find((item) => id === item.id);
  // If the element already exists, then replace the current one
  if (found) {
    const temp = [..._referenceComponents];
    const index = temp.find((item) => id === item.id);
    temp[index] = data;
    _referenceComponents = temp;
  } else {
    _referenceComponents.push(data);
  }
}

/**
 * Gets all of the views that are saved for reference.
 * @returns Array
 */
export function getReferenceComponents() {
  return _referenceComponents;
}

/**
 * This sets the view port into a variable so that it can be referenced later.
 */
export function setViewPortReference(data) {
  if (data) {
    viewPortReference = data;
  }
}

export function getViewPortReference() {
  return viewPortReference;
}

// getter setter
const _topOffsetVar = {
  offset: 0,
};

export function setTopOffset(offset) {
  _topOffsetVar.offset = offset;
}

export function getTopOffset() {
  return _topOffsetVar.offset;
}

export function clearTopOffset() {
  _topOffsetVar.offset = 0;
}

import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import { UserContext } from 'context/UserContext';
import { ToastContext } from 'context/ToastContext';
import { encryptParams } from 'shared/helper/helper';
import { signupUser, loginUser, validateAllCodes } from './authUtils';
import { getReferralByEmail } from 'routes/ReferralSystem/ReferralUtils';

// components
import PasswordField from 'shared/components/PasswordField';
import { ButtonSolid } from 'shared/components/Button/Button';
import {
  FormHeader,
  FormError,
  FormFooter,
  TermsConditions,
  FormCode,
} from './AuthComponents';

const Signup = ({ history, location }) => {
  const params = new URLSearchParams(location.search);
  const email = params.get('email');
  const verificationCode = params.get('code');

  const { setTempUser, setUser } = useContext(UserContext);

  const { setToast } = useContext(ToastContext);

  const [formValue, setFormValue] = useState({
    password: '',
    validatePassword: '',
  });
  const [isSubmitDisable, setIsSubmitDisable] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isShowPasswordRule, setIsShowPasswordRule] = useState(false);
  const [error, setError] = useState(null);

  const [referralCode, setReferralCode] = useState(null);

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const handleChange = e => {
    setError('');
    const { value, name } = e.target;

    setFormValue({ ...formValue, [name]: value });
  };

  const loggedInUser = async ({ password, code }) => {
    const device = isMobile ? 'mobile browser' : 'web';
    const bodyFormData = new FormData();
    bodyFormData.append('email', email);
    bodyFormData.append('password', password);
    bodyFormData.append('device_type', device);
    bodyFormData.append('app_version', 'na');
    bodyFormData.append('os_version', 'na');

    try {
      const response1 = await loginUser(bodyFormData);
      const user = response1.data.data;

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
      const response2 = await axios.get('/user');
      const authUser = response2.data.data;

      setUser({ ...authUser, ...user });

      if (user) {
        sessionStorage.setItem('token', user.token);
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const trackGtmEvent = event => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
    });
  };

  const submitForm = async e => {
    e.preventDefault();

    const { password, code } = formValue;
    const encryptedPassword = encryptParams(password);

    const validateCode = async () => {
      try {
        const { code } = formValue;

        if (code && code.length > 0) {
          const res = await validateAllCodes({ code });
          const data = res?.data?.data;

          const codeType = data?.type;

          if (res && codeType) {
            if (codeType) {
              handleSignup({ codeType });
            }
          }
        }
      } catch (err) {
        console.log(
          '🚀 ~ file: Signup.js:124 ~ validateCode ~ err:',
          err?.response,
        );
        setToast({
          type: 'error',
          message: 'Invalid Code',
        });

        setIsSubmitDisable(true);
      }
    };

    const handleSignup = async ({ codeType }) => {
      setIsSubmitDisable(true);
      setIsSubmitLoading(true);

      const signupBody = {
        email,
        password: encryptedPassword,
        verification_code: verificationCode,
      };

      const signupBodyWithCode = {
        email,
        password: encryptedPassword,
        verification_code: verificationCode,
        code,
        code_type: codeType,
      };

      try {
        const res = await signupUser(
          codeType ? signupBodyWithCode : signupBody,
        );

        trackGtmEvent('sign_up');
        setTempUser({ ...res.data, password: encryptedPassword });
        loggedInUser({ password: encryptedPassword, code });

        if (isMobile) {
          history.push('/account-success');
        } else {
          history.push('/pretest-onboarding');
        }

        setIsSubmitDisable(false);
        setIsSubmitLoading(false);
      } catch (err) {
        console.log({ err });
        setError({ message: err?.response?.data?.message, type: 'error' });
        setIsSubmitDisable(false);
        setIsSubmitLoading(false);
      }
    };

    if (code) {
      validateCode();
    } else {
      handleSignup({ codeType: null });
    }
  };

  const validateReferralEmail = async () => {
    try {
      const res = await getReferralByEmail({ email });
      const referralEmailData = res?.data?.data;
      const referralCode = referralEmailData?.referral_code;

      if (res) {
        setReferralCode(referralCode);
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: Signup.js:209 ~ validateReferralCode ~ err:',
        err?.response,
      );
    }
  };

  useEffect(() => {
    validateReferralEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { password, validatePassword } = formValue;
    const passwordRE =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W|_]).{8,}$/;

    if (password && validatePassword) {
      setIsSubmitDisable(true);

      if (password && !passwordRE.test(password)) {
        setError({
          message: 'The password format is invalid',
          type: 'password',
        });
      } else if (
        password &&
        validatePassword &&
        validatePassword !== password
      ) {
        setError({
          message: 'Both passwords should match',
          type: 'password-match',
        });
      } else {
        setIsSubmitDisable(false);
      }
    } else {
      setIsSubmitDisable(true);
      setError(null);
    }
  }, [formValue]);

  return (
    <div
      className={`right-content-space ${
        isShowPasswordRule ? 'signup-password-rule' : 'signup'
      }`}>
      <div className="form-content">
        <FormHeader title="Sign up for free to grow closer in your relationships." />

        <form onSubmit={submitForm}>
          <div className="form-group">
            <input
              className="email"
              type="email"
              name="email"
              placeholder="Email"
              autoComplete="off"
              value={email}
              disabled
            />

            <PasswordField
              label="Password"
              name="password"
              handleChange={handleChange}
              setIsShowPasswordRule={setIsShowPasswordRule}
              isError={error?.type === 'password'}
              error={error?.message}
            />

            <PasswordField
              label="Re-enter password"
              name="validatePassword"
              handleChange={handleChange}
              confirmPassword
              isError={error?.type === 'password-match'}
              error={error?.message}
            />
          </div>

          <FormError message={error?.type === 'error' && error?.message} />

          <FormCode
            value={referralCode ? referralCode : formValue?.code}
            handleChange={handleChange}
            disabled={referralCode}
          />

          <ButtonSolid
            className="submit"
            type="submit"
            children="Create account"
            disabled={isSubmitDisable}
            isLoading={isSubmitLoading}
          />

          <FormFooter
            text="Already have an account?"
            buttonText="Log in"
            buttonClick={() => history.push('/login')}
          />
        </form>

        <TermsConditions />
      </div>
    </div>
  );
};

export default withRouter(Signup);

import { ReactComponent as ChevronRight } from 'assets/home/chevron-right.svg';
import './StickyHeader.scss';

const StickyHeader = ({ isBackBtn, backText, handleBack, backProps }) => {
  const BackButton = () => {
    const onBack = () => {
      handleBack();
    };

    return (
      <div className="back-button" onClick={() => onBack()} {...backProps}>
        <ChevronRight className="back-icon" />{' '}
        <div className="back-text">{backText || 'Back'}</div>
      </div>
    );
  };

  return (
    <div id="sticky-header">
      <div className="header-content-container">
        <div className="header-content">{isBackBtn && <BackButton />}</div>
      </div>
    </div>
  );
};

export default StickyHeader;

import React, { useEffect, useState, useContext } from 'react'
import _ from 'lodash'
import { ToastContext } from 'context/ToastContext'
import { Close, ErrorOutline, CheckCircleOutline } from '@material-ui/icons';
import './Toast.scss'

const baseDelay = 5000

const Toast = () => {
    const { toast, setToast } = useContext(ToastContext)
    const [showToast, setShowToast] = useState(false)

    useEffect(() => {
        if (!_.isEmpty(toast)) {
            setTimeout(() => {
                setShowToast(true)
            }, 10);
        }
        const timer1 = setTimeout(() => {
            setShowToast(false)
        }, baseDelay);
        const timer2 = setTimeout(() => {
            setToast({})
        }, baseDelay + 250);

        return () => {
            clearTimeout(timer1)
            clearTimeout(timer2)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toast])

    const closeToast = () => {
        setShowToast(false)
        setTimeout(() => {
            setToast({})
        }, 250);
    }

    const renderToastIcon = () => {
        if (toast.type === 'success') return <CheckCircleOutline />
        if (toast.type === 'error') return <ErrorOutline />
    }

    if (_.isEmpty(toast)) return null

    return (
        <div id='toast-container' className={`${toast.type} ${showToast ? 'on' : 'off'}`}>
            {renderToastIcon()}
            <p>{toast.message}</p>
            <Close className='close-headspace-btn' onClick={closeToast} />
        </div>
    )
}

Toast.defaultProps = {
    type: "success",
    message: ""
};

export default Toast
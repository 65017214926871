import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from 'context/UserContext';
import { withRouter } from 'react-router-dom';
import Lottie from 'react-lottie';
import _ from 'lodash';
import { url } from 'config/urlConfig';
import {
  clearDiscountedPrice,
  getDiscountedPrice,
} from 'routes/Checkout/Discount/GetSetter';
import useProductList from 'routes/Checkout/useProductList';
import './OrderComplete.scss';

// components/assets
import Head from 'shared/components/Head.js';
import { ButtonSolid } from 'shared/components/Button/Button';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import ConfirmImage from 'assets/products/order-completed.png';
import Welcome from 'assets/onboarding/explore_1.json';

const lottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const OrderComplete = ({ history, location, match }) => {
  const { cartItem, setCartItem, user, tempUser, setUser } =
    useContext(UserContext);
  const [emailArray, setEmailArray] = useState([]);
  const [countryCode, setCountryCode] = useState(null);
  const [showOrderCompletePopup, setShowOrderCompletePopup] = useState(false);

  const [discount, setDiscount] = useState(null);
  const [discountCode, setDiscountCode] = useState(null);
  const [discountPercentage, setDiscountPercentage] = useState(null);

  const params = new URLSearchParams(location.search);
  const guest = params.get('guest') === 'true';
  const unlockplaybook = params.get('unlockplaybook') === 'true';
  const guestEmail = params.get('guestEmail');
  const addChild = params.get('addchild');
  const discCode = params.get('discountCode');
  const discPercentage = params.get('discountPercentage');

  const {
    isProductLoading,
    selectedProduct,
    selectedProductPrice,
    currencyCodeSymbol,
  } = useProductList();

  useEffect(() => {
    checkEmailArray();
    const code = localStorage.getItem('countryCode');
    const disc = getDiscountedPrice();

    const initialPrice = selectedProductPrice;

    const discountedAmount = initialPrice - disc;
    const formatttedDiscountAmount = Math.floor(discountedAmount * 100) / 100;

    setCountryCode(code);

    setDiscount(formatttedDiscountAmount);
    setDiscountCode(discCode);
    setDiscountPercentage(discPercentage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductPrice, selectedProduct, currencyCodeSymbol]);

  useEffect(() => {
    if (match.url === `/order-completed`) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderSingleOrder = () => {
    const { name } = selectedProduct;

    return (
      <div className="order-complete-single-order">
        <div className="main-item-container dual-column">
          <h1>{name}</h1>
          <h1 className="amount">{`${currencyCodeSymbol}${selectedProductPrice.toFixed(
            2,
          )}`}</h1>
        </div>
      </div>
    );
  };

  const renderTotalPrice = () => {
    let total = selectedProductPrice - discount;
    return total?.toFixed(2);
  };

  const checkEmailArray = () => {
    const email = cartItem.map(item => {
      const { type, spouse_email, giftcard } = item;
      if (type === 'Gift') return giftcard.recipient_email;
      if (type === 'Spouse') return spouse_email;
      return null;
    });

    setEmailArray(_.compact(email));
  };

  const getStarted = () => {
    clearDiscountedPrice();

    history.push('/pretest-onboarding');

    setCartItem([]);
    localStorage.removeItem('discount');
    localStorage.removeItem('allowGift');
  };

  const renderOrderCompletePopup = () => {
    return (
      <div className="end-popup-overlay">
        <div className="end-popup-container">
          <div className="content">
            <Lottie
              options={{ ...lottieOptions, animationData: Welcome }}
              width="65%"
            />
            <h1>Hey there!</h1>
            <br />
            <div>
              You're about to take a short personality test for you and your
              child.
            </div>
            <br />
            <div>
              Based on your responses, we will instantly generate your
              customised Digital Playbook.
            </div>
            <br />
            <div>
              We recommend setting aside about <strong>15 minutes</strong> for
              this.
            </div>
            <br />
            <br />
          </div>
          <div className="buttons">
            <button
              style={{
                padding: '20px 45px',
                boxShadow: '0px 2px 15px #1968654C',
              }}
              onClick={getStarted}>
              Next
            </button>
          </div>
        </div>
      </div>
    );
  };

  const redirectProfile = () => {
    clearDiscountedPrice();
    setUser({ ...user, all_books_occupied: 0 });

    if (addChild) {
      sessionStorage.removeItem('selectedIncompleteChildProfile');
    }

    setTimeout(() => {
      history.push(`${url.ADD_CHILD_TEST_PROFILE}`);
    }, 300);
  };

  const onPressManagePremiumAccess = () => {
    clearDiscountedPrice();
    history.push(`${url.HOME}`);
  };

  const renderNextButton = () => {
    if (guest) {
      return null;
    } else {
      if (addChild) {
        return (
          <ButtonSolid
            onClick={() => redirectProfile()}
            children="Start child profile"
          />
        );
      } else {
        if (unlockplaybook) {
          return (
            <ButtonSolid
              onClick={onPressManagePremiumAccess}
              children="Continue"
            />
          );
        } else {
          return (
            <ButtonSolid
              onClick={() => setShowOrderCompletePopup(true)}
              children="Continue"
            />
          );
        }
      }
    }
  };

  if (
    isProductLoading ||
    !selectedProduct ||
    !currencyCodeSymbol ||
    !selectedProductPrice
  ) {
    return <LoadingIndicator />;
  }

  return (
    <div id="order-complete-page">
      <Head
        title={
          _.isEmpty(user)
            ? 'Order Completed - Connected App'
            : 'Connected App - Order Completed'
        }
      />
      {showOrderCompletePopup && renderOrderCompletePopup()}

      <div className="container">
        <div className="order-complete-left-content">
          <img src={ConfirmImage} alt="complete" />

          <div className="detail-container">
            <h1>You're all set!</h1>
            <p>
              <span>Receipt sent to</span>
              <br />
              {guest ? guestEmail : tempUser.email || user.email}
            </p>

            {emailArray.length > 0 && (
              <p>
                <span>A redemption code has been sent to</span>
                <br />
                {emailArray.map(email => (
                  <>
                    {email}
                    <br />
                  </>
                ))}
              </p>
            )}
            {renderNextButton()}
          </div>
        </div>

        <div className="right-content">
          <h1 style={{ textAlign: 'left' }}>Your Order</h1>
          <div className="order-container">{renderSingleOrder()}</div>

          {discountCode && (
            <div className="discount-code">
              <div
                className="valid-discount-code-display"
                style={{
                  display: 'flex',
                  flex: 1,
                  wordBreak: 'break-word',
                  textAlign: 'left',
                }}>
                {`${discountCode} ${discountPercentage}% OFF`}
              </div>
              <div
                className="valid-discount-code-display right"
                style={{
                  textAlign: 'right',
                  marginLeft: 10,
                }}>
                -${discount}
              </div>
            </div>
          )}

          <div className="summary">
            <p>Grand Total</p>
            <p>
              {`${currencyCodeSymbol}${renderTotalPrice()}`}
              <br />
              {countryCode === 'SG' && (
                <span className="gst-label">(incl. GST)</span>
              )}
            </p>
          </div>
        </div>

        <div className="mobile-img-container">
          <img src={ConfirmImage} alt="complete" />
        </div>
      </div>
    </div>
  );
};

export default withRouter(OrderComplete);

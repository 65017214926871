import { useState, useEffect, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from 'context/UserContext';
import { useSignupStatus } from 'routes/Auth/authUtils';
import { useOnboardingStatus } from 'shared/components/OnboardingTour/OnboardingTourUtils';

const getPreviewChapter = ({ apiKey, token, queryParams }) =>
  axios.get('/books/preview', {
    headers: {
      'x-api-key': apiKey,
      Authorization: `Bearer ${token}`,
    },
    params: { ...queryParams },
  });

const getChildList = ({ apiKey, token }) =>
  axios.get('/relative', {
    headers: {
      'x-api-key': apiKey,
      Authorization: `Bearer ${token}`,
    },
  });

const usePreviewChapter = () => {
  const location = useLocation();
  const path = location.pathname;

  const { user, setUserRelatives, setSelectedRelative } =
    useContext(UserContext);

  const { isLandingOnboard } = useOnboardingStatus({
    path,
  });

  const { isChildProfileCompleted } = useSignupStatus({ user });

  const sessionSelectedRelative = JSON.parse(
    sessionStorage.getItem('selectedRelative'),
  );

  const [previewChapterData, setPreviewChapterData] = useState(null);
  const [isPreviewChapterLoading, setIsPreviewChapterLoading] = useState(false);
  const [childList, setChildList] = useState(null);
  const [firstChildData, setFirstChildData] = useState(sessionSelectedRelative);

  const isAnyCompletedTest = JSON.parse(
    sessionStorage.getItem('anyCompletedChildTest'),
  );

  const userToken = user?.token;

  const responseData = useMemo(
    () => ({
      previewChapterData,
      isPreviewChapterLoading,
      childs: childList,
      firstChild: firstChildData,
    }),
    [previewChapterData, isPreviewChapterLoading, childList, firstChildData],
  );

  const getPreviewChapterData = async ({ childId }) => {
    setIsPreviewChapterLoading(true);

    try {
      const queryParams = { child_id: childId };

      const res = await getPreviewChapter({
        apiKey: process.env.REACT_APP_API_KEY,
        token: userToken,
        queryParams,
      });
      const previewChapterData = res?.data?.data;

      if (previewChapterData) {
        setPreviewChapterData({
          ...previewChapterData,
          child_name: firstChildData?.name,
        });
        sessionStorage.removeItem('anyCompletedChildTest');
      } else {
        setPreviewChapterData({});
      }
    } catch (err) {
      console.log(
        'Error while fetching preview chapter data:',
        err?.response?.data || err.message || err,
      );

      // Handle the error and set appropriate state to prevent infinite loop
      // Example: set an error state to display to the user
      // setError("An error occurred while fetching preview chapter data.");
    } finally {
      setIsPreviewChapterLoading(false);
    }
  };

  const getChildListData = async () => {
    try {
      const res = await getChildList({
        apiKey: process.env.REACT_APP_API_KEY,
        token: userToken,
      });

      const childListData = res?.data?.data;
      const firstChildData = childListData[0];

      const childData = sessionSelectedRelative
        ? sessionSelectedRelative
        : firstChildData;

      setChildList(childListData);
      setUserRelatives(childListData);

      if (childData) {
        setFirstChildData(childData);
        setSelectedRelative(childData);

        sessionStorage.setItem('selectedRelative', JSON.stringify(childData));

        // Call getPreviewChapterData here when childData is available
        getPreviewChapterData({ childId: childData?.id });
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: usePreviewChapter.js:58 ~ getChildListData ~ err:',
        err?.response,
      );
    }
  };

  useEffect(() => {
    if (
      isChildProfileCompleted &&
      ((path && (!childList || childList?.length === 0)) || isAnyCompletedTest)
    ) {
      getChildListData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, childList, isAnyCompletedTest]);

  useEffect(() => {
    const childId = firstChildData?.id;

    // Check if previewChapterData is null before calling getPreviewChapterData
    if (isLandingOnboard && childId && !previewChapterData) {
      getPreviewChapterData({ childId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLandingOnboard, firstChildData, previewChapterData]);

  useEffect(() => {
    sessionStorage.setItem(
      'previewChapterResponseData',
      JSON.stringify(responseData),
    );
  }, [responseData]);

  return responseData;
};

export default usePreviewChapter;

import React, { useContext } from "react";
import _ from "lodash";
import { capitalize } from "shared/helper/helper";
import { ToastContext } from "context/ToastContext";
import UserIcon from "shared/components/UserIcon";
import "./ChildFilter.scss";

const ChildFilter = ({
  title,
  userRelatives,
  selectedChild,
  selectedChilds,
  setSelectedChilds,
  setShowAltView,
}) => {
  const { setToast } = useContext(ToastContext);

  const filterHandler = async (child) => {
    if (!_.some(selectedChilds, child)) {
      if (selectedChilds?.length < 2) {
        setSelectedChilds([...selectedChilds, child]);
        setShowAltView && setShowAltView(true);
      } else {
        setToast({
          type: "error",
          message: "Please unselect a child first.",
        });
      }
    } else {
      const newArray = [...selectedChilds];

      const index = _.findIndex(selectedChilds, child);
      newArray.splice(index, 1);
      setSelectedChilds(newArray);
      setShowAltView && setShowAltView(newArray.length > 0);
    }
  };

  if (userRelatives) {
    const excludeSelectedChild = userRelatives?.filter(
      (child) => child.id !== selectedChild?.id
    );

    return (
      <div id="child-filter">
        {title && <p>{title}</p>}

        <ul className="filter-container">
          {excludeSelectedChild?.map((child, index) => (
            <li
              onClick={() => filterHandler(child)}
              key={index}
              className={`${
                _.some(selectedChilds, { name: child?.name }) ? "active" : ""
              }`}
            >
              <div className="item-content">
                <UserIcon relative={child} />
                {capitalize(child?.name)}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
  return null;
};

export default ChildFilter;

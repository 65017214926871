import { useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import cryptojs from "crypto-js";
import randomBytes from "randombytes";

export const useOutsideAlerter = (ref, triggerFunction) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        triggerFunction();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);
};

export const removeHTML = (str) => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = str;
  return tmp.textContent || tmp.innerText || "";
};

export const capitalize = (str) => {
  return _.startCase(_.toLower(str));
};

export const shortenName = (name) => {
  if (name && name.length > 12) {
    return capitalize(name.slice(0, 12));
  }
  return capitalize(name);
};

export const convertULToArray = (content) => {
  if (!content) return [];

  const splitContent = content.split("\n");
  const newArray = splitContent.map((element) => {
    const newElem = element.replace(/(<([^>]+)>)/gi, "");
    if (newElem !== "") return newElem;
    return null;
  });

  return _.without(newArray, undefined, null, "");
};

export const calculateDaysInFocus = (tips) => {
  const { days_in_focus, focused_at } = tips;

  const focusDay = moment(focused_at, "YYYY-MM-DD");
  const current = moment().startOf("day");
  const dayDifference = current.diff(focusDay, "days") + days_in_focus;
  return dayDifference;
};

export const encryptParams = (str) => {
  let value = process.env.REACT_APP_API_KEY + str;
  let random = randomBytes(8).toString("hex");
  let iv = cryptojs.enc.Utf8.parse(random);
  let utf8_ASK = cryptojs.enc.Utf8.parse(process.env.REACT_APP_API_KEY);

  let encrypted = cryptojs.AES.encrypt(value, utf8_ASK, {
    iv: iv,
    mode: cryptojs.mode.CBC,
  });
  let mac = cryptojs
    .HmacSHA256(random + encrypted.toString(), process.env.REACT_APP_API_KEY)
    .toString(cryptojs.enc.Hex);

  const final = {
    iv: cryptojs.enc.Utf8.stringify(iv),
    value: encrypted.toString(),
    mac: mac,
  };
  const jsonObject = JSON.stringify(final);
  let encryptedParam = btoa(jsonObject);
  return encryptedParam;
};

// This returns the user's screen info
export const getScreenInfo = () => {
  const { width, height, orientation } = window.screen;
  const { type } = orientation;

  if (type.startsWith("landscape")) {
    return { width, height };
  } else {
    // Inverse the width and height as due to using a portrait mode orientation
    return {
      width: height,
      height: width,
    };
  }
};

// This returns whether if the user's screen is in the standard 16 by 9 aspect ratio
export const isScreenStandard16by9 = (width, height) => {
  const standardAspectRatio = 16 / 9;
  const aspectRatio = width / height;
  return aspectRatio === standardAspectRatio;
};

import { Link } from 'react-router-dom';
import { ReactComponent as ChevronRight } from 'assets/home/chevron-right.svg';
import './Button.scss';

export const ButtonSolid = ({ children, isLoading, ...otherProps }) => {
  return (
    <button id="button-solid" style={{ borderRadius: '5px' }} {...otherProps}>
      {isLoading ? 'Loading...' : children}
    </button>
  );
};

export const ButtonOutline = ({ children, ...otherProps }) => {
  return (
    <button id="button-outline" style={{ borderRadius: '5px' }} {...otherProps}>
      {children}
    </button>
  );
};

export const ButtonLink = ({ children, ...otherProps }) => {
  return (
    <Link id="button-link" style={{ borderRadius: '5px' }} {...otherProps}>
      {children}
    </Link>
  );
};

export const ButtonText = ({ children, ...otherProps }) => {
  return (
    <button id="button-text" {...otherProps}>
      {children}
    </button>
  );
};

export const ButtonBack = ({ title, ...otherProps }) => {
  return (
    <div id="button-back" {...otherProps}>
      <ChevronRight /> {title ? title : 'Back'}
    </div>
  );
};

import React, { useState, createContext } from "react";

export const SignupContext = createContext([]);

export const SignupProvider = ({ children }) => {
  const [email, setEmail] = useState(null);
  const [verificationCode, setVerificationCode] = useState(null);
  const [giftCode, setGiftCode] = useState(null);

  const value = {
    email,
    setEmail,
    verificationCode,
    setVerificationCode,
    giftCode,
    setGiftCode,
  };

  return (
    <SignupContext.Provider value={value}>{children}</SignupContext.Provider>
  );
};

import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { ButtonSolid } from 'shared/components/Button/Button';
import { ReactComponent as UpDownIcon } from 'assets/up-down-icon.svg';
import './ModuleSideBar.scss';

export const WebSideBar = ({ moduleItems, scrolledIndex }) => {
  const [activeIdx, setActiveIdx] = useState(1);

  useEffect(() => {
    setActiveIdx(scrolledIndex + 1);
  }, [scrolledIndex]);

  return (
    <SideBarItems
      moduleItems={moduleItems}
      activeIdx={activeIdx}
      setActiveIdx={setActiveIdx}
    />
  );
};

export const MobileSideBar = ({
  moduleItems,
  chapterData,
  chapterNumber,
  facetColor,
  selectedRelative,
  scrolledIndex,
}) => {
  const [activeIdx, setActiveIdx] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const handleModalAction = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    setActiveIdx(scrolledIndex + 1);
  }, [scrolledIndex, activeIdx]);

  const SideBarMenu = () => {
    return (
      <div
        id="mobile-side-bar-menu"
        className={openModal && 'active'}
        onClick={handleModalAction}>
        <h4 className="menu-text">{activeIdx} of 7</h4>
        <UpDownIcon className="menu-icon" />
      </div>
    );
  };

  const SideBarModal = () => {
    return (
      <div id="mobile-side-bar-modal" onClick={handleModalAction}>
        <div className="modal-outer-container">
          <SideBarMenu />

          <div className="modal-inner-container">
            <div className="modal-header">
              <div className="header">
                <h3>Chapter {chapterNumber}</h3>
                <span
                  style={{
                    color: facetColor(chapterData.name),
                    borderColor: facetColor(chapterData.name),
                  }}>
                  {chapterData.name}
                </span>
              </div>

              <div className="sub-header">
                <h3>You & {selectedRelative.name}</h3>
              </div>
            </div>

            <SideBarItems
              moduleItems={moduleItems}
              activeIdx={activeIdx}
              handleModalAction={handleModalAction}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {!openModal && <SideBarMenu />}
      {openModal && <SideBarModal />}
    </>
  );
};

const SideBarItems = ({ moduleItems, activeIdx, handleModalAction }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const titleNames = [
    'Introduction',
    'Insights',
    'Personality',
    'Perspectives',
    'Tips',
    'Try it Out',
    'Discussion Guides',
  ];

  return (
    <div id="module-title-items">
      {titleNames.map((title, idx) => {
        const itemIdx = idx + 1;

        const clickable = idx < moduleItems.length;
        const components = clickable ? moduleItems[idx]?.components : {};
        const id = clickable ? components[0]?.id : {};

        const testAutomationId = title?.toLocaleLowerCase()?.replace(/ /g, '-');

        const handleAction = () => {
          setTimeout(() => {
            isMobile ? goAnchorMobile(id) : goAnchor(id);
          }, 100);

          if (isMobile) {
            handleModalAction();
          }
        };

        return (
          <ButtonSolid
            data-test-id={testAutomationId}
            className={`module-item-btn ${activeIdx === itemIdx && 'active'} ${
              clickable ? 'clickable' : ''
            }`}
            onClick={clickable ? handleAction : null}
            children={
              <p>
                {itemIdx}.<span>{title}</span>
              </p>
            }
          />
        );
      })}
    </div>
  );
};

function goAnchor(anchorId) {
  const targetElement = document.getElementById(anchorId);
  if (targetElement) {
    const topOffset =
      targetElement.getBoundingClientRect().top + window.pageYOffset;

    let offset;
    if (window.innerWidth < 600) {
      offset = 0.08;
    } else if (window.innerWidth <= 768) {
      offset = 0.12;
    } else if (window.innerWidth <= 1024) {
      offset = 0.22;
    } else {
      offset = 0.25;
    }

    if (window.innerWidth >= 1900) {
      offset += 0.05;
    }

    if (window.innerHeight >= 820) {
      offset -= 0.05;
    }

    window.scrollTo({
      top: topOffset - window.innerHeight * offset,
      behavior: 'smooth',
    });
  }
}

function goAnchorMobile(anchorId) {
  const targetElement = document.getElementById(anchorId);
  if (targetElement) {
    const topOffset = targetElement.getBoundingClientRect().top;
    const scrollableContainer = document.querySelector('.scrollable-container');
    const moduleItemsContainer = document.querySelector(
      '.module-items-container',
    );

    if (
      scrollableContainer &&
      moduleItemsContainer &&
      moduleItemsContainer.contains(targetElement)
    ) {
      const containerTopOffset =
        scrollableContainer.getBoundingClientRect().top;
      const targetOffset =
        topOffset - containerTopOffset + moduleItemsContainer.scrollTop;

      const offset = getOffset();

      scrollableContainer.scrollTo({
        top:
          targetOffset -
          moduleItemsContainer.getBoundingClientRect().top -
          window.innerHeight * offset,
        behavior: 'smooth',
      });
    } else {
      const offset = getOffset();

      window.scrollTo({
        top: topOffset - window.innerHeight * offset,
        behavior: 'smooth',
      });
    }
  }
}

function getOffset() {
  let offset;
  if (window.innerWidth < 600) {
    offset = 0;
  } else if (window.innerWidth <= 768) {
    offset = 0;
  } else if (window.innerWidth <= 1024) {
    offset = -0.02;
  } else {
    offset = -0.01;
  }

  if (window.innerWidth >= 1900) {
    offset += 0.05;
  }

  if (window.innerHeight >= 820) {
    offset -= 0.06;
  } else {
    offset -= 0.08;
  }

  return offset;
}

import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import _ from 'lodash';
import { trackingAction, TRACKING_TOPICS } from 'shared/function/Tracking';
import { getTopics, updateNewStubs, navigateTopics } from './TopicsUtils';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';
import useChildPicker from 'shared/hooks/useChildPicker';

// components/assets
import Head from 'shared/components/Head';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import LoadingSpinner from 'shared/components/Loading/LoadingSpinner';
import ChildPicker from 'shared/components/ChildPicker/ChildPicker';
import './Topics.scss';

const Topics = () => {
  const history = useHistory();

  const { user, setUser } = useContext(UserContext);

  const { selectedChildOption, childPickerData } = useChildPicker();

  const isUserAdmin = _.isEqual(user?.is_admin, 1);

  const relativeId = selectedChildOption?.id;

  const getTopicsData = async page => {
    try {
      const queryParams = {
        relativeId: relativeId,
        page,
        per_page: 10,
      };

      const res = await getTopics({ queryParams });

      const allItemData = res.data?.data;
      const itemsData = res?.data?.data?.data;

      const newCurrentPage = allItemData?.current_page;
      const newLastPage = allItemData?.last_page;

      return { res, itemsData, newCurrentPage, newLastPage };
    } catch (err) {
      console.log('🚀 ~ getTopicsData ~ err:', err);
      throw new Error(err.message);
    }
  };

  const { items, initialLoad, isLoading, setIsLoading, isLoadingMore } =
    useInfiniteScroll(getTopicsData);

  // Calls get topics the first time the user enters the screen
  useEffect(() => {
    const processUpdateStubs = async () => {
      await updateNewStubs();
      setUser({ ...user, new_stubs: 0 });
    };

    setIsLoading(true);
    initialLoad();

    processUpdateStubs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoadingIndicator />;

  return (
    <div id="journey-screen">
      <div className="journey-wrapper">
        <div className="journey-header">
          <ChildPicker childPickerData={childPickerData} />
        </div>

        <p className="journey-description">
          Navigate daily parenting life with topical insights customised for you
          and each child. Watch this space for new content!
        </p>

        <div className="journey-content">
          <h2 className="journey-content-header">Last updated</h2>

          <div className="journey-card-wrapper">
            {items?.map((topic, idx) => {
              return (
                <JourneyCard
                  key={idx}
                  history={history}
                  idx={idx}
                  topic={topic}
                  isUserAdmin={isUserAdmin}
                  relativeId={relativeId}
                />
              );
            })}
          </div>

          {isLoadingMore && (
            <div className="spinner-wrapper">
              <LoadingSpinner isAnimation />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const JourneyCard = ({ history, idx, topic, isUserAdmin, relativeId }) => {
  const { user } = useContext(UserContext);
  const [imageWidth, setImageWidth] = useState(null);

  const isPublished = _.isEqual(topic?.status, 'published');
  const isScheduled = _.isEqual(topic?.status, 'scheduled');
  const isDraft = _.isEqual(topic?.status, 'draft');

  const isAccess = isUserAdmin || (!isUserAdmin && isPublished);
  const isGreyed = isUserAdmin ? isScheduled : !isPublished;

  const topicId = topic?.id;
  const topicSlug = topic.deeplink_slug;
  const thumbnail = topic?.thumbnail;
  const isNewContent = _.isEqual(topic?.new_stubs, 1);

  const maxImgHeight = isNewContent ? imageWidth - 25 : imageWidth;

  const handleCardNavigation = () => {
    if (isAccess) {
      navigateTopics({
        navigate: history.push,
        type: 'hub',
        topicSlug,
      });

      trackingAction({
        action: TRACKING_TOPICS.topics_select_topic,
        extraData: {
          topic_id: topicId,
          relative_id: relativeId,
          user_id: user?.id,
        },
      });
    } else return;
  };

  return (
    <div
      id="journey-card"
      className={`${isGreyed ? 'greyed' : ''} ${isAccess ? 'access' : ''}`}
      onClick={() => handleCardNavigation()}
      data-test-id={`journey-topic-${idx + 1}`}>
      <Head title="Connected App - Topics" preventIndex />

      {isDraft && <div className="draft-container">Draft</div>}

      <img
        className={`topic-image ${isNewContent ? 'new-content' : ''}`}
        style={{ maxHeight: maxImgHeight }}
        src={thumbnail}
        alt="Home Card"
        onLoad={event => {
          const imageWidth = event.target.width; // Get the width of the loaded image
          setImageWidth(imageWidth);
        }}
      />
      {isNewContent && (
        <div className="new-content-container">New Content Added</div>
      )}
    </div>
  );
};

export default Topics;

import { GAEvent, GAEcommerceEvent } from './GAUtils';
import {
  GA_EVENT_CATOGARY,
  GA_PURCHASE_JOURNEY_TYPE,
  GA_CHECKOUT_JOURNEY_TYPE,
} from './GAUtils';

export const trackingAction = ({
  category,
  action,
  extraData,
  ecommerceEventType,
  ecommerceEventData,
}) => {
  const isCategoryEcommerce = category === GA_EVENT_CATOGARY.ecommerce;
  const isTypeBeginCheckout =
    ecommerceEventType === GA_PURCHASE_JOURNEY_TYPE.begin_checkout;

  const trackAction = () => {
    if (isCategoryEcommerce) {
      GAEvent({
        category: GA_EVENT_CATOGARY.ecommerce,
        action,
      });

      GAEcommerceEvent({ ecommerceEventType, ecommerceEventData });

      if (isTypeBeginCheckout) {
        GAEcommerceEvent({
          ecommerceEventType: GA_CHECKOUT_JOURNEY_TYPE.add_shipping_info,
          ecommerceEventData,
        });
        GAEcommerceEvent({
          ecommerceEventType: GA_CHECKOUT_JOURNEY_TYPE.add_payment_info,
          ecommerceEventData,
        });
      }
    } else {
      GAEvent({
        category: category ? category : GA_EVENT_CATOGARY.navigation,
        action,
        extraData,
      });
    }
  };

  return trackAction();
};

import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { url } from 'config/urlConfig';
import { UserContext } from 'context/UserContext';
import {
  trackingAction,
  TRACKING_GENERAL,
  GA_EVENT_CATOGARY,
  GA_PURCHASE_JOURNEY_TYPE,
} from 'shared/function/Tracking';
import { getHeaderTitle, getBookList } from './MainNavigationUtils';
import { getNotifications } from 'routes/SettingsModule/SettingModuleUtils';
import { navigatePlaybooks } from 'routes/Books/BooksUtils';

// components/assets
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import { ReactComponent as Close } from 'assets/general/close.svg';
import UserIcon from 'shared/components/UserIcon';
import HomeActiveIcon from 'assets/nav/home-active-icon.png';
import HomeInactiveIcon from 'assets/nav/home-inactive-icon.png';
import PlaybookActiveIcon from 'assets/nav/playbook-active-icon.png';
import PlaybookInactiveIcon from 'assets/nav/playbook-inactive-icon.png';
import TopicsActiveIcon from 'assets/nav/topics-active-icon.png';
import TopicsInactiveIcon from 'assets/nav/topics-inactive-icon.png';
import MailIcon from 'assets/nav/mail-icon.svg';
import SettingIcon from 'assets/nav/setting-gear-icon.svg';

import './MainNavigationComponent.scss';

export const NavTabButton = ({
  headerTitle,
  navClassname,
  tabIcon,
  path,
  isNotification,
  isPathTest,
  ...otherProps
}) => {
  const pathTitle = getHeaderTitle({ path: path });
  const isTabActive = pathTitle?.includes(headerTitle);

  // Conditionally set the to prop based on isPathTest
  const toProp = isPathTest ? null : otherProps.to;

  return (
    <NavLink
      key={headerTitle}
      className={navClassname}
      to={toProp}
      {...otherProps}>
      <div id="nav-tab-button">
        <div className={`icon-wrapper ${isTabActive && 'active'}`}>
          <img src={tabIcon} alt={headerTitle} />
          {isNotification && <div className="notification-dot" />}
        </div>

        <p className={`nav-tab-title ${isTabActive && 'active'}`}>
          {headerTitle}
        </p>
      </div>
    </NavLink>
  );
};

export const ChildSelectionModal = ({
  history,
  type,
  setSelectedRelative,
  headerTitle,
  handleClose,
  ...otherProps
}) => {
  const previewChapterResponseData = JSON.parse(
    sessionStorage.getItem('previewChapterResponseData'),
  );
  const { childs } = previewChapterResponseData;

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const handleChildNavigation = async ({ selectedChild }) => {
    const getBookListData = async ({ selectedChild }) => {
      const childId = selectedChild?.id;

      if (selectedChild && childId) {
        try {
          const res = await getBookList();
          const bookListData = res?.data?.data;

          const selectedChildBook = bookListData?.find(book => {
            if (book?.child_id === childId) {
              return true;
            }

            return false;
          });
          const selectedChildBookId =
            selectedChildBook && selectedChildBook?.id;

          if (selectedChildBookId) return selectedChildBookId;
        } catch (err) {
          console.log(
            '🚀 ~ file: MainNavigation.js:111 ~ getBookListData ~ err:',
            err?.response,
          );
        }
      }
    };

    const selectedChildBookId = await getBookListData({ selectedChild });

    const isTypeSnapshot = _.isEqual(type, 'snapshot');
    const isTypeJourney = _.isEqual(type, 'journey');

    setSelectedRelative(selectedChild);
    sessionStorage.setItem('selectedRelative', JSON.stringify(selectedChild));

    if (isTypeSnapshot) {
      navigatePlaybooks({
        navigate: history.push,
        type: 'snapshot',
        bookId: selectedChildBookId,
      });
    }

    if (isTypeJourney) {
      history.push(`${url.TOPICS}`);
    }

    handleClose();
  };

  return (
    <div id="child-selction-modal" {...otherProps}>
      <div className="modal-outer-container" onClick={() => handleClose()}>
        <div
          className="modal-inner-container"
          onClick={e => e.stopPropagation()}>
          {!isMobile && (
            <Close className="modal-close-btn" onClick={() => handleClose()} />
          )}

          <div className="popup-container">
            <div className="sub-content-container">
              <h1>{headerTitle}</h1>

              {!childs ? (
                <LoadingIndicator isLoaderOnly={true} />
              ) : (
                <>
                  <br />
                  {childs?.map((child, idx) => {
                    const {
                      id: childId,
                      name: childName,
                      colour: childColour,
                      shape: childShape,
                    } = child;

                    return (
                      <button
                        key={childId}
                        className="button"
                        onClick={() =>
                          child &&
                          handleChildNavigation({
                            selectedChild: child,
                          })
                        }
                        data-test-id={`child-peaks-${idx + 1}`}>
                        <UserIcon
                          relative={{
                            colour: childColour,
                            shape: childShape,
                          }}
                        />
                        {childName}
                      </button>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        </div>

        {isMobile && (
          <Close className="modal-close-btn" onClick={() => handleClose()} />
        )}
      </div>
    </div>
  );
};

// Example usage
//  <ChildSelectionModal
//    history={history}
//    type="snapshot"
//    setSelectedRelative={setSelectedRelative}
//    headerTitle="Whose PEAKS Snapshot would you like to view?"
//    handleClose={() => {
//      enableScroll();
//      setShowSnapshotSelectionModal(false);
//    }}
//  />;

export const HeaderIconItems = ({
  history,
  path,
  isSignupCompleted,
  isPathTest,
  handleShowEndTestPrompt,
}) => {
  const { user } = useContext(UserContext);

  const sessionSelectedRelative = JSON.parse(
    sessionStorage.getItem('selectedRelative'),
  );
  const selectedRelative = sessionSelectedRelative;
  const relativeId = selectedRelative?.id;

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const [notificationList, setNotificationList] = useState([]);

  const hasUnreadMail =
    notificationList?.length !== 0 &&
    notificationList?.some(item => item?.pivot.read_at === null);

  const handleIconNavigation = (e, { type }) => {
    const handleNavigateMailbox = () => {
      history.push(`/mailbox?from=${path?.substring(1)}`);

      trackingAction({
        action: TRACKING_GENERAL.navigate_mailbox,
        extraData: { relative_id: relativeId, user_id: user?.id },
      });
    };

    const handleNavigateSettings = () => {
      trackingAction({
        category: GA_EVENT_CATOGARY.ecommerce,
        action: TRACKING_GENERAL.navigate_settings,
        extraData: { relative_id: relativeId, user_id: user?.id },
        ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.session_start,
        ecommerceEventData: {},
      });

      history.push(`${url.SETTINGS}?from=${path?.substring(1)}`);
    };

    const handleNavigateSettingsAccount = () => {
      trackingAction({
        category: GA_EVENT_CATOGARY.ecommerce,
        action: TRACKING_GENERAL.navigate_settings,
        extraData: { relative_id: relativeId, user_id: user?.id },
        ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.session_start,
        ecommerceEventData: {},
      });

      history.push(
        `${url.SETTINGS}${
          !isMobileView ? '/account' : ''
        }?from=${path?.substring(1)}`,
      );
    };

    if (isPathTest) {
      handleShowEndTestPrompt();
    } else {
      if (isSignupCompleted) {
        switch (type) {
          case 'mail':
            return handleNavigateMailbox();
          case 'setting':
            return handleNavigateSettingsAccount();
          default:
            return handleNavigateSettings();
        }
      } else {
        e.preventDefault();
      }
    }
  };

  const getNotificationData = async () => {
    try {
      const response = await getNotifications();
      setNotificationList(response?.data?.data?.reverse());
    } catch (err) {
      const { message } = err.response.data;
      console.log(message);
    }
  };

  useEffect(() => {
    if (!_.isEmpty(user)) {
      Promise.all([getNotificationData()]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.subscription_status]);

  return (
    <div
      className={`${isMobileView ? 'mob-top-nav-icons' : 'top-nav-icons'} ${
        isSignupCompleted && 'active'
      } ${isMobileView && 'mobile'}`}>
      <div className={`mail-icon-container ${isMobileView && 'mobile'}`}>
        <img
          className="mail-icon"
          src={MailIcon}
          alt="Mail"
          onClick={e => handleIconNavigation(e, { type: 'mail' })}
          data-test-id="menu-bar-mail"
        />

        {hasUnreadMail && <div className="red-dot" />}
      </div>

      <img
        className="setting-icon"
        src={SettingIcon}
        alt="Setting"
        onClick={e => handleIconNavigation(e, { type: 'setting' })}
        data-test-id="menu-bar-settings"
      />
    </div>
  );
};

export const NavItems = ({
  path,
  disableCursor,
  isPathTest,
  handleShowEndTestPrompt,
}) => {
  const { user, userRelatives } = useContext(UserContext);

  const isNewStubs = _.isEqual(user?.new_stubs, 1);

  const sessionSelectedRelative = JSON.parse(
    sessionStorage.getItem('selectedRelative'),
  );
  const selectedRelative = sessionSelectedRelative;
  const relativeId = selectedRelative?.id;

  const pathTitle = getHeaderTitle({ path: path });

  const handleNavTabButtonClick = (e, navlinkId) => {
    const isHome = _.isEqual(navlinkId, 'home');
    const isPlaybook = _.isEqual(navlinkId, 'playbook');
    const isTopics = _.isEqual(navlinkId, 'journey');

    const action =
      (isHome && TRACKING_GENERAL.navigate_spotlight) ||
      (isPlaybook && TRACKING_GENERAL.navigate_playbooks) ||
      (isTopics && TRACKING_GENERAL.navigate_journey);

    if (isPathTest) {
      e.preventDefault();
      handleShowEndTestPrompt();
    } else {
      navlinkClickHandler(e);

      trackingAction({
        action: action,
        extraData: { relative_id: relativeId, user_id: user?.id },
      });
    }
  };

  const navlinkClickHandler = async e => {
    if (isPathTest) {
      handleShowEndTestPrompt();
    } else {
      const {
        child_test_taken: childTestTaken,
        parent_test_taken: parentTestTaken,
        all_books_generated: allBooksGenerated,
      } = user;

      if (_.isEmpty(userRelatives)) {
        if (
          childTestTaken === 0 ||
          parentTestTaken === 0 ||
          allBooksGenerated === 0
        ) {
          e.preventDefault();
        }
      } else {
        return;
      }
    }
  };

  return (
    <>
      <NavTabButton
        to={`${url.HOME}`}
        navClassname={disableCursor('disabled', '')}
        onClick={e => handleNavTabButtonClick(e, 'home')}
        tabIcon={
          _.isEqual(pathTitle, 'Home') ? HomeActiveIcon : HomeInactiveIcon
        }
        headerTitle="Home"
        path={path}
        isPathTest={isPathTest}
        data-test-id="menu-bar-spotlight"
      />

      <NavTabButton
        to={`${url.PLAYBOOKSECTION}`}
        navClassname={disableCursor('disabled', '')}
        onClick={e => handleNavTabButtonClick(e, 'playbook')}
        tabIcon={
          _.isEqual(pathTitle, 'Playbook')
            ? PlaybookActiveIcon
            : PlaybookInactiveIcon
        }
        headerTitle="Playbook"
        path={path}
        isPathTest={isPathTest}
        data-test-id="menu-bar-playbook"
      />

      <NavTabButton
        to={`${url.TOPICS}`}
        navClassname={disableCursor('disabled', '')}
        onClick={e => handleNavTabButtonClick(e, 'journey')}
        tabIcon={
          pathTitle?.includes('Topics') ? TopicsActiveIcon : TopicsInactiveIcon
        }
        isNotification={isNewStubs}
        headerTitle="Topics"
        path={path}
        isPathTest={isPathTest}
        data-test-id="menu-bar-journey"
      />
    </>
  );
};

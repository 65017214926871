import moment from "moment";
import TimezoneLabels from "./TimezoneLabels";

const momentTz = require("moment-timezone");

const defaultTimezone = "Asia/Singapore";

function getListOfTimezones() {
  const displayableTimezones = Object.keys(TimezoneLabels);

  const timezones = momentTz.tz.names();
  const mappedTimezones = [];
  timezones.forEach((tz) => {
    if (displayableTimezones.includes(tz)) {
      const offset = momentTz.tz(moment.utc(), tz).utcOffset();
      // For the label
      const hours = parseInt(Math.abs(offset) / 60, 10);
      const minutes = parseInt(Math.abs(offset) % 60, 10);
      const UTCLabel = `UTC ${offset >= 0 ? "+" : "-"}${
        hours > 9 ? String(hours) : `0${hours}`
      }:${minutes > 9 ? String(minutes) : `0${minutes}`}`;

      mappedTimezones.push({
        name: tz,
        label: `(${UTCLabel}) ${TimezoneLabels[tz]}`,
        offset,
      });
    }
  });

  const sorted = mappedTimezones.sort((a, b) => a.offset - b.offset);

  // Push Kuala Lumpure, Singapore to the very top
  const defaultIndex = mappedTimezones.findIndex(
    (x) => x.name === defaultTimezone
  );
  sorted.unshift(sorted.splice(defaultIndex, 1)[0]);

  return sorted;
}

const timezones = getListOfTimezones();

const TimezonePicker = ({ selectedTimezone, setSelectedTimezone }) => {
  const handleChange = (event) => {
    setSelectedTimezone(event.target.value);
  };

  return (
    <div id="timezone">
      <label>
        <select
          name="timezone"
          value={selectedTimezone}
          onChange={handleChange}
        >
          {timezones.map((timezone) => (
            <option key={timezone.name} value={timezone.name}>
              {timezone.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default TimezonePicker;

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import {
  getReferenceComponents,
  setReferenceComponents,
} from 'shared/components/OnboardingTourV1.js/OnboardingTourUtils';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './PretestOnboarding.scss';

// components/assets
import SkipSignupPopup from 'shared/components/SkipSignupPopup/SkipSignupPopup';
import PretestMascotDesktop1 from 'assets/test/pretest-mascot-desktop1.png';
import PretestMascotDesktop2 from 'assets/test/pretest-mascot-desktop2.png';
import PretestMascotMobile1 from 'assets/test/pretest-mascot-mobile1.png';
import PretestMascotMobile2 from 'assets/test/pretest-mascot-mobile2.png';
import { ReactComponent as ArrowLeft } from 'assets/general/chevron-left.svg';
import { ReactComponent as ArrowRight } from 'assets/general/chevron-right.svg';

const getComponentPosition = id => {
  const referenceComponents = getReferenceComponents();
  if (referenceComponents && referenceComponents.length) {
    const found = referenceComponents.find(item => item.id === id);
    if (found) {
      const { component } = found;
      const { top, height, width, left } = component.getBoundingClientRect();
      return {
        top,
        height,
        width,
        left,
      };
    }
  }

  return null;
};

const SlideItem = ({ no, img, title, subTitle, MobileAction }) => {
  return (
    <div className="pretest-onboarding-content" data-value={no}>
      <div
        ref={ref => {
          setReferenceComponents({
            id: 'pretest-onboarding-content',
            component: ref,
          });
        }}
        className="slide-top-content"
        style={{
          backgroundImage: `url(${img})`,
        }}>
        <div className="slide-top-content-header">{title}</div>

        <MobileAction />
      </div>
    </div>
  );
};

const PretestOnboarding = () => {
  const history = useHistory();
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [actionComponentPosition, setActionComponentPosition] = useState(null);

  const handleSlideChange = item => {
    const isObject = _.isObject(item);
    const index = isObject ? item?.slide : item;

    setActiveSlideIndex(index);
  };

  const handleBack = () => {
    handleSlideChange(0);
  };

  const handleNext = () => {
    if (activeSlideIndex === 0) {
      handleSlideChange(1);
    } else {
      history.push('/profile-test');
    }
  };

  const MobileAction = () => {
    return (
      <>
        {isMobile && (
          <div
            className="action-container"
            style={{ ...actionComponentPosition }}>
            <div className="dots-content">
              <div
                className={`dot ${
                  activeSlideIndex === 0 ? 'active' : 'inactive'
                }`}
                onClick={() => handleSlideChange(0)}
              />
              <div
                className={`dot ${
                  activeSlideIndex === 1 ? 'active' : 'inactive'
                }`}
                onClick={() => handleSlideChange(1)}
              />
            </div>

            {activeSlideIndex === 1 && (
              <div
                className="arrow-content"
                onClick={() => history.push('/profile-test')}>
                Go
                <ArrowRight className="arrow" />
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const items = [
    SlideItem({
      no: '1',
      img: !isMobile ? PretestMascotDesktop1 : PretestMascotMobile1,
      title: (
        <>
          <p>
            Account created! Now, let’s customise this experience for you and
            your child.
          </p>

          <p>
            First, you’ll answer questions about yourself. Then, you’ll do the
            same for your child.
          </p>

          <p>
            Don’t forget to answer your child’s questions based on how{' '}
            <span>you</span> perceive <span>them</span>.
          </p>
        </>
      ),
      MobileAction: MobileAction,
    }),
    SlideItem({
      no: '2',
      img: !isMobile ? PretestMascotDesktop2 : PretestMascotMobile2,
      title: (
        <>
          <h1>Remember:</h1>

          <div className="list">
            <span>1)</span>
            <p>
              You’ll need about 15 minutes to complete both sets of questions.
            </p>
          </div>

          <div className="list">
            <span>2)</span>
            <p>
              Try to reflect on your personalities (not practised behaviours).
            </p>
          </div>

          <div className="list">
            <span>3)</span>
            <p>There are no wrong answers (so, don’t overthink it).</p>
          </div>
        </>
      ),
      MobileAction: MobileAction,
    }),
  ];

  useEffect(() => {
    const componentId = 'pretest-onboarding-content';

    setTimeout(() => {
      const componentPosition = getComponentPosition(componentId);

      const { height } = componentPosition;
      const adjustedHeight = (height / 100) * 6;

      const adjustedPosition = {
        bottom: adjustedHeight,
      };

      setActionComponentPosition(adjustedPosition);
    }, 100);
  }, []);

  return (
    <SkipSignupPopup type={'signup'}>
      <div className="pretest-onboarding-slider">
        <AliceCarousel
          items={items}
          responsive={{ items: 1 }}
          controlsStrategy="alternate"
          mouseTracking
          renderPrevButton={() => {
            return null;
          }}
          renderNextButton={() => {
            return null;
          }}
          activeIndex={activeSlideIndex}
          onSlideChanged={handleSlideChange}
        />

        {!isMobile && (
          <div className="action-container">
            <div
              className={`arrow-content ${
                activeSlideIndex === 1 ? 'visible' : 'hidden'
              }`}
              onClick={handleBack}>
              <ArrowLeft className="arrow" />
              Back
            </div>

            <div className="dots-content">
              <div
                className={`dot ${
                  activeSlideIndex === 0 ? 'active' : 'inactive'
                }`}
                onClick={() => handleSlideChange(0)}
              />
              <div
                className={`dot ${
                  activeSlideIndex === 1 ? 'active' : 'inactive'
                }`}
                onClick={() => handleSlideChange(1)}
              />
            </div>

            <div className="arrow-content" onClick={handleNext}>
              Next
              <ArrowRight className="arrow" />
            </div>
          </div>
        )}
      </div>
    </SkipSignupPopup>
  );
};

export default PretestOnboarding;

// import react from 'react'
import { ReactComponent as IconCircle } from "assets/icons/icon_circle.svg";
import { ReactComponent as IconSquare } from "assets/icons/icon_square.svg";
import { ReactComponent as IconTriangle } from "assets/icons/icon_triangle.svg";
import { ReactComponent as IconDiamond } from "assets/icons/icon_diamond.svg";
import { colorObjectV2 } from "shared/const";

const UserIcon = ({ relative, style, ...otherProps }) => {
  const colour = relative?.colour?.toLowerCase();
  const shape = relative?.shape;

  const color = colorObjectV2[colour] || colour;

  let selectedShape;
  switch (shape) {
    case "circle":
      selectedShape = (
        <IconCircle style={{ fill: color, ...style }} {...otherProps} />
      );
      break;
    case "square":
      selectedShape = (
        <IconSquare style={{ fill: color, ...style }} {...otherProps} />
      );
      break;
    case "triangle":
      selectedShape = (
        <IconTriangle
          style={{ fill: color, zIndex: "1", ...style }}
          {...otherProps}
        />
      );
      break;
    case "diamond":
      selectedShape = (
        <IconDiamond
          style={{ fill: color, zIndex: "2", ...style }}
          {...otherProps}
        />
      );
      break;
    default:
      selectedShape = (
        <IconCircle style={{ fill: color, ...style }} {...otherProps} />
      );
      break;
  }

  return selectedShape;
};

export default UserIcon;

import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import parse from 'html-react-parser';
import _ from 'lodash';
import { ToastContext } from 'context/ToastContext';
import { facetColor } from 'shared/const';
import useTrackDeeplink from 'shared/hooks/useTrackDeeplink';
import useChildPicker from 'shared/hooks/useChildPicker';
import {
  getTopic,
  getStub,
  getStubContent,
  // getStubsBigIdea,
  markStubAsRead,
  useHandleTopicsBack,
} from './TopicsUtils';

// components/assets
import Head from 'shared/components/Head';
import { ButtonSolid } from 'shared/components/Button/Button';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import { TopicsWrapper } from './TopicsComponent';
import UserIcon from 'shared/components/UserIcon';
import { ReactComponent as ChevronRight } from 'assets/home/chevron-right.svg';
import { ReactComponent as Close } from 'assets/general/close-normal-icon.svg';
import './TopicsStub.scss';

const TopicsStub = () => {
  const history = useHistory();

  const { topicSlug, stubSlug } = useParams();

  const { setToast } = useContext(ToastContext);

  const { selectedChildOption } = useChildPicker();

  const { topicId, stubId } = useTrackDeeplink({
    topicSlug,
    stubSlug,
    childId: selectedChildOption?.id,
  });

  const handleBack = useHandleTopicsBack({
    history,
    topicSlug,
    stubSlug,
  });

  const [topicData, setTopicData] = useState(null);
  const [stubData, setStubData] = useState(null);
  const [stubContentData, setStubContentData] = useState(null);
  // const [stubBigIdeaData, setStubBigIdeaData] = useState(null);
  const [selectedTip, setSelectedTip] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showTipDescModal, setShowTipDescModal] = useState(false);

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const topicTitle = topicData?.title;
  const stubFacetName = stubData?.facet_name;
  const stubTitle = stubData?.title;
  const stubImage = stubData?.featured_image;
  const isRead = !!stubData?.read_at;

  // const bigIdeaId = stubData?.stubs_big_idea_id;

  // const handleShowBigIdea = () => {
  //   history.push(
  //     `${url.TOPICSHUB}/${topicSlug}/page/${stubSlug}/big-idea/${bigIdeaId}`,
  //   );
  // };

  const handleMarkRead = async () => {
    const stubType = stubData?.type;
    const isChild = _.isEqual(stubType, 'child');

    const relativeId = isChild ? selectedChildOption?.id : null;

    const bodyParams = {
      relativeId,
    };

    try {
      await markStubAsRead({ stubsId: stubId, bodyParams });
      handleBack();
    } catch (err) {
      const { message } = err?.response?.data;

      if (message)
        setToast({
          type: 'error',
          message,
        });
    }
  };

  const getTopicData = async () => {
    try {
      const relativeId = selectedChildOption?.id;
      const queryParams = { relativeId: relativeId };

      const res = await getTopic({ queryParams, topicId });
      const topicData = res?.data?.data;

      if (topicData) {
        return topicData; // Return the fetched data
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: Journey.js:39 ~ getTopicsData ~ err:',
        err?.response,
      );
    }
  };

  // Modify the getStubData function to return the stubData after all images are loaded
  const getStubData = async () => {
    try {
      const relativeId = selectedChildOption?.id;
      const queryParams = {
        relativeId: relativeId,
      };

      const res = await getStub({ queryParams, stubsId: stubId });
      const stubData = res?.data?.data;

      if (stubData && stubData.featured_image && stubData.cloudinaryUrl) {
        // Create promises for the cloudinaryUrl
        const cloudinaryLoadingPromise = new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => resolve();
          img.onerror = error => reject(error);
          img.src = stubData.cloudinaryUrl;
        });

        // Wait for both the featured image and cloudinaryUrl to load
        await Promise.all([cloudinaryLoadingPromise]);

        // Both images have loaded, return the stubData and bigIdeaId
        return { stubData, bigIdeaId: stubData.stubs_big_idea_id };
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: JourneyStub.js:84 ~ getStubData ~ err:',
        err?.response,
      );
      throw err; // Re-throw the error to handle it outside the function if needed
    }
  };

  const getStubContentData = async () => {
    try {
      const relativeId = selectedChildOption?.id;
      const queryParams = {
        relativeId: relativeId,
      };

      const res = await getStubContent({ queryParams, stubsId: stubId });
      const stubContentData = res?.data?.data;
      return stubContentData;
    } catch (err) {
      console.log(
        '🚀 ~ file: JourneyStub.js:115 ~ getStubContentData ~ err:',
        err,
      );
      throw err; // Re-throw the error to handle it outside the function if needed
    }
  };

  // const getStubBigIdeaData = async ({ bigIdeaId }) => {
  //   try {
  //     const relativeId = selectedRelative?.id;
  //     const queryParams = {
  //       relativeId: relativeId,
  //     };
  //
  //     const res = await getStubsBigIdea({
  //       queryParams,
  //       stubsBigIdeaId: bigIdeaId,
  //     });
  //     const stubBigIdeaData = res?.data?.data;
  //     return stubBigIdeaData;
  //   } catch (err) {
  //     console.log(
  //       '🚀 ~ file: JourneyStub.js:120 ~ getStubBigIdeaData ~ err:',
  //       err?.response,
  //     );
  //     throw err; // Re-throw the error to handle it outside the function if needed
  //   }
  // };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        // Call getTopicData to get topicData
        const topicData = await getTopicData();

        // Call getStubData to get stubData and bigIdeaId
        const { stubData } = await getStubData();

        // Call getStubContentData to get stubContentData
        const stubContentData = await getStubContentData();

        // Call getStubBigIdeaData with stubBigIdeaData
        // const stubBigIdeaData = await getStubBigIdeaData({ bigIdeaId });

        // Now all the API calls and image loading are completed
        // Update the state with the fetched data
        setTopicData(topicData);
        setStubData(stubData);
        setStubContentData(stubContentData);
        // setStubBigIdeaData(stubBigIdeaData);

        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setIsLoading(false);
      }
    };

    if (topicId && stubId) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [topicId, stubId]);

  // Disable scrolling when tips modal displaying
  useEffect(() => {
    const domNode = document.body;

    if (showTipDescModal) {
      if (isMobileView) {
        domNode.style.marginTop = '0px';
        domNode.style.minHeight = '0px';
        domNode.style.height = 'calc(100vh - 100px)';
      }

      domNode.style.height = 'calc(100vh - 100px)';
      domNode.style.overflowY = 'hidden';
    } else {
      domNode.style.height = 'auto';
      domNode.style.overflowY = 'scroll';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTipDescModal]);

  if (isLoading) return <LoadingIndicator />;

  return (
    <TopicsWrapper
      backBtnText="Back"
      backBtnNavigation={() => handleBack()}
      hideMainNavigation={isMobileView && true}>
      <Head title="Connected App - Topics Stub" preventIndex />

      <div id="journey-stub">
        <div className="stub-header-container">
          <h1 className="stub-header">{stubTitle}</h1>
          <div className="stub-header-image">
            <img src={stubImage} alt="Stub" />
          </div>
          <p className="stub-header-description">
            This stub is part of a series:
          </p>
          <p
            className="stub-header-description-link"
            onClick={() => handleBack()}>
            {topicTitle}
          </p>
        </div>

        <div className="stub-content-wrapper">
          {/* Big Idea content */}
          {/* <StubEditorCard
            isBigIdea={true}
            data={stubBigIdeaData}
            isDescription={true}
            isReadMore={true}
            handleReadMore={() => handleShowBigIdea()}
          /> */}

          {stubContentData
            ?.reduce((groupedCards, content) => {
              const contentType = content?.type;

              const editorImageGroup =
                contentType === 'image' || contentType === 'editor';
              const tipsGroup = contentType === 'tips';

              // Grouping editor and image card
              if (editorImageGroup) {
                const lastGroup = groupedCards[groupedCards.length - 1];
                const lastGroupContentType = lastGroup?.[0]?.type;

                if (lastGroup && lastGroup[0]?.type === 'editor') {
                  // Group the current "image" content with the adjacent "editor" content
                  lastGroup.push(content);
                } else if (
                  (lastGroupContentType === 'editor' &&
                    contentType === 'image') ||
                  (lastGroupContentType === 'image' && contentType === 'editor')
                ) {
                  // Group with the previous "editor" or "image" content
                  lastGroup.push(content);
                } else {
                  // Start a new group for this "image" or "editor" content
                  groupedCards.push([content]);
                }
              }
              // Grouping tips cards
              else if (tipsGroup) {
                const lastGroup = groupedCards[groupedCards.length - 1];

                if (lastGroup && lastGroup[0]?.type === 'tips') {
                  // Group the current "tips" content with the adjacent "tips" content
                  lastGroup.push(content);
                } else {
                  // Start a new group if the previous item is not a "tips" item
                  groupedCards.push([content]);
                }
              } else {
                groupedCards.push(content);
              }

              return groupedCards;
            }, [])
            .map((content, index, groupedCards) => {
              const isGroup = Array.isArray(content);

              if (isGroup) {
                // Render "tips" or "editor" + "image" group
                const groupType = content[0]?.type; // Get the type of content in the group

                if (groupType === 'tips') {
                  // Check if this is the first item in the current tips group
                  const isFirstItemInTipsGroup =
                    index === 0 || groupedCards[index - 1]?.type !== 'tips';

                  return (
                    <div
                      key={index}
                      className={`stub-tips-group ${
                        isFirstItemInTipsGroup ? 'editor-tips-gap' : ''
                      }`}>
                      {content.map((tip, tipIndex) => (
                        <StubTipsCard
                          key={tipIndex}
                          tip={tip}
                          setSelectedTip={setSelectedTip}
                          setShowTipDescModal={setShowTipDescModal}
                          facetName={stubFacetName}
                        />
                      ))}
                    </div>
                  );
                } else if (groupType === 'editor' || groupType === 'image') {
                  return (
                    <div key={index} className="stub-editor-image-group">
                      {content.map((card, cardIndex) => {
                        const cardType = card?.type;

                        if (cardType === 'editor') {
                          return (
                            <StubEditorCard
                              key={cardIndex}
                              data={card}
                              isDescription={true}
                            />
                          );
                        } else if (cardType === 'image') {
                          return (
                            <StubInsightCard
                              key={cardIndex}
                              data={card}
                              type="image"
                            />
                          );
                        }
                        return null;
                      })}
                    </div>
                  );
                }
                return null;
              } else {
                const contentType = content?.type;

                // Render other types of cards
                return (
                  <div key={index}>
                    {contentType === 'editor' && (
                      <StubEditorCard data={content} isDescription={true} />
                    )}
                    {contentType === 'insight' && (
                      <StubInsightCard data={content} />
                    )}
                    {contentType === 'image' && (
                      <StubInsightCard data={content} type="image" />
                    )}
                    {contentType === 'reflection' && (
                      <StubInsightCard data={content} />
                    )}
                    {contentType === 'tips' && (
                      <StubTipsCard
                        tip={content}
                        setSelectedTip={setSelectedTip}
                        setShowTipDescModal={setShowTipDescModal}
                        facetName={stubFacetName}
                      />
                    )}
                  </div>
                );
              }
            })}
        </div>

        <ButtonSolid
          type="submit"
          className="mark-read-btn"
          children={isRead ? 'Read' : 'Mark as read'}
          onClick={() => handleMarkRead()}
          disabled={isRead}
        />

        {showTipDescModal && (
          <StubTipsDescModal
            selectedTip={selectedTip}
            setShowTipDescModal={setShowTipDescModal}
            selectedRelative={selectedChildOption}
            facetName={stubFacetName}
          />
        )}
      </div>
    </TopicsWrapper>
  );
};

const StubEditorCard = ({
  isBigIdea,
  data,
  isDescription,
  isReadMore,
  handleReadMore,
}) => {
  const title = isBigIdea && data?.title;
  const description = isBigIdea
    ? data?.short_description && parse(data?.short_description)
    : data?.description && parse(data?.description);

  return (
    <div id="stub-editor-card">
      <h1 className="stub-content-header">{title}</h1>

      {isDescription && (
        <p
          className={`stub-content-description ${
            isReadMore && 'more-content'
          }`}>
          {description}
        </p>
      )}

      {isReadMore && (
        <h4 className="read-more-btn" onClick={handleReadMore}>
          Read More
        </h4>
      )}
    </div>
  );
};

const StubInsightCard = ({ data, type }) => {
  const isTypeImage = _.isEqual(type, 'image');
  const cloudinaryUrl = data?.cloudinaryUrl;
  const imageUrl = data?.image_url;
  const image = isTypeImage ? imageUrl : cloudinaryUrl;

  return (
    <div id="stub-insight-card">
      <img src={image} alt="Stub Insight" />
    </div>
  );
};

const StubTipsCard = ({
  facetName,
  tip,
  setSelectedTip,
  setShowTipDescModal,
}) => {
  const footerBgColor = `${facetColor(facetName)}45`;

  const category = tip?.category;
  const title = tip?.title;

  const handleShowTipDescModal = ({ selectedTip }) => {
    // Set the selected tip in the state or perform any other actions with it
    setSelectedTip(selectedTip);
    setShowTipDescModal(true);
  };

  return (
    <div id="stub-tips-card">
      <div className="card-header-container">{title}</div>
      <div
        className="card-footer-container"
        style={{ backgroundColor: footerBgColor }}
        onClick={() => handleShowTipDescModal({ selectedTip: tip })}>
        <p className="footer-title">{category}</p>

        <div className="footer-read-more-container">
          <h4 className="read-more-text">Read more</h4>
          <ChevronRight className="read-more-icon" />
        </div>
      </div>
    </div>
  );
};

const StubTipsDescModal = ({
  selectedTip,
  setShowTipDescModal,
  facetName,
  selectedRelative,
}) => {
  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const fctColor = facetColor(facetName);
  const footerBgColor = `${facetColor(facetName)}45`;

  const childName = selectedRelative?.name;
  const title = selectedTip?.title;
  const description = parse(selectedTip?.description);
  const category = selectedTip?.category;

  return (
    <div id="stub-tips-desc-modal" onClick={() => setShowTipDescModal(false)}>
      <div className="modal-content-wrapper">
        {!isMobileView && (
          <div className="modal-close-container">
            <Close className="modal-close-btn" />
          </div>
        )}

        <div className="tips-desc-container">
          <div className="card-content-container">
            <div className="card-header">
              <div className="header-wrapper">
                <UserIcon
                  className="header-child-icon"
                  relative={selectedRelative}
                />
                <p className="header-child-name">{childName}</p>
                <span
                  className="header-facet-burble"
                  style={{
                    color: fctColor,
                    borderColor: fctColor,
                  }}>
                  {facetName}
                </span>
              </div>
            </div>

            <div className="card-content">
              <h2 className="card-content-title">{title}</h2>
              <p className="card-content-description">{description}</p>
            </div>
          </div>

          <div
            className="card-footer-container"
            style={{
              backgroundColor: footerBgColor,
            }}>
            {category}
          </div>
        </div>

        {isMobileView && (
          <div className="modal-close-container">
            <Close className="modal-close-btn" />
          </div>
        )}
      </div>
    </div>
  );
};

export default TopicsStub;

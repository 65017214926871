import React, { useRef, useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { capitalize } from 'shared/helper/helper';
import { ReactComponent as Info } from 'assets/general/info-white-icon.svg';
import './PeaksSnapshotComponents.scss';

export const Tooltip = ({ data, ...otherProps }) => {
  const iconRef = useRef(null);

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const [isTooltipVisible, setTooltipVisible] = useState(
    isMobile ? false : true,
  );

  const childName = capitalize(data?.child_name);

  const handleClick = () => {
    if (isMobile) {
      setTooltipVisible(!isTooltipVisible);
    }
  };

  const handleClickOutside = event => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setTooltipVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={iconRef}
      className="peaksnapshot-hover-button"
      onClick={handleClick}
      {...otherProps}>
      <Info className="subheader-info-icon" />

      {isTooltipVisible && (
        <div className="peaksnapshot-tooltip-text" id="align-bottom">
          <div className="test-tooltip-text">
            <p>Below is a summary of the various PEAKS Factors and Facets.</p>

            <p>
              Each of these are explored in the corresponding sections and
              chapters of your Playbook.
            </p>

            <p>
              Discover at a glance, how {childName}'s personality relates to
              yours across 25 personality traits.
            </p>

            <p>To begin, try tapping on any of the letters: P, E, A, K or S.</p>
          </div>
        </div>
      )}
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import parse from 'html-react-parser';
import { domainColor } from 'shared/const';
import { capitalize } from 'shared/helper/helper';
import Vimeo from '@u-wave/react-vimeo';
import { listChapters } from 'routes/Books/api';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import { ReactComponent as Close } from 'assets/general/close.svg';
import './VideoModal.scss';

export const DomainIntoVideoModal = ({
  bookId,
  sectionId,
  childName,
  handleClose,
}) => {
  const [chapterData, setChapterData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showNext, setShowNext] = useState(false);

  const chapterTitle = chapterData?.intro_video_desc;
  const domainName = chapterData?.name;
  const videoUrl = chapterData?.intro_video;
  const labelColor = domainColor(domainName);

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const getChapterList = async () => {
    setIsLoading(true);

    try {
      const res = await listChapters(bookId, sectionId);
      const { data } = res.data;
      setChapterData(data);

      setIsLoading(false);
    } catch (err) {
      console.log(err?.response);
      setIsLoading(false);
    }
  };

  const handleNext = async () => {
    setChapterData(null);
  };

  const handlePlayerEnd = () => {
    setShowNext(true);
  };

  useEffect(() => {
    getChapterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <div className="loading-wrapper">
        <LoadingIndicator loadingText="Loading your profile..." />
      </div>
    );
  }

  return (
    <div
      className={`domain-video-content-overlay ${chapterData ? 'active' : ''}`}>
      <div className="content-container" onClick={e => e.stopPropagation()}>
        <div className="head">
          <div className="left">
            <div className="label" style={{ backgroundColor: labelColor }}>
              {capitalize(domainName)}
            </div>

            {chapterTitle && (
              <div className="title">
                {parse(chapterTitle.replace('{[child]}', childName))}
              </div>
            )}
          </div>

          {!isMobile && <Close className="close-icon" onClick={handleClose} />}
        </div>

        <div className="content">
          <div className="video-container">
            {videoUrl && (
              <Vimeo
                video={videoUrl}
                width={680}
                height={420}
                onEnd={handlePlayerEnd}
                autoplay={true}
              />
            )}
          </div>

          {isMobile && <Close className="close-icon" onClick={handleClose} />}

          {showNext && <button onClick={handleNext}>Next</button>}
        </div>
      </div>
    </div>
  );
};

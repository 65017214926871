import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import axios from 'axios';
import { UserContext } from 'context/UserContext';
import { useSignupStatus } from 'routes/Auth/authUtils';
import { getHeaderTitle, useMainNavDetector } from './MainNavigationUtils';
import useStickyHeader from 'shared/hooks/useStickyHeader';
import checkIsCurrentPath from 'shared/function/checkIsCurrentPath';

// components & assets
import { ButtonLink } from 'shared/components/Button/Button';
import { HeaderIconItems, NavItems } from './MainNavigationComponents';
import Logout from 'shared/components/Logout/Logout';
import ManagePremiumRibbon from 'routes/ManagePremiumAccess/ManagePremiumRibbon';
import SideNav from './SideNav';
import { EndTestPrompt } from 'routes/ProfileTest/Test/TestComponents';
import logoImg from 'assets/logo-main.png';
import './MainNavigation.scss';

const MainNavigation = ({ location, history }) => {
  const { user, setUser, userRelatives } = useContext(UserContext);

  const tokenFromStorage =
    localStorage.getItem('token') || sessionStorage.getItem('token');

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const path = location.pathname;

  const { isMainNav } = useMainNavDetector();

  const [isLoading, setIsLoading] = useState(true);

  const handleLogout = () => {
    setUser({});

    sessionStorage.removeItem('notification_banner');
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    history.push('/login');
  };

  const getUser = async () => {
    try {
      const userToken = user.token ? user.token : tokenFromStorage;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + userToken;
      const response = await axios.get('/user');
      const authUser = response.data.data;

      const updatedUser = { ...authUser, ...user, token: userToken };
      setUser(updatedUser);
      setIsLoading(false);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    if (tokenFromStorage) {
      getUser();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user?.token) {
      setIsLoading(true);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
      getUser();
    } else {
      setUser({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.token]);

  useEffect(() => {
    if (!_.isEmpty(user)) {
      Promise.all([getUser()]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.subscription_status]);

  const renderNav = () => {
    // eslint-disable-next-line no-mixed-operators
    // user.is_temp === 1
    if (_.isEmpty(user) && !isLoading) {
      return (
        <>
          <nav className="main-nav no1">
            <div className="container">
              <SideNav />
              <div className="logo-container">
                <a href="https://www.getconnected.sg/" target="blank">
                  <img src={logoImg} alt="" />
                </a>
              </div>

              <div className="right-nav">
                {user.is_temp === 1 ? (
                  <Logout handleLogout={() => handleLogout()} />
                ) : (
                  <>
                    <ButtonLink
                      className="signup"
                      to="/signup"
                      children="Sign Up"
                    />

                    <ButtonLink
                      className="login"
                      to="/login"
                      children="Log In"
                    />
                  </>
                )}
              </div>
            </div>
          </nav>
        </>
      );
    }

    const isChildTestTaken = user.child_test_taken === 1;
    const isParentTestTaken = user.parent_test_taken === 1;
    const isAllBooksGenerated = user.all_books_generated === 1;

    const disableCursor = (disabled, pressable) => {
      if (_.isEmpty(userRelatives)) {
        if (!isChildTestTaken || !isParentTestTaken || !isAllBooksGenerated)
          return disabled;
      } else return pressable;
    };

    if (!_.isEmpty(user)) {
      return (
        <>
          {isMobileView ? (
            <MobileNav
              history={history}
              path={path}
              disableCursor={disableCursor}
            />
          ) : (
            <DesktopNav
              history={history}
              location={location}
              disableCursor={disableCursor}
              isChildTestTaken={isChildTestTaken}
              isParentTestTaken={isParentTestTaken}
              isAllBooksGenerated={isAllBooksGenerated}
              handleLogout={handleLogout}
            />
          )}
        </>
      );
    }

    return (
      <nav className="main-nav mobile-hidden">
        <div className="container dashboard"></div>
      </nav>
    );
  };

  if (!isMainNav) return null;

  return renderNav();
};

const MobileNav = ({ history, path, disableCursor }) => {
  const { user } = useContext(UserContext);

  const { isSignupCompleted } = useSignupStatus({ user });

  const headerTitle = getHeaderTitle({ path: path });

  const domainSlug = JSON.parse(
    sessionStorage.getItem('domainSlug'),
  )?.toLowerCase();
  const disablePaths = !path.includes(domainSlug) && !path.includes('snapshot');

  useStickyHeader({
    headerSelector: '.mob-top-nav',
    otherItemSelector: '#react-smartbanner',
  });

  return (
    <>
      {headerTitle && disablePaths && (
        <nav className={`mob-top-nav ${!headerTitle && 'display-none'} `}>
          <div className="mob-top-nav-header">
            <div className="mob-top-nav-title-container">
              <h1 className="mob-top-nav-title">{headerTitle}</h1>
            </div>
            <HeaderIconItems
              history={history}
              path={path}
              isSignupCompleted={isSignupCompleted}
              isPathTest={false}
              handleShowEndTestPrompt={() => {}}
            />
          </div>
        </nav>
      )}

      {headerTitle && (
        <nav className="bottom-nav">
          <NavItems
            path={path}
            disableCursor={disableCursor}
            isPathTest={false}
            handleShowEndTestPrompt={() => {}}
          />
        </nav>
      )}
    </>
  );
};

const DesktopNav = ({
  history,
  location,
  disableCursor,
  isChildTestTaken,
  isParentTestTaken,
  isAllBooksGenerated,
  handleLogout,
  setShowJourneySelectionModal,
}) => {
  const { user, userRelatives, setTempUser, setTempChild } =
    useContext(UserContext);

  const { isSignupCompleted } = useSignupStatus({ user });

  const path = location.pathname;
  const isPathTest = checkIsCurrentPath({ path, pathName: 'test' });

  const params = new URLSearchParams(location.search);
  const testType = isPathTest && path.replace('/test/', '');
  const addChild = params.get('addchild');

  const [showEndTestPopup, setShowEndTestPopup] = useState(false);

  const handleClickLogo = () => {
    if (isPathTest) {
      setShowEndTestPopup(true);
    } else {
      if (_.isEmpty(userRelatives)) {
        window.open('https://www.getconnected.sg/', '_blank');
      } else {
        if (!isChildTestTaken || !isParentTestTaken || !isAllBooksGenerated) {
          return;
        } else {
          window.open('https://www.getconnected.sg/', '_blank');
        }
      }
    }
  };

  const handleShowEndTestPrompt = () => {
    setShowEndTestPopup(true);
  };

  return (
    <nav className="main-nav mobile-hidden">
      <div className="container dashboard">
        <div className="left-content">
          <div
            className="logo-container dashboard"
            style={{ cursor: disableCursor('default', 'pointer') }}>
            <img src={logoImg} onClick={() => handleClickLogo()} alt="" />
          </div>
        </div>

        <div className="nav-container dashboard">
          <NavItems
            path={path}
            disableCursor={disableCursor}
            setShowJourneySelectionModal={setShowJourneySelectionModal}
            isPathTest={isPathTest}
            handleShowEndTestPrompt={handleShowEndTestPrompt}
          />
        </div>

        <div className="right-nav">
          <HeaderIconItems
            history={history}
            path={path}
            isSignupCompleted={isSignupCompleted}
            isPathTest={isPathTest}
            handleShowEndTestPrompt={handleShowEndTestPrompt}
          />
          {!isSignupCompleted && <Logout handleLogout={() => handleLogout()} />}
        </div>
      </div>

      <ManagePremiumRibbon history={history} isChapterModule={false} />

      {showEndTestPopup && (
        <EndTestPrompt
          history={history}
          type={testType}
          isAddChild={addChild}
          setTempUser={setTempUser}
          setTempChild={setTempChild}
          setShowEndTestPopup={setShowEndTestPopup}
        />
      )}
    </nav>
  );
};

export default withRouter(MainNavigation);

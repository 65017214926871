import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { UserContext } from 'context/UserContext';
import _ from 'lodash';
import useDisableScreenScrollable from 'shared/hooks/useDisableScreenScrollable';
import { shortenName } from 'shared/helper/helper';
import {
  updateNotificationSettings,
  getNotificationSettings,
  getSpotlightSettings,
  updateSpotlightSettings,
  handleSettingBack,
} from '../SettingModuleUtils';

// components/assets
import { ButtonSolid } from 'shared/components/Button/Button';
import { BackButton, TabBar } from './SettingsComponent';
import IOSTimePicker from './IOSTimePicker';
import TimezonePicker from './TimezonePicker';
import UserIcon from 'shared/components/UserIcon';
import { ReactComponent as Checked } from 'assets/general/checked-plain-icon.svg';
import { ReactComponent as Close } from 'assets/general/close.svg';
import './Spotlight.scss';

const Spotlight = ({ history }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const toTab = params.get('tab');
  const isToTabDelivery = _.isEqual(toTab, 'delivery');

  const tabs = [
    {
      label: 'Content',
      value: 'content',
      type: 'left',
      isSelected: isToTabDelivery ? false : true,
    },
    {
      label: 'Delivery',
      value: 'delivery',
      type: 'right',
      isSelected: isToTabDelivery ? true : false,
    },
  ];

  const [spotlightItems, setSpotlightItems] = useState(tabs);
  const [selectedSpotlightItem, setSelectedSpotlightItem] = useState(
    isToTabDelivery ? 'delivery' : 'content',
  );
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isContentValueChanged, setIsContentValueChanged] = useState(false);
  const [isDeliveryValueChanged, setIsDeliveryValueChanged] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const handleShowTabContent = ({ item }) => {
    const updatedSpotlights = spotlightItems.map(spotlight => {
      if (spotlight.value === item.value) {
        return { ...spotlight, isSelected: true };
      } else if (spotlight.isSelected) {
        return { ...spotlight, isSelected: false };
      }
      return spotlight;
    });

    setSpotlightItems(updatedSpotlights);
    setSelectedSpotlightItem(item.value);

    setErrorMessage(null);
    setSuccessMessage(null);
  };

  return (
    <div id="settings-spotlight">
      <div className="user-form">
        {isMobile && (
          <BackButton
            title="Settings"
            handleClick={() =>
              handleSettingBack({ history, location, type: 'tab', isMobile })
            }
          />
        )}
        <h1 className="header">Manage Spotlight</h1>

        <div className="settings-spotlight-container">
          <TabBar
            items={spotlightItems}
            handleShowTabContent={handleShowTabContent}
          />

          {selectedSpotlightItem && (
            <TabContent
              selectedSpotlightItem={selectedSpotlightItem}
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              isContentValueChanged={isContentValueChanged}
              setIsContentValueChanged={setIsContentValueChanged}
              isDeliveryValueChanged={isDeliveryValueChanged}
              setIsDeliveryValueChanged={setIsDeliveryValueChanged}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const TabContent = ({
  selectedSpotlightItem,
  successMessage,
  setSuccessMessage,
  errorMessage,
  setErrorMessage,
  isContentValueChanged,
  setIsContentValueChanged,
  isDeliveryValueChanged,
  setIsDeliveryValueChanged,
}) => {
  const { user, setUser } = useContext(UserContext);

  const [formValue, setFormValue] = useState({
    spotlightTimezone: '',
    sendSpotlight: 0,
    sendSpotlightAt: '',
    sendAnnouncement: 0,
    sendAnnouncementAt: 1,
    relatives: '',
  });

  const [spotlightSettingsData, setSpotlightSettingsData] = useState([]);
  const [isIOSPickerOnTouch, setIsIOSPickerOnTouch] = useState(false);
  const [databaseTimezone, setDatabaseTimezone] = useState({});
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [selectedTime, setSelectedTime] = useState({
    hour: 6,
    minute: 0,
    ampm: 'am',
  });

  const isTabContent = _.isEqual(selectedSpotlightItem, 'content');

  useDisableScreenScrollable({ isDisable: isIOSPickerOnTouch });

  const handleChildToggle = spotlightSetting => {
    const index = _.findIndex(spotlightSettingsData, [
      'id',
      spotlightSetting.id,
    ]);

    if (spotlightSetting.send_spotlight === 1) {
      spotlightSettingsData[index].send_spotlight = 0;
      setSpotlightSettingsData([...spotlightSettingsData]);
    } else if (spotlightSetting.send_spotlight === 0) {
      spotlightSettingsData[index].send_spotlight = 1;
      setSpotlightSettingsData([...spotlightSettingsData]);
    }
  };

  const handleEmailToggle = () => {
    if (formValue.sendSpotlight === 1) {
      setFormValue({ ...formValue, sendSpotlight: 0 });
    } else if (formValue.sendSpotlight === 0) {
      setFormValue({ ...formValue, sendSpotlight: 1 });
    }
  };

  const submitForm = async () => {
    setSuccessMessage(null);
    setErrorMessage(null);

    const emailValueToSubmit = {
      spotlight_timezone: formValue.spotlightTimezone,
      send_spotlight: formValue.sendSpotlight,
      send_spotlight_at: formValue.sendSpotlightAt,
      relatives: formValue.relatives,
      send_announcement_noti: 1,
      is_mob: 0,
    };

    const spotlightValueToSubmit = {
      relatives: spotlightSettingsData,
    };

    try {
      await updateNotificationSettings({ ...user, ...emailValueToSubmit });
      await updateSpotlightSettings({
        ...user,
        ...spotlightValueToSubmit,
      });

      setSuccessMessage('Changes successfully saved');
      setIsContentValueChanged(false);
      setIsDeliveryValueChanged(false);

      setUser({ ...user, ...emailValueToSubmit });
    } catch (err) {
      const { message } = err.response.data;
      setErrorMessage(message);
    }
  };

  const getSpotlightSettingsData = async () => {
    try {
      const res = await getSpotlightSettings();
      const { data } = res.data;

      if (data) setSpotlightSettingsData(data);
    } catch (err) {
      console.log({ err });
    }
  };

  const getNotificationSettingsData = async () => {
    try {
      const res = await getNotificationSettings();
      const { data } = res.data;

      const hourValue =
        data.send_spotlight_at.slice(0, 2) === '00'
          ? '12'
          : data.send_spotlight_at.slice(0, 2);
      const ampmValue = data.send_spotlight_at.slice(0, 2);

      setFormValue({
        ...formValue,
        sendSpotlight: data?.send_spotlight,
        spotlightTimezone: data?.spotlight_timezone,
        relatives: data?.relative,
        sendSpotlightAt: data?.send_spotlight_at,
        sendAnnouncement: data?.send_announcement_noti,
      });
      setIsContentValueChanged(false);
      setIsDeliveryValueChanged(false);

      setSelectedTimezone(data?.spotlight_timezone);
      setDatabaseTimezone(data?.spotlight_timezone);
      setSelectedTime({
        hour: hourValue > 12 ? parseInt(hourValue - 12) : parseInt(hourValue),
        minute: parseInt(data.send_spotlight_at.slice(3, 5)),
        ampm: ampmValue === '00' ? 'pm' : ampmValue > 12 ? 'pm' : 'am',
      });
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    Promise.all([getSpotlightSettingsData(), getNotificationSettingsData()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedTimezone) {
      setFormValue({
        ...formValue,
        spotlightTimezone: selectedTimezone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimezone]);

  useEffect(() => {
    const isValueChanged =
      Array.isArray(formValue.relatives) &&
      formValue.relatives.some((formData, index) => {
        const spotlightSettingData = spotlightSettingsData[index];

        return (
          formData?.send_spotlight !== spotlightSettingData?.send_spotlight ||
          formData?.spotlightTimezone !==
            spotlightSettingData.spotlight_timezone ||
          formData?.sendSpotlightAt !==
            spotlightSettingData?.send_spotlight_at ||
          formData?.sendAnnouncement !==
            spotlightSettingData?.send_announcement_noti
        );
      });

    setIsContentValueChanged(isValueChanged);
  }, [formValue, spotlightSettingsData, setIsContentValueChanged]);

  useEffect(() => {
    const isValueChanged =
      formValue?.sendSpotlight !== user?.send_spotlight ||
      formValue?.sendSpotlightAt !== user?.send_spotlight_at ||
      formValue?.spotlightTimezone !== databaseTimezone;

    setIsDeliveryValueChanged(isValueChanged);
  }, [
    formValue.sendSpotlight,
    formValue.sendSpotlightAt,
    formValue.spotlightTimezone,
    user.send_spotlight,
    user.send_spotlight_at,
    databaseTimezone,
    setIsDeliveryValueChanged,
  ]);

  useEffect(() => {
    const { hour, minute, ampm } = selectedTime;
    const hour24Format = (ampm === 'pm' ? hour + 12 : hour)?.toLocaleString(
      'en-US',
      {
        minimumIntegerDigits: 2,
        useGrouping: false,
      },
    );

    const minute24Format = minute?.toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });

    if (selectedTime) {
      setFormValue({
        ...formValue,
        sendSpotlightAt: `${
          hour24Format === '24' ? '00' : hour24Format
        }:${minute24Format}:00`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime]);

  return (
    <div className="settings-spotlight-form">
      {isTabContent ? (
        <>
          {spotlightSettingsData?.length > 0 && (
            <div className="settings-spotlight-content">
              <p className="content-description">
                Adjust which child's insights are generated for you each day.
              </p>

              <div className="settings-spotlight-childs">
                {spotlightSettingsData?.map((spotlightSetting, key) => (
                  <div className="child-group" key={key}>
                    <div className="input-container">
                      <div className="left">
                        <UserIcon relative={spotlightSetting} />

                        <h3 className="child-name">
                          {shortenName(spotlightSetting?.name)}
                        </h3>
                      </div>

                      <div className="right">
                        <div
                          className={`switch ${
                            spotlightSetting.send_spotlight === 0
                              ? ''
                              : 'active'
                          }`}
                          onClick={() => handleChildToggle(spotlightSetting)}>
                          <span />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="settings-spotlight-content">
          <div className="settings-spotlight-delivery">
            <div className="input-container">
              <h3 className="header">Get notified by:</h3>
              <div className="email-container">
                <h3 className="header">Email</h3>
                <div
                  className={`switch ${
                    formValue.sendSpotlight === 0 ? '' : 'active'
                  }`}
                  onClick={() => {
                    handleEmailToggle();
                  }}>
                  <span />
                </div>
              </div>

              <h3 className="header daily">Sent daily at:</h3>
              <div
                className="time-picker-container"
                onTouchStart={() => setIsIOSPickerOnTouch(true)}
                onTouchEnd={() => setIsIOSPickerOnTouch(false)}>
                <IOSTimePicker
                  selectedTime={selectedTime}
                  setSelectedTime={setSelectedTime}
                />
              </div>

              <TimezonePicker
                selectedTimezone={selectedTimezone}
                setSelectedTimezone={setSelectedTimezone}
              />
            </div>
          </div>
        </div>
      )}

      <div className="submit-container">
        {isTabContent && successMessage && !isContentValueChanged && (
          <p className="success-message">
            <Checked /> {successMessage}
          </p>
        )}
        {!isTabContent && successMessage && !isDeliveryValueChanged && (
          <p className="success-message">
            <Checked /> {successMessage}
          </p>
        )}
        {errorMessage && (
          <p className="error-message">
            <Close /> {errorMessage}
          </p>
        )}

        {isTabContent && (
          <ButtonSolid
            className={`submit-btn ${!isContentValueChanged && 'disabled'} ${
              isContentValueChanged && 'success'
            }`}
            onClick={submitForm}
            disabled={!isContentValueChanged}
            children="Save Changes"
          />
        )}

        {!isTabContent && (
          <ButtonSolid
            className={`submit-btn ${!isDeliveryValueChanged && 'disabled'} ${
              isDeliveryValueChanged && 'success'
            }`}
            onClick={submitForm}
            disabled={!isDeliveryValueChanged}
            children="Save Changes"
          />
        )}
      </div>
    </div>
  );
};

export default withRouter(Spotlight);

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { useOnboardingStatus } from 'shared/components/OnboardingTour/OnboardingTourUtils';
import checkIsCurrentPath from 'shared/function/checkIsCurrentPath';

export const getBookList = () => axios.get('/books');

// MOB header title
export const getHeaderTitle = ({ path }) => {
  const isPathHome = checkIsCurrentPath({
    path,
    pathName: 'home',
  });

  const isPathPlaybook = checkIsCurrentPath({
    path,
    pathName: 'playbook',
  });

  const isPathTopics = checkIsCurrentPath({
    path,
    pathName: 'topics',
  });

  switch (true) {
    case isPathHome && !path.includes('settings'):
      return 'Home';
    case isPathPlaybook:
      return 'Playbook';
    case isPathTopics:
      return 'Topics (Beta)';
    default:
      return null;
  }
};

export const useMainNavDetector = () => {
  const location = useLocation();
  const path = location.pathname;

  const { isOnboard } = useOnboardingStatus({
    path,
  });

  const [isMainNav, setIsMainNav] = useState(true);

  const isLoginOrSignup =
    path === '/login' ||
    path === '/sign-up' ||
    path === '/forgot-password' ||
    path === '/reset-password' ||
    path === '/signup' ||
    path === '/signup-verification';
  const isSignupReferral = checkIsCurrentPath({
    path,
    pathName: 'signup-referral',
  });
  const isSignupSuccess = checkIsCurrentPath({
    path,
    pathName: 'account-success',
  });
  const isTestCompleted = checkIsCurrentPath({
    path,
    pathName: 'welcome',
  });

  const isSettingsOrMailbox =
    checkIsCurrentPath({
      path,
      pathName: 'settings',
    }) ||
    checkIsCurrentPath({
      path,
      pathName: 'mailbox',
    });
  const isTopicsBigIdea = checkIsCurrentPath({
    path,
    pathName: 'big-idea',
  });
  const isCheckout = checkIsCurrentPath({
    path,
    pathName: 'checkout',
  });
  const isOrderComplete = checkIsCurrentPath({
    path,
    pathName: 'order-completed',
  });
  const isLobbyArea = path.includes('lobby-area');

  const isHideMainNav =
    isLoginOrSignup ||
    isSignupReferral ||
    isSignupSuccess ||
    isTestCompleted ||
    isOnboard ||
    isSettingsOrMailbox ||
    isTopicsBigIdea ||
    isCheckout ||
    isOrderComplete ||
    isLobbyArea;

  useEffect(() => {
    if (isHideMainNav) {
      setIsMainNav(false);
    } else {
      setIsMainNav(true);
    }
  }, [isHideMainNav]);

  return { isMainNav };
};

import axios from 'axios';

const downloadFile = ({ source, fileName }) => {
  if (typeof source === 'string') {
    // If source is a URL
    axios
      .get(source, { responseType: 'blob' })
      .then(response => {
        const contentType = response.headers['content-type'];
        if (contentType && contentType.toLowerCase() === 'application/pdf') {
          const fileurl = URL.createObjectURL(
            new Blob([response.data], { type: 'application/pdf' }),
          );
          initiateDownload(fileurl, fileName);
        } else {
          console.error(
            'Invalid Content-Type. Expected application/pdf.',
            response,
          );
        }
      })
      .catch(error => console.error('Error fetching file:', error));
  } else if (source instanceof Blob) {
    // If source is a Blob (file content)
    const fileurl = URL.createObjectURL(source);
    initiateDownload(fileurl, fileName);
  } else {
    console.error('Invalid source type.');
  }
};

const initiateDownload = (url, fileName) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();

  // Clean up
  setTimeout(() => {
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, 1000);
};

export default downloadFile;

import React, { useState, useEffect, useContext, useMemo } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import countryList from 'react-select-country-list';
import Select from 'react-select';
import moment from 'moment';
import { url } from 'config/urlConfig';
import { ToastContext } from 'context/ToastContext';
import { UserContext } from 'context/UserContext';
import { trackingAction, TRACKING_PURCHASES } from 'shared/function/Tracking';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import { clearDiscountedPrice, setDiscountedPrice } from './Discount/GetSetter';
import { useDetectPreviousPath } from 'routes/SettingsModule/SettingModuleUtils';
import {
  validateDiscountCode,
  updatePaymentStatus,
  paymentOptions,
  navigatePayment,
  updateUserPaymentStatus,
  WALLET_TYPES,
} from './CheckoutUtils';
import useProductList from './useProductList';
import usePaymentData from './usePaymentData';
import './Checkout.scss';

// Payment
import CardForm from './CardForm';
import PayNowForm from './PayNowForm';
import { PayPalButton } from 'react-paypal-button-v2';
import usePaymentRequest from './usePaymentRequest';

// components/assets
import Head from 'shared/components/Head.js';
import { HeaderBack } from 'routes/ManagePremiumAccess/ManagePremiumComponents';
import { ButtonSolid } from 'shared/components/Button/Button';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import { ReactComponent as ArrowLeft } from 'assets/general/chevron-left.svg';
import { ReactComponent as VisaIcon } from 'assets/products/payment-visa-icon.svg';
import { ReactComponent as MastercardIcon } from 'assets/products/payment-mastercard-icon.svg';
import { ReactComponent as AmexIcon } from 'assets/products/payment-amex-icon.svg';
import { ReactComponent as PaynowIcon } from 'assets/products/payment-paynow-icon.svg';
import { ReactComponent as GooglePayIcon } from 'assets/products/google-pay-icon.svg';
import { ReactComponent as ApplePayIcon } from 'assets/products/apple-pay-icon.svg';
import { ErrorOutlineOutlined } from '@material-ui/icons';
import { Close } from '@material-ui/icons';

const Checkout = ({ location }) => {
  const history = useHistory();

  const { setToast } = useContext(ToastContext);

  const { user, setUser, cartItem, setCartItem } = useContext(UserContext);

  const { userPremiumEndDate } = useUserPremiumAccess();

  const { previousPath } = useDetectPreviousPath({
    location,
  });

  const [paymentMethod, setPaymentMethod] = useState('');
  const [showPaymentSelect, setShowPaymentSelect] = useState(true);
  const [orderId, setOrderId] = useState(null);

  const [countryCode, setCountryCode] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const params = new URLSearchParams(location.search);
  const addChild = params.get('addchild') === 'true';
  const unlockplaybook = params.get('unlockplaybook') === 'true';

  const isMobileView = useMediaQuery({ query: '(max-width: 500px)' });

  const checkoutBackText = addChild
    ? 'Add a child profile'
    : !isMobileView
    ? 'Upgrade or renew Premium'
    : 'Upgrade to Premium';

  const [selectedCountry, setSelectedCountry] = useState('');
  const options = useMemo(() => countryList().getData(), []);

  const [discountCode, setDiscountCode] = useState(null);
  const [discountInfo, setDiscountInfo] = useState(null);
  const [isDiscountCodeValid, setIsDiscountCodeValid] = useState(false);
  const [isDiscountCodeError, setIsDiscountCodeError] = useState(false);
  const [calculationResult, setCalculationResult] = useState(null);

  const {
    isProductLoading,
    selectedProduct,
    selectedProductPrice,
    currencyCodeSymbol,
  } = useProductList();

  const productId = selectedProduct?.id;

  const formattedPaymentMethod =
    paymentMethod?.charAt(0).toUpperCase() + paymentMethod.slice(1);
  const paymentLabel = paymentOptions[formattedPaymentMethod]?.label;
  const total = calculationResult?.total_price;

  const {
    isPaymentLoading,
    paymentData,
    requestPaymentFromAPI,
    resetPaymentData,
  } = usePaymentData({
    calculationResult,
    setOrderId,
  });

  const isPaynowProcessing =
    paymentMethod && _.isEqual(paymentMethod, 'paynow') && isPaymentLoading;

  const { paymentRequest } = usePaymentRequest({
    paymentData,
    selectedProduct,
    totalPrice: total,
    unlockplaybook,
    addChild,
    discountCode,
    isDiscountCodeValid,
    discountInfo,
    history,
  });

  const handleBack = () => {
    if (addChild) {
      history.push(`${url.ADD_CHILD}?from=${previousPath}`);

      if (orderId)
        trackingAction({
          action: TRACKING_PURCHASES.web_purchase_child_profile_cancelled,
          extraData: {
            order_id: orderId,
            product_id: productId,
            user_id: user?.id,
          },
        });
    } else {
      history.push(`${url.UPGRADE_PREMIUM}?from=${previousPath}`);

      if (orderId)
        trackingAction({
          action: TRACKING_PURCHASES.web_purchase_premium_access_cancelled,
          extraData: {
            order_id: orderId,
            product_id: productId,
            user_id: user?.id,
          },
        });
    }
  };

  const onValidateDiscountCode = async () => {
    const bodyParams = { discount_code: discountCode };

    try {
      const res = await validateDiscountCode(bodyParams);

      const isDiscountCodeValid = res?.data?.data?.status;
      const discountInfo = res?.data?.data;

      if (isDiscountCodeValid) {
        setIsDiscountCodeValid(true);
        setIsDiscountCodeError(false);

        setDiscountInfo(discountInfo);
      } else {
        setIsDiscountCodeError(true);
        setIsDiscountCodeValid(false);
      }
    } catch (err) {
      console.log({ err });
      setIsDiscountCodeValid(false);
    }
  };

  const onClearDiscountCode = () => {
    setIsDiscountCodeValid(false);
    setIsDiscountCodeError(false);
    setDiscountCode('');
  };

  const getGeoInfo = () => {
    const code = localStorage.getItem('countryCode'); // Cache

    if (code) {
      setCountryCode(code);
    } else {
      setModalOpen(true);

      // ! Probably need to revisit this
      // ! Currently it causes Paypal to not working properly
      // fetch('https://ipapi.co/json/')
      //   .then(response => response.json())
      //   .then(data => {
      //     const { country } = data;
      //     setCountryCode(country);
      //     localStorage.setItem('countryCode', country);

      //     if (country !== 'SG') {
      //       setModalOpen(true);
      //     }
      //   })
      //   .catch(error => {
      //     console.error('Error fetching geo info:', error);
      //   });
    }
  };

  const changeHandler = value => {
    setSelectedCountry(value);
  };

  const trackGtmEvent = (event, data) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
      user_id: user.id,
      [event]: {
        value: data,
      },
    });
  };

  const updateOrdersFromAPI = async data => {
    const totalPrice = selectedProductPrice;
    trackGtmEvent('purchase', totalPrice);

    const navigateUserPayment = () => {
      navigatePayment({
        history,
        addChild,
        unlockplaybook,
        discountCode,
        isDiscountCodeValid,
        discountInfo,
      });
    };

    const STATUS = {
      SUCCESS: 'Success',
      FAIL: 'Failed',
    };

    let body = {
      payment_status: STATUS.SUCCESS,
      intent_id: data.orderID,
    };

    setCartItem([body, ...cartItem]);

    updateUserPaymentStatus({
      orderId,
      selectedProduct,
      status: STATUS.SUCCESS,
      paymentIntentId: data.orderID,
      navigatePayment: navigateUserPayment,
      setToast,
      user,
      setUser,
      unlockplaybook,
    });
  };

  useEffect(() => {
    // Just to be safe... clear any form of discount here
    clearDiscountedPrice();

    getGeoInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      resetPaymentData();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!discountCode) setIsDiscountCodeError(false);
  }, [discountCode]);

  useEffect(() => {
    const type = addChild ? 'Child' : 'Self';

    const price = selectedProductPrice;
    const special_discount = 0;
    const special_discount_price = price - special_discount;

    const promo_discount_id = discountInfo?.id;
    const promo_discount = discountInfo?.percentage / 100;
    const promo_percentage = discountInfo?.percentage;

    const discount_price = special_discount_price * promo_discount;
    const promo_discount_price = Math.floor(discount_price * 100) / 100;

    const discounted_price =
      (special_discount_price &&
        special_discount_price - promo_discount_price) ||
      (promo_discount_price && price - promo_discount_price) ||
      price;

    setDiscountedPrice(discounted_price);

    setCalculationResult({
      type,
      price,
      special_discount,
      special_discount_price,
      promo_discount,
      promo_percentage,
      promo_discount_price,
      discounted_price,
      total_price: discounted_price,
      discount_code_id: promo_discount_id,
    });
  }, [discountInfo, addChild, selectedProductPrice]);

  useEffect(() => {
    if (calculationResult?.promo_discount_price) {
      localStorage.setItem(
        'discount',
        JSON.stringify(calculationResult?.promo_discount_price),
      );
    }
  }, [calculationResult]);

  useEffect(() => {
    resetPaymentData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  useEffect(() => {
    const isCountryMY = _.isEqual(countryCode, 'MY');

    if (countryCode) {
      if (isCountryMY) localStorage.setItem('countryCurrency', 'MYR');
      else localStorage.setItem('countryCurrency', 'SGD');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryCode]);

  const renderCountryPopup = () => {
    if (!modalOpen) return null;
    else {
      return (
        <div className="country-modal">
          <div className="modal-container">
            <p>Please select place of residence</p>
            <Select
              options={options}
              value={selectedCountry}
              onChange={changeHandler}
            />
            <button
              onClick={() => {
                const { value } = selectedCountry;
                localStorage.setItem('countryCode', value);
                setCountryCode(value);
                setModalOpen(false);
              }}
              disabled={selectedCountry === ''}>
              Okay
            </button>
          </div>
        </div>
      );
    }
  };

  //test
  const renderCartItem = () => {
    const price = selectedProductPrice;

    const purchasedItem = addChild
      ? 'Additional child profile'
      : '1 year Premium access';

    // display expiry date
    const subscriptionEndDate = moment(userPremiumEndDate).format('DD/MM/YYYY');

    // const
    return (
      <div className="order-content">
        <div className="order-content-container content-dual-column">
          <h1>{purchasedItem}</h1>
          <h1 className="amount">{`${currencyCodeSymbol}${price?.toFixed(
            2,
          )}`}</h1>
        </div>

        {!addChild && (
          <>
            <div class="expiry-date-subtitle">{`Your subscription ends on ${subscriptionEndDate}.`}</div>
            <div class="expiry-date-subtitle-bolded">
              Connected does not store your credit card information
            </div>
            <div class="expiry-date-subtitle">
              You will be asked to enter your credit card details before
              renewing your subscription.
            </div>
          </>
        )}
      </div>
    );
  };

  const renderTotalPrice = () => {
    let total = calculationResult?.total_price;
    return total?.toFixed(2);
  };

  const switchScreen = () => {
    setShowPaymentSelect(false);
  };

  const initWalletPayment = type => {
    try {
      requestPaymentFromAPI(type);
      paymentRequest.show();
    } catch (err) {
      console.log('Wallet Payment Error:: ', err.message);
    }
  };

  const renderPaymentRequestButton = () => {
    if (paymentRequest) {
      const { _buttonTypeName: type } = paymentRequest;

      if (type && Object.values(WALLET_TYPES).includes(type)) {
        return (
          <div
            onClick={() => {
              initWalletPayment(type);
            }}>
            {type === WALLET_TYPES.APPLE_PAY ? 'Apple Pay' : 'Google Pay'}
            <span className="payment-icon-container">
              {type === WALLET_TYPES.APPLE_PAY ? (
                <ApplePayIcon className="payment-icon" />
              ) : (
                <GooglePayIcon className="payment-icon" />
              )}
            </span>
          </div>
        );
      }
    }

    return null;
  };

  const renderPaypalButton = () => {
    const isCountryMY = _.isEqual(countryCode, 'MY');
    const currency = isCountryMY ? 'MYR' : 'SGD';

    if (countryCode && countryCode === 'SG' && currency) {
      return (
        <PayPalButton
          amount={renderTotalPrice()}
          shippingPreference="NO_SHIPPING"
          onSuccess={(details, data) => {
            updateOrdersFromAPI(data);
          }}
          catchError={(details, data) => {
            updatePaymentStatus(orderId, {
              payment_status: 'Failed',
            });
          }}
          currency={currency}
          options={{
            clientId: process.env.REACT_APP_PAYPAL_KEY,
            currency,
            disableFunding: 'credit,card',
          }}
          onClick={() => {
            requestPaymentFromAPI(paymentOptions.Paypal.type);
            setPaymentMethod(paymentOptions.Paypal.type);
          }}
          style={{
            layout: 'vertical',
            color: 'gold',
            shape: 'rect',
          }}
        />
      );
    }

    return null;
  };

  const renderPaymentScreen = () => {
    if (showPaymentSelect) {
      return (
        <>
          <div className="payment-option-container">
            <div className="payment-option-item">
              {countryCode === 'SG' && (
                <div
                  onClick={() => {
                    requestPaymentFromAPI(paymentOptions.Paynow.type);
                    setPaymentMethod(paymentOptions.Paynow.type);
                  }}>
                  {isPaynowProcessing ? 'Processing payment...' : 'PayNow'}
                  <span className="payment-icon-container">
                    <PaynowIcon className="payment-icon paynow" />
                  </span>
                </div>
              )}

              <div
                onClick={() => {
                  requestPaymentFromAPI(paymentOptions.Card.type);
                  setPaymentMethod(paymentOptions.Card.type);
                  switchScreen();
                }}>
                Debit/Credit Card
                <span className="payment-icon-container">
                  <AmexIcon className="payment-icon" />
                  <VisaIcon className="payment-icon" />
                  <MastercardIcon className="payment-icon" />
                </span>
              </div>

              {renderPaymentRequestButton()}

              {paymentMethod === paymentOptions.Paynow.type && paymentData && (
                <PayNowForm
                  paymentData={paymentData}
                  setPaymentMethod={setPaymentMethod}
                  discountCode={discountCode}
                  isDiscountCodeValid={isDiscountCodeValid}
                  discountInfo={discountInfo}
                />
              )}
            </div>

            <div className="payment-paypal-item">{renderPaypalButton()}</div>
          </div>
        </>
      );
    } else {
      if (paymentMethod === paymentOptions.Card.type) {
        return (
          <CardForm
            paymentData={paymentData}
            discountCode={discountCode}
            isDiscountCodeValid={isDiscountCodeValid}
            discountInfo={discountInfo}
          />
        );
      }
    }
  };

  const PaymentHeader = () => {
    return (
      <h1 className="payment-header">
        {showPaymentSelect ? (
          'Payment'
        ) : (
          <>
            <ArrowLeft
              onClick={() => {
                resetPaymentData();
                setShowPaymentSelect(true);
              }}
            />
            {paymentLabel}
          </>
        )}
      </h1>
    );
  };

  if (isProductLoading) return <LoadingIndicator />;

  return (
    <div id="checkout-page">
      {renderCountryPopup()}
      <Head
        title={
          _.isEmpty(user)
            ? 'Checkout - Connected App'
            : 'Connected App - Checkout'
        }
      />
      <HeaderBack
        title={checkoutBackText}
        history={history}
        location={location}
        handleClick={() => handleBack()}
      />

      {isMobileView && <PaymentHeader />}

      <div className="container">
        <div className="payment-container">
          {!isMobileView && <PaymentHeader />}

          {showPaymentSelect && (
            <div className="discount-container">
              <div className="discount-code-input">
                <div className="input-container">
                  <input
                    className={`${isDiscountCodeError && 'error'}`}
                    name="discount-code"
                    placeholder="Enter discount code"
                    value={isDiscountCodeValid ? '' : discountCode}
                    onChange={e => setDiscountCode(e.target.value)}
                    disabled={isDiscountCodeValid}
                  />
                  {isDiscountCodeError && (
                    <ErrorOutlineOutlined className="error-icon" />
                  )}
                </div>

                <ButtonSolid
                  disabled={!discountCode || isDiscountCodeValid}
                  onClick={() => onValidateDiscountCode()}
                  children="Apply"
                />
              </div>

              {isDiscountCodeValid && !isDiscountCodeError && (
                <div className="discount-code-value">
                  <p>
                    {discountCode}{' '}
                    <Close
                      className="clear-icon"
                      onClick={() => onClearDiscountCode()}
                    />
                  </p>
                </div>
              )}

              {isDiscountCodeError && !isDiscountCodeValid && (
                <div className="invalid-code-error">
                  <p>Invalid code</p>
                </div>
              )}
            </div>
          )}

          {showPaymentSelect && (
            <h3 className="payment-method-label">Payment Method</h3>
          )}

          {renderPaymentScreen()}
        </div>

        <div className="checkout-summary-container">
          <h1>Your Order</h1>
          <div>{renderCartItem()}</div>

          {isDiscountCodeValid && (
            <div className="discount-code">
              <div
                className="valid-discount-code-display"
                style={{
                  display: 'flex',
                  flex: 1,
                  wordBreak: 'break-word',
                  textAlign: 'left',
                }}>
                {`${discountCode} ${calculationResult?.promo_percentage}% OFF`}
              </div>
              <div
                className="valid-discount-code-display right"
                style={{
                  textAlign: 'right',
                  marginLeft: 10,
                }}>
                {`-${currencyCodeSymbol}${calculationResult?.promo_discount_price}`}
              </div>
            </div>
          )}

          <div className="total">
            <p>Grand Total</p>
            <p>
              {currencyCodeSymbol}
              {renderTotalPrice()}
              <br />
              {countryCode === 'SG' && (
                <span className="gst-label">(incl. GST)</span>
              )}{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Checkout);

import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import { UserContext } from 'context/UserContext';
import { url } from 'config/urlConfig';
import { colorObjectV2 } from 'shared/const';
import { capitalize } from 'shared/helper/helper';
import {
  trackingAction,
  TRACKING_PLAYBOOKS,
  TRACKING_TESTS,
} from 'shared/function/Tracking';
import { useSignupStatus } from 'routes/Auth/authUtils';
import { createChild, updateChild } from 'routes/ProfileTest/ProfileTestUtils';

// components/assets
import Head from 'shared/components/Head.js';
import { ButtonSolid } from 'shared/components/Button/Button';
import { ReactComponent as IconCircle } from 'assets/icons/icon_circle.svg';
import { ReactComponent as IconTriangle } from 'assets/icons/icon_triangle.svg';
import { ReactComponent as IconSquare } from 'assets/icons/icon_square.svg';
import { ReactComponent as IconDiamond } from 'assets/icons/icon_diamond.svg';
import { ReactComponent as Checked } from 'assets/general/checked-plain-icon.svg';
import { Check } from '@material-ui/icons';

const ChildProfileScreen = ({
  history,
  isAddChild,
  formValueChild,
  setFormValueChild,
}) => {
  const { setTempChild, user } = useContext(UserContext);

  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const { isSignupCompleted } = useSignupStatus({ user });

  const selectedIncompleteChildProfile = JSON.parse(
    sessionStorage.getItem('selectedIncompleteChildProfile'),
  );

  const formValueName = formValueChild?.name;
  const formValueGender = formValueChild?.gender;
  const formValueBirth = formValueChild?.date_of_birth;
  const formValueColour = formValueChild?.colour;
  const formValueShape = formValueChild?.shape;

  // Function to update tempChild with the latest form values and update session storage
  async function updateTempChildAndSessionStorage({ formValue, type }) {
    setIsSubmitting(true);

    try {
      // Assuming selectedIncompleteChildProfile is already defined
      for (let key in formValue) {
        if (
          selectedIncompleteChildProfile.hasOwnProperty(key) &&
          selectedIncompleteChildProfile[key] !== formValue[key]
        ) {
          selectedIncompleteChildProfile[key] = formValue[key];
        }
      }

      // Update session storage with the modified selectedIncompleteChildProfile
      sessionStorage.setItem(
        'selectedIncompleteChildProfile',
        JSON.stringify({
          ...selectedIncompleteChildProfile,
        }),
      );

      const bodyParams = {
        ...selectedIncompleteChildProfile,
        gender: selectedIncompleteChildProfile?.gender === 'female' ? 'F' : 'M',
      };

      // Update the child profile data via an asynchronous call
      await updateChild(selectedIncompleteChildProfile?.id, bodyParams).then(
        () => {
          setIsSubmitting(false);

          sessionStorage.setItem(
            'selectedRelative',
            JSON.stringify(selectedIncompleteChildProfile),
          );

          if (isAddChild) {
            history.push(`/test/${type}?addchild=true`);
          } else {
            history.push(`/test/${type}`);
          }
        },
      );
    } catch (error) {
      const { message } = error.response.data;
      setError(message);
      setIsSubmitting(false);
      console.log('🚀 ~ updateTempChildAndSessionStorage ~ message:', message);
    }
  }

  const submitForm = async () => {
    const capitalizeName = capitalize(formValueName);
    const gender = formValueGender === 'female' ? 'F' : 'M';
    const dateOfBirth = formValueBirth;

    const newFormValue = {
      ...formValueChild,
      name: capitalizeName,
      gender,
      date_of_birth: dateOfBirth,
    };
    const typeChild = 'child';

    if (!selectedIncompleteChildProfile) {
      try {
        const reponse = await createChild(newFormValue);
        const data = reponse?.data?.data;
        const childData = { ...data, date_of_birth: data?.birthday };

        setTempChild(childData);
        sessionStorage.setItem(
          'selectedIncompleteChildProfile',
          JSON.stringify(childData),
        );
        sessionStorage.setItem('selectedRelative', JSON.stringify(childData));
        setIsSubmitting(true);
        setSuccess('Account details submitted.');

        setTimeout(() => {
          if (isAddChild) {
            history.push(`/test/${typeChild}?addchild=true`);
          } else {
            history.push(`/test/${typeChild}`);
          }

          if (isSignupCompleted) {
            trackingAction({
              action: TRACKING_PLAYBOOKS.register_new_child,
              extraData: { relative_id: data?.id, user_id: user?.id },
            });
          } else {
            trackingAction({
              action: TRACKING_TESTS.start_child_test,
              user_id: user?.id,
              extraData: { relative_id: data?.id, user_id: user?.id },
            });
          }
        }, 1000);
      } catch (err) {
        const { message } = err.response.data;
        setError(message);
      }
    } else {
      // Call the function to update tempChild with the latest form values and update session storage
      updateTempChildAndSessionStorage({
        formValue: formValueChild,
        type: typeChild,
      });
    }
  };

  const handleChange = e => {
    const { value, name } = e.target;

    if (name === 'date_of_birth' && value === 'DD/MM/YYYY') {
      return; // Exit early if the date format is invalid
    }

    setFormValueChild({ ...formValueChild, [name]: value });
    setError('');
  };

  useEffect(() => {
    if (user.all_books_occupied === 1) {
      history.push(`${url.HOME}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.all_books_occupied, history]);

  useEffect(() => {
    if (formValueChild) {
      const validate = () => {
        let emptyCount = 0;
        Object.entries(formValueChild).forEach(item => {
          if (item[1] === '') emptyCount++;
        });

        if (emptyCount > 0) {
          setIsFormCompleted(false);
        } else {
          setIsFormCompleted(true);
        }
      };
      validate();
    }
  }, [formValueChild]);

  const colorShape = (value, color) => {
    switch (value) {
      case 'square':
        return <IconSquare style={{ fill: colorObjectV2[color] }} />;
      case 'circle':
        return <IconCircle style={{ fill: colorObjectV2[color] }} />;
      case 'triangle':
        return <IconTriangle style={{ fill: colorObjectV2[color] }} />;
      case 'diamond':
        return <IconDiamond style={{ fill: colorObjectV2[color] }} />;
      default:
        return <IconCircle style={{ fill: colorObjectV2[color] }} />;
    }
  };

  const SelectionSwitch = ({ items, label }) => {
    return (
      <div className="selection-switch-container">
        {Object.entries(items).map(item => (
          <div
            key={item[0]}
            className={`selection ${
              formValueGender === item[0] ? 'selected' : ''
            }`}
            onClick={() =>
              setFormValueChild({ ...formValueChild, gender: item[0] })
            }>
            {item[1]}
          </div>
        ))}
      </div>
    );
  };

  const OnClickShape = shape => {
    setFormValueChild({ ...formValueChild, shape: shape });
    setError('');
  };

  const OnClickColour = color => {
    setFormValueChild({ ...formValueChild, colour: color });
    setError('');
  };

  return (
    <div className="right-content signup">
      <Head
        title={
          _.isEmpty(user)
            ? 'Child Profile | Connected'
            : 'Connnected | Child Profile'
        }
      />

      <div className="auth-form-container">
        <div className="form">
          <div className="form-group-name">
            <label>Name</label>
            <input
              name="name"
              type="text"
              maxlength="12"
              onChange={handleChange}
              placeholder="Name"
              value={formValueName}
              style={{ textTransform: 'capitalize' }}
            />
          </div>

          <div className="form-group-inline">
            <div className="form-group">
              <label>Child is a</label>
              <SelectionSwitch
                items={{ male: 'Boy', female: 'Girl' }}
                label="gender"
              />
            </div>
            <div className="form-group">
              <label>Born on</label>
              <InputMask
                name="date_of_birth"
                mask="99/99/9999"
                maskPlaceholder="DD/MM/YYYY"
                onChange={handleChange}
                value={formValueBirth}
                placeholder="DD/MM/YYYY"
              />
            </div>
          </div>

          <div className="form-group">
            <label style={{ marginBottom: '12px' }}>Choose a shape</label>
            <div className="shape-container">
              <div
                className={`single-shape ${
                  formValueShape === 'circle' ? 'selected' : ''
                }`}
                onClick={() => OnClickShape('circle')}>
                <IconCircle
                  style={{
                    fill: !formValueColour
                      ? '#e8e8e8'
                      : colorObjectV2[formValueColour],
                    opacity: !formValueColour && '1',
                  }}
                />{' '}
                <Checked className="selected-icon" />
              </div>
              <div
                className={`single-shape ${
                  formValueShape === 'triangle' ? 'selected' : ''
                }`}
                onClick={() => OnClickShape('triangle')}>
                <IconTriangle
                  style={{
                    fill: !formValueColour
                      ? '#e8e8e8'
                      : colorObjectV2[formValueColour],
                    opacity: !formValueColour && '1',
                  }}
                />
                <Checked className="selected-icon-triangle" />
              </div>
              <div
                className={`single-shape ${
                  formValueShape === 'diamond' ? 'selected' : ''
                }`}
                onClick={() => OnClickShape('diamond')}>
                <IconDiamond
                  style={{
                    fill: !formValueColour
                      ? '#e8e8e8'
                      : colorObjectV2[formValueColour],
                    opacity: !formValueColour && '1',
                  }}
                />
                <Checked className="selected-icon" />
              </div>
              <div
                className={`single-shape ${
                  formValueShape === 'square' ? 'selected' : ''
                }`}
                onClick={() => OnClickShape('square')}>
                <IconSquare
                  style={{
                    fill: !formValueColour
                      ? '#e8e8e8'
                      : colorObjectV2[formValueColour],
                    opacity: !formValueColour && '1',
                  }}
                />
                <Checked className="selected-icon" />
              </div>
            </div>
          </div>

          <div className="form-group">
            <label style={{ margin: '12px 0px' }}>Choose a colour</label>
            <div className="color-container">
              <div
                className={`single-color ${
                  formValueColour === 'blue' ? 'selected' : ''
                }`}
                onClick={() => OnClickColour('blue')}>
                {colorShape(formValueShape, 'blue')}{' '}
                <Checked
                  className={`${
                    formValueShape === 'triangle'
                      ? 'selected-icon-triangle'
                      : 'selected-icon'
                  }`}
                />
              </div>
              <div
                className={`single-color ${
                  formValueColour === 'purple' ? 'selected' : ''
                }`}
                onClick={() => OnClickColour('purple')}>
                {colorShape(formValueShape, 'purple')}
                <Checked
                  className={`${
                    formValueShape === 'triangle'
                      ? 'selected-icon-triangle'
                      : 'selected-icon'
                  }`}
                />
              </div>
              <div
                className={`single-color ${
                  formValueColour === 'pink' ? 'selected' : ''
                }`}
                onClick={() => OnClickColour('pink')}>
                {colorShape(formValueShape, 'pink')}
                <Checked
                  className={`${
                    formValueShape === 'triangle'
                      ? 'selected-icon-triangle'
                      : 'selected-icon'
                  }`}
                />
              </div>
              <div
                className={`single-color ${
                  formValueColour === 'green' ? 'selected' : ''
                }`}
                onClick={() => OnClickColour('green')}>
                {colorShape(formValueShape, 'green')}
                <Checked
                  className={`${
                    formValueShape === 'triangle'
                      ? 'selected-icon-triangle'
                      : 'selected-icon'
                  }`}
                />
              </div>
            </div>
          </div>

          {error !== '' && (
            <div className="error-container">
              <p>{error}</p>
            </div>
          )}

          {success !== '' && (
            <div className="success-container">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Check style={{ marginRight: '20px' }} />
                <p> {success}</p>
              </div>
            </div>
          )}

          <ButtonSolid
            onClick={submitForm}
            className="submit"
            disabled={!isFormCompleted || isSubmitting}
            children={isSubmitting ? 'Submitting...' : 'Next'}
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(ChildProfileScreen);

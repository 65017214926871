import * as Sentry from '@sentry/react';
import { UserContext, userData } from 'context/UserContext';

export const HARD_CODED_ERRORS = {
  SOMETHING_WENT_WRONG: 'Something went wrong, please try again.',
  NO_INTERNET:
    "You're currently not connected to the internet, please make sure you are connected to the internet and try again.",
  INVALID_VERSION: 'Invalid version',
};

function convertFormDataToObject(body) {
  if (body instanceof FormData) {
    const object = {};
    for (const [key, value] of body.entries()) {
      if (key.toLowerCase().includes('password')) {
        // Don't log in anyone's password
        continue;
      }
      object[key] = value;
    }
    return object;
  } else {
    return body;
  }
}

export function logToSentry(method, path, params, body, errorMessage) {
  let currentUserData = null;
  if (userData?.current) {
    currentUserData = userData.current;
  }

  const userId = currentUserData?.id;

  const isHardcoded = Object.values(HARD_CODED_ERRORS).find(
    error => error === errorMessage,
  );
  const processedErrorMessage = isHardcoded
    ? `${errorMessage} (Hard coded error message)`
    : errorMessage;

  const formattedBody = convertFormDataToObject(body);

  const event = {
    message: `ES001: ${method} ${path} - ${processedErrorMessage}`,
    fingerprint: [`REQUEST-API-ERROR: ${path}`],
    level: 'Error',
    extra: {
      ...(method ? { method } : {}),
      ...(path ? { path } : {}),
      ...(params ? { params } : {}),
      ...(body ? { body: formattedBody } : {}),
      ...(userId ? { userId } : {}),
    },
  };

  Sentry.captureEvent(event);
}

import React from 'react';
import { Helmet } from 'react-helmet';
import defaultImage from 'assets/home/poster.jpg';

const Head = ({ title, description, image, preventIndex }) => {
  const url = window.location.href;
  let robotsContent = 'follow, index';

  if (preventIndex) {
    robotsContent = 'nofollow, noindex';
  }

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={title}
      // meta={[
      //     {
      //         name: 'description',
      //         content: description,
      //     },
      // ]}
      link={[
        {
          rel: 'canonical',
          href: url,
        },
      ]}>
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />

      <meta property="og:image" content={image} />
      <meta name="twitter:image" content={image} />

      {process.env.REACT_APP_STAGE === 'production' ? (
        <meta name="robots" content={robotsContent} />
      ) : (
        <meta name="robots" content="nofollow, noindex" />
      )}
      <meta property="og:url" content={url} />

      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary" />
    </Helmet>
  );
};

Head.defaultProps = {
  title: 'Connected',
  preventIndex: false,
  description: `Connected is about bringing families and communities closer. Share it with your spouse, extended family, friends, community members, teachers and coaches. The more insights gathered from different perspectives, the easier it will become to navigate the behaviours you observe in your children.`,
  image: defaultImage,
};

export default Head;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./LoadingScreen.scss";

const LoadingScreen = () => {
  const history = useHistory();

  const [progress, setProgress] = useState(0);
  const [descriptionIndex, setDescriptionIndex] = useState(0);

  const descriptions = [
    "There are no wrong answers",
    "Don't overthink it",
    "Set aside enough time to complete both profiles",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(interval);

          setTimeout(() => {
            history.push(`/test/parent`);
          }, 3500);
        } else {
          return prevProgress + 20;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [history]);

  useEffect(() => {
    if (descriptionIndex === 2) return;

    const intervalId = setInterval(() => {
      setDescriptionIndex((descriptionIndex + 1) % descriptions.length);
    }, 4000);

    return () => clearInterval(intervalId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [descriptionIndex]);

  return (
    <div className="loading-screen">
      <div className="loading-screen-content">
        <div className="loading-screen-header">
          <h3>Remember:</h3>
          <p>{descriptions[descriptionIndex]}</p>
        </div>

        <div className="progress-container">
          <div
            className="progress-bar"
            style={{
              width: `${progress}%`,
            }}
          />
          <div
            className="loading-text"
            style={{
              color: `${progress > 99 || !progress ? "#fff" : "#50C1BC"}`,
            }}
          >
            {descriptionIndex > 1 ? "Completed" : "Loading..."}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;

import './SingleChapterCardRibbon.scss';

const SingleChapterCardRibbon = ({ labelColor, ribbonText }) => {
  return (
    <div
      className="ribbon-container"
      style={{
        backgroundColor: `${labelColor}59`,
      }}>
      {ribbonText.map(val => (
        <p>
          <li>{val}</li>
        </p>
      ))}
    </div>
  );
};

export default SingleChapterCardRibbon;

import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { ToastContext } from 'context/ToastContext';
import { validateReferralCode } from './ReferralUtils';
import { emailVerification } from 'routes/Auth/authUtils';

// components/assets
import Head from 'shared/components/Head';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import { ButtonSolid } from 'shared/components/Button/Button';
import {
  Background,
  FormHeader,
  FormInput,
  FormError,
  FormFooter,
} from 'routes/Auth/AuthComponents';
import { FormCode } from './ReferralComponents';
import 'routes/Auth/Auth.scss';

const ReferralSignupEmail = ({ history }) => {
  const [formValue, setFormValue] = useState({
    email: '',
    code: '',
  });

  const [validParamReferralCode, setValidParamReferralCode] = useState(null);
  const [isCodeValidating, setIsCodeValidating] = useState(true);
  const [validFormReferralCode, setValidFormReferralCode] = useState(null);
  const [formReferralCodeMessage, setFormReferralCodeMessage] = useState(null);

  const [submitDisable, setSubmitDisable] = useState(true);
  const [error, setError] = useState('');

  const { setToast } = useContext(ToastContext);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const paramReferralCode = params.get('code');

  const validReferralCode = validParamReferralCode
    ? validParamReferralCode
    : validFormReferralCode;
  const isValidFormReferralCode = validFormReferralCode ? true : false;

  const handleChange = e => {
    setError('');
    const { value, name } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const trackGtmEvent = event => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
    });
  };

  const validateParamReferralCode = async () => {
    setIsCodeValidating(true);

    try {
      const res = await validateReferralCode({ code: paramReferralCode });

      if (res) {
        setValidParamReferralCode(paramReferralCode);
        setIsCodeValidating(false);
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: ReferralSignupEmail.js:77 ~ validateParamReferralCode ~ err:',
        err,
      );

      if (err) {
        const errMessage = err?.response?.data?.message;

        if (!validReferralCode) {
          setToast({
            type: 'error',
            message: errMessage,
          });
        }

        setIsCodeValidating(false);
      }
    }
  };

  const validateFormReferralCode = async () => {
    try {
      const res = await validateReferralCode({ code: formValue?.code });
      console.log(
        '🚀 ~ file: ReferralSignupEmail.js:96 ~ validateFormReferralCode ~ res:',
        res,
      );

      if (res) {
        setValidFormReferralCode(formValue?.code);
        setFormReferralCodeMessage('Valid referral code');
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: ReferralSignupEmail.js:99 ~ validateFormReferralCode ~ err:',
        err?.response,
      );

      if (err) {
        setValidFormReferralCode(null);
        setFormReferralCodeMessage('Invalid referral code');
        setSubmitDisable(true);
      }
    }
  };

  const submitForm = async e => {
    e.preventDefault();

    const email = formValue?.email;
    const encodeEmail = encodeURIComponent(email);
    const bodyFormData = { email: email, referral_code: validReferralCode };

    try {
      const res = await emailVerification(bodyFormData);

      if (res) {
        trackGtmEvent('create_account');
        history.push(`/signup-verification?email=${encodeEmail}`);
        setSubmitDisable(false);
      }
    } catch (err) {
      console.log({ err });
      setError({ message: err?.response?.data?.message, type: 'error' });
      setSubmitDisable(true);
    }
  };

  useEffect(() => {
    const { email } = formValue;
    const emailRE =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email && emailRE.test(email)) {
      setSubmitDisable(false);
    } else if (email && !emailRE.test(email)) {
      setError({
        message: 'Please enter a valid email address',
        type: 'email',
      });
      setSubmitDisable(true);
    }
  }, [formValue, validFormReferralCode]);

  useEffect(() => {
    validateParamReferralCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formValue?.code) validateFormReferralCode();
    else setFormReferralCodeMessage(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValue?.code]);

  if (isCodeValidating) return <LoadingIndicator />;

  return (
    <div id="auth-screen" className="signup-referral">
      <Head
        title="Connected"
        description="Connected is a first-of-its-kind digital playbook for parents. Providing contextualised insights into your child's personality-driven behaviour. Bringing out the best in one another through understanding."
      />

      <div className={`auth-outer-container signup`}>
        <div className="auth-inner-container">
          <Background type="signup" />
          <div className="right-content-space">
            <div className="form-content">
              <FormHeader title="Sign up for free to grow closer in your relationships." />

              <form onSubmit={submitForm}>
                <FormInput
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={e => handleChange(e)}
                  autoComplete="off"
                  isError={error?.type === 'email'}
                  error={error?.message}
                />

                <FormError
                  message={error?.type === 'error' && error?.message}
                />

                <FormCode
                  value={
                    validReferralCode ? validReferralCode : formValue?.code
                  }
                  formCode={formValue?.code}
                  handleChange={handleChange}
                  isValidFormReferralCode={isValidFormReferralCode}
                  message={formReferralCodeMessage}
                  disabled={validParamReferralCode}
                />

                <ButtonSolid
                  className="submit"
                  type="submit"
                  children="Create account"
                  disabled={submitDisable}
                />

                <FormFooter
                  text="Already have an account?"
                  buttonText="Log in"
                  buttonClick={() => history.push('/login')}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ReferralSignupEmail);

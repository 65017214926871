import { useEffect } from 'react';

const useDisableScreenScrollable = ({ isDisable }) => {
  useEffect(() => {
    const originalOverflowStyle = document.body.style.overflow;

    if (isDisable) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalOverflowStyle;
    }

    // Cleanup function to reset overflow style when component unmounts
    return () => {
      document.body.style.overflow = originalOverflowStyle;
    };
  }, [isDisable]);
};

export default useDisableScreenScrollable;

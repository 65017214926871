import axios from 'axios';
import _ from 'lodash';

export const loginUser = bodyFormData =>
  axios({
    method: 'post',
    url: '/auth/login',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: bodyFormData,
  });

export const getTempRelative = bodyFormData =>
  axios({
    method: 'post',
    url: '/relative/temp',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: bodyFormData,
  });

export const register = body => axios.post('/auth/signupv2', body);

export const forgotPassword = bodyFormData =>
  axios({
    method: 'post',
    url: '/auth/forgotpassword',
    data: bodyFormData,
  });

export const resetPassword = bodyFormData =>
  axios({
    method: 'post',
    url: '/auth/resetpassword',
    data: bodyFormData,
  });

export const emailVerification = body => axios.post('/auth/verify-email', body);

export const resendEmailVerification = body =>
  axios.post('/auth/reverify-email', body);

export const signupUser = body => axios.post('/auth/signupv2', body);

export const validateGiftCode = body =>
  axios.post('/auth/giftcode/validation', body);

export const validatePremiumAccessCode = body =>
  axios.post('/premiumAccessCode/validate', body);

export const validateAllCodes = body =>
  axios.post('/auth/verify-all-codes', body);

export const getUserData = () => axios.get('/user');

export const getCartItem = () => axios.get('/carts');

export const getRelative = () => axios.get('/relative');

export const getIncompleteOrder = () => axios.get('/orders/incomplete');

export const updateDemoFlag = body => axios.put('/user/demo-flag', body);

export const useSignupStatus = ({ user }) => {
  const parentTestTaken = user?.parent_test_taken;
  const childTestTaken = user?.child_test_taken;
  const parentAnswerSaved = user?.parent_answer_saved;
  const childAnswerSaved = user?.child_answer_saved;

  const isParentProfileCompleted =
    parentTestTaken && _.isEqual(parentTestTaken, 1);
  const isChildProfileCompleted =
    childTestTaken && _.isEqual(childTestTaken, 1);
  const isParentTestCompleted = _.isEqual(parentAnswerSaved, 1);
  const isChildTestCompleted = _.isEqual(childAnswerSaved, 1);

  const onboardSnapshot = user?.onboard_snapshot;
  const isOnboardSnapshotCompleted = _.isEqual(onboardSnapshot, 1);

  const isSignupCompleted =
    isOnboardSnapshotCompleted ||
    (isParentProfileCompleted &&
      isChildProfileCompleted &&
      isParentTestCompleted &&
      isChildTestCompleted);

  return {
    isSignupCompleted,
    isParentProfileCompleted,
    isChildProfileCompleted,
  };
};

export const colorObject = {
  red: '#FC7676',
  orange: '#FFBB6C',
  yellow: '#FFF081',
  green: '#98CC68',
  blue: '#93EAFF',
  nicholas: '#66C0D9',
  pink: '#FA7DC0',
  purple: '#D573F2',
};

//flip the bar colour
export const progressColor = {
  blue: '#b3dfec',
  purple: '#f6d9ff',
  pink: '#ffbfce',
  green: '#d7f5bc',
};

export const getProfileColour = color => {
  const clr = color?.toLowerCase();

  switch (clr) {
    case 'blue':
      return '#66C0D9';
    case 'purple':
      return '#D573F2';
    case 'pink':
      return '#FF7E9B';
    case 'green':
      return '#98CC68';
    default:
      return '#66C0D9';
  }
};

export const colorObjectV2 = {
  blue: '#66C0D9',
  purple: '#D573F2',
  pink: '#FF7E9B',
  green: '#98CC68',
};

export const getScoreLabel = number => {
  if (number >= 0 && number <= 44) {
    return 'left_label';
  }
  if (number > 44 && number < 55) {
    return 'middle_label';
  }
  if (number >= 55 && number <= 100) {
    return 'right_label';
  }
};

export const plotGraphResult = result => {
  const val = result;
  if (result < 45) {
    return (30 / 45) * val;
  }
  if (result >= 45 && result <= 54) {
    return 30 + (val - 45) * 4;
  }
  return 30 + 40 + (val - 55) * (30 / 45);
};

export const DOMAIN_NAME = {
  P: 'PURPOSE',
  E: 'ENERGY',
  A: 'AFFIRMATION',
  K: 'KNOWLEDGE',
  S: 'SUSTAINABILITY',
};

export const getDomainName = value => {
  switch (value?.toUpperCase()) {
    case 'P':
      return 'Purpose';
    case 'E':
      return 'Energy';
    case 'A':
      return 'Affirmation';
    case 'K':
      return 'Knowledge';
    case 'S':
      return 'Sustainability';
    default:
      return 'Purpose';
  }
};

export const domainColor = value => {
  switch (value?.toUpperCase()) {
    case 'P':
    case 'PURPOSE':
      return '#FC9951';
    case 'E':
    case 'ENERGY':
      return '#FCC715';
    case 'A':
    case 'AFFIRMATION':
      return '#50C878';
    case 'K':
    case 'KNOWLEDGE':
      return '#47ABD6';
    case 'S':
    case 'SUSTAINABILITY':
      return '#F45051';
    default:
      return '#F4F4F4';
  }
};

export const categoryColor = value => {
  switch (value) {
    case 'Conversation':
      return '#FFAA60';
    case 'Activity':
      return '#F27374';
    case 'Reflection':
      return '#358DA5';
    case 'Strategy':
      return '#50C1BC';
    default:
      return '#50C1BC';
  }
};

export const facetColor = value => {
  switch (value) {
    case 'Preparedness':
    case 'Organisation':
    case 'Dutifulness':
    case 'Success Driven':
    case 'Focus':
      return '#FC9951';
    case 'Interactivity':
    case 'Assertiveness':
    case 'Vibrance':
    case 'Thrill Seeking':
    case 'Exuberance':
      return '#FCC715';
    case 'Trust':
    case 'Forthrightness':
    case 'Other-centredness':
    case 'Co-operation':
    case 'Empathy':
      return '#50C878';
    case 'Futuristic':
    case 'Emotional Sensitivity':
    case 'Open-mindedness':
    case 'Intellectual Curiosity':
    case 'Liberality':
      return '#47ABD6';
    case 'Composure':
    case 'Anger Management':
    case 'Resilience':
    case 'Embarrassment Bridling':
    case 'Stress Control':
      return '#F45051';
    default:
      return '#F4F4F4';
  }
};

export const facetInDomain = value => {
  switch (value) {
    case 'Preparedness':
    case 'Organisation':
    case 'Dutifulness':
    case 'Success Driven':
    case 'Focus':
      return DOMAIN_NAME.P;
    case 'Interactivity':
    case 'Assertiveness':
    case 'Vibrance':
    case 'Thrill Seeking':
    case 'Exuberance':
      return DOMAIN_NAME.E;
    case 'Trust':
    case 'Forthrightness':
    case 'Other-centredness':
    case 'Co-operation':
    case 'Empathy':
      return DOMAIN_NAME.A;
    case 'Futuristic':
    case 'Emotional Sensitivity':
    case 'Open-mindedness':
    case 'Intellectual Curiosity':
    case 'Liberality':
      return DOMAIN_NAME.K;
    case 'Composure':
    case 'Anger Management':
    case 'Resilience':
    case 'Embarrassment Bridling':
    case 'Stress Control':
      return DOMAIN_NAME.S;
    default:
      return 'none';
  }
};

export const moduleTypeColor = value => {
  switch (value) {
    case 'CONTENT':
      return '#50C1BC';
    case 'GOAL':
      return '#66C0D9';
    case 'TIPS_L2':
      return '#FC7676';
    default:
      return '#50C1BC';
  }
};

export const chapterNumber = chapter => {
  switch (chapter) {
    case 'Purpose':
      return 0;
    case 'Energy':
      return 5;
    case 'Affirmation':
      return 10;
    case 'Knowledge':
      return 15;
    case 'Sustainability':
      return 20;
    default:
      return 0;
  }
};

export const prices = {
  subscription: 49.98,
  addChild: 19.98,
};

// Code types

export const CODE_TYPES = {
  CHILD: 'CHILD',
  PREMIUM_ACCESS_AND_CHILD: 'PREMIUM_ACCESS_AND_CHILD',
  SINGLE_USE_PREMIUM_ACCESS: 'SINGLE_USE_PREMIUM_ACCESS',
  MULTI_USE_PREMIUM_ACCESS: 'MULTI_USE_PREMIUM_ACCESS',
};

// Transaction history
export const HISTORY_TYPES = {
  CODE_REDEMPTION: 'Code redemption',
  EXPIRY: 'Premium access expired',
  GIFT: 'Premium access gifted',
  REACTIVATION: 'Premium access reactivated',
  REFUND: 'Refund',
  PURCHASE: 'Purchase',
};

export const HISTORY_MODES = {
  // User Subscription Expiry Log
  MODE_REACTIVATION: 'REACTIVATION',
  MODE_EXPIRY: 'EXPIRY',
  MODE_GIFT: 'GIFT',
  // Order Payment Types
  MODE_STRIPE: 'STRIPE',
  MODE_PAYPAL: 'PAYPAL',
  MODE_IOS: 'IOS',
  MODE_ANDROID: 'ANDROID',
  // Premium access code
  MODE_PREMIUM_ACCESS_CODE: 'PREMIUM_ACCESS_CODE',
  // Gift code
  MODE_GIFT_CODE: 'GIFT_CODE',
  // User creation
  MODE_USER_CREATION: 'USER_CREATION',
  // Referral
  MODE_REFERRER: 'REFERRER',
  MODE_REFERRED: 'REFERRED',
};

export const PAYMENT_METHOD_TYPES = {
  CARD: 'Card',
  PAYNOW: 'PayNow',
  PAYPAL: 'PayPal',
  APPLE_PAY: 'Apple Pay',
  GOOGLE_PAY: 'Google Pay',
};

export const GIFTCARD_CAMPAIGN_TYPES = {
  CHILD: 'CHILD',
  PREMIUM_ACCESS_AND_CHILD: 'PREMIUM_ACCESS_AND_CHILD',
  SINGLE_USE_PREMIUM_ACCESS: 'SINGLE_USE_PREMIUM_ACCESS',
  MULTI_USE_PREMIUM_ACCESS: 'MULTI_USE_PREMIUM_ACCESS',
};

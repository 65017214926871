import React, { useContext, useState, useEffect } from 'react';
import { ToastContext } from 'context/ToastContext';
import { withRouter } from 'react-router-dom';
import * as yup from 'yup';
import { ButtonSolid } from 'shared/components/Button/Button';
import { FormHeader, FormInput, FormError } from './AuthComponents';
import { forgotPassword } from './authUtils';

const ForgotPassword = ({ history }) => {
  const { setToast } = useContext(ToastContext);
  const [formValue, setFormValue] = useState({
    email: '',
  });
  const [error, setError] = useState(null);
  const [submitDisable, setSubmitDisable] = useState(true);

  const submitForm = async e => {
    e.preventDefault();

    const { email } = formValue;

    const bodyFormData = new FormData();
    bodyFormData.append('email', email);

    try {
      let schema = yup.object().shape({
        email: yup
          .string()
          .email('Invalid email format')
          .required('Email is required'),
      });
      const valid = await schema.validate({ email: email });
      if (valid) {
        await forgotPassword(bodyFormData);
        history.push('/reset-password');
      }
    } catch (err) {
      err.errors
        ? setError({ message: err?.errors[0], type: 'error' })
        : err.response
        ? setError({ message: err?.response?.data?.message, type: 'error' })
        : setError(null);

      const { message } = err.response.data;
      setToast({
        type: 'error',
        message,
      });
    }
  };

  const handleChange = e => {
    const { value, name } = e.target;
    const emailRE =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (name === 'email' && !emailRE.test(value)) {
      setError({
        message: 'Please enter a valid email address',
        type: 'email',
      });
    } else {
      setError(null);
    }

    setFormValue({ ...formValue, [name]: value });
  };

  const handleBack = () => {
    history.push('/login');
  };

  useEffect(() => {
    const { email } = formValue;

    if (email && !error) setSubmitDisable(false);
    else setSubmitDisable(true);
  }, [formValue, error]);

  return (
    <div className="right-content-space">
      <div className="form-content">
        <FormHeader
          title="Forgot password"
          backButton
          backButtonClick={() => handleBack()}
        />

        <form onSubmit={submitForm}>
          <div className="form-group">
            <FormInput
              type="email"
              name="email"
              placeholder="Email Address"
              handleChange={handleChange}
              isError={error?.type === 'email'}
              error={error?.message}
            />
          </div>

          <FormError message={error?.type === 'error' && error?.message} />

          <ButtonSolid
            type="submit"
            className="submit"
            children="Submit"
            disabled={submitDisable}
          />
        </form>
      </div>
    </div>
  );
};

export default withRouter(ForgotPassword);

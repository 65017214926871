import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

// components/assets
import Card from './TipsModuleComponent/Card';
import CardWDescription from './TipsModuleComponent/CardWDescription';
import { ReactComponent as Close } from 'assets/general/close-normal-icon.svg';
import './TipsModule.scss';

const TipsModule = ({ title, components, facet, LockOverlay }) => {
  const [selectedTips, setSelectedTips] = useState(null);

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  useEffect(() => {
    const domNode = document.body;

    if (selectedTips) {
      if (isMobile) {
        domNode.style.marginTop = '0px';
        domNode.style.minHeight = '0px';
        domNode.style.height = 'calc(100vh - 100px)';
      }

      domNode.style.height = 'calc(100vh - 100px)';
      domNode.style.overflowY = 'hidden';
    } else {
      domNode.style.height = 'auto';
      domNode.style.overflowY = 'scroll';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTips]);

  useEffect(() => {
    if (isMobile) {
      const domNode = document.body;

      if (!_.isEmpty(selectedTips)) {
        domNode.style.height = '100vh';
        domNode.style.overflowY = 'hidden';
      } else {
        domNode.style.height = 'auto';
        domNode.style.overflowY = 'scroll';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTips]);

  return (
    <section className="module tips">
      <h1 className="tips-title">{title}</h1>

      <div className="tips-container">
        <div className="left-column">
          <div className="non-focus-container">
            {components.map((tips, index) => (
              <Card
                key={index}
                tips={tips}
                selectedTips={selectedTips}
                setSelectedTips={setSelectedTips}
                facet={facet}
              />
            ))}
          </div>
        </div>
        {selectedTips && (
          <div className="right-column">
            {!isMobile && (
              <div className="web-content-container">
                <Close
                  className="tips-modal-close-btn"
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedTips(null);
                  }}
                />

                <CardWDescription
                  selectedTips={selectedTips}
                  facet={facet}
                  setSelectedTips={setSelectedTips}
                />
              </div>
            )}

            {isMobile && (
              <>
                <CardWDescription
                  selectedTips={selectedTips}
                  facet={facet}
                  setSelectedTips={setSelectedTips}
                />

                <Close
                  className="tips-modal-close-btn"
                  onClick={e => {
                    e.stopPropagation();
                    setSelectedTips(null);
                  }}
                />
              </>
            )}
          </div>
        )}

        <LockOverlay />
      </div>
    </section>
  );
};

export default withRouter(TipsModule);

import _ from 'lodash';
import { ReactComponent as CheckedIcon } from 'assets/general/checked-plain-icon-code.svg';
import './FormComponents.scss';

export const InputDefault = ({ ...otherProps }) => {
  return <input id="input-default" {...otherProps} />;
};

export const FormMessage = ({ message, ...otherProps }) => {
  const messageType = message?.type;
  const isTypeDefault = !messageType || _.isEqual(messageType, 'default');
  const isTypeSuccess = _.isEqual(messageType, 'success');
  const isCheckedIcon = isTypeDefault || isTypeSuccess;
  const messageText = message?.text;

  return (
    <div id="form-message" {...otherProps}>
      <div
        className={`form-message-content-wrapper ${
          isTypeDefault ? '' : isTypeSuccess ? 'success' : 'error'
        }`}>
        {isCheckedIcon && (
          <CheckedIcon className="form-message-checked-icon" fill="#FFFFFF" />
        )}
        <p className="form-message-text">{messageText}</p>
      </div>
    </div>
  );
};

import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { UserContext } from 'context/UserContext';
import { useMediaQuery } from 'react-responsive';
import { url } from 'config/urlConfig';
import { prices } from 'shared/const';
import { getCurrencyCodeSymbol } from 'shared/function/CountryUtils';
import useProductList from 'routes/Checkout/useProductList';

// components/assets
import Head from 'shared/components/Head.js';
import { HeaderBack } from './ManagePremiumComponents';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import RedemptionCodePopUp from 'routes/Books/RedemptionCodePopUp';
import CodeRedemptionSuccessPopUp from 'routes/ManagePremiumAccess/CodeRedemptionSuccessPopUp';
import BuyBookImg from 'assets/manage-premium/buy-book.png';
import RedeemGiftImg from 'assets/manage-premium/redeem-gift.png';
import './ManagePremiumAccess.scss';

const ManagePremiumAccess = () => {
  const history = useHistory();

  const { user } = useContext(UserContext);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const previousPath = params.get('from');

  const [isOpen, setIsOpen] = useState(false);
  const [isShowingCodeRedemptionPopUp, setIsShowingCodeRedemptionPopUp] =
    useState(false);
  const [productList, setProductList] = useState([]);

  const isDesktopView = useMediaQuery({ query: '(min-width: 992px)' });
  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const { is_demo: isDemo } = user;
  const isUserDemo = _.isEqual(isDemo, 1);

  const subscriptionPrice = prices?.subscription;
  const addChildPrice = prices?.addChild;

  const { isProductLoading, getProductList } = useProductList();

  const currency = localStorage.getItem('countryCurrency')
    ? localStorage.getItem('countryCurrency')
    : 'SGD';

  const productCurrency = currency.toLowerCase();
  const currencyCodeSymbol = getCurrencyCodeSymbol(currency);

  const init = async () => {
    try {
      const data = await getProductList();
      setProductList(data);
    } catch (err) {
      console.log('debug_kkh:: something went wrong when loading the products');
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    if (isUserDemo) {
      history.replace(`${url.UPGRADE_PREMIUM}?from=${previousPath}`);
    } else {
      history.push(`${url.ADD_CHILD}?from=${previousPath}`);
    }
  };

  const handleBack = () => {
    history.push(`/settings?from=${previousPath}`);
  };

  const renderItemPrice = () => {
    const product = productList.find(item => {
      if (isUserDemo) {
        return item.type === 'PREMIUM_ACCESS';
      }

      return item.type === 'PLAYBOOK';
    });

    if (product) {
      const { price } = product;

      const defaultProductPrice = price.find(price => price.currency === 'SGD');
      const selectedProductPrice = price.find(
        price => price.currency === productCurrency.toUpperCase(),
      );
      const productPrice = selectedProductPrice
        ? selectedProductPrice
        : defaultProductPrice;

      if (productPrice) {
        const { amount } = productPrice;
        return (
          <h2 className="name price">{`${currencyCodeSymbol}${amount.toFixed(
            2,
          )}${isUserDemo ? '' : '/child'}`}</h2>
        );
      }
    }

    // If nothing is returned
    if (isUserDemo) {
      return <h2 className="name price">{`$${subscriptionPrice}`}</h2>;
    }

    return <h2 className="name price">{`$${addChildPrice}/child`}</h2>;
  };

  if (isProductLoading || productList.length < 1) {
    return <LoadingIndicator />;
  }

  if (isShowingCodeRedemptionPopUp) {
    return (
      <CodeRedemptionSuccessPopUp
        onPressNext={() => {
          history.push(`${url.PLAYBOOKSECTION}`);
        }}
      />
    );
  }

  return (
    <div id="manage-premium-page">
      <Head title="Connected App - Add Gift/Book" preventIndex />
      {isMobileView && (
        <HeaderBack
          title={'Settings'}
          history={history}
          location={location}
          handleClick={handleBack}
        />
      )}

      <div className="manage-premium-container">
        <div className="manage-premium-header">
          <h2>Manage Premium access</h2>
          {isDesktopView && <div></div>}
        </div>

        <div className="manage-premium-items-container">
          {/* default section */}
          <div className="shop-container">
            <div className="shop-card left-card" onClick={() => handleClick()}>
              <div
                className="image-container book"
                style={{ backgroundImage: `url(${BuyBookImg})` }}
              />

              <h2 className="name">
                {isUserDemo ? 'Upgrade to Premium' : 'Add a child profile'}
              </h2>

              {renderItemPrice()}

              <div className="subtitle">
                {isUserDemo ? '(1 year access)' : '(One-time purchase)'}
              </div>
            </div>

            <div className="shop-card" onClick={() => setIsOpen(true)}>
              <div
                className="image-container gift"
                style={{ backgroundImage: `url(${RedeemGiftImg})` }}
              />

              <h2 className="name">
                {isUserDemo
                  ? 'Redeem gift code'
                  : 'Add a child profile via gift code'}
              </h2>
            </div>
          </div>

          {/* extra grey section for demo user */}
          {isUserDemo && (
            <div className="demo-container">
              <div className="subheader">
                <h3>
                  Upgrade to Premium for the option to add more child profiles
                </h3>
              </div>

              <div className="shop-container demo-grey">
                <div className="shop-card left-card demo">
                  <div
                    className="image-container book demo-grey"
                    style={{
                      backgroundImage: `url(${BuyBookImg})`,
                    }}
                  />

                  <h2 className="name demo">Add a child profile</h2>

                  <h2 className="name demo price">
                    {`$${addChildPrice}/child`}
                  </h2>

                  <div className="subtitle demo">{'(One-time purchase)'}</div>
                </div>

                <div className="shop-card demo">
                  <div
                    className="image-container gift demo-grey"
                    style={{
                      backgroundImage: `url(${RedeemGiftImg})`,
                    }}
                  />

                  <h2 className="name demo">
                    Add a child profile via gift code
                  </h2>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <RedemptionCodePopUp
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onSuccess={() => setIsShowingCodeRedemptionPopUp(true)}
      />
    </div>
  );
};

export default ManagePremiumAccess;

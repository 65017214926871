import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { ButtonSolid } from 'shared/components/Button/Button';
import { FormHeader, FormInput, FormError, FormFooter } from './AuthComponents';
import { emailVerification } from './authUtils';

const SignupCheckout = ({ history }) => {
  const [formValue, setFormValue] = useState({
    email: '',
  });

  const [submitDisable, setSubmitDisable] = useState(true);
  const [error, setError] = useState('');

  const handleChange = e => {
    setError('');
    const { value, name } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const trackGtmEvent = event => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: event,
    });
  };

  const submitForm = async e => {
    e.preventDefault();

    const email = formValue?.email;
    const encodeEmail = encodeURIComponent(email);
    const bodyFormData = { email: email };

    try {
      const res = await emailVerification(bodyFormData);

      if (res) {
        trackGtmEvent('create_account');
        history.push(`/signup-verification?email=${encodeEmail}`);
        setSubmitDisable(false);
      }
    } catch (err) {
      console.log({ err });
      setError({ message: err?.response?.data?.message, type: 'error' });
      setSubmitDisable(true);
    }
  };

  useEffect(() => {
    const { email } = formValue;
    const emailRE =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email && emailRE.test(email)) {
      setSubmitDisable(false);
    } else if (email && !emailRE.test(email)) {
      setError({
        message: 'Please enter a valid email address',
        type: 'email',
      });
      setSubmitDisable(true);
    }
  }, [formValue]);

  return (
    <div className="right-content-space">
      <div className="form-content">
        <FormHeader title="Sign up for free to grow closer in your relationships." />

        <form onSubmit={submitForm}>
          <FormInput
            type="email"
            name="email"
            placeholder="Email"
            onChange={e => handleChange(e)}
            autoComplete="off"
            isError={error?.type === 'email'}
            error={error?.message}
          />

          <FormError message={error?.type === 'error' && error?.message} />

          <ButtonSolid
            className="submit"
            type="submit"
            children="Create account"
            disabled={submitDisable}
          />

          <FormFooter
            text="Already have an account?"
            buttonText="Log in"
            buttonClick={() => history.push('/login')}
          />
        </form>
      </div>
    </div>
  );
};

export default withRouter(SignupCheckout);

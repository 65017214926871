import React, { useState, useEffect, useContext } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ToastContext } from 'context/ToastContext';
import { UserContext } from 'context/UserContext';
import _ from 'lodash';
import moment from 'moment';
import parse from 'html-react-parser';
import { goAnchorTop } from 'shared/function/goAnchor';
import {
  getNotifications,
  readNotifications,
  deleteNotifications,
  handleSettingBack,
} from '../SettingModuleUtils';

// components/assets
import { BackButton } from './SettingsComponent';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import { AccessTime } from '@material-ui/icons';
import { ReactComponent as EmptyMailbox } from 'assets/groups/empty-mailbox.svg';
import './Mailbox.scss';

const Mailbox = ({ history }) => {
  const location = useLocation();

  const [notificationList, setNotificationList] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState([]);

  const [showDeleteMailModal, setShowDeleteMailModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { setToast } = useContext(ToastContext);
  const { badgeCount, setBadgeCount } = useContext(UserContext);

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const isMaxWidth531 = useMediaQuery({ query: '(max-width: 531px)' });

  const handleClickNotificationItem = notification => {
    let updatedSelectedNotification = [];

    if (selectedNotification.length > 1) {
      // If there are more than one selected notifications,
      // replace the first element with the new notification
      updatedSelectedNotification = [notification, selectedNotification[0]];
    } else {
      updatedSelectedNotification = [notification, ...selectedNotification];
    }

    setSelectedNotification(updatedSelectedNotification);
  };

  const readSpecificNotification = async ({ notification }) => {
    try {
      if (notification && notification?.id) {
        const res = await readNotifications(notification?.id);
        setBadgeCount(badgeCount - 1);
        const { data } = res.data;
        const tempList = [...notificationList];
        const notIndex = _.findIndex(
          notificationList,
          item => item.id === notification.id,
        );
        tempList[notIndex] = data;
        setNotificationList(tempList);
      }

      // goToTop();
    } catch (err) {
      const { message } = err?.response?.data;
      setToast({
        type: 'error',
        message,
      });
    }
  };

  const handleRemoveNotification = async () => {
    try {
      await deleteNotifications(selectedNotification.id);
      await getNotificationData();
      setShowDeleteMailModal(false);

      if (isMobile) {
        setSelectedNotification([]);
      } else {
        setSelectedNotification([notificationList[0]]);
      }

      setToast({
        type: 'success',
        message: 'Notification has been deleted.',
      });
    } catch (err) {
      const { message } = err.response.data;
      setToast({
        type: 'error',
        message,
      });
    }
  };

  const getNotificationData = async () => {
    setIsLoading(true);

    try {
      const response = await getNotifications();
      const reverseNotifications = response?.data?.data?.reverse();
      const firstNotification = reverseNotifications && reverseNotifications[0];

      setNotificationList(reverseNotifications);
      !isMobile && setSelectedNotification([firstNotification]);

      setIsLoading(false);
    } catch (err) {
      const { message } = err.response.data;
      setToast({
        type: 'error',
        message,
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getNotificationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedNotification) {
      goAnchorTop();
    }
  }, [selectedNotification]);

  useEffect(() => {
    if (
      selectedNotification?.length !== 0 &&
      selectedNotification?.length > 1
    ) {
      readSpecificNotification({ notification: selectedNotification[1] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotification]);

  const NotificationItemContent = () => {
    const item = selectedNotification[0];
    const image = item?.image;
    const message =
      typeof item?.message === 'string' ? parse(item?.message) : '';

    return (
      <div className="content">
        {image && <img src={image} alt="Message" />}
        <p className="content-description">{message}</p>
      </div>
    );
  };

  const NotificationListItem = ({ notification }) => {
    const id = notification?.id;
    const title = notification?.title;
    const pivot = notification?.pivot;

    let formattedTitle;

    if (isMaxWidth531) {
      formattedTitle = title?.length > 25 ? `${title?.slice(0, 25)}...` : title;
    } else {
      formattedTitle = title?.length > 55 ? `${title?.slice(0, 55)}...` : title;
    }

    const readAt = pivot?.read_at;
    const sentAt = moment(pivot?.sent_at).format('DD MMMM YYYY');

    const isSelectedNotification = _.isEqual(selectedNotification[0]?.id, id);

    return (
      <div
        className={`notification-list-item ${!readAt ? 'unread' : ''}  ${
          isSelectedNotification && 'active'
        }`}
        key={id}
        onClick={() => handleClickNotificationItem(notification)}>
        <header>
          <h2>
            {formattedTitle} <div className="signal" />{' '}
          </h2>
          <div className="header-info">
            <div className="date">
              <AccessTime /> {sentAt}
            </div>
          </div>
        </header>
      </div>
    );
  };

  const DeleteMailModal = () => (
    <div
      id="delete-notification-dialog"
      onClick={() => setShowDeleteMailModal(false)}>
      <div className="dialog-container" onClick={e => e.stopPropagation()}>
        <div className="content-container">
          <h1>Are you sure?</h1>
          <p>Do you want to delete this mail?</p>
        </div>

        <div className="action-container">
          <button
            className="accept-btn"
            type="button"
            onClick={() => handleRemoveNotification()}>
            Yes
          </button>
          <button
            className="cancel-btn"
            type="button"
            onClick={() => setShowDeleteMailModal(false)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  if (isLoading) return <LoadingIndicator />;

  return (
    <div id="mailbox-screen">
      <div className="mailbox-container">
        {((isMobile && _.isEmpty(selectedNotification)) || !isMobile) && (
          <div className="notification-header">
            <BackButton
              history={history}
              handleClick={() => {
                readSpecificNotification({
                  notification: selectedNotification[0],
                });

                handleSettingBack({ history, location, isMobile });
              }}
            />
            <h1 className="notification-title">Notification centre</h1>
          </div>
        )}

        <div
          className={`notification-wrapper ${
            notificationList?.length === 0 && 'empty-notification'
          }`}>
          {((isMobile && _.isEmpty(selectedNotification)) || !isMobile) && (
            <div className="notification-list-container">
              {notificationList?.length !== 0 ? (
                <React.Fragment>
                  {notificationList?.map(item => (
                    <NotificationListItem key={item.id} notification={item} />
                  ))}
                </React.Fragment>
              ) : (
                <div className="notification-list-container">
                  <div className="empty-notification">
                    <EmptyMailbox />
                    <p>
                      All good here!
                      <br />
                      Your mailbox is empty.
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}

          {notificationList?.length !== 0 &&
            !_.isEmpty(selectedNotification) && (
              <div className="notification-content-container">
                <header>
                  {!isMobile && <h3> {selectedNotification[0]?.title}</h3>}
                  {isMobile && (
                    <BackButton
                      history={history}
                      title="Notification centre"
                      handleClick={() => setSelectedNotification(null)}
                    />
                  )}
                </header>

                <div className="sub-header">
                  {isMobile && <h3>{selectedNotification?.title}</h3>}

                  <div className="header-info">
                    <div className="date">
                      <AccessTime />{' '}
                      {moment(selectedNotification?.pivot?.sent_at).format(
                        'DD MMMM YYYY',
                      )}
                    </div>
                  </div>
                </div>

                <NotificationItemContent />
              </div>
            )}
        </div>
      </div>

      {showDeleteMailModal && <DeleteMailModal />}
    </div>
  );
};
export default withRouter(Mailbox);

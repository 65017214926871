import axios from 'axios';

export const getReferralByEmail = ({ email }) =>
  axios.post('/referral/referral-by-email', {
    email,
  });

export const getReferrals = ({ queryParams }) =>
  axios.get('/referral/referrals', {
    params: { ...queryParams },
  });

export const getReferralParentingVillage = () =>
  axios.get('/referral/parentingVillage');

export const validateReferralCode = ({ code }) =>
  axios.post('/auth/verify-referral', { code });

export const inviteSteps = [
  {
    label: '1',
    description: `Click 'Invite a friend' to share your unique referral link, or tap 'Copy' to grab your referral code.`,
  },
  {
    label: '2',
    description:
      'Your loved ones create their profile with your referral link or code.',
  },
  {
    label: '3',
    description:
      'You both get 7 days Premium access as a reward in your Connected account!',
  },
];

import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Redirect, useHistory } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import _ from 'lodash';
import { url } from 'config/urlConfig';
import useScrollBlock from '../shared/components/OnboardingTourV1.js/useScrollBlock';
import OnboardingTour from 'shared/components/OnboardingTour/OnboardingTour';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import usePreviewChapter from 'shared/hooks/usePreviewChapter';
import { useSignupStatus } from './Auth/authUtils';
import { useOnboardingStatus } from 'shared/components/OnboardingTour/OnboardingTourUtils';

const ProtectedRoute = props => {
  const { location } = props;
  const history = useHistory();
  const path = location.pathname;

  const { user, setUser } = useContext(UserContext);

  const [isAuthenticated, setIsAuthenticated] = useState(
    _.isEmpty(user) ? true : false,
  );

  const { isLandingOnboard } = useOnboardingStatus({
    path,
  });

  const { isPreviewChapterLoading } = usePreviewChapter();

  const [, allowScroll] = useScrollBlock();

  const {
    isSignupCompleted,
    isParentProfileCompleted,
    isChildProfileCompleted,
  } = useSignupStatus({ user });

  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(true);

  const tokenFromStorage =
    localStorage.getItem('token') || sessionStorage.getItem('token');

  const Component = props.component;

  const SignupInProgressRoutes = [
    '/pretest-onboarding',
    '/profile-test',
    '/test-loading',
    '/profile',
    '/test/parent',
    '/profile-test-child',
    '/test/child',
    '/welcome',
  ];

  useEffect(() => {
    if (_.isEmpty(user)) {
      if (token) {
        getUser();
      } else {
        setUser({});
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    } else {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
      setIsAuthenticated(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!isSignupCompleted && !SignupInProgressRoutes.includes(path)) {
      if (
        isParentProfileCompleted &&
        _.isEqual(isParentProfileCompleted, false)
      ) {
        history.push('/profile-test');
      } else if (
        !isChildProfileCompleted &&
        _.isEqual(isChildProfileCompleted, false)
      ) {
        history.push('/profile-test-child');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path, user, history]);

  // Landing Onboard
  useEffect(() => {
    if (
      isLandingOnboard &&
      isSignupCompleted &&
      !SignupInProgressRoutes.includes(path)
    ) {
      history.push(`${url.PLAYBOOKSNAPSHOT}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLandingOnboard, isSignupCompleted, history]);

  useEffect(() => {
    if (tokenFromStorage) {
      setToken(tokenFromStorage);
    }

    if (!tokenFromStorage && !SignupInProgressRoutes.includes(path)) {
      history.push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenFromStorage, path, history]);

  useEffect(() => {
    window.addEventListener('beforeunload', allowScroll());
    return () => {
      window.removeEventListener('beforeunload', allowScroll());
    };
  }, [allowScroll]);

  const getUser = async () => {
    try {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      const response = await axios.get('/user');
      const authUser = response?.data?.data;

      authUser.token = token;
      setUser(authUser);
      setIsAuthenticated(true);
    } catch (err) {
      console.log(err?.message);
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  } else {
    return isAuthenticated ? (
      <OnboardingTour history={history} isLoading={isPreviewChapterLoading}>
        <Component />
      </OnboardingTour>
    ) : (
      <Redirect
        to={{
          pathname: `/login`,
          state: { from: location },
        }}
      />
    );
  }
};

export default ProtectedRoute;

import React, { useState } from 'react';
import { GAUser } from 'shared/function/Tracking/GAUtils';
import { ButtonSolid } from 'shared/components/Button/Button';
import './Logout.scss';

const Logout = ({ handleLogout }) => {
  const [showConfirmationModal, setshowConfirmationModal] = useState(false);

  const onLogout = () => {
    sessionStorage.removeItem('previewChapterResponseData');
    sessionStorage.removeItem('selectedProduct');
    sessionStorage.removeItem('selectedRelative');
    sessionStorage.removeItem('notification_banner');
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    GAUser({ type: 'logout' });
    handleLogout();
  };

  return (
    <div id="logout">
      <ButtonSolid
        onClick={() => setshowConfirmationModal(true)}
        className="logout-btn"
        children="Log Out"
      />

      {showConfirmationModal && (
        <div
          id="logout-confirmation-modal"
          onClick={() => setshowConfirmationModal(false)}>
          <div
            className="logout-confirmation-container"
            onClick={e => e.stopPropagation()}>
            <div className="confirmation-content-container">
              <h1>Are you sure?</h1>
              <p>Do you want to log out from your account?</p>
            </div>

            <div className="confirmation-action-container">
              <button
                className="accept-btn"
                type="button"
                onClick={() => onLogout()}>
                Logout
              </button>
              <button
                className="cancel-btn"
                type="button"
                onClick={() => setshowConfirmationModal(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Logout;

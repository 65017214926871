import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { url } from 'config/urlConfig';
import { prices, CODE_TYPES } from 'shared/const';
import { getCurrencyCodeSymbol } from 'shared/function/CountryUtils';
import {
  trackingAction,
  TRACKING_PURCHASES,
  GA_EVENT_CATOGARY,
  GA_PURCHASE_JOURNEY_TYPE,
} from 'shared/function/Tracking';
import { useDetectPreviousPath } from 'routes/SettingsModule/SettingModuleUtils';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import useProductList from 'routes/Checkout/useProductList';

// components/assets
import { ButtonSolid, ButtonText } from 'shared/components/Button/Button';
import { HeaderBack } from './ManagePremiumComponents';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import RedemptionCodePopUp from 'routes/Books/RedemptionCodePopUp';
import CodeRedemptionSuccessPopUp from 'routes/ManagePremiumAccess/CodeRedemptionSuccessPopUp';
import { ReactComponent as CheckPlainIcon } from 'assets/general/checked-plain-icon.svg';
import upgradePremiumWebImg from 'assets/manage-premium/upgrade-premium-web.png';
import upgradePremiumMobImg from 'assets/manage-premium/upgrade-premium-mob.png';
import './UpgradePremium.scss';
import { UserContext } from 'context/UserContext';

const UpgradePremium = () => {
  const { user } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();

  const subscriptionPrice = prices?.subscription;

  const contentDescriptions = [
    'Premium content and features',
    'Access new content and features as they are developed',
    'Option to add additional child profiles',
  ];

  const { previousPath } = useDetectPreviousPath({
    location,
  });

  const { isUserPremiumAccess } = useUserPremiumAccess();

  const { selectedProduct, isProductLoading, getProductList } =
    useProductList();

  const [isRedemptionCodeOpen, setIsRedemptionCodeOpen] = useState(false);
  const [isRedemptionSuccess, setIsRedemptionSuccess] = useState(false);
  const [productList, setProductList] = useState([]);

  const currency = localStorage.getItem('countryCurrency')
    ? localStorage.getItem('countryCurrency')
    : 'SGD';

  const productCurrency = currency.toLowerCase();
  const currencyCodeSymbol = getCurrencyCodeSymbol(currency);

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const upgradePremiumImg = isMobileView
    ? upgradePremiumMobImg
    : upgradePremiumWebImg;

  const init = async () => {
    try {
      const data = await getProductList();
      setProductList(data);
    } catch (err) {
      console.log('debug_kkh:: something went wrong when loading the products');
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckoutPremiumUpgrade = () => {
    getProductList();

    trackingAction({
      category: GA_EVENT_CATOGARY.ecommerce,
      action: isUserPremiumAccess
        ? TRACKING_PURCHASES.checkout_premium_extend
        : TRACKING_PURCHASES.checkout_premium_upgrade,
      ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.add_to_cart,
      ecommerceEventData: { productData: selectedProduct },
      extraData: {
        user_id: user?.id,
      },
    });

    history.push(`/checkout?unlockplaybook=true&from=${previousPath}`);
  };

  const handleRedeemCode = () => {
    setIsRedemptionCodeOpen(true);
  };

  const ContentDescription = () => {
    return (
      <div className="upgrade-premium-sub-content">
        {contentDescriptions.map((item, index) => {
          const key = `modal-description-item-${index}`;

          return (
            <div key={key} className="list-item-container">
              <CheckPlainIcon fill={'#50c1bc'} />
              {item}
            </div>
          );
        })}
      </div>
    );
  };

  const renderItemPrice = () => {
    const product = productList.find(item => item.type === 'PREMIUM_ACCESS');

    if (product) {
      const { price } = product;

      const defaultProductPrice = price.find(price => price.currency === 'SGD');
      const selectedProductPrice = price.find(
        price => price.currency === productCurrency.toUpperCase(),
      );
      const productPrice = selectedProductPrice
        ? selectedProductPrice
        : defaultProductPrice;

      if (productPrice) {
        const { amount } = productPrice;
        return `${currencyCodeSymbol}${amount.toFixed(2)}`;
      }
    }

    return `$${subscriptionPrice}`;
  };

  if (isProductLoading || productList.length < 1) {
    return <LoadingIndicator />;
  }

  if (isRedemptionSuccess) {
    return (
      <CodeRedemptionSuccessPopUp
        onPressNext={() => {
          history.push(`${url.PLAYBOOKSECTION}`);
        }}
      />
    );
  }

  return (
    <div id="upgrade-premium-screen">
      <HeaderBack
        title={'Manage account'}
        history={history}
        location={location}
      />

      <h2 className="upgrade-premium-header">Upgrade or renew Premium</h2>

      <div className="upgrade-premium-outer-container">
        <div
          className="upgrade-premium-inner-container"
          onClick={e => e.stopPropagation()}>
          <div className="upgrade-premium-popup-container">
            <div className="upgrade-premium-popup-content-container image-container">
              <img src={upgradePremiumImg} alt="Upgrade Premium" />
            </div>
          </div>

          <div className="upgrade-premium-sub-content-container">
            <ContentDescription />

            <h2 className="upgrade-premium-price-subtitle">
              {renderItemPrice()}
              <div className="subtitle">(1 year access)</div>
            </h2>

            <div className="upgrade-premium-modal-button-container">
              <ButtonSolid
                className="upgrade-premium-button"
                onClick={() => handleCheckoutPremiumUpgrade()}
                children="Checkout"
              />

              <div className="upgrade-premium-divider-container">
                <div className="upgrade-premium-divider" />
                or
                <div className="upgrade-premium-divider" />
              </div>

              <ButtonText
                className="redeem-code-button"
                children="Redeem code"
                onClick={handleRedeemCode}
              />
            </div>
          </div>
        </div>
      </div>

      <RedemptionCodePopUp
        type={CODE_TYPES.MULTI_USE_PREMIUM_ACCESS}
        setIsOpen={setIsRedemptionCodeOpen}
        isOpen={isRedemptionCodeOpen}
        onSuccess={() => setIsRedemptionSuccess(true)}
      />
    </div>
  );
};

UpgradePremium.defaultProps = {
  handleCheckout: () => {},
  handleRedeemCode: () => {},
};
export default UpgradePremium;

const checkIsCurrentPath = ({ path, pathName }) => {
  const currentPath = () => {
    const parts = path.split('/'); // Split the path by "/"

    if (parts[1] === pathName) {
      return true;
    }
    return false;
  };

  const isCurrentPath = currentPath();

  return isCurrentPath;
};

export default checkIsCurrentPath;

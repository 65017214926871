import { useState, useEffect, useContext, useMemo } from 'react';
import axios from 'axios';
import { UserContext } from 'context/UserContext';
import _ from 'lodash';
import { url } from 'config/urlConfig';

// assets
import spotlightImg from 'assets/onboarding-tour/onboarding-spotlight.png';
import playbookImg from 'assets/onboarding-tour/onboarding-playbook.png';
import snapshotImg2 from 'assets/onboarding-tour/peaksnapshot-onboarding.png';
import topicsWebImg from 'assets/onboarding-tour/onboarding-topics-web.png';
import topicsMobImg from 'assets/onboarding-tour/onboarding-topics-mob.png';

export const updateOnboardStatus = body => axios.put('/user/onboard', body);

export const useOnboardingStatus = ({ path }) => {
  const { user, setUser } = useContext(UserContext);

  const [isOnboard, setIsOnboard] = useState(false);

  // Landing Onboard
  const isLandingOnboard = _.isEqual(user?.onboard_snapshot, 0);

  const isOnboardHome =
    path === url.HOME && _.isEqual(user?.onboard_spotlight, 0);
  const isOnboardPlaybook =
    path === url.PLAYBOOKSECTION && _.isEqual(user?.onboard_playbook, 0);
  const isOnboardSnapshot =
    path === url.PLAYBOOKSNAPSHOT && _.isEqual(user?.onboard_snapshot, 0);
  const isOnboardTopics =
    path === url.TOPICS && _.isEqual(user?.onboard_journey, 0);

  const isOnboardType =
    (isOnboardHome && 'home') ||
    (isOnboardPlaybook && 'playbook') ||
    (isOnboardSnapshot && 'snapshot') ||
    (isOnboardTopics && 'topics');

  // Note: Some terms, like "spotlight" and "journey," are still being used,
  // as the backend hasn't been updated to reflect the changes.
  const updateOnboard = async ({ type }) => {
    const isTypeHome = _.isEqual(type, 'home');
    const isTypePlaybook = _.isEqual(type, 'playbook');
    const isTypeSnapshot = _.isEqual(type, 'snapshot');
    const isTypeTopics = _.isEqual(type, 'topics');

    try {
      await updateOnboardStatus({
        type: isOnboardHome ? 'spotlight' : isOnboardTopics ? 'journey' : type,
      });

      setUser({
        ...user,
        onboard_spotlight: isTypeHome ? 1 : user?.onboard_spotlight,
        onboard_playbook: isTypePlaybook ? 1 : user?.onboard_playbook,
        onboard_snapshot: isTypeSnapshot ? 1 : user?.onboard_snapshot,
        onboard_journey: isTypeTopics ? 1 : user?.onboard_journey,
      });
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    const isOnboard =
      isOnboardHome ||
      isOnboardPlaybook ||
      isOnboardSnapshot ||
      isOnboardTopics;

    if (isOnboard) {
      setIsOnboard(true);
    } else {
      setIsOnboard(false);
    }
  }, [
    isOnboardHome,
    isOnboardPlaybook,
    isOnboardSnapshot,
    isOnboardTopics,
    path,
  ]);

  return {
    isOnboard,
    isOnboardType,
    isLandingOnboard,
    updateOnboard,
  };
};

export const useOnboardItems = ({ previewChapterData, isMobileView }) => {
  const { user, selectedRelative: contextSelectedRelative } =
    useContext(UserContext);

  const selectedRelativeFromStorage =
    contextSelectedRelative ||
    JSON.parse(sessionStorage.getItem('selectedRelative'));

  const [topicsImg, setTopicsImg] = useState(null);

  const data = previewChapterData && previewChapterData;
  const bookId = data?.book_id;
  const chapterId = data?.chapter_id;
  const sectionId = data?.section_id;

  const userName = user?.name?.toLowerCase();
  const childName =
    selectedRelativeFromStorage?.name?.toLowerCase() ||
    data?.child_name?.toLowerCase();

  const childGender =
    selectedRelativeFromStorage?.gender === 'male' ? 'his' : 'her';

  useEffect(() => {
    // Define the logic to set the correct image based on isMobileView
    if (!!isMobileView) {
      setTopicsImg(topicsMobImg);
    } else {
      setTopicsImg(topicsWebImg);
    }
  }, [isMobileView]);

  const onboardItems = useMemo(() => {
    if (
      !previewChapterData ||
      !childName ||
      !bookId ||
      !sectionId ||
      !chapterId
    ) {
      return [];
    }

    return [
      {
        type: 'home',
        headerTop: `Hi, ${userName}!`,
        headerBottom: `Welcome to your Connected journey!`,
        subHeader: `Here's your Home feed, where you can:`,
        SubHeaderPos: 'bottom',
        img: spotlightImg,
        description: [
          `Get a daily customised insight about ${childName}`,
          `Better understand ${childName} with deep dives into ${childGender} personality`,
          `Save and share these insights with your loved ones`,
        ],
        buttons: [{ type: 'default', title: 'Next' }],
      },
      {
        type: 'snapshot',
        headerTop: 'Your PEAKS Snapshot',
        subHeader: 'A personality overview before applying your Playbook tips',
        SubHeaderPos: 'top',
        img: snapshotImg2,
        description: `Discover at a glance, how ${childName}'s personality relates to yours across 5 PEAKS Factors and 25 PEAKS Facets.`,
        subDescription:
          'Get started, then tap on any of the PEAKS letters to explore each personality facet.',
        buttons: [{ type: 'default', title: 'Get started' }],
      },
      {
        type: 'playbook',
        headerTop: 'Your Playbook',
        subHeader: 'Premium content with everyday tips to apply!',
        SubHeaderPos: 'top',
        img: playbookImg,
        description: `Get equipped with customised tips and practical handles for your parenting journey with ${childName}.`,
        subDescription:
          "As a Basic user, we've prepared a sample chapter for you to experience the Playbook:",
        buttons: [
          {
            type: 'default',
            title: 'Preview',
            navigation: `playbooks`,
          },
          { type: 'outline', title: 'Skip' },
        ],
      },
      {
        type: 'topics',
        headerTop: 'Your Topical Insights',
        subHeader: 'Customised insights for everyday parenting life',
        SubHeaderPos: 'top',
        img: topicsImg,
        description:
          'Introducing a fresh way to look at the parenting topics close to your heart.',
        subDescription:
          'Get topical content tailored for you and each child, with new topics released regularly.',
        buttons: [{ type: 'default', title: 'Get started' }],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewChapterData, childName, bookId, sectionId, chapterId]);

  return onboardItems;
};

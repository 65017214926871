export default function getSnapshotImages() {
  const alphaArray = ['P', 'E', 'A', 'K', 'S'];
  const snapshotImages = [];

  // In Node.js version 16 and later, as ES module support improved, the requirement to use
  // `.default` to access the default export of a CommonJS module was removed
  alphaArray.forEach(alpha => {
    // Handle chapter images (1 to 5)
    Array.from({ length: 5 }, (_, i) => i + 1).forEach(j => {
      snapshotImages.push({
        label: `${alpha + j}`,
        jpg: require(`./${alpha + j}.jpg`),
      });
    });
  });

  return snapshotImages;
}

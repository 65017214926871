import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import * as Scroll from 'react-scroll';
import { useKeenSlider } from 'keen-slider/react';
import { UserContext } from 'context/UserContext';
import { NoAccessContext } from 'context/NoAccessContext.js';
import _ from 'lodash';
import parse from 'html-react-parser';
import { domainColor } from 'shared/const';
import { TESTING_PLAYBOOK_ID } from 'shared/const/TestAutomationIds';
import { capitalize } from 'shared/helper/helper';
import {
  trackingAction,
  TRACKING_PLAYBOOKS,
  TRACKING_GENERAL,
} from 'shared/function/Tracking';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import useChildPicker from 'shared/hooks/useChildPicker';
import useStickyHeader from 'shared/hooks/useStickyHeader';
import {
  getSectionDomainCompletedColor,
  getSectionMenuCompletedBackground,
  navigatePlaybooks,
} from '../BooksUtils';
import { getSection, getBooksVideo, feedbackAppear } from '../api';

// components/assets
import Head from 'shared/components/Head.js';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import ChildPicker from 'shared/components/ChildPicker/ChildPicker';
import NoAccessModal from 'routes/ManagePremiumAccess/NoAccessModal';
import { SectionVideoModal } from '../BookChapter/VideoModal.js';
import {
  BookCard,
  SectionMenuImageWeb,
  SectionMenuImageMob,
} from '../BooksComponent';
import videoPlayIcon from 'assets/books/video-play-icon.png';
import videoTimeIcon from 'assets/books/video-time-icon.png';
import './BookSection.scss';

const scroller = Scroll.scroller;

const BookSection = ({ history }) => {
  const { user, setUser } = useContext(UserContext);

  const { noAccessOpen, setNoAccessOpen } = useContext(NoAccessContext);

  const selectedRelative = JSON.parse(
    sessionStorage.getItem('selectedRelative'),
  );

  const previewChapterResponseData = JSON.parse(
    sessionStorage.getItem('previewChapterResponseData'),
  );
  const { childs } = previewChapterResponseData;

  const { isUserPremiumAccess } = useUserPremiumAccess();

  const { selectedChildOption, childPickerData } = useChildPicker();

  const childId = selectedChildOption?.id;
  const childName = capitalize(selectedChildOption?.name);

  const [bookId, setBookId] = useState(null);
  const [sectionData, setSectionData] = useState([]);
  const [selectedSection, setSelectedSection] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const [videoArray, setVideoArray] = useState(null);
  const [videoObject, setVideoObject] = useState(null);
  const [options, setOptions] = useState({});
  const [endingCardState, setEndingCardState] = useState(false);
  const [activeSlider, setActiveSlider] = useState(1);

  const [sliderRef, slider] = useKeenSlider(options);

  useStickyHeader({
    headerSelector: '.page-header',
    otherItemSelector: '#react-smartbanner',
  });

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });
  const customSize = useMediaQuery({
    query: '(min-width: 1024px) and (max-width: 1300px)',
  });

  const sectionId = selectedSection?.id;

  const getSectionData = async () => {
    setIsLoading(true);

    try {
      const res = await getSection(bookId);
      const { data } = res?.data;

      if (data) {
        checkActiveSlider(data);
        setSectionData(data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err.message);
      setIsLoading(false);
    }
  };

  const checkActiveSlider = cardArray => {
    let slideToSet = 0;

    if (cardArray[0].percentage < 100) slideToSet = 1;
    else if (cardArray[1].percentage < 100) slideToSet = 2;
    else if (cardArray[2].percentage < 100) slideToSet = 3;
    else if (cardArray[3].percentage < 100) slideToSet = 4;
    else if (cardArray[4].percentage < 100) slideToSet = 5;
    else slideToSet = 6;

    setActiveSlider(slideToSet);
  };

  const getVideoData = async () => {
    const res = await getBooksVideo();
    const { data } = res?.data;

    const isAllChapterCompletedState = data?.some(video => {
      return video?.book_completed === true;
    });

    if (isAllChapterCompletedState) {
      setEndingCardState(true);
    }

    setVideoArray(data);
  };

  const handleSelectDomain = async (section, index) => {
    const relativeId = selectedRelative?.id;

    const { id: sectionId, domain_id: domainId, name: domainName } = section;

    if (isUserPremiumAccess || domainName.toLowerCase() === 'purpose') {
      trackingAction({
        action: TRACKING_PLAYBOOKS.section_menu_select_domain,
        extraData: {
          domain_id: domainId,
          book_id: bookId,
          section_id: sectionId,
          relative_id: relativeId,
          user_id: user?.id,
        },
      });

      slider.moveToSlide(index + 1);

      setSelectedSection(section);

      navigatePlaybooks({
        navigate: history.push,
        type: 'chapter-menu',
        domainSlug: domainName,
        bookId,
        sectionId,
        domainId,
      });
    } else {
      setNoAccessOpen(true);
    }
  };

  const openVideo = type => {
    let vidObj;
    switch (type) {
      case 'intro':
        vidObj = _.find(videoArray, { type: 'Welcome' });
        setVideoObject(vidObj);
        break;
      case 'outro':
        vidObj = _.find(videoArray, { type: 'Ending' });
        setVideoObject(vidObj);
        appearFeedback();
        break;
      default:
        break;
    }
  };

  const onNavigateSnapshot = async () => {
    navigatePlaybooks({
      navigate: history.push,
      type: 'snapshot',
      bookId,
    });

    trackingAction({
      action: TRACKING_GENERAL.navigate_spotlight,
      extraData: { relative_id: childId, user_id: user?.id },
    });
  };

  const onShowWelcomeVideo = async () => {
    openVideo('intro');

    trackingAction({
      action: TRACKING_PLAYBOOKS.section_menu_play_intro_video,
      extraData: {
        user_id: user?.id,
      },
    });
  };

  const onShowEndingVideo = async () => {
    endingCardState && openVideo('outro');

    trackingAction({
      action: TRACKING_PLAYBOOKS.section_menu_play_ending_video,
      extraData: {
        user_id: user?.id,
      },
    });
  };

  const appearFeedback = async () => {
    await feedbackAppear({ feedback_shown: 1 });
    setUser({ ...user, feedback_shown: 1 });
  };

  useEffect(() => {
    //handle refresh
    if (performance.navigation.type === 1) {
      if (sectionId) {
        setTimeout(
          () =>
            navigatePlaybooks({
              navigate: history.replace,
              type: 'section-menu',
              bookId,
              sectionId,
            }),
          100,
        );

        scroller.scrollTo('book-chapter-page', {
          duration: 1000,
          delay: 4000,
          smooth: true,
        });
      } else {
        navigatePlaybooks({
          navigate: history.replace,
          type: 'section-menu',
          bookId,
        });
      }
    }

    if (bookId) {
      Promise.all([getSectionData(), getVideoData()]);
    }

    return () => setSectionData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookId, sectionId, history]);

  useEffect(() => {
    if (sectionData?.length > 0) {
      setTimeout(() => {
        setIsLoading(false);

        setOptions({
          slidesPerView: customSize ? 2.9 : 4,
          initial: activeSlider,
          mode: 'snap',
          spacing: 15,
          centered: true,
          align: 'center',
          loop: false,
          breakpoints: {
            '(min-width: 720px) and (max-width: 992px)': {
              slidesPerView: 2,
            },
            '(max-width: 719px)': {
              slidesPerView: 1.2,
            },
          },
        });
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionData]);

  useEffect(() => {
    if (!bookId) {
      setIsLoading(true);

      const selectedChild = childs?.find(child => {
        return child?.id === selectedChildOption?.id;
      });

      const selectedChildBookId = selectedChild?.book_id;

      setBookId(selectedChildBookId);
    } else {
      setIsLoading(false);
      setBookId(bookId);
    }
  }, [bookId, selectedChildOption, childs]);

  useEffect(() => {
    setNoAccessOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || sectionData?.length === 0) return <LoadingIndicator />;

  return (
    <div id="book-section-page">
      <Head title="Connected App - Book Section" preventIndex />

      <div
        ref={sliderRef}
        id="book-section-container"
        className="book-section-container">
        <div className="book-section-header">
          <ChildPicker childPickerData={childPickerData} />
        </div>

        <div
          id="snapshot"
          data-test-id={TESTING_PLAYBOOK_ID.SECTION_SNAPSHOT_CARD}
          className="section-card snapshot">
          <BookCard
            type="snapshot"
            handleClick={() => onNavigateSnapshot()}
            leftContent={
              isMobileView ? (
                <SectionMenuImageMob section="Snapshot" />
              ) : (
                <SectionMenuImageWeb section="Snapshot" />
              )
            }
            rightContent={
              <h2>How you and {[childName]}’s personality relates</h2>
            }
          />
        </div>

        <div
          id="intro-video"
          data-test-id={TESTING_PLAYBOOK_ID.SECTION_INTRO_VIDEO_CARD}
          className="section-card video">
          <BookCard
            type="video"
            handleClick={() => onShowWelcomeVideo()}
            leftContent={
              <div className="img-container">
                <div className="image-wrapper">
                  <img className="play-icon" src={videoPlayIcon} alt="Play" />
                  <SectionMenuImageWeb section="Intro" />
                  <img className="time-icon" src={videoTimeIcon} alt="Time" />
                </div>
              </div>
            }
            rightContent={<h2>Introducing your Playbook</h2>}
          />
        </div>

        {sectionData &&
          sectionData?.map((section, index) => {
            const {
              id,
              dynamic_header,
              subheader_description,
              name,
              percentage,
            } = section;

            const isCompletedState = _.isEqual(percentage, 100);

            const completedBackgroundImage = isCompletedState
              ? getSectionMenuCompletedBackground({
                  section: name,
                })
              : 'null';

            return (
              <React.Fragment key={id}>
                {section && (
                  <div
                    key={id}
                    id={id}
                    data-test-id={`${
                      TESTING_PLAYBOOK_ID.SECTION_DOMAIN_CARD
                    }-${name.toLowerCase()}`}
                    className="section-card chapter"
                    onClick={() => handleSelectDomain(section, index)}>
                    <div
                      className="book-card"
                      style={{
                        backgroundImage:
                          isCompletedState && !isMobileView
                            ? `url(${completedBackgroundImage})`
                            : 'null',
                      }}>
                      <div className="left-container section">
                        {isMobileView && <SectionMenuImageMob section={name} />}
                        {/* {!isMobileView && isCompletedState && (
                          <SectionMenuCompletedImage section={name} />
                        )} */}
                        {!isMobileView && (
                          <SectionMenuImageWeb section={name} />
                        )}
                      </div>

                      <div
                        className="right-container"
                        style={{
                          backgroundColor:
                            isCompletedState && isMobileView
                              ? getSectionDomainCompletedColor(name)
                              : 'null',
                        }}>
                        <div
                          className="domain"
                          style={{ backgroundColor: domainColor(name) }}>
                          {name}
                        </div>
                        <h2 className="parsed-header">
                          {parse(dynamic_header)}
                        </h2>
                        <p>{parse(subheader_description)}</p>
                        <div
                          className="progress"
                          style={{
                            backgroundColor:
                              name === 'Affirmation'
                                ? '#DEEDDC'
                                : domainColor(name) + '40',
                          }}>
                          <div
                            className="progress-bar"
                            style={{
                              width: percentage + '%',
                              backgroundColor: domainColor(name),
                            }}
                          />
                          {percentage === 100 ? (
                            <p
                              style={{
                                color: '#fff',
                                left: 0,
                                right: 0,
                                textAlign: 'center',
                              }}>
                              Completed
                            </p>
                          ) : (
                            <p
                              style={{
                                color:
                                  percentage > 86 ? '#fff' : domainColor(name),
                              }}>
                              {percentage} %
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          })}

        <div
          id="ending-video"
          data-test-id={TESTING_PLAYBOOK_ID.SECTION_ENDING_VIDEO_CARD}
          className={`section-card video ${
            !endingCardState ? 'disabled' : ''
          }`}>
          <BookCard
            type="video"
            onClick={() => onShowEndingVideo()}
            leftContent={
              <div className="img-container">
                <div className="image-wrapper">
                  <img className="play-icon" src={videoPlayIcon} alt="Play" />
                  <SectionMenuImageWeb section="Ending" />
                  <img className="time-icon" src={videoTimeIcon} alt="Time" />
                </div>
              </div>
            }
            rightContent={<h2>One journey ends, another begins</h2>}
          />
        </div>
      </div>

      {noAccessOpen && (
        <NoAccessModal
          history={history}
          isPreviewBtn={true}
          handleClose={() => setNoAccessOpen(false)}
        />
      )}

      {videoObject && (
        <SectionVideoModal
          videoObject={videoObject}
          setVideoObject={setVideoObject}
        />
      )}
    </div>
  );
};

export default withRouter(BookSection);

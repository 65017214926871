import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import _ from 'lodash';
import { UserContext } from 'context/UserContext';
import { url } from 'config/urlConfig';
import { facetColor } from 'shared/const';
import useScrollDetector from 'shared/hooks/useScrollDetector';
import useCloudinaryImageLoader from 'shared/hooks/useCloudinaryImageLoader';
import { getStubsBigIdea, getStubs } from './TopicsUtils';
import { ButtonSolid } from 'shared/components/Button/Button';
import { TopicsWrapper } from './TopicsComponent';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import UserIcon from 'shared/components/UserIcon';
import './TopicsStubBigIdea.scss';

const TopicsStubBigIdea = () => {
  const history = useHistory();

  const { topicId, stubId, stubBigIdeaId } = useParams();

  const { isAtBottom } = useScrollDetector();

  const { user, selectedRelative: contextSelectedRelative } =
    useContext(UserContext);

  const sessionSelectedRelative = JSON.parse(
    sessionStorage.getItem('selectedRelative'),
  );
  const selectedRelative = contextSelectedRelative || sessionSelectedRelative;

  const [stubBigIdeaData, setStubBigIdeaData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [stubsData, setStubsData] = useState(null);
  const [stubs, setStubs] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);

  const bigIdeaId = stubBigIdeaData?.id;
  const bigIdeaTitle = stubBigIdeaData?.title;
  const bigIdeaDescription =
    stubBigIdeaData?.expanded_description &&
    parse(stubBigIdeaData?.expanded_description);

  const handleBack = () => {
    history.push(`${url.TOPICSHUB}/${topicId}/stub/${stubId}`);
  };

  // Modify the getStubBigIdeaData function to return the stubBigIdeaData after all images are loaded
  const getStubBigIdeaData = async () => {
    try {
      const relativeId = selectedRelative?.id;
      const queryParams = {
        relativeId: relativeId,
      };

      const res = await getStubsBigIdea({
        queryParams,
        stubsBigIdeaId: stubBigIdeaId,
      });
      const stubBigIdeaData = res?.data?.data;
      return stubBigIdeaData;
    } catch (err) {
      console.log(
        '🚀 ~ file: JourneyStub.js:84 ~ getStubBigIdeaData ~ err:',
        err?.response,
      );
      throw err; // Re-throw the error to handle it outside the function if needed
    }
  };

  const getStubsData = async () => {
    try {
      const relativeId = selectedRelative?.id;
      const queryParams = {
        relativeId: relativeId,
        topicId: topicId,
        per_page: 10,
        page: nextPage,
        stubsBigIdeaId: bigIdeaId,
      };

      const res = await getStubs({ queryParams });
      const stubsData = res.data?.data;

      if (stubsData) {
        return stubsData;
      }
    } catch (err) {
      if (err)
        console.log(
          '🚀 ~ file: JourneyStubBigIdea.js:112 ~ getStubsData ~ err:',
          err,
        );
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      try {
        // Call all three functions asynchronously and wait for their completion
        const [stubBigIdeaData, stubsData] = await Promise.all([
          getStubBigIdeaData(),
          getStubsData(),
        ]);

        const stubs = stubsData?.data;

        // Now all the API calls are completed
        // Update the state with the fetched data
        setStubBigIdeaData(stubBigIdeaData);
        setStubsData(stubsData);

        if (stubs && stubs?.length > 0) {
          if (nextPage > 1) {
            // Append the new data to the existing stubs
            setStubs(prevStubs =>
              prevStubs ? [...prevStubs, ...stubs] : stubs,
            );
          } else {
            setStubs(stubs);
          }

          setIsLoading(false);
        } else {
          // If there is no new data, just set isLoading to false
          setIsLoading(false);
        }

        if (nextPage > 1) {
          // After data is fetched and rendered, scroll back to the previous position
          window.scrollTo(0, scrollPosition);
        }

        setIsLoading(false);
      } catch (err) {
        // Handle any errors that might occur during data loading
        console.log(
          '🚀 ~ file: JourneyStubBigIdea.js:155 ~ fetchData ~ err:',
          err,
        );
        setIsLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Load more stubs
  useEffect(() => {
    setStubs([]); // Reset the stubs when selectedRelative changes
    setNextPage(1); // Reset the nextPage to 1
  }, [selectedRelative]);

  useEffect(() => {
    if (stubsData && stubsData?.length > 0) {
      const currentPage = stubsData.current_page; // Use stubsData.current_page
      setCurrentPage(currentPage);
    }
  }, [stubsData]);

  useEffect(() => {
    if (isAtBottom) {
      setNextPage(currentPage + 1);
      setScrollPosition(window.pageYOffset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAtBottom]);

  if (isLoading) return <LoadingIndicator />;

  return (
    <TopicsWrapper
      backBtnText="Back"
      backBtnNavigation={() => handleBack()}
      hideMainNavigation={true}>
      <div id="journey-stub-big-idea">
        <h1 className="big-idea-header">{bigIdeaTitle}</h1>

        <div className="big-idea-content">
          <div className="idea-description-wrapper">
            <p className="content-description">{bigIdeaDescription}</p>
          </div>

          {stubs?.length !== 0 && (
            <>
              <h2 className="content-header">Related insights:</h2>
              <div className="idea-insight-card-wrapper">
                {stubs?.map(stub => {
                  return (
                    <StubIdeaInsightCard
                      stub={stub}
                      user={user}
                      selectedRelative={selectedRelative}
                    />
                  );
                })}
              </div>
            </>
          )}

          <ButtonSolid
            className="back-btn"
            onClick={() => handleBack()}
            children="Back"
          />
        </div>
      </div>
    </TopicsWrapper>
  );
};

const StubIdeaInsightCard = ({ stub, user, selectedRelative }) => {
  const stubType = stub?.type;
  const stubImage = stub?.cloudinaryUrl;
  const facetName = stub?.facet_name;

  const isTypeParent = _.isEqual(stubType, 'parent');
  const headerValue = isTypeParent ? user : selectedRelative;
  const headerName = isTypeParent ? 'You' : selectedRelative?.name;
  const isNameLong = headerName?.length + facetName?.length > 18;
  const isNameSuperLong = headerName?.length + facetName?.length > 22;
  const isNameOverLong = headerName?.length + facetName?.length > 30;

  const isImageLoaded = useCloudinaryImageLoader(stubImage);

  return (
    <div id="stub-idea-insight-card">
      <div className="header-container">
        <div
          className={`header-user ${isNameLong ? 'long-name' : ''} ${
            isNameSuperLong ? 'super-long-name' : ''
          } ${isNameOverLong ? 'over-long-name' : ''}`}>
          {headerValue && <UserIcon relative={headerValue} />}
          {headerName}
        </div>

        <span
          className={`${isNameLong ? 'long-name' : ''} ${
            isNameSuperLong ? 'super-long-name' : ''
          } ${isNameOverLong ? 'over-long-name' : ''}`}
          style={{
            borderColor: facetColor(facetName),
            color: facetColor(facetName),
          }}>
          {facetName}
        </span>
      </div>

      {isImageLoaded ? (
        <div className="image-container">
          <img src={stubImage} alt="Stub" />
        </div>
      ) : (
        <div className="image-container loading">
          <LoadingIndicator isLoaderOnly={true} />
        </div>
      )}
    </div>
  );
};

export default TopicsStubBigIdea;

import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { url } from 'config/urlConfig';
import { shortenName } from 'shared/helper/helper';
import { TESTING_CHILD_PICKER_ID } from 'shared/const/TestAutomationIds';
import {
  isStringTakingTooMuchSpace,
  truncateStringWithCondition,
} from 'shared/function/String/truncateString';
import {
  trackingAction,
  TRACKING_PURCHASES,
  GA_EVENT_CATOGARY,
  GA_PURCHASE_JOURNEY_TYPE,
} from 'shared/function/Tracking';
import { MANAGE_PREMIUM_TYPE } from 'routes/ManagePremiumAccess/ManagePremiumUtils';
import useProductList from 'routes/Checkout/useProductList';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import useDisableScreenScrollable from 'shared/hooks/useDisableScreenScrollable';

// components/assets
import LoadingIndicator from '../Loading/LoadingIndicator';
import EmptyChildAlertPopup from 'routes/ManagePremiumAccess/EmptyChildAlertPopup';
import UserIcon from '../UserIcon';
import { ReactComponent as ArrowDownIcon } from 'assets/general/arrow-down-black-icon.svg';
import { ReactComponent as PlusIcon } from 'assets/general/plus-black-icon.svg';
import { ReactComponent as ErrorIcon } from 'assets/general/error-outline-red-icon.svg';
import { ReactComponent as EmptyProfileIcon } from 'assets/books/empty-profile-icon.svg';
import './ChildPicker.scss';

const ChildPicker = ({
  title,
  popupTitle,
  disablePopup,
  childPickerData = {},
}) => {
  const history = useHistory();
  const location = useLocation();

  const path = location?.pathname;
  const currentPath = path?.substring(1);

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });
  const isMobileSize380 = useMediaQuery({ query: '(max-width: 380px)' });

  const {
    user,
    childs,
    selectedChildOption,
    updateSelectedChildOption,
    isAnyProfileIncompleted,
    showPopup,
    setShowPopup,
  } = childPickerData;

  const { isUserPremiumAccess } = useUserPremiumAccess();

  const { selectedProduct } = useProductList({
    type: MANAGE_PREMIUM_TYPE.add_child_profile,
  });

  useDisableScreenScrollable({ isDisable: isMobile && showPopup });

  const [showEmptyChildAlertPopup, setShowEmptyChildAlertPopup] =
    useState(false);

  const isNameTakingTooMuchSpace = isStringTakingTooMuchSpace({
    string: selectedChildOption?.name,
    stringThreshold: 150,
    fontSize: '18px',
    fontFamily: 'Montserrat", sans-serif',
  });

  const selectedChildName = isMobile
    ? truncateStringWithCondition({
        string: shortenName(selectedChildOption?.name),
        isStringTakingTooMuchSpace: isNameTakingTooMuchSpace,
        maxLength: isMobileSize380 ? 9 : 12,
      })
    : truncateStringWithCondition({
        string: shortenName(selectedChildOption?.name),
        isStringTakingTooMuchSpace: isNameTakingTooMuchSpace,
        maxLength: 12,
      });

  const onHoverShowPopup = isShow => {
    if (!isMobile && !disablePopup) setShowPopup(isShow);
  };

  const onClickShowPopup = () => {
    if (isMobile && !disablePopup) setShowPopup(true);
  };

  const onClickClosePopup = () => {
    if (isMobile) {
      setShowPopup(!showPopup);
    } else {
      setShowPopup(false);
    }
  };

  const handleAccessNavigation = e => {
    e.stopPropagation(); // Prevent the click event from bubbling up

    if (isUserPremiumAccess) {
      if (isAnyProfileIncompleted) {
        setShowEmptyChildAlertPopup(true);
      } else {
        trackingAction({
          category: GA_EVENT_CATOGARY.ecommerce,
          action: TRACKING_PURCHASES.view_child_profile,
          ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.view_item,
          ecommerceEventData: { productData: selectedProduct },
          extraData: {
            user_id: user?.id,
          },
        });

        history.push(`${url.ADD_CHILD}?from=${currentPath}`);
      }
    } else {
      trackingAction({
        category: GA_EVENT_CATOGARY.ecommerce,
        action: TRACKING_PURCHASES.view_premium_upgrade,
        ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.view_item,
        ecommerceEventData: { productData: selectedProduct },
        extraData: {
          user_id: user?.id,
        },
      });

      history.push(`${url.UPGRADE_PREMIUM}?from=${currentPath}`);
    }
  };

  const PickerPopup = () => {
    const isOneChildOnly = childs?.length !== 0 && childs?.length < 2;

    return (
      <div
        className="picker-popup-container"
        onMouseEnter={() => onHoverShowPopup(true)}
        onMouseLeave={() => onHoverShowPopup(false)}>
        {isMobile && (
          <div className="close-button-container">
            <div className="close-button" />
          </div>
        )}

        <h2 className="popup-title">{popupTitle || 'Select profile'}</h2>

        <div
          className={`childs-selection-container ${
            childs?.length === 0 ? 'loader' : ''
          } ${isOneChildOnly ? 'single' : ''}`}>
          {childs && childs?.length !== 0 ? (
            <>
              {childs?.map((child, idx) => {
                const childId = child?.id;
                const childName = child?.name || child?.child_name;
                const isEmptyBook = !childName;
                const isChildProfileIncomplete = child?.test_completed === 0;

                const itemName = isEmptyBook
                  ? 'Create child profile'
                  : shortenName(childName);
                const isSelectedChild = child?.id === selectedChildOption?.id;
                const isLastChild = idx === childs?.length - 1;

                const onSelectChild = selectedChild => {
                  const selectedIncompleteChildProfile = {
                    id: selectedChild?.id,
                    name: selectedChild?.name,
                    gender: selectedChild?.gender,
                    date_of_birth: selectedChild?.birthday,
                    colour: selectedChild?.colour,
                    shape: selectedChild?.shape,
                  };

                  if (isEmptyBook) {
                    history.push(`${url.ADD_CHILD_TEST_PROFILE}`);
                    sessionStorage.removeItem('selectedIncompleteChildProfile');
                  } else if (isChildProfileIncomplete) {
                    sessionStorage.setItem(
                      'selectedIncompleteChildProfile',
                      JSON.stringify(selectedIncompleteChildProfile),
                    );
                    history.push(`${url.ADD_CHILD_TEST_PROFILE}`);
                  } else {
                    if (!isSelectedChild) {
                      updateSelectedChildOption(child);
                    } else {
                      setShowPopup(false);
                    }
                  }
                };

                return (
                  <div
                    key={childId}
                    className="child-option-container"
                    onClick={() => onSelectChild(child)}>
                    <div
                      className={`option-content-container ${
                        isLastChild && 'last'
                      }`}>
                      <div className="option-content">
                        {isEmptyBook ? (
                          <EmptyProfileIcon className="option-shape" />
                        ) : (
                          <UserIcon className="option-shape" relative={child} />
                        )}

                        <h4
                          className={`option-title ${
                            isSelectedChild && 'active'
                          } ${isEmptyBook && !isMobile && 'empty-profile'}`}>
                          {itemName}
                        </h4>
                      </div>

                      {!isOneChildOnly && (
                        <>
                          {isEmptyBook || isChildProfileIncomplete ? (
                            <ErrorIcon className="error-icon" />
                          ) : (
                            <div
                              className={`option-radio-button ${
                                isSelectedChild ? 'selected' : ''
                              }`}>
                              {isSelectedChild && (
                                <div className="selected-icon" />
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <LoadingIndicator isLoaderOnly />
          )}
        </div>

        {isOneChildOnly && (
          <p className="one-child-label">You have only 1 child tbc</p>
        )}

        <div
          className="access-navigation-btn"
          onClick={e => handleAccessNavigation(e)}>
          <div className="access-plus-icon-container">
            <PlusIcon className="access-plus-icon" />
          </div>
          {isUserPremiumAccess ? 'Add a child' : 'Upgrade to Premium'}
        </div>
      </div>
    );
  };

  return (
    <div
      id="child-picker"
      data-test-id={TESTING_CHILD_PICKER_ID.CHILD_PICKER}
      className={`${showPopup && 'popup-active'}`}
      onClick={() => onClickClosePopup()}>
      <div className="picker-content">
        {title ? (
          <h1>{title}</h1>
        ) : (
          <h1 className={`picker-title ${disablePopup ? 'disable' : null}`}>
            <UserIcon
              className={`title-shape ${disablePopup ? 'disable' : null}`}
              relative={user}
            />
            You &amp;
            <div>
              <div
                data-test-id={
                  TESTING_CHILD_PICKER_ID.CHILD_PICKER_SELECTED_VALUE
                }
                className={`picker-value-container ${
                  disablePopup ? 'disable' : null
                }`}
                onMouseEnter={() => onHoverShowPopup(true)}
                onMouseLeave={() => onHoverShowPopup(false)}
                onClick={() => onClickShowPopup()}>
                <UserIcon
                  className={`title-shape ${disablePopup ? 'disable' : null}`}
                  relative={selectedChildOption}
                />
                {selectedChildName}{' '}
                {!disablePopup && (
                  <ArrowDownIcon className="value-arrow-icon" />
                )}
              </div>

              {showPopup && !isMobile && <PickerPopup />}
            </div>
          </h1>
        )}
      </div>

      {showPopup && isMobile && <PickerPopup />}
      {showEmptyChildAlertPopup && (
        <EmptyChildAlertPopup
          history={history}
          location={location}
          setIsOpen={setShowEmptyChildAlertPopup}
        />
      )}
    </div>
  );
};

export default ChildPicker;

import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { loginUser, getRelative } from './authUtils';
import { encryptParams } from 'shared/helper/helper';
import { GAUser } from 'shared/function/Tracking/GAUtils';
import { url } from 'config/urlConfig';

// components/assets
import Head from 'shared/components/Head.js';
import { ButtonSolid } from 'shared/components/Button/Button';
import { FormHeader, FormInput, FormFooter } from './AuthComponents';
import { ReactComponent as Checked } from 'assets/general/checked-plain-icon.svg';
import { ReactComponent as Visibility } from 'assets/auth/feather-eye-on-icon.svg';
import { ReactComponent as VisibilityOff } from 'assets/auth/feather-eye-off-icon.svg';

const Login = ({ history, location }) => {
  const { setUser, setSelectedRelative, setUserRelatives, setUserResult } =
    useContext(UserContext);

  const [formValue, setFormValue] = useState({
    email: '',
    password: '',
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState(null);
  const [submitDisable, setSubmitDisable] = useState(true);

  // Remember Check
  const remember = localStorage.getItem('rememberMe');
  const [rememberMe, setRememberMe] = useState(
    remember !== null ? JSON.parse(remember) : true,
  );

  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = width <= 768;

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const gtmEvent = userid => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'login',
      user_id: userid,
      login: {
        method: 'web',
      },
    });
  };

  const submitForm = async e => {
    e.preventDefault();
    const { email, password } = formValue;
    const encryptedPassword = encryptParams(password);
    const bodyFormData = new FormData();
    const device = isMobile ? 'mobile browser' : 'web';
    bodyFormData.append('email', email);
    bodyFormData.append('password', encryptedPassword);
    bodyFormData.append('device_type', device);
    bodyFormData.append('app_version', 'na');
    bodyFormData.append('os_version', 'na');

    setSelectedRelative([]);
    setUserRelatives([]);
    setUserResult([]);

    // show referral notification each time re-login
    localStorage.setItem('hideReferralNotification', false);

    try {
      const response1 = await loginUser(bodyFormData);
      const user = response1.data.data;
      gtmEvent(user.userId);
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
      const response2 = await axios.get('/user');
      const authUser = response2.data.data;
      const response3 = await getRelative();
      const relativeData = response3.data.data;

      rememberMe
        ? localStorage.setItem('token', user?.token)
        : sessionStorage.setItem('token', user?.token);

      setUser({ ...authUser, ...user });
      GAUser({ type: 'login', user: { ...authUser, ...user } });

      const defaultPageCondition = () => {
        if (user.parent_answer_saved === 0) history.push('/profile-test');
        else if (user.child_answer_saved === 0)
          history.push('/profile-test-child');
        else if (location.state) {
          const { from } = location.state;
          const { pathname, search } = from;
          history.push(pathname + search);
        } else if (_.isEmpty(relativeData)) {
          if (
            (user.parent_answer_saved === 1 && user.parent_test_taken === 0) ||
            (user.child_answer_saved === 1 && user.child_test_taken === 0)
          ) {
            history.push('/lobby-area/result-error');
          } else if (user.all_books_generated === 0) {
            history.push('/lobby-area/generate-book-error');
          }
        } else history.push(`${url.HOME}`);
      };

      if (user.is_temp) {
        if (user.is_demo === 0 || user.is_demo === 1) {
          defaultPageCondition();
        } else if (user.parent_answer_saved === 0)
          history.push('/profile-test');
        else if (user.child_answer_saved === 0)
          history.push('/profile-test-child');
        else if (location.state) {
          const { from } = location.state;
          const { pathname, search } = from;
          history.push(pathname + search);
        }
      } else if (user.parent_answer_saved === 0) history.push('/profile-test');
      else if (user.child_answer_saved === 0)
        history.push('/profile-test-child');
      else if (location.state) {
        const { from } = location.state;
        const { pathname, search } = from;
        history.push(pathname + search);
      } else if (_.isEmpty(relativeData)) {
        if (
          (user.parent_answer_saved === 1 && user.parent_test_taken === 0) ||
          (user.child_answer_saved === 1 && user.child_test_taken === 0)
        ) {
          history.push('/lobby-area/result-error');
        } else if (user.all_books_generated === 0) {
          history.push('/lobby-area/generate-book-error');
        }
      } else history.push(`${url.HOME}`);
    } catch (err) {
      err.errors
        ? setError({ message: err?.errors[0], type: 'password' })
        : err.response
        ? setError({ message: err?.response?.data?.message, type: 'password' })
        : setError(null);
    }
  };

  const handleChange = e => {
    const { value, name } = e.target;

    setFormValue({ ...formValue, [name]: value });
    setError(null);
  };

  const handleRememberMe = () => {
    localStorage.setItem('rememberMe', !rememberMe);
    setRememberMe(!rememberMe);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const { email, password } = formValue;
    const emailRE =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (email && !emailRE.test(email)) {
      setError({
        message: 'Please enter a valid email address',
        type: 'email',
      });
    } else {
      if (email && password && !error) {
        setSubmitDisable(false);
      } else setSubmitDisable(true);
    }
  }, [formValue, error]);

  return (
    <div className="right-content-space">
      <Head title="Log In - Connected App" />

      <div className="form-content">
        <FormHeader title="Welcome back!" />

        <form onSubmit={submitForm}>
          <div className="form-group">
            <FormInput
              type="email"
              name="email"
              placeholder="Email"
              handleChange={handleChange}
              isError={error?.type === 'email'}
              error={error?.message}
            />
          </div>

          <div className="form-group">
            <FormInput
              type={passwordShown ? 'text' : 'password'}
              autoComplete="off"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              isPassword
              isError={error?.type === 'password'}
              error={error?.message}
            />

            <button
              type="button"
              className="password-reveal-btn"
              onClick={togglePasswordVisiblity}>
              {passwordShown ? <Visibility /> : <VisibilityOff />}
            </button>
          </div>

          <div className="login-form-feature">
            <div className={`check-container ${rememberMe ? 'checked' : ''}`}>
              <div className="checkbox" onClick={handleRememberMe}>
                <Checked />
              </div>
              <p>Remember me</p>
            </div>

            <button
              className="forgot-password-btn"
              type="button"
              onClick={() => history.push('/forgot-password')}
              data-test-id="forgot-password">
              Forgot password?
            </button>
          </div>

          <ButtonSolid
            id="button-login"
            type="submit"
            className="submit"
            children="Login"
            disabled={submitDisable}
          />

          <FormFooter
            text="Don’t have an account?"
            buttonText="Sign up"
            buttonClick={() => history.push('/signup')}
            testAutoId="sign-up"
          />
        </form>
      </div>
    </div>
  );
};

export default withRouter(Login);

import React, { useContext, useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { url } from 'config/urlConfig';
import { UserContext } from 'context/UserContext';
import { handleSettingBack } from 'routes/SettingsModule/SettingModuleUtils';
import {
  inviteSteps,
  getReferrals,
  getReferralParentingVillage,
} from './ReferralUtils';

// components/assets
import { ButtonSolid } from 'shared/components/Button/Button';
import {
  InputDefault,
  FormMessage,
} from 'shared/components/FormComponents/FormComponents';
import { BackButton } from 'routes/SettingsModule/components/SettingsComponent';
import { ReferralStepper } from './ReferralComponents';
import { ReactComponent as ShareIcon } from 'assets/referral/referral-share-icon.svg';
import { ReactComponent as ShareDisabledIcon } from 'assets/referral/referral-share-disabled-icon.svg';
import referralSettingsWebImg from 'assets/referral/referral-system-settings-web.png';
import referralSettingsMobImg from 'assets/referral/referral-system-settings-mob.png';
import './ReferralSettings.scss';

const ReferralSettings = () => {
  const history = useHistory();
  const location = useLocation();

  const { user } = useContext(UserContext);

  const [copyMessage, setCopyMessage] = useState(null);
  const [isCopyBtnDisabled, setIsCopyBtnDisabled] = useState(false);
  const [isInviteBtnDisabled, setIsInviteBtnDisabled] = useState(false);
  const [referrals, setReferrals] = useState(null);
  const [parentingVillageData, setParentingVillageData] = useState(null);

  const userReferralCode = user?.referral_code;

  const params = new URLSearchParams(location.search);
  const previousPath = params.get('from');

  const currentUrl = window.location.href;
  const currentBaseUrl = currentUrl.split('/').slice(0, 3).join('/');

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const sortedReferrals = referrals
    ?.sort((a, b) => new Date(a.created_date) - new Date(b.created_date))
    .reverse();

  const referralSettingsImg = isMobile
    ? referralSettingsMobImg
    : referralSettingsWebImg;

  const referralsLength = referrals?.length;
  const totalCompletedReferredUsers =
    parentingVillageData?.number_of_completed_referrals;
  const totalAwardedDaysFromReferrals =
    parentingVillageData?.number_of_days_awarded_from_completed_referrals;

  const handleCopyCode = () => {
    navigator.clipboard.writeText(userReferralCode);
    setCopyMessage({
      text: 'Referral code copied',
    });
    setIsCopyBtnDisabled(true);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${currentBaseUrl}/signup-referral?code=${userReferralCode}`,
    );
    setCopyMessage({
      text: 'Link copied',
    });
    setIsInviteBtnDisabled(true);
  };

  const handleBack = () => {
    const isFromReferralBanner = previousPath.includes('referral-banner');

    if (isFromReferralBanner) {
      history.push(`${url.HOME}`);
    } else {
      handleSettingBack({ history, location, type: 'tab', isMobile });
    }
  };

  const getReferralData = async () => {
    const queryParams = { type: 'referrer' };

    try {
      const res = await getReferrals({ queryParams });
      const referralData = res?.data?.data;

      setReferrals(referralData);
    } catch (err) {
      console.log(
        '🚀 ~ file: ReferralSettings.js:82 ~ getReferralData ~ err:',
        err?.response,
      );
    }
  };

  const getReferralParentingVillageData = async () => {
    try {
      const res = await getReferralParentingVillage();
      const parentingVillageData = res?.data?.data;

      setParentingVillageData(parentingVillageData);
    } catch (err) {
      console.log(
        '🚀 ~ file: ReferralSettings.js:103 ~ getReferralParentingVillageData ~ err:',
        err,
      );
    }
  };

  useEffect(() => {
    Promise.all([getReferralData(), getReferralParentingVillageData()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (copyMessage) {
      setTimeout(() => {
        setCopyMessage(null);
        setIsCopyBtnDisabled(false);
        setIsInviteBtnDisabled(false);
      }, 3000);
    }
  }, [copyMessage]);

  return (
    <div id="referral-settings">
      {isMobile && (
        <BackButton history={history} handleClick={() => handleBack()} />
      )}

      <h1 className="referral-settings-header">Refer A Friend</h1>

      <img
        className="referral-settings-image"
        src={referralSettingsImg}
        alt="Referral System"
      />

      <ReferralStepper
        type="vertical"
        header="Get 7 days of complimentary Premium access in 3 steps:"
        steps={inviteSteps}
      />

      <div className="referral-code-link-container">
        <div className="referral-code-wrapper">
          <InputDefault
            className="referral-code-input"
            value={userReferralCode}
            disabled
          />

          <ButtonSolid
            className="referral-code-copy-btn"
            onClick={() => handleCopyCode()}
            children="Copy"
            disabled={isCopyBtnDisabled}
          />
        </div>

        <div className="referral-link-wrapper">
          {copyMessage && (
            <FormMessage
              className="referral-copy-message"
              message={copyMessage}
            />
          )}

          <ButtonSolid
            className="referral-link-invite-btn"
            onClick={() => handleCopyLink()}
            children={
              <>
                {isInviteBtnDisabled ? <ShareDisabledIcon /> : <ShareIcon />}
                Invite a friend
              </>
            }
            disabled={isInviteBtnDisabled}
          />
        </div>
      </div>

      <div className="referral-village-container">
        <h3 className="referral-village-header">
          Your personal parenting village
        </h3>

        {referralsLength !== 0 ? (
          <p className="referral-village-description">
            You've successfully referred{' '}
            <span>{totalCompletedReferredUsers} friend(s)</span> so far! <br />{' '}
            This gave you <span>{totalAwardedDaysFromReferrals} day(s)</span> of
            premium access.
          </p>
        ) : (
          <p className="referral-village-description">
            You haven't referred any friends so far. Share your referral link to
            get started!
          </p>
        )}

        {referralsLength !== 0 && (
          <div className="referral-village-list">
            {sortedReferrals?.map((village, idx) => {
              const referredUserEmail = village?.referred_user_email;
              const isReferredDeleted = _.isEqual(
                village?.is_referred_user_deleted,
                true,
              );
              const referredUserHeader = isReferredDeleted
                ? '[Deleted account]'
                : referredUserEmail;

              const isReferredSignUp = village?.is_referred_user_signed_up;
              const isReferredCreateProfile =
                village?.is_referred_user_created_profile;

              const villageSteps = [
                {
                  label: '1',
                  description: 'Sign up',
                  status: isReferredSignUp ? 'active' : 'inactive',
                },
                {
                  label: '2',
                  description: 'Create account',
                  status: isReferredCreateProfile ? 'active' : 'in-progress',
                },
                {
                  label: '3',
                  description: 'Create profile',
                  status: isReferredCreateProfile ? 'active' : 'inactive',
                },
              ];

              return (
                <ReferralStepper
                  type="horizontal"
                  header={referredUserHeader}
                  listLength={referralsLength}
                  idx={idx}
                  steps={villageSteps}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralSettings;

import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import parse from 'html-react-parser';
import _ from 'lodash';
import { url } from 'config/urlConfig';
import { NoAccessContext } from 'context/NoAccessContext.js';
import { UserContext } from 'context/UserContext';
import { VideoContext } from 'context/VideoContext';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import { trackingAction, TRACKING_PLAYBOOKS } from 'shared/function/Tracking';
import { capitalize, shortenName } from 'shared/helper/helper';
import { domainColor, chapterNumber } from 'shared/const';
import { TESTING_PLAYBOOK_ID } from 'shared/const/TestAutomationIds';
import { navigatePlaybooks } from '../BooksUtils';
import { listChapters, updateVideoStatus } from '../api';

// components/assets
import Head from 'shared/components/Head.js';
import { ButtonSolid } from 'shared/components/Button/Button';
import { ChapterVideoModal } from './VideoModal';
import Tooltip from 'shared/components/Tooltip/Tooltip';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import NoAccessModal from 'routes/ManagePremiumAccess/NoAccessModal';
import {
  BookCard,
  ChapterMenuImage,
  ChapterVideoImage,
} from '../BooksComponent';
import SingleChapterCardRibbon from './SingleChapterCardRibbon';
import BreakImage from 'assets/books/chick_image_break.png';
import videoPlayIcon from 'assets/books/video-play-icon.png';
import videoTimeIcon from 'assets/books/video-time-icon.png';
import { ReactComponent as InfoIcon } from 'assets/info-white-icon.svg';
import { ReactComponent as Checked } from 'assets/general/checked-plain-icon.svg';
import { ReactComponent as ChevronRight } from 'assets/home/chevron-right.svg';
import './BookChapter.scss';
import 'react-spring-bottom-sheet/dist/style.css';

const BookChapter = ({ history }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const readEnd = params.get('read_end') === 'true';

  const { user, selectedRelative } = useContext(UserContext);

  const { noAccessOpen, setNoAccessOpen } = useContext(NoAccessContext);

  const [chapterData, setChapterData] = useState({});
  const [chapterList, setChapterList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isOpen, setIsOpen] = useState(false);
  const [readEndStatus, setReadEndStatus] = useState(readEnd);

  const bookId = JSON.parse(sessionStorage.getItem('bookId'));
  const sectionId = JSON.parse(sessionStorage.getItem('sectionId'));
  const domainId = JSON.parse(sessionStorage.getItem('domainId'));

  const getChapterList = async () => {
    setIsLoading(true);

    const res = await listChapters(bookId, sectionId);
    const { data } = res.data;

    if (data) {
      setChapterData(data);
      setChapterList(data.chapters);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (readEndStatus) {
      setTimeout(() => {
        setIsOpen(true);
      }, 700);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getChapterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId]);

  useEffect(() => {
    setNoAccessOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LoadingIndicator />;

  return (
    <div id="book-chapter-screen">
      <Head title="Connected App - Book Chapter" preventIndex />

      {chapterData && (
        <BookChapterContent
          history={history}
          user={user}
          bookChapterData={{
            bookId,
            sectionId,
            relativeId: selectedRelative?.id,
            domainId,
          }}
          getChapterList={getChapterList}
          chapterData={chapterData}
          setChapterData={setChapterData}
          chapterList={chapterList}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setReadEndStatus={setReadEndStatus}
          selectedRelative={selectedRelative}
          setNoAccessOpen={setNoAccessOpen}
        />
      )}

      {noAccessOpen && (
        <NoAccessModal
          history={history}
          isPreviewBtn={true}
          handleClose={() => setNoAccessOpen(false)}
        />
      )}
    </div>
  );
};

const BookChapterContent = ({
  history,
  user,
  bookChapterData,
  getChapterList,
  chapterData,
  setChapterData,
  chapterList,
  isOpen,
  setIsOpen,
  setReadEndStatus,
  selectedRelative,
  setNoAccessOpen,
}) => {
  const { chapterVideoData, setChapterVideoData } = useContext(VideoContext);

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const { bookId, sectionId, domainId, relativeId } = bookChapterData;

  const domainName = chapterData?.name;

  const handleChapterBack = () => {
    navigatePlaybooks({
      navigate: history.push,
      type: 'section-menu',
      bookId,
    });

    trackingAction({
      action: TRACKING_PLAYBOOKS.chapter_menu_back,
      extraData: {
        book_id: bookId,
        section_id: sectionId,
        user_id: user?.id,
      },
    });
  };

  const handleHoverTooltip = () => {
    trackingAction({
      action: TRACKING_PLAYBOOKS.chapter_menu_tooltip,
      extraData: {
        domain_id: domainId,
        book_id: bookId,
        section_id: sectionId,
        relative_id: relativeId,
        user_id: user?.id,
      },
    });
  };

  return (
    <div className="book-chapter-content">
      <div className="page-header">
        <div className="back-container">
          <div className="back-btn" onClick={() => handleChapterBack()}>
            <ChevronRight />
            <div> Section Menu</div>
          </div>
        </div>

        <section className="chapter-section">
          <div className="chapter-hightlight-container">
            <div
              className="label"
              style={{ backgroundColor: domainColor(domainName) }}>
              {capitalize(domainName)}
            </div>

            <Tooltip
              button={<InfoIcon className="chapter-info-icon" />}
              position="bottom"
              text={
                <p>
                  Content in this section has been curated for you and{' '}
                  {selectedRelative?.name} based on the PEAKS Factor:{' '}
                  {domainName}.
                </p>
              }
              onMouseEnter={() => handleHoverTooltip()}
              onClick={() => handleHoverTooltip()}
            />
          </div>

          <h1>{chapterData?.title}</h1>

          <div
            className="progress"
            style={{
              backgroundColor:
                domainName === 'Affirmation'
                  ? '#DEEDDC'
                  : domainColor(domainName) + '40',
            }}>
            <div
              className="progress-bar"
              style={{
                width: chapterData?.percentage + '%',
                backgroundColor: domainColor(domainName),
              }}
            />

            {chapterData?.percentage === 100 ? (
              <p
                style={{
                  color: '#fff',
                  left: 0,
                  right: 0,
                  textAlign: 'center',
                }}>
                Completed
              </p>
            ) : (
              <p
                style={{
                  color:
                    chapterData?.percentage > 86
                      ? '#fff'
                      : domainColor(domainName),
                }}>
                {chapterData?.percentage} %
              </p>
            )}
          </div>

          <div className="chapter-container">
            <BookVideoCard
              type="intro"
              code={domainName}
              data={chapterData}
              getChapterList={getChapterList}
              chapterList={chapterList}
              setChapterData={setChapterData}
              chapterNumber={chapterNumber(chapterData.name)}
              bookChapterData={bookChapterData}
            />

            {chapterList?.length > 0 &&
              chapterList?.map((chapter, index) => (
                <BookChapterCard
                  key={index}
                  history={history}
                  bookChapterData={bookChapterData}
                  chapter={chapter}
                  chapterList={chapterData}
                  chapterNumber={chapterNumber(chapterData.name)}
                  labelColor={domainColor(chapterData?.name)}
                  index={index}
                  setNoAccessOpen={setNoAccessOpen}
                />
              ))}

            <BookVideoCard
              type="outro"
              code={domainName}
              data={chapterData}
              getChapterList={getChapterList}
              chapterList={chapterList}
              user={user}
              bookChapterData={bookChapterData}
            />

            {isOpen && (
              <div className="take-a-break">
                <div className="content-container">
                  <img src={BreakImage} alt="take a break" />
                  <div className="content">
                    <h1>Take a break for today?</h1>
                    <p>Connected works best when paired with reflection: </p>
                    <br />
                    <p>
                      We recommend taking some time to think about what you've
                      learned about your child and their personality today.
                    </p>
                  </div>
                  <div className="button-container">
                    <button onClick={() => history.push(`${url.HOME}`)}>
                      Back to home
                    </button>
                    <button
                      onClick={() => {
                        setIsOpen(false);
                        setReadEndStatus(false);
                      }}>
                      Later
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {isMobileView && (
            <div className="back-btn-container">
              <ButtonSolid
                className="back-btn"
                onClick={() => handleChapterBack()}
                children="Back to section menu"
              />
            </div>
          )}
        </section>
      </div>

      {chapterVideoData && (
        <ChapterVideoModal
          chapterVideoData={chapterVideoData}
          setChapterVideoData={setChapterVideoData}
          childName={shortenName(selectedRelative?.name)}
        />
      )}
    </div>
  );
};

const BookChapterCard = ({
  history,
  bookChapterData,
  chapter,
  chapterNumber,
  labelColor,
  chapterList,
  index,
  setNoAccessOpen,
}) => {
  const { isUserPremiumAccess } = useUserPremiumAccess();

  const domainSlug = JSON.parse(sessionStorage.getItem('domainSlug'));

  const { user } = useContext(UserContext);

  const {
    id,
    order,
    name,
    title,
    subheader,
    read_status,
    ribbon_text: ribbonText,
  } = chapter;

  const { domainId, bookId, sectionId, relativeId } = bookChapterData;

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const cardState = () => {
    if (read_status) return '';
  };

  const handleSelectChapter = () => {
    if (isUserPremiumAccess || name.toLowerCase() === 'preparedness') {
      navigatePlaybooks({
        navigate: history.push,
        type: 'chapter',
        domainSlug,
        chapterSlug: name,
        state: chapterList,
      });

      trackingAction({
        action: TRACKING_PLAYBOOKS.chapter_menu_select_chapter,
        extraData: {
          domain_id: domainId,
          book_id: bookId,
          section_id: sectionId,
          relative_id: relativeId,
          chapter_id: id,
          user_id: user?.id,
        },
      });
    } else {
      setNoAccessOpen(true);
    }
  };

  return (
    <div
      data-test-id={`${
        TESTING_PLAYBOOK_ID.CHAPTER_FACET_CARD
      }-${name?.toLowerCase()}`}
      className={`single-chapter${index} single-chapter`}
      onClick={() => handleSelectChapter()}>
      <BookCard
        screen="chapter-menu"
        leftContent={<ChapterMenuImage code={chapter?.code} />}
        rightContent={
          <>
            <div className={`single-chapter-content`}>
              <div className="content">
                <div className="single-chapter-head">
                  <span>Chapter {order + chapterNumber}</span>
                  {name !== '' && (
                    <span
                      className="facet"
                      style={{ color: labelColor, borderColor: labelColor }}>
                      {name}
                    </span>
                  )}
                </div>

                <h2>{parse(title)}</h2>
                {subheader && (
                  <div className="subheader">{parse(subheader)}</div>
                )}
              </div>

              {read_status === 1 && (
                <div className="complete-icon-container">
                  <Checked />
                </div>
              )}
              {!cardState() && read_status !== 1 && <div></div>}
            </div>

            {isMobileView && !cardState() && ribbonText.length > 0 && (
              <SingleChapterCardRibbon
                labelColor={labelColor}
                ribbonText={ribbonText}
              />
            )}
          </>
        }
      />

      {!isMobileView && !cardState() && ribbonText.length > 0 && (
        <div>
          <SingleChapterCardRibbon
            labelColor={labelColor}
            ribbonText={chapter?.ribbon_text}
          />
        </div>
      )}
    </div>
  );
};

const BookVideoCard = ({
  location,
  type,
  data,
  code,
  chapterList,
  getChapterList,
  chapterNumber,
  bookChapterData,
}) => {
  const { name } = data;

  const { domainId, bookId, sectionId, relativeId } = bookChapterData;

  const { setChapterVideoData } = useContext(VideoContext);

  const [title, setTitle] = useState('');
  const [readStatus, setReadStatus] = useState();
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');

  const { user } = useContext(UserContext);

  const handlePlayVideo = async ({ bookId, sectionId }) => {
    const isVideoIntro = _.isEqual(type, 'intro');
    const action = isVideoIntro
      ? TRACKING_PLAYBOOKS.chapter_menu_play_intro_video
      : TRACKING_PLAYBOOKS.chapter_menu_play_ending_video;

    await updateVideoStatus(bookId, sectionId, {
      video_type: isVideoIntro ? 'intro' : 'ending',
      video_is_watched: 1,
    }).then(() => {
      trackingAction({
        action: action,
        extraData: {
          domain_id: domainId,
          book_id: bookId,
          section_id: sectionId,
          relative_id: relativeId,
          user_id: user?.id,
        },
      });
    });
    setReadStatus(1);

    setChapterVideoData({
      videoType: type,
      chapterTitle: title,
      videoUrl,
      domainName: name,
      chapterList,
      getChapterList,
      chapterNumber,
    });
  };

  useEffect(() => {
    if (type === 'intro') {
      setTitle(data.intro_video_desc);
      setVideoUrl(data.intro_video);
      setReadStatus(data.intro_vid_watched);
      setVideoThumbnail(<ChapterVideoImage type="intro" code={code} />);
    } else {
      setTitle(data.ending_video_desc);
      setVideoUrl(data.ending_video);
      setReadStatus(data.ending_vid_watched);
      setVideoThumbnail(<ChapterVideoImage type="ending" code={code} />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, location]);

  return (
    <div
      data-test-id={`${
        type === 'intro'
          ? TESTING_PLAYBOOK_ID.CHAPTER_INTRO_VIDEO_CARD
          : TESTING_PLAYBOOK_ID.CHAPTER_ENDING_VIDEO_CARD
      }`}
      className={`single-chapter video`}
      onClick={() => handlePlayVideo({ bookId, sectionId })}>
      <BookCard
        screen="chapter-menu"
        type="video"
        leftContent={
          <div className={`single-chapter-header`}>
            <div className="img-container">
              <div className="image-wrapper">
                {videoThumbnail}
                <img className="play-icon" src={videoPlayIcon} alt="Play" />
                <img className="time-icon" src={videoTimeIcon} alt="Time" />
              </div>
            </div>
          </div>
        }
        rightContent={
          <div className={`single-chapter-content video`}>
            <div className="content">
              <h2>{parse(title)}</h2>
            </div>

            {readStatus === 1 && (
              <div className="complete-icon-container">
                <Checked />
              </div>
            )}
          </div>
        }
      />
    </div>
  );
};

export default BookChapter;

import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { VictoryLine } from 'victory';
import { UserContext } from 'context/UserContext';
import { trackingAction, TRACKING_SNAPSHOT } from 'shared/function/Tracking';
import { getScoreLabel, plotGraphResult, getProfileColour } from 'shared/const';
import UserIcon from 'shared/components/UserIcon';

const PeaksSnapshotGraph = ({
  showAltView,
  selectedTab,
  setSelectedTab,
  setSelectedFacet,
  childId,
  newUserResult,
  selectedRelatives,
}) => {
  const { user, selectedRelative, userRelatives } = useContext(UserContext);

  const [data, setData] = useState([]);
  const [relativeData, setRelativeData] = useState([]);
  const [oldRelativeData, setOldRelativeData] = useState([]);
  const [otherChildData, setOtherChildData] = useState([]);
  const [otherChildIDs, setOtherChildIDs] = useState([]);
  const selectedObject = _.find(userRelatives, { id: childId });

  const handleClickDomainTab = ({ singleData }) => {
    const tabId = singleData?.id;
    const tabName = singleData?.name;
    const tabFacets = singleData?.facets[0];
    const relativeId = selectedRelative?.id;

    const isTabPurpose = _.isEqual(tabName, 'Purpose');
    const isTabEnergy = _.isEqual(tabName, 'Energy');
    const isTabAffirmation = _.isEqual(tabName, 'Affirmation');
    const isTabKnowledge = _.isEqual(tabName, 'Knowledge');
    const isTabSustainability = _.isEqual(tabName, 'Sustainability');
    const action =
      (isTabPurpose && TRACKING_SNAPSHOT.snapshot_graph_purpose) ||
      (isTabEnergy && TRACKING_SNAPSHOT.snapshot_graph_energy) ||
      (isTabAffirmation && TRACKING_SNAPSHOT.snapshot_graph_affirmation) ||
      (isTabKnowledge && TRACKING_SNAPSHOT.snapshot_graph_knowledge) ||
      (isTabSustainability && TRACKING_SNAPSHOT.snapshot_graph_sustainability);

    setSelectedTab(tabName);
    setSelectedFacet(tabFacets);

    trackingAction({
      action,
      extraData: {
        domain_id: tabId,
        relative_id: relativeId,
        user_id: user?.id,
      },
    });
  };

  const getResult = async () => {
    const resultData = [];
    const childResultData = [];
    const oldChildResultData = [];
    const othersData = [];
    const othersDataId = [];
    newUserResult.forEach(singleResult => {
      const {
        id,
        name,
        left_label,
        middle_label,
        right_label,
        parent_result,
        child_result,
        facets,
        other_child,
      } = singleResult;
      resultData.push({
        id,
        name,
        left_label,
        middle_label,
        right_label,
        parent_result: plotGraphResult(_.toInteger(parent_result)),
        facets,
      });

      child_result &&
        child_result.forEach((result, index) => {
          if (!childResultData[index]) childResultData[index] = [];
          if (!oldChildResultData[index]) oldChildResultData[index] = [];
          childResultData[index].push(
            plotGraphResult(_.toInteger(result.score)),
          );
          oldChildResultData[index].push(_.toInteger(result.score));
        });

      other_child &&
        other_child.forEach((data, index) => {
          if (!othersData[index]) othersData[index] = [];
          othersData[index].push(plotGraphResult(_.toInteger(data.score)));
          if (!othersDataId.includes(data.id)) {
            othersDataId.push(data.id);
          }
        });
    });

    setData(resultData);
    setOldRelativeData(oldChildResultData);
    setRelativeData(childResultData);
    setOtherChildData(othersData);
    setOtherChildIDs(othersDataId);
  };

  useEffect(() => {
    getResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserResult]);

  const graphPanel = () => {
    const profileColor = color => getProfileColour(color);

    return (
      <>
        <VictoryLine
          data={data}
          domain={{ y: [0, 100] }}
          domainPadding={0}
          padding={{ top: 0, bottom: 10, left: 5, right: 5 }}
          y="parent_result"
          labels={() => 'square'}
          style={{
            data: {
              stroke: profileColor(user.colour),
              strokeWidth: 2,
              opacity: 0.5,
            },
            labels: { fill: 'transparent' },
          }}
        />

        {relativeData?.length > 0 && relativeData[0] && (
          <VictoryLine
            key={1}
            data={relativeData[0]}
            domain={{ y: [0, 100] }}
            domainPadding={0}
            padding={{ top: 0, bottom: 10, left: 5, right: 5 }}
            y="score"
            labels={() => 'square'}
            style={{
              data: {
                stroke:
                  profileColor(selectedObject.colour) || selectedObject.colour,
                strokeWidth: 2,
                opacity: 0.5,
              },
              labels: { fill: 'transparent' },
            }}
          />
        )}

        {otherChildData?.length > 0 &&
          selectedRelatives?.length > 0 &&
          selectedRelatives?.map((singleRelative, index) => {
            const selectedRelativeIndex = otherChildIDs.findIndex(
              id => id === singleRelative.id,
            );
            if (otherChildData[selectedRelativeIndex]) {
              return (
                <VictoryLine
                  key={index + 1}
                  data={otherChildData[selectedRelativeIndex]}
                  domain={{ y: [0, 100] }}
                  domainPadding={0}
                  padding={{ top: 0, bottom: 10, left: 5, right: 5 }}
                  y="score"
                  labels={() => 'square'}
                  style={{
                    data: {
                      stroke:
                        profileColor(singleRelative.colour) ||
                        singleRelative.colour,
                      strokeWidth: 2,
                      opacity: 0.5,
                    },
                    labels: { fill: 'transparent' },
                  }}
                />
              );
            } else {
              return null;
            }
          })}
      </>
    );
  };

  const pointOverlay = () => {
    if (relativeData.length === 0) {
      return null;
    }

    return (
      <div className="point-overlay">
        {data.map((singleData, index) => {
          return (
            <div
              key={singleData.id}
              style={{ bottom: `${singleData.parent_result}%` }}
              className={`position-${index + 1}`}>
              <UserIcon relative={user} />
            </div>
          );
        })}
        {relativeData?.map((singleArray, index1) => {
          return singleArray.map((data, index) => (
            <div
              key={index}
              style={{ bottom: `${data}%` }}
              className={`position-${index + 1}`}>
              {selectedObject && <UserIcon relative={selectedObject} />}
            </div>
          ));
        })}
        {otherChildData?.length > 0 &&
          selectedRelatives?.length > 0 &&
          selectedRelatives?.map((singleRelative, index) => {
            const selectedRelativeIndex = otherChildIDs.findIndex(
              id => id === singleRelative.id,
            );
            return otherChildData[selectedRelativeIndex]?.map(
              (data, keyIndex) => {
                return (
                  <div
                    key={keyIndex}
                    style={{ bottom: `${data}%` }}
                    className={`position-${keyIndex + 1}`}>
                    {singleRelative && <UserIcon relative={singleRelative} />}
                  </div>
                );
              },
            );
          })}
      </div>
    );
  };

  const graphOverlay = () => {
    const profileColor = color => getProfileColour(color);

    if (!showAltView) {
      return (
        <div className="graph-overlay">
          {relativeData?.length > 0 &&
            data?.map((singleData, index) => {
              if (
                getScoreLabel(newUserResult[index]?.parent_result) ===
                getScoreLabel(oldRelativeData[0][index])
              ) {
                return (
                  <div
                    key={singleData.id}
                    style={{
                      backgroundImage: `linear-gradient(-24deg, ${profileColor(
                        user.colour,
                      )}, ${profileColor(user.colour)} 50%, ${
                        profileColor(selectedObject.colour) ||
                        selectedObject.colour
                      } 50%)`,
                    }}
                    className={
                      getScoreLabel(newUserResult[index]?.parent_result) +
                      ` position-${index + 1} mix`
                    }>
                    {
                      singleData[
                        getScoreLabel(newUserResult[index]?.parent_result)
                      ]
                    }
                  </div>
                );
              } else {
                return (
                  <React.Fragment key={singleData.id}>
                    <div
                      key={'parent-' + singleData.id}
                      style={{ backgroundColor: profileColor(user.colour) }}
                      className={
                        getScoreLabel(newUserResult[index]?.parent_result) +
                        ` position-${index + 1} parent`
                      }>
                      {
                        singleData[
                          getScoreLabel(newUserResult[index]?.parent_result)
                        ]
                      }
                    </div>
                    <div
                      key={'child-' + singleData.id}
                      style={{
                        backgroundColor:
                          profileColor(selectedObject.colour) ||
                          selectedObject.colour,
                      }}
                      className={
                        getScoreLabel(oldRelativeData[0][index]) +
                        ` position-${index + 1}`
                      }>
                      {singleData[getScoreLabel(oldRelativeData[0][index])]}
                    </div>
                  </React.Fragment>
                );
              }
            })}
        </div>
      );
    } else {
      return (
        <div className="graph-overlay reveal">
          {data?.map((singleItem, index) => {
            return (
              <React.Fragment key={singleItem.id}>
                <div
                  key={singleItem.left_label}
                  className={`left-label position-${index + 1}`}>
                  {singleItem.left_label}
                </div>
                <div
                  key={singleItem.middle_label}
                  className={`middle-label position-${index + 1}`}>
                  {singleItem.middle_label}
                </div>
                <div
                  key={singleItem.right_label}
                  className={`right-label position-${index + 1}`}>
                  {singleItem.right_label}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      );
    }
  };

  const getDomainColor = () => {
    switch (selectedTab) {
      case 'Purpose':
        return '#FC9951';
      case 'Energy':
        return '#FCC715';
      case 'Affirmation':
        return '#50C878';
      case 'Knowledge':
        return '#47ABD6';
      case 'Sustainability':
        return '#F45051';
      default:
        return 'transparent';
    }
  };

  if (selectedObject) {
    return (
      <>
        <div className={`graph-container ${showAltView ? '' : 'middle-line'}`}>
          {data?.length === 0 && relativeData?.length === 0 ? (
            <h3 className="loading">Collecting data...</h3>
          ) : (
            <>
              <div className="peaks-button-overlay">
                {data?.map((singleData, index) => (
                  <div
                    key={singleData.id}
                    onClick={() => {
                      setSelectedTab(singleData.name);
                      setSelectedFacet(singleData.facets[0]);
                    }}
                  />
                ))}
              </div>
              {graphOverlay()}
              {pointOverlay()}
              {graphPanel()}
            </>
          )}
        </div>
        <div className="peaks-bar-container">
          {data.map((singleData, index) => {
            return (
              <div
                className={`single-peaks ${
                  selectedTab === singleData.name ? 'active' : ''
                }`}
                style={{ borderBottomColor: getDomainColor() }}
                key={singleData.id}
                onClick={() => handleClickDomainTab({ singleData })}>
                <span className="alphabet">{singleData.name.charAt(0)}</span>
                <p className="facet">{singleData.name}</p>
              </div>
            );
          })}
        </div>
      </>
    );
  }

  return null;
};

export default withRouter(PeaksSnapshotGraph);

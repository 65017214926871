import { url } from 'config/urlConfig';
import purposeWebCompletedBackground from 'assets/books/section-purpose-completed-background.jpg';
import energyWebCompletedBackground from 'assets/books/section-energy-completed-background.jpg';
import affirmationWebCompletedBackground from 'assets/books/section-affirmation-completed-background.jpg';
import knowledgeWebCompletedBackground from 'assets/books/section-knowledge-completed-background.jpg';
import sustainabilityWebCompletedBackground from 'assets/books/section-sustainability-completed-background.jpg';

export const DomainIndex = [
  { code: 'P', index: 0 },
  { code: 'E', index: 1 },
  { code: 'A', index: 2 },
  { code: 'K', index: 3 },
  { code: 'S', index: 4 },
];

export const getSectionMenuCompletedBackground = ({ section }) => {
  switch (section) {
    case 'Purpose':
      return purposeWebCompletedBackground;
    case 'Energy':
      return energyWebCompletedBackground;
    case 'Affirmation':
      return affirmationWebCompletedBackground;
    case 'Knowledge':
      return knowledgeWebCompletedBackground;
    case 'Sustainability':
      return sustainabilityWebCompletedBackground;
    default:
      return null; // Return null for unknown sections
  }
};

export const getSectionDomainCompletedColor = value => {
  switch (value?.toUpperCase()) {
    case 'P':
    case 'PURPOSE':
      return '#FFF9F6';
    case 'E':
    case 'ENERGY':
      return '#FDFCF2';
    case 'A':
    case 'AFFIRMATION':
      return '#F6FDF6';
    case 'K':
    case 'KNOWLEDGE':
      return '#F5F9FD';
    case 'S':
    case 'SUSTAINABILITY':
      return '#FDF6F6';
    default:
      return '#FFF9F6';
  }
};

export const navigatePlaybooks = ({
  navigate,
  type,
  domainSlug,
  chapterSlug,
  bookId,
  sectionId,
  domainId,
  chapterId,
  state,
}) => {
  const navigateUrlByType = type => {
    switch (type) {
      case 'playbooks' || null:
        return `${url.PLAYBOOK}`;
      case 'section-menu':
        return `${url.PLAYBOOKSECTION}`;
      case 'chapter-menu':
        return `${url.PLAYBOOK}/${domainSlug
          ?.toLowerCase()
          .replace(' ', '-')}/chapter`;
      case 'chapter':
        return `${url.PLAYBOOK}/${domainSlug?.toLowerCase()}/${chapterSlug
          ?.toLowerCase()
          .replace(' ', '-')}`;
      case 'snapshot':
        return `${url.PLAYBOOKSNAPSHOT}`;
      default:
        return `${url.PLAYBOOK}`;
    }
  };

  const navigateUrl = navigateUrlByType(type);

  if (domainSlug)
    sessionStorage.setItem('domainSlug', JSON.stringify(domainSlug));

  if (bookId) sessionStorage.setItem('bookId', JSON.stringify(bookId));
  if (sectionId) sessionStorage.setItem('sectionId', JSON.stringify(sectionId));
  if (domainId) sessionStorage.setItem('domainId', JSON.stringify(domainId));
  if (chapterId) sessionStorage.setItem('chapterId', JSON.stringify(chapterId));

  // specific for snapshot
  if (type === 'snapshot') {
    if (!domainSlug) sessionStorage.removeItem('domainSlug');
    if (!chapterId) sessionStorage.removeItem('chapterId');
  }

  if (!type || !navigateUrl) return navigate(navigateUrlByType('playbooks'));

  return navigate({ pathname: navigateUrl, state });
};

import './Tooltip.scss';

const Tooltip = ({ button, position, text, ...otherProps }) => {
  return (
    <div className="hover-button" {...otherProps}>
      {button}

      <div className="tooltip-text" id={position}>
        {text}
      </div>
    </div>
  );
};

export default Tooltip;

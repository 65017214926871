import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from 'context/UserContext';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Lottie from 'react-lottie';
import axios from 'axios';
import _ from 'lodash';
import { encryptParams } from 'shared/helper/helper';
import { loginUser } from 'routes/Auth/authUtils';
import {
  signupUser,
  validateGiftCode,
  updateDemoFlag,
} from 'routes/Auth/authUtils';

// components/assets
import Head from 'shared/components/Head.js';
import { DebounceInput } from 'react-debounce-input';
import PasswordField from 'shared/components/PasswordField';
import BackgroundWeb from 'assets/test-bg-pattern.svg';
import BackgroundMobile from 'assets/test-bg-pattern.png';
import animationData from 'assets/animation/checkout_signup.json';
import { ReactComponent as Checked } from 'assets/general/checked-plain-icon-code.svg';
import './CheckoutLanding.scss';

const lottieOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const CODESTATUS = {
  VALID: 'Valid Gift Code',
  INVALID: 'Invalid Gift Code',
};

const CheckoutAdvertisement = ({ history }) => {
  const { setTempUser, setUser } = useContext(UserContext);
  const [formValue, setFormValue] = useState({
    email: '',
    password: '',
    validatePassword: '',
  });
  const [submitDisable, setSubmitDisable] = useState(true);
  const [error, setError] = useState('');
  const [isValidGiftCode, setIsValidGiftCode] = useState(false);
  const [codeMessage, setCodeMessage] = useState('');

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });
  const customSize = useMediaQuery({
    query: '(min-width: 359px) and (max-width: 376px)',
  });

  useEffect(() => {
    const { email, password, validatePassword, code } = formValue;
    const emailRE =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const passwordRE =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W|_]).{8,}$/;

    async function validateCode() {
      if (code) {
        const codeBody = {
          gift_code: code,
        };

        try {
          const response = await validateGiftCode(codeBody);
          const result = response.data;

          if (!_.isEmpty(result)) {
            setCodeMessage('Valid Gift Code');
            setIsValidGiftCode(true);
          }
        } catch (err) {
          if (err) {
            console.log({ err });

            setCodeMessage('Invalid Gift Code');
            setSubmitDisable(true);
            setIsValidGiftCode(false);
          }
        }
      } else {
        setCodeMessage('');
      }
    }

    validateCode();

    if (email !== '' && password !== '' && validatePassword !== '') {
      setSubmitDisable(true);

      if (!emailRE.test(email)) {
        setError('Please enter a valid email address');
      } else if (!passwordRE.test(password)) {
        setError('The password format is invalid');
      } else if (password !== validatePassword) {
        setError('Both passwords should match');
      } else if (code && !isValidGiftCode) {
        setSubmitDisable(true);
      } else {
        setSubmitDisable(false);
      }
    } else setSubmitDisable(true);
  }, [formValue, codeMessage, isValidGiftCode]);

  const handleChange = e => {
    setError('');
    setCodeMessage('');

    const { value, name } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const loggedInUser = async ({ email, password, code }) => {
    const device = isMobile ? 'mobile browser' : 'web';
    const bodyFormData = new FormData();
    bodyFormData.append('email', email);
    bodyFormData.append('password', password);
    bodyFormData.append('device_type', device);
    bodyFormData.append('app_version', 'na');
    bodyFormData.append('os_version', 'na');

    try {
      const response1 = await loginUser(bodyFormData);
      const user = response1.data.data;
      sessionStorage.setItem('token', user.token);

      axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
      const response2 = await axios.get('/user');
      const authUser = response2.data.data;

      if (code) {
        setUser({ ...authUser, ...user });
      } else {
        await updateDemoFlag({ is_demo: 1 });
        setUser({ ...authUser, ...user, is_demo: 1 });
      }
    } catch (err) {
      console.log({ err });
    }
  };

  const submitForm = async () => {
    const { email, password, code } = formValue;
    const encryptedPassword = encryptParams(password);

    const signupBody = {
      email,
      password: encryptedPassword,
      gift_code: code,
    };

    try {
      setSubmitDisable(true);
      const res = await signupUser(signupBody);
      const { data } = res.data;
      setTempUser({ ...data, password: encryptedPassword });
      loggedInUser({ email, password: encryptedPassword, code });

      history.push('/pretest-onboarding');
    } catch (err) {
      console.log({ err });
      const { data } = err.response;
      setError(data.message !== CODESTATUS.INVALID && data.message);
      setSubmitDisable(false);
    }
  };

  return (
    <div
      id="checkout-advertisement-page"
      style={{
        backgroundImage: `url(${isMobile ? BackgroundMobile : BackgroundWeb})`,
      }}>
      <Head title="Checkout Details - Connected App" />

      <div className="header">
        <h1 className="title">Unlock powerful insights about your child</h1>
        <p className="sub-title">Start your journey to better understanding</p>
      </div>

      <div className="container">
        <div className="left-content">
          <div className="image-container">
            <Lottie
              style={
                isMobile
                  ? {
                      width: '100%',
                      height: '280px',
                      borderRadius: '20px 20px 0px 0px',
                      margin: '0px',
                      boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.29)',
                    }
                  : {
                      width: '100%',
                      height:
                        (error && codeMessage && '750px') ||
                        ((error || codeMessage) && '700px') ||
                        '650px',
                      borderRadius: '20px 0px 0px 20px',
                      boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.29)',
                    }
              }
              options={{ ...lottieOptions, animationData: animationData }}
              height="650px"
            />
          </div>
        </div>

        <div className="right-content">
          <h1>First, let's create your account</h1>
          <p className="sub-title">
            Unlocking insights about your child is just a few steps away
          </p>
          <input
            type="email"
            name="email"
            placeholder="Email"
            onChange={e => handleChange(e)}
            autoComplete="off"
          />

          <PasswordField
            label="Password"
            name="password"
            handleChange={handleChange}
          />

          <PasswordField
            label="Re-enter password"
            name="validatePassword"
            handleChange={handleChange}
            confirmPassword
          />

          {error && (
            <div className="error-container">
              <p>{error}</p>
            </div>
          )}

          <div className="code-container">
            <div className="code-divider"></div>
            <div className="code-text">Have a code?</div>
            <div className="code-divider"></div>
          </div>

          <DebounceInput
            minLength={0}
            debounceTimeout={800}
            type="text"
            name="code"
            placeholder="Enter code"
            onChange={handleChange}
          />

          {codeMessage && (
            <div
              className={`error-container ${isValidGiftCode ? 'valid' : ''}`}>
              {isValidGiftCode && (
                <Checked className="checked-icon" fill="#FFFFFF" />
              )}

              <p>{codeMessage}</p>
            </div>
          )}

          <button
            className="submit"
            onClick={submitForm}
            disabled={submitDisable}>
            Get started
          </button>

          <p className="login-text">
            Already have an account? {customSize && <br />}{' '}
            <a href="/login">Log In</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CheckoutAdvertisement);

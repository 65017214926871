import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { UserContext } from 'context/UserContext';
import { ButtonSolid } from 'shared/components/Button/Button';
import UserIcon from 'shared/components/UserIcon';

const Result = ({ type, next, history }) => {
  const { tempUser, user } = useContext(UserContext);

  const mobileSize2 = useMediaQuery({ query: '(max-width: 500px)' });
  const customSize2 = useMediaQuery({ query: '(min-width: 501px)' });

  useEffect(() => {
    if (type === 'parent-result') {
      if (_.isEmpty(tempUser)) {
        history.push('/profile-test');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, tempUser]);

  useEffect(() => {
    if (type === 'child-result') {
      history.push('/welcome?test-completed=true');
    }
  }, [type, history]);

  return (
    <div
      className={
        type === 'parent-result' ? 'parent-container' : 'child-container'
      }>
      <div className="parent-wrap">
        {type === 'parent-result' && (
          <div className="shape-wrapper">
            <UserIcon
              relative={user}
              style={{ width: '105px', height: '105px' }}
            />
          </div>
        )}

        <div id="result-content">
          <div id="result-header">
            {customSize2 && (
              <>
                <div>{type === 'parent-result' && `You're halfway there!`}</div>
                <h1>{type === 'parent-result' && `Complete your`}</h1>
                <h1>{type === 'parent-result' && `child's profile to`}</h1>
                <h1>{type === 'parent-result' && `generate your`}</h1>
                <h1 style={{ paddingBottom: '10px' }}>
                  {type === 'parent-result' && `Playbook`}
                </h1>
              </>
            )}

            {mobileSize2 && (
              <div style={{ padding: '0px 40px' }}>
                <div>{type === 'parent-result' && `You're halfway there!`}</div>
                <h1>
                  {type === 'parent-result' &&
                    `Complete your child’s profile to generate your Playbook`}
                </h1>
              </div>
            )}
          </div>

          <div id="result-footer">
            {type === 'parent-result' && (
              <ButtonSolid
                className="parent-button"
                type="button"
                onClick={next}
                children="Next"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Result);

import React, { useState, useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { url } from 'config/urlConfig';
import { UserContext } from 'context/UserContext';
import { colorObjectV2 } from 'shared/const';

// components/assets
import { ButtonSolid, ButtonText } from 'shared/components/Button/Button';
import ParentProfileScreen from './components/ParentProfileScreen';
import ChildProfileScreen from './components/ChildProfileScreen';
import ProfileHeader from 'assets/profile-setup-header.png';
import loginImage from 'assets/login-img.svg';
import { ReactComponent as IconCircle } from 'assets/icons/icon_circle.svg';
import { ReactComponent as IconTriangle } from 'assets/icons/icon_triangle.svg';
import { ReactComponent as IconSquare } from 'assets/icons/icon_square.svg';
import { ReactComponent as IconDiamond } from 'assets/icons/icon_diamond.svg';
import { ReactComponent as ChevronRight } from 'assets/home/chevron-right.svg';
import './ProfileTest.scss';

const ProfileTest = ({ history, match, location }) => {
  const { user } = useContext(UserContext);

  const selectedIncompleteChildProfile = JSON.parse(
    sessionStorage.getItem('selectedIncompleteChildProfile'),
  );

  const [formValueParent, setFormValueParent] = useState({
    name: user?.name,
    parent: user?.parent && user?.name ? user?.parent : null,
    birthday: user?.birthday,
    colour: user?.colour === null ? 'blue' : user?.colour,
    shape: user?.shape === null ? 'circle' : user?.shape,
  });

  const [formValueChild, setFormValueChild] = useState(
    selectedIncompleteChildProfile || {
      name: '',
      gender: '',
      date_of_birth: '',
      colour: '',
      shape: '',
    },
  );
  const [showEndTestPopup, setShowEndTestPopup] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const params = new URLSearchParams(location.search);
  const addChild = params.get('addchild');

  useEffect(() => {
    if (match.url === `/profile-test-child?addchild=true`) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }

    if (match.url === `/profile-test-child`) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }

    if (match.path === '/profile-test') {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shapeFormParent = value => {
    switch (value) {
      case 'square':
        return (
          <IconSquare
            style={{
              fill: colorObjectV2[formValueParent?.colour],
              height: '100px',
              width: '100px',
            }}
          />
        );
      case 'circle':
        return (
          <IconCircle
            style={{
              fill: colorObjectV2[formValueParent?.colour],
              height: '100px',
              width: '100px',
            }}
          />
        );
      case 'triangle':
        return (
          <IconTriangle
            style={{
              fill: colorObjectV2[formValueParent?.colour],
              height: '100px',
              width: '100px',
            }}
          />
        );
      case 'diamond':
        return (
          <IconDiamond
            style={{
              fill: colorObjectV2[formValueParent?.colour],
              height: '100px',
              width: '100px',
            }}
          />
        );
      default:
        return <div></div>;
    }
  };

  const shapeFormChild = value => {
    switch (value) {
      case 'square':
        return (
          <IconSquare
            style={{
              fill: colorObjectV2[formValueChild?.colour],
              height: '100px',
              width: '100px',
            }}
          />
        );
      case 'circle':
        return (
          <IconCircle
            style={{
              fill: colorObjectV2[formValueChild?.colour],
              height: '100px',
              width: '100px',
            }}
          />
        );
      case 'triangle':
        return (
          <IconTriangle
            style={{
              fill: colorObjectV2[formValueChild?.colour],
              height: '100px',
              width: '100px',
            }}
          />
        );
      case 'diamond':
        return (
          <IconDiamond
            style={{
              fill: colorObjectV2[formValueChild?.colour],
              height: '100px',
              width: '100px',
            }}
          />
        );
      default:
        return <div></div>;
    }
  };

  const renderEndTestPopup = () => {
    const handleBack = () => {
      history.push(`${url.PLAYBOOKSECTION}`);
    };

    return (
      <div className="end-popup-overlay">
        <div className="end-popup-container">
          <div className="content">
            <h1>Your profile is not complete:</h1>
            <br />
            <div>Test that have not been completed will not be saved.</div>
            <br />
            <br />
          </div>
          <div className="buttons">
            <ButtonSolid
              style={{
                padding: '20px 45px',
                boxShadow: '0px 2px 15px #1968654C',
              }}
              children="Continue"
              onClick={() => setShowEndTestPopup(false)}
            />

            <ButtonText
              style={{ padding: '6px 45px', backgroundColor: 'transparent' }}
              children="Exit profile creation"
              onClick={() => handleBack()}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="profile-page">
      {isMobile &&
        (match.path === '/profile-test' ||
          match.path === '/profile-test-child') && (
          <div className="header">
            <div>
              <img
                style={{ width: '100%', height: '100%' }}
                src={ProfileHeader}
                alt="profile-header"
              />
            </div>
            <div>
              <div
                className={
                  match.path === '/profile-test'
                    ? 'parent-profile'
                    : 'child-profile'
                }>
                {addChild && isMobile && (
                  <div
                    className="back"
                    onClick={() => setShowEndTestPopup(true)}>
                    <ChevronRight /> Back
                  </div>
                )}

                <h1
                  className={
                    match.path === '/profile-test'
                      ? 'header-text'
                      : 'header-text-child'
                  }>
                  {match.path === '/profile-test'
                    ? 'Tell us about yourself'
                    : 'Tell us about your child'}
                </h1>
              </div>
            </div>
          </div>
        )}
      <div className="container">
        <div className="left-content">
          {match.path === '/profile-test' ? (
            <>
              {!isMobile && <h1>Tell us about yourself</h1>}
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#5D5D5D',
                }}>
                These symbols will be used for your profiles
              </div>
              <br />
              {formValueParent?.colour !== '' &&
                shapeFormParent(formValueParent?.shape)}
            </>
          ) : match.path === '/profile-test-child' ? (
            <>
              {!isMobile && <h1>About your child…</h1>}
              <div
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  color: '#5D5D5D',
                }}>
                This symbol will be used for their profile
              </div>
              <br />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                {formValueChild?.colour &&
                  shapeFormChild(formValueChild?.shape)}
              </div>
            </>
          ) : (
            <>
              <h1>
                <span>New</span> understanding.
                <br />
                <span>New</span> connections.
              </h1>
              <img src={loginImage} alt="" />
            </>
          )}
        </div>

        {match.path === '/profile-test' && (
          <ParentProfileScreen
            history={history}
            formValueParent={formValueParent}
            setFormValueParent={setFormValueParent}
          />
        )}
        {match.path === '/profile-test-child' && (
          <ChildProfileScreen
            history={history}
            isAddChild={addChild}
            formValueChild={formValueChild}
            setFormValueChild={setFormValueChild}
          />
        )}
      </div>

      {showEndTestPopup && renderEndTestPopup()}
    </div>
  );
};

export default ProfileTest;

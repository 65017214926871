import React, { useState, useEffect, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import { encryptParams } from 'shared/helper/helper';
import _ from 'lodash';
import { UserContext } from 'context/UserContext';
import { deleteAccount } from '../SettingModuleUtils';
import { ButtonSolid } from 'shared/components/Button/Button';
import { FormInput, FormError } from 'routes/Auth/AuthComponents';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete-dustbin-icon.svg';
import { ReactComponent as CloseNormal } from 'assets/general/close-normal-icon.svg';
import { ReactComponent as CloseStrong } from 'assets/general/close-strong-icon.svg';
import './DeleteAccountModal.scss';

const DeleteAccountModal = ({ history, setOpenModal, ...otherProps }) => {
  const { user, setUser } = useContext(UserContext);

  const [currentStep, seCurrentStep] = useState(1);

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const handleProceed = () => {
    seCurrentStep(2);
  };

  const handleClose = () => {
    const isDeleteSuccess = _.isEqual(currentStep, 3);

    if (isDeleteSuccess) {
      setUser({ ...user, token: null });
      history.push('/login');
    } else {
      setOpenModal(false);
    }
  };

  const WizardStep = ({ step }) => {
    switch (step) {
      case 1:
        return (
          <DeleteAccountMessage
            handleClose={handleClose}
            handleProceed={handleProceed}
          />
        );
      case 2:
        return <DeleteAccountVerification seCurrentStep={seCurrentStep} />;
      case 3:
        return <DeleteAccountSuccess history={history} />;
      default:
        return (
          <DeleteAccountMessage
            handleClose={handleClose}
            handleProceed={handleProceed}
          />
        );
    }
  };

  return (
    <div id="delete-account">
      <div
        className={`delete-account-modal`}
        onClick={() => handleClose()}
        {...otherProps}>
        <div className="delete-account-close-container">
          {isMobileView && (
            <CloseStrong
              className="delete-account-close-btn"
              onClick={() => handleClose(false)}
            />
          )}

          {!isMobileView && (
            <CloseNormal
              className="delete-account-close-btn"
              onClick={() => handleClose(false)}
            />
          )}

          <div
            className="delete-account-modal-container"
            onClick={e => {
              e.stopPropagation();
            }}>
            {currentStep && <WizardStep step={currentStep} />}
          </div>
        </div>
      </div>
    </div>
  );
};

const DeleteAccountMessage = ({ handleClose, handleProceed }) => {
  return (
    <div className="delete-account-sub-content-container">
      <h2>Permanently delete your account?</h2>

      <p className="subtitle-top">
        By deleting your account, all of your information and progress will be
        removed from our system and cannot be restored.
      </p>
      <p className="subtitle-bottom">
        This action cannot be undone and no refunds will be issued for deleted
        accounts.
      </p>

      <div className="delete-account-modal-button-container">
        <ButtonSolid
          className="solid-button"
          onClick={handleClose}
          children="Back"
        />

        <ButtonSolid
          className="outline-button alert"
          onClick={handleProceed}
          children="Delete"
        />
      </div>
    </div>
  );
};

const DeleteAccountVerification = ({ seCurrentStep }) => {
  const [formValue, setFormValue] = useState({
    password: null,
    confirm: null,
  });
  const [formError, setFormError] = useState(null);
  const [submitDisable, setSubmitDisable] = useState(true);

  const handleSubmit = async () => {
    const password = formValue?.password;
    const encryptedPassword = encryptParams(password);
    const confirm = formValue?.confirm;

    const bodyParams = { confirm_delete: confirm, password: encryptedPassword };

    try {
      await deleteAccount(bodyParams);

      seCurrentStep(3);
    } catch (err) {
      const { message } = err?.response?.data;

      if (message) setFormError(message);
    }
  };

  const handleChange = e => {
    setFormError(null);

    const { value, name } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  useEffect(() => {
    const password = formValue?.password;
    const confirm = formValue?.confirm;

    if (password && confirm) {
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [formValue]);

  return (
    <div className="delete-account-sub-content-container">
      <h3 className="form-input-title">Please enter your password</h3>
      <FormInput
        name="password"
        placeholder="Password"
        type="password"
        onChange={handleChange}
        autoComplete="off"
      />

      <h3 className="form-input-title">Please enter DELETE to proceed</h3>
      <FormInput
        name="confirm"
        placeholder="DELETE"
        type="text"
        onChange={handleChange}
      />

      <FormError message={formError} />

      <div className="delete-account-modal-button-container">
        <ButtonSolid
          className="solid-button"
          onClick={handleSubmit}
          children="Enter"
          disabled={submitDisable}
        />
      </div>
    </div>
  );
};

const DeleteAccountSuccess = ({ history }) => {
  const { user, setUser } = useContext(UserContext);

  const handleLogout = () => {
    setUser({ ...user, token: null });
    history.push('/login');
  };

  return (
    <div className="delete-account-sub-content-container success">
      <DeleteIcon className="delete-account-icon" />
      <h3 className="delete-success-title">Account successfully deleted</h3>

      <div className="delete-account-modal-button-container">
        <ButtonSolid
          className="solid-button delete-success"
          onClick={() => handleLogout()}
          children="Close"
        />
      </div>
    </div>
  );
};

DeleteAccountModal.defaultProps = {
  handleProceed: () => {},
  handleSubmit: () => {},
  handleClose: () => {},
};
export default DeleteAccountModal;

export default function getChapterImages(imageDir) {
  const alphaArray = ['P', 'E', 'A', 'K', 'S'];
  const chapterImages = [];
  const videoImages = [];

  // In Node.js version 16 and later, as ES module support improved, the requirement to use
  // `.default` to access the default export of a CommonJS module was removed
  alphaArray.forEach(alpha => {
    // Handle chapter images (1 to 5)
    Array.from({ length: 5 }, (_, i) => i + 1).forEach(j => {
      chapterImages.push({
        label: `${alpha + j}`,
        jpg: require(`./${imageDir}/${alpha + j}.jpg`),
      });
    });

    // Handle video images (1 and 6)
    videoImages.push({
      label: `${alpha}0IntroVideo`,
      jpg: require(`./${imageDir}/${alpha}0IntroVideo.jpg`),
    });
    videoImages.push({
      label: `${alpha}6EndingVideo`,
      jpg: require(`./${imageDir}/${alpha}6EndingVideo.jpg`),
    });
  });

  return {
    chapterImages,
    videoImages,
  };
}

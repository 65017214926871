import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { url } from 'config/urlConfig';
import { UserContext } from 'context/UserContext';
import {
  getUserData,
  reAssignChapters,
  getRelative,
} from './WaitingScreenUtils';
import SuccessImage from 'assets/lobbyarea/success-image.png';
import ErrorImage from 'assets/lobbyarea/error-image.png';
import './WaitingScreen.scss';

const WaitingScreen = ({ history, match }) => {
  const { user, setSelectedRelative, setUserRelatives, setCartItem, setUser } =
    useContext(UserContext);
  const [dataResult, setDataResult] = useState('');
  const [colorChange, setColorChange] = useState('');
  const [generateResult, setGenerateResult] = useState('');

  const switchColorTest = color => {
    switch (color) {
      case 'GREEN':
        return '#50C1BC';
      case 'RED':
        return '#F45051';
      default:
        return '#5D5D5D';
    }
  };

  const getRelativeData = async () => {
    try {
      const res = await getRelative();
      const relativeData = res.data.data;
      console.log('relativeData ===>', relativeData);
      setUserRelatives(relativeData);
    } catch (err) {
      const { message } = err;
      console.log('getRelativeData  ===> error', message);
    }
  };

  const handleReAssignChapter = async () => {
    try {
      const res = await reAssignChapters();
      const { message } = res.data;
      setDataResult(
        'Generate playbook success. You will now redirect to Home screen',
      );
      setColorChange('GREEN');
      if (message) {
        getRelativeData();
        getUserInfo();
      }
    } catch (err) {
      console.log('error', err.message);
      setDataResult(
        'Fail to generate playbook! The technical team will contact you shortly once the issue is solve',
      );
      setColorChange('RED');
      setGenerateResult('FAILED');
    }
  };

  const getUserInfo = async () => {
    const res = await getUserData();
    const { data } = res.data;
    setUser({ ...user, all_books_generated: data.all_books_generated });
    setGenerateResult('SUCCESS');
  };

  const handleParentChildResult = async () => {
    const res = await getUserData();
    const { data } = res.data;
    const childTestTaken = data.child_test_taken;
    const parentTestTaken = data.parent_test_taken;
    if (childTestTaken === 1 && parentTestTaken === 1) {
      history.push(`${url.HOME}`);
    }
  };

  const handleGenerateBook = async () => {
    const res = await getUserData();
    const { data } = res.data;
    const allBooksGenrated = data.all_books_generated;
    if (allBooksGenrated === 1) {
      setDataResult(
        'Generate playbook success. You will now redirect to Home screen',
      );
      setColorChange('GREEN');
      setGenerateResult('SUCCESS');
    } else {
      handleReAssignChapter();
    }
  };

  useEffect(() => {
    if (match.url === '/lobby-area/result-error') {
      handleParentChildResult();
    }
    if (match.url === '/lobby-area/generate-book-error') {
      setTimeout(() => {
        handleGenerateBook();
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.url]);

  const clearContext = async () => {
    setCartItem([]);
    setUser({});
    setSelectedRelative({});
    setUserRelatives([]);
  };

  const logout = async () => {
    clearContext();
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    history.push('/login');
  };

  return (
    <div className="waiting-screen">
      <div className="container">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: '20px',
          }}>
          <img
            className="img-size"
            alt="error"
            src={generateResult === 'SUCCESS' ? SuccessImage : ErrorImage}
          />
        </div>
        <h1>
          {generateResult === 'SUCCESS'
            ? 'All set! We’ve resolved the issue.'
            : 'Oops! It seems that we’ve hit a technical glitch.'}
        </h1>

        <p>
          {generateResult === 'SUCCESS'
            ? 'Your Playbook is ready. Now it’s time to continue your journey to understanding!'
            : 'Hang in there. We’ll update you as soon as we’ve resolved the issue.'}
        </p>

        {match.url === '/lobby-area/generate-book-error' &&
          _.isEmpty(generateResult) && (
            <p
              style={{
                color: switchColorTest(colorChange),
                fontWeight: 'bold',
              }}>
              {_.isEmpty(dataResult) ? 'Generating...' : dataResult}
            </p>
          )}
        <br />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {generateResult === 'SUCCESS' ? (
            <button
              className="success-button"
              onClick={() => history.push(`${url.HOME}`)}>
              Dive in
            </button>
          ) : (
            <button onClick={logout}>Log Out</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(WaitingScreen);

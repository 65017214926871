import React from 'react';
import Lottie from 'react-lottie';
import loadingSpinner from 'assets/animation/general/loading-spinner.json';
import './LoadingSpinner.scss';

const LoadingSpinner = ({ position, isAnimation }) => {
  const lottieOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className={`spinner-container ${position}`}>
      {isAnimation ? (
        <Lottie
          options={{ ...lottieOptions, animationData: loadingSpinner }}
          width="30px"
          height="30px"
        />
      ) : (
        <div className="loading-spinner" />
      )}
    </div>
  );
};

export default LoadingSpinner;

import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { navigatePlaybooks } from '../BooksUtils';
import { UserContext } from 'context/UserContext';
import { trackingAction, TRACKING_SNAPSHOT } from 'shared/function/Tracking';
import { plotGraphResult, chapterNumber, domainColor } from 'shared/const';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import {
  getBorderColorSource,
  getPeaksDomainDescription,
  getGraphTableColour,
  goAnchorFacet,
} from './peaksSnapshotUtils';

// components/assets
import { ButtonOutline } from 'shared/components/Button/Button';
import NoAccessModal from 'routes/ManagePremiumAccess/NoAccessModal';
import { DomainIntoVideoModal } from 'shared/components/VideoModal/VideoModal';
import { SnapshotCardImage } from '../BooksComponent';
import UserIcon from 'shared/components/UserIcon';
import { ReactComponent as ArrowDownIcon } from 'assets/general/arrow-down-black-icon.svg';
import { ReactComponent as Checked } from 'assets/general/checked-plain-icon.svg';
import './PeaksSnapshotDetails.scss';

const HtmlToReactParser = require('html-to-react').Parser;

const PeaksSnapshotDetails = ({
  history,
  selectedTab,
  newUserResult,
  selectedRelatives,
  childName,
  paramChapterId,
}) => {
  const { user, selectedRelative } = useContext(UserContext);

  const { isUserPremiumAccess } = useUserPremiumAccess();

  const htmlToReactParser = new HtmlToReactParser();

  const [paramChapId, setParamChapId] = useState(null);
  const [showDescription, setShowDescription] = useState(false);
  const [selectedDescriptionIndex, setSelectedDescriptionIndex] =
    useState(null);
  const [noAccessOpen, setNoAccessOpen] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);

  const borderColorSource = getBorderColorSource({ tab: selectedTab });

  const domainDescription = getPeaksDomainDescription({
    domain: selectedTab,
    childName: childName,
  });
  const selectedObject = _.find(newUserResult, { name: selectedTab });

  const {
    name: domainName,
    code: domainCode,
    facets,
    child_result,
    left_label,
    middle_label,
    right_label,
    parent_result,
  } = selectedObject;

  const bookId = facets[0]?.chapter?.book_id;
  const sectionId = facets[0]?.chapter?.section_id;
  const relativeId = selectedRelative?.id;

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });
  const isDesktopView = useMediaQuery({ query: '(min-width: 992px)' });

  const navigateSelectedFacet =
    paramChapterId &&
    facets?.find(item => {
      if (item?.chapter?.id === paramChapterId) return true;
      return false;
    });
  const navigateSelectedIndex = navigateSelectedFacet?.chapter?.order - 1;

  const handleExplorePremiumContent = ({
    bookId,
    sectionId,
    chapterId,
    domainName,
    facetName,
  }) => {
    if (isUserPremiumAccess || _.isEqual(facetName, 'Preparedness')) {
      navigatePlaybooks({
        navigate: history.push,
        type: 'chapter',
        domainSlug: domainName,
        chapterSlug: facetName,
        bookId,
        sectionId,
        chapterId,
      });

      trackingAction({
        action: TRACKING_SNAPSHOT.snapshot_explore,
        extraData: {
          book_id: bookId,
          section_id: sectionId,
          chapter_id: chapterId,
          relative_id: relativeId,
          user_id: user?.id,
        },
      });
    } else {
      setNoAccessOpen(true);
    }
  };

  const handleOpenVideoModal = () => {
    setOpenVideoModal(true);

    trackingAction({
      action: TRACKING_SNAPSHOT.snapshot_video_preview,
      extraData: {
        book_id: bookId,
        section_id: sectionId,
        relative_id: relativeId,
        user_id: user?.id,
      },
    });
  };

  useEffect(() => {
    if (paramChapterId) {
      setParamChapId(paramChapterId);
    }
  }, [paramChapterId]);

  useEffect(() => {
    if (paramChapId) {
      setShowDescription(true);
      setSelectedDescriptionIndex(navigateSelectedIndex);
    }
  }, [paramChapId, navigateSelectedIndex]);

  const domainData = () => {
    const { leftLabel, middleLabel, rightLabel } = getGraphTableColour({
      parentScore: parent_result,
      childScore: child_result[0]?.score,
      userColour: user?.colour,
      childColour: child_result[0]?.colour,
    });

    return (
      <div className="domain-bar-graph-wrapper">
        <div className="bar-graph-container">
          <div className="bar-graph">
            <UserIcon
              relative={user}
              style={{ left: plotGraphResult(parent_result) + '%' }}
            />
            <UserIcon
              key={child_result[0].id}
              relative={child_result[0]}
              style={{ left: plotGraphResult(child_result[0].score) + '%' }}
            />
            <div className="graph-label">
              <table>
                <tr>
                  <td
                    style={{
                      background: leftLabel ? leftLabel[0] : '',
                      color: leftLabel ? leftLabel[1] : '',
                    }}>
                    <p>{left_label}</p>
                  </td>
                  <td
                    style={{
                      background: middleLabel ? middleLabel[0] : '',
                      color: middleLabel ? middleLabel[1] : '',
                    }}>
                    <p>{middle_label}</p>
                  </td>
                  <td
                    style={{
                      background: rightLabel ? rightLabel[0] : '',
                      color: rightLabel ? rightLabel[1] : '',
                    }}>
                    <p>{right_label}</p>
                  </td>
                </tr>
              </table>
            </div>

            <span className="bar"></span>
          </div>
        </div>
      </div>
    );
  };

  const facetData = () => {
    const handleExpandChapter = ({ isSelectedIndex, index, facet }) => {
      const isShowDescription =
        showDescription && selectedDescriptionIndex === index;
      const currentScrollPosition = window.scrollY;

      const bookId = facet?.chapter?.book_id;
      const sectionId = facet?.chapter?.section_id;
      const chapterId = facet?.chapter?.id;
      const facetId = facet?.id;
      const relativeId = selectedRelative?.id;

      setParamChapId(null);
      setSelectedDescriptionIndex(index);

      if (isSelectedIndex) {
        setShowDescription(!showDescription);

        if (!isShowDescription) {
          setTimeout(() => {
            goAnchorFacet(chapterId, currentScrollPosition);
          }, 100);
        }
      } else {
        setShowDescription(true);

        setTimeout(() => {
          goAnchorFacet(chapterId, currentScrollPosition);
        }, 100);
      }

      trackingAction({
        action: TRACKING_SNAPSHOT.snapshot_expand_chapter,
        extraData: {
          book_id: bookId,
          section_id: sectionId,
          chapter_id: chapterId,
          facet_id: facetId,
          relative_id: relativeId,
          user_id: user?.id,
        },
      });
    };

    const data = facets?.map((result, index) => {
      const bookId = result?.chapter?.book_id;
      const sectionId = result?.chapter?.section_id;
      const labelColor = domainColor(domainCode);

      const facetCode = result?.code;
      const facetName = result?.name;
      const facetDescription = htmlToReactParser.parse(result?.description);

      const chapterId = result?.chapter?.id;
      const chapterNo = chapterNumber(domainName);
      const chapterOrder = chapterNo + index + 1;
      const chapterTitle = result?.chapter?.title;
      const chapterSubHeader = htmlToReactParser.parse(
        result?.chapter?.subheader,
      );

      const isChapterRead = _.isEqual(result?.chapter?.read_status, 1);

      const { leftLabel, middleLabel, rightLabel } = getGraphTableColour({
        parentScore: result?.parent_result,
        childScore: result.child_result[0]?.score,
        userColour: user?.colour,
        childColour: child_result[0]?.colour,
      });

      const isShowDescription =
        showDescription && selectedDescriptionIndex === index;

      return (
        <div id={chapterId} key={index} className="bar-outer-container">
          <div
            className={`bar-inner-container ${
              isShowDescription ? 'expand' : null
            }`}
            onClick={() =>
              handleExpandChapter({
                isSelectedIndex: selectedDescriptionIndex === index,
                index,
                facet: result,
              })
            }>
            <div className="main-facet-header">
              {<h3 className="main-facet-title">{facetName}</h3>}

              <ArrowDownIcon
                className={`main-facet-icon ${isShowDescription && 'active'}`}
                data-test-id="drop-down-p1"
              />
            </div>

            <div className="bar-graph">
              <UserIcon
                relative={user}
                style={{ left: plotGraphResult(result.parent_result) + '%' }}
              />
              <UserIcon
                key={result?.child_result[0]?.id}
                relative={result?.child_result[0]}
                style={{
                  left: plotGraphResult(result?.child_result[0]?.score) + '%',
                }}
              />

              {selectedRelatives?.length > 0 &&
                selectedRelatives.map(relative => {
                  const selectedIndex = _.findIndex(result?.other_child, {
                    id: relative.id,
                  });

                  if (selectedIndex !== -1) {
                    const selectedChild = result?.other_child[selectedIndex];
                    const leftPosition =
                      plotGraphResult(selectedChild?.score) + '%';

                    const iconStyle = {
                      left: leftPosition,
                    };

                    return (
                      <UserIcon
                        key={selectedChild?.id}
                        relative={selectedChild}
                        style={iconStyle}
                      />
                    );
                  }

                  // If the relative is not found, return null
                  return null;
                })}

              <div className="graph-label">
                <table>
                  <tr>
                    <td
                      style={{
                        background: leftLabel ? leftLabel[0] : '',
                        color: leftLabel ? leftLabel[1] : '',
                      }}>
                      <p>{result.left_label}</p>
                    </td>
                    <td
                      style={{
                        background: middleLabel ? middleLabel[0] : '',
                        color: middleLabel ? middleLabel[1] : '',
                      }}>
                      <p>{result.middle_label}</p>
                    </td>
                    <td
                      style={{
                        background: rightLabel ? rightLabel[0] : '',
                        color: rightLabel ? rightLabel[1] : '',
                      }}>
                      <p>{result.right_label}</p>
                    </td>
                  </tr>
                </table>
              </div>
              <span className="bar"></span>
            </div>

            {isShowDescription && (
              <div className="graph-expand-content">
                <p className="graph-expand-description">{facetDescription}</p>

                <h3>Refer to your Playbook for practical tips and handles:</h3>

                <div
                  className="graph-expand-more-details"
                  onClick={() =>
                    handleExplorePremiumContent({
                      bookId,
                      sectionId,
                      chapterId,
                      domainName,
                      facetName,
                    })
                  }>
                  <div className="graph-expand-divider" />

                  <div className="chapter-container">
                    <SnapshotCardImage code={facetCode} />

                    <div className="chapter-content">
                      <div className="chapter-header">
                        <span className="chapter-number">
                          Chapter {chapterOrder}
                        </span>

                        {facetName !== '' && (
                          <span
                            className="chapter-facet"
                            style={{
                              color: labelColor,
                              borderColor: labelColor,
                            }}>
                            {facetName}
                          </span>
                        )}
                      </div>

                      <h3
                        className={`chapter-title ${
                          !chapterSubHeader && 'max-height'
                        }`}>
                        {chapterTitle}
                      </h3>

                      {chapterSubHeader && (
                        <div className="chapter-sub-title">
                          {chapterSubHeader}
                        </div>
                      )}

                      {isDesktopView && (
                        <ButtonOutline
                          className="chapter-explore-btn"
                          children="Explore Premium content"
                        />
                      )}
                    </div>

                    {isChapterRead && (
                      <div className="complete-icon-container">
                        <Checked />
                      </div>
                    )}
                  </div>

                  {isMobileView && (
                    <ButtonOutline
                      className="chapter-explore-btn"
                      children="Explore Premium content"
                    />
                  )}
                </div>
              </div>
            )}
          </div>

          {noAccessOpen && (
            <NoAccessModal
              className="transparent"
              history={history}
              isPreviewBtn={true}
              handleClose={() => setNoAccessOpen(false)}
            />
          )}
        </div>
      );
    });

    return data;
  };

  return (
    <div className="peaks-detailed-container">
      <div
        className="right-container"
        style={{ borderColor: borderColorSource }}>
        <div className="main-domain-title">{selectedTab}</div>

        <div className="domain-content-container">
          {domainData()}
          <div className="domain-description">{domainDescription}</div>
          <h3
            className="domain-video-link"
            onClick={() => handleOpenVideoModal()}>
            Find out more in this video preview from your Playbook.
          </h3>
        </div>

        {facetData()}
      </div>

      {openVideoModal && (
        <DomainIntoVideoModal
          bookId={bookId}
          sectionId={sectionId}
          childName={childName}
          handleClose={() => setOpenVideoModal(false)}
        />
      )}
    </div>
  );
};

export default withRouter(PeaksSnapshotDetails);

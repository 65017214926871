import axios from 'axios';

// Profile
export const loginUser = bodyFormData =>
  axios({
    method: 'post',
    url: '/auth/login',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: bodyFormData,
  });

export const getTempRelative = bodyFormData =>
  axios({
    method: 'post',
    url: '/relative/temp',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: bodyFormData,
  });

export const register = body => axios.post('/auth/signupv2', body);

export const forgotPassword = bodyFormData =>
  axios({
    method: 'post',
    url: '/auth/forgotpassword',
    data: bodyFormData,
  });

export const resetPassword = bodyFormData =>
  axios({
    method: 'post',
    url: '/auth/resetpassword',
    data: bodyFormData,
  });

export const getUserData = () => axios.get('/user');

export const updateUser = body => axios.put('/profiles/parent', body);

export const createChild = body => axios.post('/profiles/child', body);

export const updateChild = (relativeId, body) =>
  axios.put(`/relative/${relativeId}`, body);

export const createEmptyBook = () =>
  axios.post('/books/helper/create-empty-book');

// Test
export const getQuestion = ({ type, id }) =>
  axios.get(`/quiz?type=${type}&child_id=${id}`);
export const submitAnswer = body => axios.post('/quiz/submit-answer', body);
export const reSubmitAnswer = body => axios.post('/quiz/resubmit-answer', body);
export const getResult = ({ id }) => axios.get(`/relative/${id}/results`);
export const AssignChapters = body =>
  axios.post('/books/assign-chapters', body);

export const getRelative = () => axios.get('/relative');

export const getBooksVideo = () => axios.get('/books/videos');

export const completeChildTest = async ({ type, id, answers }) => {
  const res = await submitAnswer({
    type,
    id,
    answers,
  });

  sessionStorage.setItem('anyCompletedChildTest', true);

  return res;
};

import React, { useEffect, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { UserProvider } from 'context/UserContext';
import { ToastProvider } from 'context/ToastContext';
import { SignupProvider } from 'context/SignupContext';
import { VideoProvider } from 'context/VideoContext';
import { NoAccessProvider } from 'context/NoAccessContext';
import NotificationBanner from 'shared/components/NotificationBanner';
import ProtectedRoute from './routes/ProtectedRoute';
import Notification from 'shared/components/Notification';
import Toast from 'shared/components/Toast';
import ScrollToTop from 'shared/components/ScrollToTop';
import 'react-smartbanner/dist/main.css';
import './config/axiosConfig';
import './App.scss';
import './global.scss';
import 'intro.js/introjs.css';

// Routes
import { url } from 'config/urlConfig';
import { protectedRoutes } from 'config/routeConfig';
import Auth from 'routes/Auth/Auth';
import AccountCreationSuccess from 'routes/Auth/AccountCreationSuccess/AccountCreationSuccess';
import CheckoutLanding from 'routes/Auth/CheckoutLanding/CheckoutLanding';
import ReferralSignupEmail from 'routes/ReferralSystem/ReferralSignupEmail';
import MainNavigation from 'routes/MainNavigation/MainNavigation';
import Checkout from 'routes/Checkout/Checkout';
import OrderComplete from './routes/OrderComplete';
import NotFound from 'routes/NotFound';

// Stripe/GA
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { GAInit } from 'shared/function/Tracking/GAUtils';
import RouteChangeTracker from 'shared/function/Tracking/RouteChangeTracker';

// Init Google Analytics
GAInit();

const protectedSwitch = () => (
  <Switch>
    {protectedRoutes.map((route, index) => (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        component={route.component}
      />
    ))}
  </Switch>
);

const App = () => {
  const containerRef = useRef();
  const location = window.location;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  useEffect(() => {
    //Landing redirect to Wix
    if (location.pathname === '/') {
      return (location.href = 'https://www.getconnected.sg/');
    }
  }, [location]);

  return (
    <div
      ref={ref => {
        containerRef.current = ref;
      }}>
      <UserProvider>
        <ToastProvider>
          <SignupProvider>
            <VideoProvider>
              <NoAccessProvider>
                <NotificationBanner />
                <Notification />

                <Router>
                  <RouteChangeTracker />

                  <MainNavigation />
                  <Toast />
                  <ScrollToTop />

                  <Switch>
                    {/* Landing screens have migrate to Wix */}
                    {/* Login/Signup */}
                    <Route
                      path={[
                        '/login',
                        '/forgot-password',
                        '/reset-password',
                        '/signup',
                        '/signup-verification',
                        '/sign-up',
                      ]}
                      component={Auth}
                    />
                    <Route
                      path="/signup-referral"
                      component={ReferralSignupEmail}
                    />
                    <Route
                      path="/checkout-signup"
                      component={CheckoutLanding}
                    />
                    <Route
                      path="/account-success"
                      component={AccountCreationSuccess}
                    />

                    {/* Profile/Test */}
                    <ProtectedRoute
                      path={['/profile-test', '/profile-test-child']}
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path="/test-loading"
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path="/pretest-onboarding"
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path="/test/:types"
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path="/welcome"
                      component={protectedSwitch}
                    />

                    {/* Main Navigation */}
                    <ProtectedRoute
                      path={`${url.SETTINGS}/:settingId`}
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path={`${url.SETTINGS}`}
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path="/mailbox"
                      component={protectedSwitch}
                    />

                    <ProtectedRoute
                      path={`${url.HOME}`}
                      component={protectedSwitch}
                    />
                    {/* Playbook Section become first screen */}
                    <ProtectedRoute
                      path={`${url.PLAYBOOKSECTION}`}
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path={`${url.PLAYBOOK}/:domainSlug/chapter`}
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path={`${url.PLAYBOOK}/:domainSlug/:chapterSlug`}
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path={`${url.PLAYBOOKSNAPSHOT}`}
                      component={protectedSwitch}
                    />

                    <ProtectedRoute
                      path={`${url.TOPICS}`}
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path={`${url.TOPICSHUB}/:topicSlug`}
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path={`${url.TOPICSBIGIDEA}/:topicSlug`}
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path={`${url.TOPICSHUB}/:topicSlug/page/:stubSlug`}
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path={`${url.TOPICSHUB}/:topicSlug/page/:stubSlug/big-idea/:stubBigIdeaId`}
                      component={protectedSwitch}
                    />

                    {/* Premium Access */}
                    <ProtectedRoute path="/shop" component={protectedSwitch} />
                    <ProtectedRoute
                      path="/upgrade-premium"
                      component={protectedSwitch}
                    />
                    <ProtectedRoute
                      path="/add-child-profile"
                      component={protectedSwitch}
                    />
                    <Route path="/checkout">
                      <Elements stripe={stripePromise}>
                        <Checkout />
                      </Elements>
                    </Route>
                    <Route path="/order-completed" component={OrderComplete} />

                    {/* Others */}
                    <ProtectedRoute
                      path="/lobby-area/:errortypes"
                      component={protectedSwitch}
                    />
                    <Route component={NotFound} />
                  </Switch>
                </Router>
              </NoAccessProvider>
            </VideoProvider>
          </SignupProvider>
        </ToastProvider>
      </UserProvider>
    </div>
  );
};

export default App;

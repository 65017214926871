import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { url } from 'config/urlConfig';
import { DebounceInput } from 'react-debounce-input';
import Stepper from 'shared/components/Stepper/Stepper';
import { Close } from '@material-ui/icons';
import { ReactComponent as NotificationIcon } from 'assets/referral/referral-notification-icon.svg';
import './ReferralComponents.scss';

export const ReferralNotification = () => {
  const hideReferralNotification = localStorage.getItem(
    'hideReferralNotification',
  );

  const isHideReferralNotification = _.isEqual(
    hideReferralNotification,
    'true',
  );

  const [showNotification, setShowNotification] = useState(
    isHideReferralNotification ? false : true,
  );

  const history = useHistory();

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const handleNavigate = () => {
    if (isMobileView) {
      history.push(`/settings/referral?from=referral-banner`);
    } else {
      history.push(`/settings/referral?from=${url.HOME.replace('/', '')}`);
    }
  };

  const handleClose = () => {
    setShowNotification(false);
    localStorage.setItem('hideReferralNotification', true);
  };

  if (!showNotification) return null;

  return (
    <div id="referral-notification">
      <div
        className="referral-notification-content"
        onClick={() => handleNavigate()}>
        <NotificationIcon className="referral-notification-icon" />
        <p className="referral-notification-text">
          Refer a friend and get 7 days of Premium access!
        </p>
      </div>

      <Close className="referral-close-icon" onClick={() => handleClose()} />
    </div>
  );
};

export const ReferralStepper = ({
  type,
  header,
  steps,
  listLength,
  idx,
  ...otherProps
}) => {
  const isTypeVertical = _.isEqual(type, 'vertical');
  const referralLabelVariant = isTypeVertical ? 'solid' : 'outline';

  return (
    <div id="referral-stepper">
      {header && (
        <p
          className={`referral-stepper-header ${
            isTypeVertical ? 'vertical' : 'horizontal'
          }`}>
          {header}
        </p>
      )}
      <Stepper
        type={type}
        steps={steps}
        labelVariant={referralLabelVariant}
        {...otherProps}
      />
      {!isTypeVertical && idx !== listLength - 1 && (
        <div className="referral-stepper-divider" />
      )}
    </div>
  );
};

export const FormCode = ({
  formCode,
  isValidFormReferralCode,
  message,
  handleChange,
  ...otherProps
}) => {
  return (
    <div id="referral-form-code">
      <div className="code-content">
        <div className="code-divider"></div>
        <div className="code-text">Have a code?</div>
        <div className="code-divider"></div>
      </div>

      <div className="form-group">
        <DebounceInput
          id="referral-form-code"
          className={`${
            formCode ? (isValidFormReferralCode ? 'success' : 'error') : ''
          }`}
          minLength={0}
          debounceTimeout={800}
          type="text"
          name="code"
          placeholder="Enter code"
          onChange={handleChange}
          {...otherProps}
        />

        {message && (
          <p
            className={
              formCode
                ? isValidFormReferralCode
                  ? 'input-success'
                  : 'input-error'
                : ''
            }>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { InView } from 'react-intersection-observer';
import _ from 'lodash';
import { navigatePlaybooks } from '../BooksUtils';
import { NoAccessContext } from 'context/NoAccessContext.js';
import useTrackDeeplink from 'shared/hooks/useTrackDeeplink';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import {
  facetColor,
  chapterNumber as processChapterNumber,
} from 'shared/const';
import {
  getChapters,
  updateReadStatus,
  updateLastRead,
  listChapters,
} from '../api';

// components/assets
import Head from 'shared/components/Head.js';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import NoAccessModal from 'routes/ManagePremiumAccess/NoAccessModal';
import ManagePremiumRibbon from 'routes/ManagePremiumAccess/ManagePremiumRibbon';
import { ButtonSolid } from 'shared/components/Button/Button';
import { WebSideBar, MobileSideBar } from './ModuleSideBar';
import ArticleModule from './ArticleModule';
import PrimerModule from './PrimerModule';
import TipsModule from './TipsModule';
import ComicModule from './ComicModule';
import { ReactComponent as ChevronRight } from 'assets/home/chevron-right.svg';
import { ReactComponent as DividerIcon } from 'assets/books/book-divider-shape-icon.svg';
import './ChapterModule.scss';

const CHAPTER_TYPE = {
  COMIC: 'COMIC',
  ARTICLE: 'ARTICLE',
  TIPS: 'TIPS',
  PRIMER: 'PRIMER',
  primer: 'primer',
};

const ChapterModule = ({ history, location }) => {
  const params = new URLSearchParams(location.search);
  const index = params.get('index');

  const { domainSlug, chapterSlug } = useParams();

  const previewChapterResponseData = JSON.parse(
    sessionStorage.getItem('previewChapterResponseData'),
  );
  const { childs } = previewChapterResponseData;

  const sessionSelectedRelative = JSON.parse(
    sessionStorage.getItem('selectedRelative'),
  );
  const selectedRelative = sessionSelectedRelative || (childs && childs[0]);
  const childId = selectedRelative?.id;

  const { bookId, sectionId, chapterId } = useTrackDeeplink({
    domainSlug,
    chapterSlug,
    childId,
  });

  const { noAccessOpen, setNoAccessOpen } = useContext(NoAccessContext);

  const { isUserPremiumAccess } = useUserPremiumAccess();

  const [isChapterData, setIsChapterData] = useState(false);
  const [chapterData, setChapterData] = useState(null);
  const [chapterContent, setChapterContent] = useState([]);
  const [activeModuleIndex, setActiveModuleIndex] = useState(0);
  const [chapterList, setChapterList] = useState(location.state);
  const [isLoading, setIsLoading] = useState(true);

  // Divider References
  const [scrollIndex, setScrollIndex] = useState(0);
  const dividerOffsetRef = useRef();
  const sectionViewportVisibilityRef = useRef([]);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const getTrueChapterNumber = () => {
    const { chapters, name: domainName } = chapterList;
    const { id: chapterId } = chapterData;
    const { order } = chapters.find(item => item.id === chapterId);
    const processedChapterNumber = processChapterNumber(domainName) + order;

    return processedChapterNumber;
  };

  const sortSectionVisibility = (index, isVisible) => {
    const temp = [...sectionViewportVisibilityRef.current];
    temp[index] = isVisible;

    const indexes = [];
    temp.forEach((item, index) => {
      if (item === true) {
        indexes.push(index);
      }
    });

    // This will be the most bottom section
    const maxNumber = Math.max(...indexes);
    sectionViewportVisibilityRef.current = temp;
    setScrollIndex(maxNumber);
  };

  const getChapterData = async () => {
    setIsLoading(true);

    try {
      const res = await getChapters(bookId, chapterId);
      const { data } = res.data;

      setChapterData(data);
      setChapterContent(data.content);
    } catch (err) {
      console.log('🚀 ~ getChapterData ~ err:', err?.response);
      setChapterData(null);
      setChapterContent([]);
    } finally {
      // Set isLoading to false after handling the response or error
      setIsLoading(false);
    }
  };

  const updateStatus = async () => {
    await updateReadStatus(bookId, chapterId);

    navigatePlaybooks({
      navigate: history.push,
      type: 'section-menu',
      bookId,
    });
  };

  const getNextChapter = () => {
    const { chapters } = chapterList;
    const currentChapterIndex = _.findIndex(chapters, c => c.id === chapterId);
    return chapters[currentChapterIndex + 1];
  };

  const continueReadingTrigger = index => {
    let body = {};
    const processedChapterNumber = getTrueChapterNumber();

    if (index === -1) {
      const nextChapter = getNextChapter();

      if (!nextChapter) {
        body = {
          sectionId,
          selectedRelative,
          bookId,
          chapterId,
          getNext: 0,
          type: 'Video',
          videoType: 'Ending',
          title: chapterList.ending_video_desc,
        };
      } else {
        body = {
          sectionId,
          selectedRelative,
          index: 0,
          bookId,
          getNext: 1,
          type: 'Chapter',
          chapterId: nextChapter.id,
          chapterNumber: parseInt(processedChapterNumber) + 1,
        };
      }
    } else {
      const { name: facetName } = chapterData;
      const { title, components } = chapterContent[index];
      const type = components[0] ? components[0].type : '';
      body = {
        index,
        facetName,
        sectionId,
        chapterNumber: processedChapterNumber,
        chapterId,
        selectedRelative,
        bookId,
        title:
          type === 'PRIMER'
            ? `PEAKS Insights: You & ${selectedRelative.name}`
            : title,
        type: 'Chapter',
        getNext: 0,
      };
    }

    updateLastRead(body);
  };

  const switchActiveModule = async condition => {
    switch (condition) {
      case 'next':
        if (activeModuleIndex === chapterContent.length - 1) {
          await updateStatus();
        } else {
          setActiveModuleIndex(index => index + 1);
          continueReadingTrigger(activeModuleIndex + 1);
          window.scrollTo(0, 0);
        }
        break;
      case 'back':
        if (activeModuleIndex === 0) return;
        setActiveModuleIndex(index => index - 1);
        continueReadingTrigger(activeModuleIndex - 1);
        window.scrollTo(0, 0);
        break;
      case 'done':
        continueReadingTrigger(-1);
        await updateStatus();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (parseInt(index) > 0) setActiveModuleIndex(parseInt(index));
    else setActiveModuleIndex(0);

    if (childId && sectionId && chapterId) {
      getChapterData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionId, chapterId, childId]);

  useEffect(() => {
    if (chapterContent.length > 0 && chapterList)
      continueReadingTrigger(activeModuleIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterContent, chapterList]);

  useEffect(() => {
    const fetchChapterList = async () => {
      try {
        const res = await listChapters(bookId, sectionId);
        const { data } = res.data;
        if (res) setChapterList(data);
      } catch (err) {
        console.log('🚀 ~ fetchChapterList ~ err:', err?.response);
        setChapterList(null);
      }
    };

    // Fetch chapterList only when needed
    if (!chapterList) {
      fetchChapterList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterList, bookId, sectionId, childId]);

  useEffect(() => {
    const isChapterData =
      bookId &&
      sectionId &&
      chapterData &&
      chapterContent &&
      chapterContent.length > 0 &&
      chapterList &&
      chapterList?.chapters?.length > 0;

    if (chapterData) {
      if (isChapterData && _.isEqual(isChapterData, true))
        setIsChapterData(true);
      else setIsChapterData(false);
    }
  }, [bookId, sectionId, chapterData, chapterContent, chapterList]);

  // Memoized the component so it will not be affected by any state changes that might cause any UI Bugs
  // the component will only be affected by chapterData, chapterList and chapterContent
  const ModuleItem = useCallback(() => {
    const ItemDivider = () => {
      return (
        <div className="divider-container">
          <div className="divider-line"></div>
          <DividerIcon className="divider-shape" />
          <div className="divider-line"></div>
        </div>
      );
    };

    const LockOverlay = () => {
      return null;
      // if (!isDemo) return null;

      // return (
      //   <div className="lock-message-overlay">
      //     <div className="lock-container">
      //       <img className="lock-icon" src={LockGreenIcon} alt="Lock" />

      //       <h3>
      //         Oops, you're currently in light mode and don't have access to this
      //       </h3>
      //       <p>This feature is only available in the Playbook.</p>
      //     </div>
      //   </div>
      // );
    };

    return chapterContent?.map((item, index) => {
      const { title, components } = item;

      if (components.length === 0 || !components[0].body) {
        return (
          <section className="module empty">
            <h2>Module is empty</h2>
          </section>
        );
      }

      const { id, type, body } = components[0];

      let componentToRender = null;

      if (type === CHAPTER_TYPE.primer || type === CHAPTER_TYPE.PRIMER) {
        componentToRender = (
          <PrimerModule
            body={body}
            selectedRelative={selectedRelative}
            LockOverlay={LockOverlay}
          />
        );
      } else if (type === CHAPTER_TYPE.COMIC) {
        componentToRender = (
          <ComicModule
            title={title}
            body={body}
            domain={chapterData.name}
            switchActiveModule={switchActiveModule}
          />
        );
      } else if (type === CHAPTER_TYPE.ARTICLE) {
        componentToRender = (
          <ArticleModule title={title} body={body} LockOverlay={LockOverlay} />
        );
      } else if (type === CHAPTER_TYPE.TIPS) {
        componentToRender = (
          <TipsModule
            title={title}
            components={components}
            facet={chapterData.name}
            LockOverlay={LockOverlay}
          />
        );
      }

      return (
        <InView
          as="div"
          id={id}
          key={index}
          onChange={inView => {
            sortSectionVisibility(index, inView);
          }}>
          {componentToRender}
          {index !== chapterContent?.length - 1 && (
            <div
              class={`content-divider-${index}`}
              ref={ref => {
                if (!dividerOffsetRef.current) {
                  dividerOffsetRef.current = [];
                }

                dividerOffsetRef.current[index] = ref;
              }}>
              <ItemDivider />
            </div>
          )}
        </InView>
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapterData, chapterContent, chapterList]);

  const BackButton = ({ isEmptyModule }) => {
    const handleBack = () => {
      navigatePlaybooks({
        navigate: history.push,
        type: 'section-menu',
        bookId,
      });
    };

    return (
      <div
        className="back-btn"
        onClick={() => handleBack()}
        data-test-id="chapter-exit-preview">
        {!isLoading && <ChevronRight className="back-img" />}{' '}
        <div className="back-text">
          {isEmptyModule || !isUserPremiumAccess
            ? 'Exit preview'
            : 'Section menu'}
        </div>
      </div>
    );
  };

  const renderEmptyModule = () => {
    return (
      <>
        {isMobile && (
          <div className="page-header">
            <div className="page-header-content">
              <div className="back">
                <BackButton isEmptyModule />
              </div>
            </div>
          </div>
        )}

        <div className="module-content">
          {!isMobile && (
            <div className="module-side-bar">
              <BackButton />
            </div>
          )}
          <section className="module empty">
            <h2>Module not available</h2>
          </section>
        </div>
      </>
    );
  };

  const renderModulePage = () => {
    const processedChapterNumber = getTrueChapterNumber();
    const isChapterContentEmpty = !(
      chapterContent && chapterContent?.length > 0
    );

    return (
      <>
        {isMobile && (
          <div className={`page-header ${!isUserPremiumAccess && 'demo'}`}>
            <div className="page-header-content">
              <div className="back">
                <BackButton />

                <MobileSideBar
                  moduleItems={chapterContent}
                  chapterData={chapterData}
                  chapterNumber={processedChapterNumber}
                  facetColor={facetColor}
                  selectedRelative={selectedRelative}
                  scrolledIndex={scrollIndex}
                />
              </div>

              <ManagePremiumRibbon history={history} isChapterModule={true} />
            </div>
          </div>
        )}

        {!isChapterContentEmpty && (
          <div className="module-content">
            {!isMobile && (
              <div
                className={`module-side-bar ${!isUserPremiumAccess && 'demo'}`}>
                <BackButton />

                <WebSideBar
                  moduleItems={chapterContent}
                  scrolledIndex={scrollIndex}
                />
              </div>
            )}

            <div className="scrollable-container">
              <div className="module-items-container">
                <div className="subheader">
                  <h3>Chapter {processedChapterNumber}</h3>
                  <span
                    style={{
                      color: facetColor(chapterData.name),
                      borderColor: facetColor(chapterData.name),
                    }}>
                    {chapterData.name}
                  </span>
                </div>

                <ModuleItem />

                <div className="module-nav">
                  <div className="right-nav">
                    <ButtonSolid
                      className="chapter-done-btn"
                      onClick={() => switchActiveModule('done')}
                      children="Done"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {isChapterContentEmpty && (
          <div className="module-content">
            <section className="module empty">
              <h2>Module not available</h2>
            </section>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    setNoAccessOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || !isChapterData) {
    return <LoadingIndicator />;
  }

  return (
    <div id="chapter-module-page">
      <Head title="Connected App - Chapter Module" preventIndex />

      <div className={`container ${!isUserPremiumAccess && 'demo'}`}>
        {isChapterData ? renderModulePage() : renderEmptyModule()}
      </div>

      {noAccessOpen && (
        <NoAccessModal
          history={history}
          isPreviewBtn={true}
          handleClose={() => setNoAccessOpen(false)}
        />
      )}
    </div>
  );
};

export default withRouter(ChapterModule);

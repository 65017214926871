import { useEffect, useState } from 'react';

const useCloudinaryImageLoader = src => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = src;

    image.onload = () => {
      setLoaded(true);
    };

    image.onerror = () => {
      setLoaded(true); // Mark as loaded even if the image fails to load
    };

    return () => {
      image.onload = null; // Clean up event handlers
      image.onerror = null;
    };
  }, [src]);

  return loaded;
};

export default useCloudinaryImageLoader;

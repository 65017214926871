import _ from 'lodash';
import { Check } from '@material-ui/icons';
import './Stepper.scss';

const Stepper = ({ steps, type, labelVariant }) => {
  const isTypeVertical = _.isEqual(type, 'vertical');
  const isLabelOutline = _.isEqual(labelVariant, 'outline');

  return (
    <div
      id="stepper"
      className={`${isTypeVertical ? 'vertical' : 'horizontal'}`}>
      {steps?.map((step, index) => {
        const stepLabel = step?.label;
        const stepDescription = step?.description;

        const stepStatus = step?.status;
        const isStatusActive = _.isEqual(stepStatus, 'active');
        const isStatusInProgress = _.isEqual(stepStatus, 'in-progress');
        const isStatusInactive = _.isEqual(stepStatus, 'inactive');

        const statusStyle =
          (isStatusInProgress && 'progress') ||
          (isStatusInactive && 'inactive') ||
          'active';

        return (
          <div
            className={`step-container ${
              isTypeVertical ? 'vertical' : 'horizontal'
            }`}
            key={index}>
            <div className="step-connector-wrapper">
              <div
                className={`step-label-wrapper ${
                  isLabelOutline ? `outline ${statusStyle}` : 'solid'
                }`}>
                {isStatusActive ? (
                  <Check className="step-label-checked-icon" />
                ) : (
                  <p
                    className={`step-label-text ${
                      isTypeVertical ? 'vertical' : `horizontal ${statusStyle}`
                    }`}>
                    {stepLabel}
                  </p>
                )}
              </div>

              {index !== steps.length - 1 && (
                <div
                  className={`step-connector ${
                    isTypeVertical ? 'vertical' : `horizontal ${statusStyle}`
                  }`}
                />
              )}
            </div>

            <p
              className={`step-description ${
                isTypeVertical ? 'vertical' : `horizontal ${statusStyle}`
              }`}>
              {stepDescription}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;

import { useEffect } from 'react';

const useOutsideClickForTips = (ref, callback) => {
  const handleClick = e => {
    const element = document.querySelector('.card-w-description');
    if (ref && !element.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClickForTips;

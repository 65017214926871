import React, { useContext, useState, useEffect } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { UserContext } from 'context/UserContext';
import { url } from 'config/urlConfig';
import {
  trackingAction,
  TRACKING_PURCHASES,
  GA_EVENT_CATOGARY,
  GA_PURCHASE_JOURNEY_TYPE,
} from 'shared/function/Tracking';
import {
  useDetectPreviousPath,
  handleSettingBack,
  getNotifications,
} from './SettingModuleUtils';
import { MANAGE_PREMIUM_TYPE } from 'routes/ManagePremiumAccess/ManagePremiumUtils';
import useChildPicker from 'shared/hooks/useChildPicker';
import useProductList from 'routes/Checkout/useProductList';
import packageInfo from '../../../package.json';

// components/assets
import { ButtonSolid } from 'shared/components/Button/Button';
import Head from 'shared/components/Head.js';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import Logout from 'shared/components/Logout/Logout';
import {
  BackButton,
  SettingsContentContainer,
} from './components/SettingsComponent';
import EmptyChildAlertPopup from 'routes/ManagePremiumAccess/EmptyChildAlertPopup';
import { SelectedSettingTab } from './SettingModuleUtils';
import './SettingsModule.scss';
import './components/SettingsComponent.scss';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';

const { version } = packageInfo;

const SettingsModuleV2 = () => {
  const {
    user,
    setUser,
    setSelectedRelative,
    setUserRelatives,
    setCartItem,
    setBadgeCount,
  } = useContext(UserContext);

  const { isUserDemo } = useUserPremiumAccess();

  const history = useHistory();
  const location = useLocation();

  const path = location.pathname;

  const { previousPath } = useDetectPreviousPath({
    location,
  });

  const { isAnyProfileIncompleted, isChildPickerLoading } = useChildPicker();

  const { selectedProduct } = useProductList({
    type: isUserDemo
      ? MANAGE_PREMIUM_TYPE.upgrade_premium
      : MANAGE_PREMIUM_TYPE.add_child_profile,
  });

  const [settingId, setSettingId] = useState(null);
  const [showEmptyChildAlertPopup, setShowEmptyChildAlertPopup] =
    useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const settingTabs = [
    { type: 'account', name: 'Account', group: 1 },
    { type: 'profiles', name: 'Profiles', group: 1 },
    { type: 'spotlight', name: 'Spotlight', group: 1 },
    { type: 'referral', name: 'Referral', group: 1 },
    { type: 'contact', name: 'Contact Us', group: 2 },
    { type: 'terms', name: 'Terms of Use', group: 2 },
    { type: 'policy', name: 'Privacy Policy', group: 2 },
  ];

  const getNotificationCount = async () => {
    let count = 0;
    try {
      const response = await getNotifications();
      const notifications = response?.data?.data.reverse();

      if (notifications) {
        await notifications.forEach(noti => {
          if (noti.pivot.read_at === null) {
            count++;
          }
        });
        setBadgeCount(count);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clearContext = async () => {
    setCartItem([]);
    setUser({});
    setSelectedRelative({});
    setUserRelatives([]);
  };

  const handlePremiumAccess = async () => {
    if (isUserDemo) {
      trackingAction({
        category: GA_EVENT_CATOGARY.ecommerce,
        action: TRACKING_PURCHASES.view_premium_upgrade,
        ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.view_item,
        ecommerceEventData: { productData: selectedProduct },
        extraData: {
          user_id: user?.id,
        },
      });

      history.push(`${url.UPGRADE_PREMIUM}?from=${previousPath}`);
    } else {
      if (isAnyProfileIncompleted) {
        setShowEmptyChildAlertPopup(true);
      } else {
        trackingAction({
          category: GA_EVENT_CATOGARY.ecommerce,
          action: TRACKING_PURCHASES.view_child_profile,
          ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.view_item,
          ecommerceEventData: { productData: selectedProduct },
          extraData: {
            user_id: user?.id,
          },
        });

        history.push(`${url.ADD_CHILD}?from=${previousPath}`);
        setShowEmptyChildAlertPopup(false);
      }
    }
  };

  const handleLogout = async () => {
    clearContext();
    history.push('/login');
  };

  useEffect(() => {
    getNotificationCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const settingId =
      path.split('/').pop() !== 'settings' ? path.split('/').pop() : '';

    if (settingId) {
      setSettingId(settingId);
    } else {
      setSettingId(null);
    }
  }, [path, isMobile]);

  const TabGroup = ({ header, group }) => {
    const handleTabNavigation = ({ item }) => {
      history.push(`/settings/${item?.type}?from=${previousPath}`);
    };

    return (
      <div className="settings-tab-group">
        {header && <h3>{header}</h3>}

        {settingTabs?.map(item => {
          if (_.isEqual(item?.group, group)) {
            return (
              <ButtonSolid
                className={
                  (settingId
                    ? `${item?.type}` === settingId
                    : !isMobile && `${item?.type}` === 'account') && 'active'
                }
                onClick={() => handleTabNavigation({ item })}
                children={<div className="left-item">{item?.name}</div>}
              />
            );
          }

          return null;
        })}
      </div>
    );
  };

  if (isChildPickerLoading) return <LoadingIndicator />;

  return (
    <div id="settings-page">
      <Head title="Connected | Settings" preventIndex />

      <div className="container">
        <div
          className={`sidebar-container ${!settingId ? 'mobile-active' : ''}`}>
          <BackButton
            history={history}
            handleClick={() =>
              handleSettingBack({ history, location, isMobile })
            }
          />

          <div className="settings-header">
            <h2>User Settings</h2>
            <Logout handleLogout={() => handleLogout()} />
          </div>

          <div className="settings-manage-premium">
            <p className="manage-premium-text">
              You're currently in{' '}
              <span>{isUserDemo ? 'Basic' : 'Premium'}</span> mode.
            </p>

            <ButtonSolid
              className="manage-premium-button"
              children={
                isUserDemo ? 'Upgrade to Premium' : 'Add a child profile'
              }
              onClick={() => handlePremiumAccess()}
            />
          </div>

          <TabGroup header="Manage" group={1} />
          <TabGroup header="Support" group={2} />

          <div className="button-group">
            <p className="version">Version {version}</p>
          </div>
        </div>

        {settingId && (
          <SettingsContentContainer>
            <div
              className={`SelectedSettingTab ${
                settingId ? 'mobile-active' : ''
              }`}>
              <SelectedSettingTab settingId={settingId} />
            </div>
          </SettingsContentContainer>
        )}
      </div>

      {showEmptyChildAlertPopup && (
        <EmptyChildAlertPopup
          history={history}
          location={location}
          setIsOpen={setShowEmptyChildAlertPopup}
        />
      )}
    </div>
  );
};

export default withRouter(SettingsModuleV2);

import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { ToastContext } from 'context/ToastContext';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import { listProducts } from './CheckoutUtils';
import { getCurrencyCodeSymbol } from 'shared/function/CountryUtils';
import { MANAGE_PREMIUM_TYPE } from 'routes/ManagePremiumAccess/ManagePremiumUtils';
import _ from 'lodash';

const useProductList = ({ type = null } = {}) => {
  const location = useLocation();

  const { setToast } = useContext(ToastContext);

  const { isUserDemo } = useUserPremiumAccess();

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductPrice, setSelectedProductPrice] = useState(null);
  const [isSelectedProductInactive, setIsSelectedProductInactive] =
    useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);

  const params = new URLSearchParams(location.search);
  const addChild = params.get('addchild');
  const isAddChild =
    _.isEqual(addChild, 'true') ||
    (type && _.isEqual(type, MANAGE_PREMIUM_TYPE.add_child_profile));
  const currency = localStorage.getItem('countryCurrency')
    ? localStorage.getItem('countryCurrency')
    : 'SGD';

  const productCurrency = currency.toLowerCase();
  const currencyCodeSymbol = getCurrencyCodeSymbol(currency);

  const getProductList = async () => {
    setIsProductLoading(true);

    try {
      const res = await listProducts();

      const productListData = res?.data?.data;

      const selectedProductData = productListData?.find(product =>
        isUserDemo
          ? product.type === 'PREMIUM_ACCESS'
          : isAddChild
          ? product.type === 'PLAYBOOK'
          : product.type === 'PREMIUM_ACCESS',
      );

      if (selectedProductData) {
        const isSelectedProductInactive = _.isEqual(
          selectedProductData.is_active,
          0,
        );

        setSelectedProduct({
          ...selectedProductData,
          currency: productCurrency,
        });
        sessionStorage.setItem(
          'selectedProduct',
          JSON.stringify({
            ...selectedProductData,
            currency: productCurrency,
          }),
        );

        setIsSelectedProductInactive(isSelectedProductInactive);
      } else {
        // Handle case where no selected product is found
        console.error('No selected product found');
        setToast({
          type: 'error',
          message: 'No selected product found',
        });
      }

      setIsProductLoading(false);

      return productListData;
    } catch (err) {
      const { message } = err;
      console.log(
        '🚀 ~ file: Checkout.js:210 ~ getProductList ~ message:',
        message,
      );

      setToast({
        type: 'error',
        message,
      });
      setIsProductLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProduct && isSelectedProductInactive) {
      setToast({
        type: 'error',
        message: 'Please try again, payment option inactive',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct, isSelectedProductInactive]);

  useEffect(() => {
    if (!selectedProduct) getProductList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  useEffect(() => {
    const productPrices = selectedProduct?.price;

    if (productPrices) {
      // Default price will always be SGD
      const defaultProductPrice = productPrices.find(
        price => price.currency === 'SGD',
      );
      const selectedProductPrice = productPrices.find(
        price => price.currency === productCurrency.toUpperCase(),
      );
      const productPrice = selectedProductPrice
        ? selectedProductPrice
        : defaultProductPrice;

      // TODO: when needed other country currency
      // const selectedProductPriceAmount =
      //   selectedProductPrice?.amount || productPrices[0]?.amount;

      const selectedProductPriceAmount = productPrice.amount;

      if (selectedProductPriceAmount) {
        setSelectedProductPrice(selectedProductPriceAmount);
      }
    }
  }, [productCurrency, selectedProduct]);

  return {
    isProductLoading,
    isSelectedProductInactive,
    selectedProduct,
    selectedProductPrice,
    getProductList,
    currencyCodeSymbol,
  };
};

export default useProductList;

import { useState, useEffect } from 'react';
import useScrollDetector from 'shared/hooks/useScrollDetector';

const useInfiniteScroll = getItemsData => {
  const { isAtBottom } = useScrollDetector();

  const [items, setItems] = useState(null);
  const [nextPage, setNextPage] = useState(1);
  const [lastPage, setLastPage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // This is for when the user is getting new data via the infinite scroll.
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // This is for when the user loads page 1
  const initialLoad = async () => {
    try {
      const { itemsData, newCurrentPage, newLastPage } = await getItemsData(1);

      setNextPage(newCurrentPage + 1);
      setLastPage(newLastPage);
      setItems(itemsData);
      setIsLoading(false);
    } catch (err) {
      console.log('🚀 ~ initialLoad ~ err:', err);
      setIsLoading(false);
    }
  };

  // This is for when the user loads the subsequent pages
  const loadMoreItems = async () => {
    setIsLoadingMore(true);

    try {
      if (!isLoadingMore && nextPage <= lastPage) {
        const previousItems = [...items];
        const { itemsData, newCurrentPage, newLastPage } = await getItemsData(
          nextPage,
        );

        const newNextPage = newCurrentPage + 1;

        setNextPage(newNextPage);
        setLastPage(newLastPage);

        setItems([...previousItems, ...itemsData]);

        setIsLoading(false);
        setIsLoadingMore(false);
      }
    } catch (err) {
      console.log('🚀 ~ loadMoreItems ~ err:', err?.response);
      setIsLoadingMore(false);
    }
  };

  // Calls get topics each time the user scrolls to the bottom
  useEffect(() => {
    const isAllItemsLoaded = nextPage >= lastPage;

    if (isAtBottom && !isAllItemsLoaded) {
      loadMoreItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAtBottom, nextPage, lastPage]);

  return {
    items,
    initialLoad,
    isLoading,
    setIsLoading,
    isLoadingMore,
  };
};

export default useInfiniteScroll;

import { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import { ToastContext } from 'context/ToastContext';
import { UserContext } from 'context/UserContext';
import { navigatePayment, updateUserPaymentStatus } from './CheckoutUtils';
import useProductList from './useProductList';

const usePayNowPayment = ({
  paymentData,
  discountCode,
  isDiscountCodeValid,
  discountInfo,
}) => {
  const stripe = useStripe();

  const { setToast } = useContext(ToastContext);

  const { user, setUser } = useContext(UserContext);

  const { selectedProduct } = useProductList();
  const selectedProductData = JSON.parse(
    sessionStorage.getItem('selectedProduct'),
  );

  const productData = selectedProduct || selectedProductData;

  const history = useHistory();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const addChild = params.get('addchild') === 'true';
  const unlockplaybook = params.get('unlockplaybook') === 'true';

  const [showPayNowScreen, setShowPayNowScreen] = useState(false);
  const [payNowQRCodeURL, setPayNowQRCodeURL] = useState(null);
  const [isURLChecked, setIsURLChecked] = useState(false);

  const navigateUserPayment = () => {
    navigatePayment({
      history,
      addChild,
      unlockplaybook,
      discountCode,
      isDiscountCodeValid,
      discountInfo,
    });
  };

  useEffect(() => {
    const fetchPayNowQRCodeURL = async () => {
      try {
        // Request payment data from internal API
        const orderId = paymentData.id;
        const clientSecret = paymentData.client_secret;

        // Create a PaymentIntent for PayNow payment
        const { paymentIntent, error } = await stripe.confirmPayNowPayment(
          clientSecret,
          {},
        );

        if (error) {
          // Handle payment error
          console.error('Payment failed:', error);
        } else if (paymentIntent.status === 'succeeded') {
          // Payment succeeded
          console.log('Payment succeeded');

          // Retrieve the PayNow QR code URL from the PaymentIntent
          const payNowQRCodeURL =
            paymentIntent?.next_action?.display_qr_code.url;
          setPayNowQRCodeURL(payNowQRCodeURL);

          const STATUS = {
            SUCCESS: 'Success',
            FAIL: 'Failed',
          };

          updateUserPaymentStatus({
            orderId,
            selectedProduct: productData,
            status: STATUS.SUCCESS,
            paymentIntentId: paymentIntent?.id,
            navigatePayment: navigateUserPayment,
            setToast,
            user,
            setUser,
            unlockplaybook,
          });
        } else {
          // Handle other payment status scenarios if needed
        }
      } catch (err) {
        // Handle any other errors that may occur during the process
        console.error('Error processing payment:', err);
      } finally {
        // Mark the URL as checked
        setIsURLChecked(true);
      }
    };

    // Check if the URL has not been checked before fetching
    if (!isURLChecked && !payNowQRCodeURL) {
      fetchPayNowQRCodeURL();
      setShowPayNowScreen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      setShowPayNowScreen(false);
      setPayNowQRCodeURL(null);
      setIsURLChecked(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stripe,
    isURLChecked,
    payNowQRCodeURL,
    productData,
    paymentData,
    user,
    setUser,
    setToast,
    unlockplaybook,
    addChild,
    discountCode,
    isDiscountCodeValid,
    discountInfo,
  ]);

  return { showPayNowScreen, payNowQRCodeURL };
};

export default usePayNowPayment;

import { useDetectPreviousPath } from 'routes/SettingsModule/SettingModuleUtils';
import { ReactComponent as ArrowLeft } from 'assets/general/chevron-left.svg';
import './ManagePremiumComponents.scss';

export const HeaderBack = ({
  title,
  history,
  location,
  handleClick,
  ...otherProps
}) => {
  const { previousPath } = useDetectPreviousPath({
    location,
  });

  const handleBack = () => {
    history.push(`/settings/account?from=${previousPath}`);
  };

  return (
    <header id="manage-premium">
      <div
        className="header-container"
        onClick={handleClick ? handleClick : handleBack}
        {...otherProps}>
        <ArrowLeft /> {title}
      </div>
    </header>
  );
};

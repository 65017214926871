import { getProfileColour } from 'shared/const';

export function goAnchorTop() {
  const scrollableContainer = document.querySelector('.peaks-page');
  const moduleItemsContainer = document.querySelector(
    '.peaks-detailed-container',
  );

  const offset = getOffset();

  if (scrollableContainer && moduleItemsContainer) {
    const containerTopOffset = scrollableContainer.getBoundingClientRect().top;
    const targetOffset = containerTopOffset - offset;

    scrollableContainer.scrollTo({
      top: targetOffset,
      behavior: 'smooth',
    });
  } else {
    window.scrollTo({
      top: -offset,
      behavior: 'smooth',
    });
  }
}

export function goAnchorFacet(chapterId, currentScrollPosition = 0) {
  const targetElement = document.getElementById(chapterId);

  if (targetElement) {
    const topOffset = targetElement.getBoundingClientRect().top;
    const scrollableContainer = document.querySelector('.peaks-page');
    const moduleItemsContainer = document.querySelector(
      '.peaks-detailed-container',
    );

    if (
      scrollableContainer &&
      moduleItemsContainer &&
      moduleItemsContainer.contains(targetElement)
    ) {
      const containerTopOffset =
        scrollableContainer.getBoundingClientRect().top;
      const targetOffset =
        topOffset -
        containerTopOffset +
        moduleItemsContainer.scrollTop +
        moduleItemsContainer.getBoundingClientRect().top;

      const offset = targetOffset - getOffset() + currentScrollPosition;

      scrollableContainer.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    } else {
      const offset = topOffset - getOffset() + currentScrollPosition;

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
    }
  }
}

function getOffset() {
  let offset;

  if (window.innerWidth < 500) {
    offset = 80;
  } else {
    offset = 100;
  }

  return offset;
}

export const getBorderColorSource = ({ tab }) => {
  switch (tab) {
    case 'Purpose':
    case 'Preparedness':
    case 'Organisation':
    case 'Dutifulness':
    case 'Success Driven':
    case 'Focus':
      return '#FC9951';
    case 'Energy':
    case 'Interactivity':
    case 'Assertiveness':
    case 'Vibrance':
    case 'Thrill Seeking':
    case 'Exuberance':
      return '#FCC715';
    case 'Affirmation':
    case 'Trust':
    case 'Forthrightness':
    case 'Other-centredness':
    case 'Co-operation':
    case 'Empathy':
      return '#50C1BC';
    case 'Knowledge':
    case 'Futuristic':
    case 'Emotional Sensitivity':
    case 'Open-mindedness':
    case 'Intellectual Curiosity':
    case 'Liberality':
      return '#358DA5';
    case 'Sustainability':
    case 'Composure':
    case 'Anger Management':
    case 'Resilience':
    case 'Embarrassment Bridling':
    case 'Stress Control':
      return '#F45051';
    default:
      return '#FC9951';
  }
};

export const getPeaksDomainDescription = ({ domain, childName }) => {
  const getDomainDescription = selectedDomain => {
    switch (selectedDomain) {
      case 'Purpose':
        return `how you and ${childName} prefer to approach tasks and get things done`;
      case 'Energy':
        return `how you and ${childName} socialise and recharge`;
      case 'Affirmation':
        return `how you and ${childName} get along with others`;
      case 'Knowledge':
        return `how you and ${childName} approach ideas and new concepts`;
      case 'Sustainability':
        return `how you and ${childName} respond to stress and emotions`;
      default:
        return `how you and ${childName} prefer to approach tasks and get things done`;
    }
  };

  const domainDescription = getDomainDescription(domain);

  return `${domain} is a PEAKS Factor that describes ${domainDescription}. It is made up of the following 5 PEAKS Facets below.`;
};

export const getGraphTableColour = ({
  userColour,
  childColour,
  parentScore,
  childScore,
}) => {
  const userClr = getProfileColour(userColour);
  const childClr = getProfileColour(childColour);

  const leftLabelData = () => {
    if (childScore >= 0 && childScore <= 44) {
      if (parentScore >= 0 && parentScore <= 44) {
        return [
          `linear-gradient(-20deg, ${userClr} 50%, ${childClr} 50%)`,
          '#FFFFFF',
        ];
      } else {
        return [childClr, '#FFFFFF'];
      }
    }

    if (parentScore >= 0 && parentScore <= 44) {
      if (childScore >= 0 && childScore <= 44) {
        return [
          `linear-gradient(-20deg, ${userClr} 50%, ${childClr} 50%)`,
          '#FFFFFF',
        ];
      } else {
        return [userClr, '#FFFFFF'];
      }
    }
  };

  const middleLabelData = () => {
    if (childScore > 44 && childScore < 55) {
      if (parentScore > 44 && parentScore < 55) {
        return [
          `linear-gradient(-20deg, ${userClr} 50%, ${childClr} 50%)`,
          '#FFFFFF',
        ];
      } else {
        return [childClr, '#FFFFFF'];
      }
    }

    if (parentScore > 44 && parentScore < 55) {
      if (childScore > 44 && childScore < 55) {
        return [
          `linear-gradient(-20deg, ${userClr} 50%, ${childClr} 50%)`,
          '#FFFFFF',
        ];
      } else {
        return [userClr, '#FFFFFF'];
      }
    }
  };

  const rightLabelData = () => {
    if (childScore >= 55 && childScore <= 100) {
      if (parentScore >= 55 && parentScore <= 100) {
        return [
          `linear-gradient(-20deg, ${userClr} 50%, ${childClr} 50%)`,
          '#FFFFFF',
        ];
      } else {
        return [childClr, '#FFFFFF'];
      }
    }

    if (parentScore >= 55 && parentScore <= 100) {
      if (childScore >= 55 && childScore <= 100) {
        return [
          `linear-gradient(-20deg, ${userClr} 50%, ${childClr} 50%)`,
          '#FFFFFF',
        ];
      } else {
        return [userClr, '#FFFFFF'];
      }
    }
  };

  const leftLabel = leftLabelData();
  const middleLabel = middleLabelData();
  const rightLabel = rightLabelData();

  return { leftLabel, middleLabel, rightLabel };
};

// Get and set the discount amount so it can be accessed from different screens
// Just remember to clear the discounted price when redirecting out

// Needs to be an object or it will be immutable
const _discountedPriceVar = {
  discountedPrice: 0,
};

export function setDiscountedPrice(newPrice) {
  _discountedPriceVar.discountedPrice = newPrice;
}

export function getDiscountedPrice() {
  return _discountedPriceVar.discountedPrice;
}

export function clearDiscountedPrice() {
  _discountedPriceVar.discountedPrice = 0;
}

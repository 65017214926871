import React, { createRef, useContext } from 'react';
import { useMediaQuery } from 'react-responsive';
import axios from 'axios';
import _ from 'lodash';
import { facetColor } from 'shared/const';
import { shortenName } from 'shared/helper/helper';
import { trackingAction, TRACKING_SPOTLIGHT } from 'shared/function/Tracking';
import useCloudinaryImageLoader from 'shared/hooks/useCloudinaryImageLoader';

// components/assets
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import { ButtonSolid, ButtonOutline } from 'shared/components/Button/Button';
import { ReactComponent as SearchIcon } from 'assets/home/search.svg';
import downloadIcon from 'assets/general/download-blue-icon.png';
import shareIcon from 'assets/general/share-icon.png';
import UserIcon from 'shared/components/UserIcon';
import './SpotlightCard.scss';
import { UserContext } from 'context/UserContext';

const SpotlightCard = ({ type, data, handleExplore, DaysAgo }) => {
  const ref = createRef(null);

  const bookId = data?.book_id;
  const chapterId = data?.chapter_id;
  const facetName = data?.facet_name;
  const daysAgo = data?.daysAgo;
  const cloudinaryUrl = data?.cloudinaryUrl;
  const spotlightStringId = data?.id;

  const relative = data?.relative;
  const childName = shortenName(relative?.name);
  const relativeId = relative?.id;

  const { user } = useContext(UserContext);

  const isImageLoaded = useCloudinaryImageLoader(cloudinaryUrl);

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const isTypeRecent = _.isEqual(type, 'recent');
  const testingAutomationId = isTypeRecent
    ? {
        card: 'recent-spotlight-card',
        explore: 'recent-explore',
        download: 'recent-download',
      }
    : {
        card: 'today-spotlight-card',
        explore: 'today-explore',
        download: 'today-download',
      };

  const downloadBtnImg = isMobileView ? shareIcon : downloadIcon;
  const downloadBtnText = isMobileView ? 'Share' : 'Download';

  const handleDownloadImage = url => {
    axios.get(url, { responseType: 'blob' }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${facetName}.png`);
      document.body.appendChild(link);
      link.click();
    });

    trackingAction({
      action: TRACKING_SPOTLIGHT.spotlight_explore,
      extraData: {
        book_id: bookId,
        chapter_id: chapterId,
        spotlight_string_id: spotlightStringId,
        relative_id: relativeId,
        user_id: user?.id,
      },
    });
  };

  return (
    <div
      className="spotlight-overall-card"
      onClick={handleExplore}
      data-test-id={testingAutomationId?.card}>
      <header>
        <div className={`header-user`}>
          {relative && <UserIcon relative={relative} />}
          {childName}
        </div>
        <span
          style={{
            borderColor: facetColor(facetName),
            color: facetColor(facetName),
          }}>
          {facetName}
        </span>
      </header>

      {isImageLoaded ? (
        <div className="spotlight-card-container">
          <img ref={ref} src={cloudinaryUrl} alt="Spotlight Card" />
        </div>
      ) : (
        <LoadingIndicator isLoaderOnly />
      )}

      <div>
        {DaysAgo && daysAgo !== 0 && (
          <div className="days-ago-wrapper">
            {daysAgo} day{daysAgo >= 2 ? 's' : ''} ago
          </div>
        )}
        <div
          className="spotlight-card-button"
          onClick={e => {
            e.stopPropagation();
          }}>
          <ButtonSolid
            onClick={() => handleExplore()}
            children={
              <>
                <SearchIcon className="icon" />
                Explore
              </>
            }
            data-test-id={testingAutomationId?.explore}
          />

          <ButtonOutline
            className="download-btn"
            onClick={e => {
              e.stopPropagation();
              handleDownloadImage(cloudinaryUrl);
            }}
            children={
              <>
                <img src={downloadBtnImg} alt="Download" />
                {downloadBtnText}
              </>
            }
            data-test-id={testingAutomationId?.download}
          />
        </div>
      </div>
    </div>
  );
};

SpotlightCard.defaultProps = {
  handleExplore: () => {},
  data: {},
};

export default SpotlightCard;

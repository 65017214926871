import React, { useState, useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { UserContext } from 'context/UserContext';
import { url } from 'config/urlConfig';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import { prices, CODE_TYPES } from 'shared/const';
import { calculateDayDifference } from 'shared/function/calculateDateDifference';
import { getCurrencyCodeSymbol } from 'shared/function/CountryUtils';
import {
  trackingAction,
  TRACKING_PURCHASES,
  GA_EVENT_CATOGARY,
  GA_PURCHASE_JOURNEY_TYPE,
} from 'shared/function/Tracking';
import { MANAGE_PREMIUM_TYPE } from './ManagePremiumUtils';
import { useDetectPreviousPath } from 'routes/SettingsModule/SettingModuleUtils';
import useProductList from 'routes/Checkout/useProductList';

// components/assets
import { ButtonSolid, ButtonText } from 'shared/components/Button/Button';
import { HeaderBack } from './ManagePremiumComponents';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import RedemptionCodePopUp from 'routes/Books/RedemptionCodePopUp';
import CodeRedemptionSuccessPopUp from 'routes/ManagePremiumAccess/CodeRedemptionSuccessPopUp';
import AddChildDisclaimerModal from './AddChildDisclaimerModal';
import addChildProfileWebImg from 'assets/settings/settings-add-child-profile-web.png';
import addChildProfileMobImg from 'assets/settings/settings-add-child-profile-mob.png';
import './AddChildProfile.scss';

const AddChildProfile = () => {
  const history = useHistory();
  const location = useLocation();

  const { user } = useContext(UserContext);

  const { isUserPremiumPermanent } = useUserPremiumAccess();

  const addChildPrice = prices?.addChild;

  const { previousPath } = useDetectPreviousPath({
    location,
  });

  const { selectedProduct, isProductLoading, getProductList } = useProductList({
    type: MANAGE_PREMIUM_TYPE.add_child_profile,
  });

  const [isRedemptionCodeOpen, setIsRedemptionCodeOpen] = useState(false);
  const [isRedemptionSuccess, setIsRedemptionSuccess] = useState(false);
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);
  const [productList, setProductList] = useState([]);

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const addChildProfileImg = isMobileView
    ? addChildProfileMobImg
    : addChildProfileWebImg;

  const currency = localStorage.getItem('countryCurrency')
    ? localStorage.getItem('countryCurrency')
    : 'SGD';

  const productCurrency = currency.toLowerCase();
  const currencyCodeSymbol = getCurrencyCodeSymbol(currency);

  const init = async () => {
    try {
      const data = await getProductList();
      setProductList(data);
    } catch (err) {
      console.log('debug_kkh:: something went wrong when loading the products');
    }
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckoutChildProfile = () => {
    const diffInDays = calculateDayDifference(
      user?.premium_start_date,
      user?.premium_end_date,
    );

    const isSubscriptionLessThan30Days = diffInDays < 30;

    if (!isUserPremiumPermanent && isSubscriptionLessThan30Days) {
      setIsDisclaimerOpen(true);
    } else {
      getProductList();

      trackingAction({
        category: GA_EVENT_CATOGARY.ecommerce,
        action: TRACKING_PURCHASES.checkout_child_profile,
        ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.add_to_cart,
        ecommerceEventData: { productData: selectedProduct },
        extraData: {
          user_id: user?.id,
        },
      });

      history.push(`/checkout?addchild=true&from=${previousPath}`);
    }
  };

  const handleRedeemCode = () => {
    setIsRedemptionCodeOpen(true);
  };

  const handleBack = () => {
    history.push(`/settings?from=${previousPath}`);
  };

  if (isRedemptionSuccess) {
    return (
      <CodeRedemptionSuccessPopUp
        onPressNext={() => {
          history.push(`${url.ADD_CHILD_TEST_PROFILE}`);
        }}
      />
    );
  }

  const renderItemPrice = () => {
    const product = productList.find(item => item.type === 'PLAYBOOK');

    if (product) {
      const { price } = product;

      const defaultProductPrice = price.find(price => price.currency === 'SGD');
      const selectedProductPrice = price.find(
        price => price.currency === productCurrency.toUpperCase(),
      );
      const productPrice = selectedProductPrice
        ? selectedProductPrice
        : defaultProductPrice;

      if (productPrice) {
        const { amount } = productPrice;
        return `${currencyCodeSymbol}${amount.toFixed(2)}/child`;
      }
    }

    return `$${addChildPrice}/child`;
  };

  if (isProductLoading || productList.length < 1) {
    return <LoadingIndicator />;
  }

  return (
    <div id="add-child-screen">
      {isMobileView && (
        <HeaderBack
          title={'Settings'}
          history={history}
          location={location}
          handleClick={handleBack}
        />
      )}

      <h2 className="add-child-header">Add a child profile</h2>

      <div className="add-child-outer-container">
        <div
          className="add-child-inner-container"
          onClick={e => e.stopPropagation()}>
          <div className="add-child-popup-container">
            <div className="add-child-popup-content-container">
              <img src={addChildProfileImg} alt="Add Child Profile" />
            </div>
          </div>

          <div className="add-child-sub-content-container">
            <p>
              Build a new profile for 1 additional child. This is a one-time
              add-on to your Premium access.
              <br />
              <br />
              Note: Any additional profiles can only be accessed while your
              Premium access is active.
            </p>

            <h2 className="add-child-price-subtitle">
              {renderItemPrice()}
              <div className="subtitle">(One-time purchase)</div>
            </h2>

            <div className="add-child-modal-button-container">
              <ButtonSolid
                className="add-child-button"
                onClick={() => handleCheckoutChildProfile()}
                children="Checkout"
              />

              <div className="add-child-divider-container">
                <div className="add-child-divider" />
                or
                <div className="add-child-divider" />
              </div>

              <ButtonText
                className="redeem-code-button"
                children="Redeem code"
                onClick={handleRedeemCode}
              />
            </div>
          </div>
        </div>
      </div>

      <RedemptionCodePopUp
        type={CODE_TYPES.CHILD}
        setIsOpen={setIsRedemptionCodeOpen}
        isOpen={isRedemptionCodeOpen}
        onSuccess={() => setIsRedemptionSuccess(true)}
      />

      {isDisclaimerOpen && (
        <AddChildDisclaimerModal
          history={history}
          previousPath={previousPath}
          setIsOpen={setIsDisclaimerOpen}
        />
      )}
    </div>
  );
};

AddChildProfile.defaultProps = {
  handleCheckout: () => {},
  handleRedeemCode: () => {},
  handleBack: () => {},
};
export default AddChildProfile;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./IOSTimePicker.scss";

const hours = Array.from({ length: 12 }, (_, i) => ({
  value: i + 1,
  label: i + 1,
}));
const minutes = [
  { value: 0, label: "00" },
  { value: 15, label: "15" },
  { value: 30, label: "30" },
  { value: 45, label: "45" },
];
const ampm = [
  { value: "am", label: "am" },
  { value: "pm", label: "pm" },
];

const IOSTimePicker = ({ selectedTime, setSelectedTime }) => {
  const optionGroups = {
    hour: hours,
    minute: minutes,
    ampm: ampm,
  };

  const handleChange = (name, value) => {
    setSelectedTime((prevValueGroups) => ({
      ...prevValueGroups,
      [name]: value,
    }));
  };

  return (
    <ScrollablePicker
      optionGroups={optionGroups}
      valueGroups={selectedTime}
      onChange={handleChange}
    />
  );
};

const ScrollablePicker = ({
  optionGroups,
  valueGroups,
  onChange,
  itemHeight,
  height,
}) => {
  const renderInner = () => {
    const highlightStyle = {
      height: itemHeight,
      marginTop: -(itemHeight / 2),
    };
    const columnNodes = Object.keys(optionGroups).map((name, idx) => (
      <>
        <PickerColumn
          key={name}
          name={name}
          options={optionGroups[name]}
          value={valueGroups[name]}
          itemHeight={itemHeight}
          columnHeight={height}
          onChange={onChange}
        />
        {idx === 0 && <p className="picker-icon">:</p>}
      </>
    ));
    return (
      <div className="picker-inner">
        <div className="picker-highlight" style={highlightStyle}></div>
        {columnNodes}
      </div>
    );
  };

  const containerStyle = {
    height: height,
  };

  return (
    <div className="picker-container" style={containerStyle}>
      {renderInner()}
    </div>
  );
};

const PickerColumn = ({
  options,
  name,
  value,
  itemHeight,
  columnHeight,
  onChange,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startDragY, setStartDragY] = useState(0);
  const [startScrollerTranslate, setStartScrollerTranslate] = useState(0);
  const [scrollerTranslate, setScrollerTranslate] = useState(0);
  const minTranslate =
    columnHeight / 2 - itemHeight * options.length + itemHeight / 2;
  const maxTranslate = columnHeight / 2 - itemHeight / 2;

  useEffect(() => {
    const selectedIndex = options.findIndex((option) => option.value === value);
    if (selectedIndex < 0) {
      console.warn(
        `Warning: "${name}" doesn't contain an option of "${value}".`
      );
      handleValueSelected(options[0].value);
    } else {
      setScrollerTranslate(
        columnHeight / 2 - itemHeight / 2 - selectedIndex * itemHeight
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, name, value, itemHeight, columnHeight]);

  const handleValueSelected = (newValue) => {
    onChange(name, newValue);
  };

  const handleDragStart = (event) => {
    event.preventDefault();
    const touchY = event.pageY || event.touches[0].pageY;
    setStartDragY(touchY);
    setStartScrollerTranslate(scrollerTranslate);
    setIsDragging(true);
  };

  const handleDragMove = (event) => {
    event.preventDefault();
    if (!isDragging) {
      return;
    }
    const touchY = event.pageY || event.touches[0].pageY;
    const dragDelta = touchY - startDragY;
    let nextScrollerTranslate = startScrollerTranslate + dragDelta;
    if (nextScrollerTranslate < minTranslate) {
      nextScrollerTranslate = minTranslate;
    } else if (nextScrollerTranslate > maxTranslate) {
      nextScrollerTranslate = maxTranslate;
    }
    setScrollerTranslate(nextScrollerTranslate);
  };

  const handleDragEnd = () => {
    if (!isDragging) {
      return;
    }
    setIsDragging(false);
    setTimeout(() => {
      let activeIndex;
      if (scrollerTranslate > maxTranslate) {
        activeIndex = 0;
      } else if (scrollerTranslate < minTranslate) {
        activeIndex = options.length - 1;
      } else {
        activeIndex = -Math.floor(
          (scrollerTranslate - maxTranslate) / itemHeight
        );
      }
      handleValueSelected(options[activeIndex].value);
    }, 0);
  };

  const handleDragCancel = () => {
    if (!isDragging) {
      return;
    }
    setIsDragging(false);
    setScrollerTranslate(startScrollerTranslate);
  };

  const handleItemClick = (option) => {
    if (option !== value) {
      handleValueSelected(option);
    }
  };

  const renderItems = () => {
    return options.map((option, index) => (
      <div
        key={index}
        className={`picker-item${
          option.value === value ? " picker-item-selected" : ""
        }`}
        style={{ height: `${itemHeight}px`, lineHeight: `${itemHeight}px` }}
        onClick={() => handleItemClick(option.value)}
      >
        {option.label}
      </div>
    ));
  };

  const translateString = `translate3d(0, ${scrollerTranslate}px, 0)`;
  const style = {
    MsTransform: translateString,
    MozTransform: translateString,
    OTransform: translateString,
    WebkitTransform: translateString,
    transform: translateString,
  };
  if (isDragging) {
    style.transitionDuration = "0ms";
  }

  // onMouse functions make touch events work on a desktop browser
  // onTouch functions are responsible for handling touch events on mobile devices
  return (
    <div className="picker-column">
      <div
        className="picker-scroller"
        style={style}
        onMouseDown={handleDragStart}
        onMouseMove={handleDragMove}
        onMouseUp={handleDragEnd}
        onMouseLeave={handleDragCancel}
        onTouchStart={handleDragStart}
        onTouchMove={handleDragMove}
        onTouchEnd={handleDragEnd}
        onTouchCancel={handleDragCancel}
      >
        {renderItems()}
      </div>
    </div>
  );
};

ScrollablePicker.propTypes = {
  optionGroups: PropTypes.object.isRequired,
  valueGroups: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  itemHeight: PropTypes.number,
  height: PropTypes.number,
};

ScrollablePicker.defaultProps = {
  itemHeight: 36,
  height: 216,
};

PickerColumn.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  itemHeight: PropTypes.number.isRequired,
  columnHeight: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IOSTimePicker;

import { DebounceInput } from 'react-debounce-input';
import loginBgImage from 'assets/auth/login-background-image.png';
import signupBgImage from 'assets/auth/signup-background-image.png';
import connectedLogoImage from 'assets/logo-main.png';
import { ReactComponent as ArrowLeftIcon } from 'assets/auth/arrow-left-black-icon.svg';
import { ReactComponent as CheckedIcon } from 'assets/general/checked-plain-icon-code.svg';
import { ErrorOutline } from '@material-ui/icons';

export const Background = ({ type, ...otherProps }) => {
  return (
    <div
      className={`left-content-space ${type === 'signup' && 'signup'}`}
      {...otherProps}>
      {type === 'login' ? (
        <img src={loginBgImage} alt="" />
      ) : (
        <img src={signupBgImage} alt="" />
      )}
    </div>
  );
};

export const FormHeader = ({
  type,
  title,
  desc,
  backButton,
  backButtonClick,
  ...otherProps
}) => {
  return (
    <div className="form-header" {...otherProps}>
      <img
        src={connectedLogoImage}
        alt="Connected"
        onClick={() => window.open('https://www.getconnected.sg/', '_blank')}
      />

      <div
        className={`form-title ${!backButton && 'center'} ${
          type === 'verification' && 'verification-screen'
        }`}>
        {backButton && (
          <ArrowLeftIcon className="back-btn" onClick={backButtonClick} />
        )}
        <h1>{title}</h1>
      </div>

      <p>{desc}</p>
    </div>
  );
};

export const FormInput = ({
  handleChange,
  isPassword,
  isError,
  error,
  ...otherProps
}) => {
  return (
    <div className="form-group">
      <input
        className={`${isError ? 'error' : ''}`}
        onChange={handleChange}
        onKeyUp={handleChange}
        {...otherProps}
      />

      {isError && (
        <ErrorOutline
          className={`input-error-icon ${isPassword && 'password'}`}
        />
      )}

      {isError && <p className="input-error">{error}</p>}
    </div>
  );
};

export const FormError = ({ message, isSuccess, ...otherProps }) => {
  if (!message) return null;

  return (
    <>
      {isSuccess ? (
        <div
          className={`error-container ${isSuccess && 'valid'}`}
          {...otherProps}>
          <CheckedIcon className="checked-icon" fill="#FFFFFF" />
          <p className="error-message">{message}</p>
        </div>
      ) : (
        <div className="error-container" {...otherProps}>
          <p className="error-message">{message}</p>
        </div>
      )}
    </>
  );
};

export const FormFooter = ({
  text,
  className,
  buttonText,
  buttonClick,
  buttonDisabled,
  testAutoId,
  ...otherProps
}) => {
  return (
    <div
      className={`form-footer ${className ? className : ''}`}
      {...otherProps}>
      <p>{text}</p>

      <button
        type="button"
        onClick={buttonClick}
        disabled={buttonDisabled}
        data-test-id={testAutoId}>
        {buttonText}
      </button>
    </div>
  );
};

export const TermsConditions = () => {
  return (
    <p className="terms-container">
      By clicking Sign up, you agree to our{' '}
      <a href="/terms" target="_blank">
        Terms and Conditions
      </a>{' '}
      and{' '}
      <a href="/privacy" target="_blank">
        Privacy Statement
      </a>
    </p>
  );
};

export const FormCode = ({
  code,
  handleChange,
  isSuccess,
  message,
  ...otherProps
}) => {
  return (
    <div className="form-code">
      <div className="code-content">
        <div className="code-divider"></div>
        <div className="code-text">Have a code?</div>
        <div className="code-divider"></div>
      </div>

      <div className="form-group">
        <DebounceInput
          className={`${code ? (!isSuccess ? 'error' : 'success') : ''}`}
          minLength={0}
          debounceTimeout={800}
          type="text"
          name="code"
          placeholder="Enter code"
          onChange={handleChange}
          {...otherProps}
        />

        {message && (
          <p className={isSuccess ? 'input-success' : 'input-error'}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

import React from 'react'
import { withRouter } from 'react-router-dom'

const LandingScreen = ({ history, logo }) => (
    <div className='right-content landing'>
        <img src={logo} alt='' />
        <button onClick={() => history.push('/profile-test')}>Take the test</button>
        <button onClick={() => history.push('/login')}>I already have an account</button>
    </div>
)

export default withRouter(LandingScreen)
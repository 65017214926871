import React from 'react';
import { ButtonSolid } from 'shared/components/Button/Button';
import ConfirmImage from 'assets/products/order-completed.png';
import './CodeRedemptionSuccessPopUp.scss';

function CodeRedemptionSuccessPopUp({ onPressNext }) {
  return (
    <div id="code-redemption-success">
      <div className="layout">
        <div className="container">
          <img src={ConfirmImage} alt="complete" />
          <h2 className="title">Code redeemed successfully!</h2>
          <ButtonSolid onClick={onPressNext} children="Next" />
        </div>
      </div>
    </div>
  );
}

CodeRedemptionSuccessPopUp.defaultProps = {
  onPressNext: () => {},
};

export default CodeRedemptionSuccessPopUp;

import { url } from './urlConfig';
import PretestOnboarding from 'routes/ProfileTest/Profile/PretestOnboarding';
import ProfileTest from 'routes/ProfileTest/Profile';
import LoadingScreen from 'routes/ProfileTest/Profile/LoadingScreen';
import Test from 'routes/ProfileTest/Test/Test';
import Welcome from 'routes/ProfileTest/Test/Welcome';
import Spotlight from 'routes/Home/Spotlight';
import PeaksSnapshot from 'routes/Books/PeaksSnapshot/PeaksSnapshot';
import BookSection from 'routes/Books/BookSection/BookSection';
import BookChapter from 'routes/Books/BookChapter/BookChapter';
import ChapterModule from 'routes/Books/ChapterModule';
import Topics from 'routes/Topics/Topics';
import TopicsTopicalHub from 'routes/Topics/TopicsTopicalHub';
import TopicsTopicalBigIdea from 'routes/Topics/TopicsTopicalBigIdea';
import TopicsStub from 'routes/Topics/TopicsStub';
import TopicsStubBigIdea from 'routes/Topics/TopicsStubBigIdea';
import SettingsModule from 'routes/SettingsModule/SettingsModule';
import Mailbox from 'routes/SettingsModule/components/Mailbox';
import ManagePremiumAccess from 'routes/ManagePremiumAccess/ManagePremiumAccess';
import UpgradePremium from 'routes/ManagePremiumAccess/UpgradePremium';
import AddChildProfile from 'routes/ManagePremiumAccess/AddChildProfile';
import WaitingScreen from 'routes/WaitingScreen/WaitingScreen';
import NotFound from 'routes/NotFound';

export const protectedRoutes = [
  {
    path: ['/profile-test', '/profile-test-child'],
    component: ProfileTest,
  },
  { path: '/test/:types', component: Test },
  {
    path: '/pretest-onboarding',
    component: PretestOnboarding,
  },
  {
    path: '/test-loading',
    component: LoadingScreen,
  },
  {
    path: '/welcome',
    component: Welcome,
  },
  { path: `${url.SETTINGS}`, component: SettingsModule },
  { path: `${url.SETTINGS}/:settingId`, component: SettingsModule },
  { path: '/mailbox', component: Mailbox },
  { path: `${url.HOME}`, component: Spotlight, exact: true },
  { path: `${url.PLAYBOOKSECTION}`, component: BookSection, exact: true },
  {
    path: `${url.PLAYBOOK}/:domainSlug/chapter`,
    component: BookChapter,
  },
  {
    path: `${url.PLAYBOOK}/:domainSlug/:chapterSlug`,
    component: ChapterModule,
  },
  { path: `${url.PLAYBOOKSNAPSHOT}`, component: PeaksSnapshot },
  { path: `${url.TOPICS}`, component: Topics, exact: true },
  {
    path: `${url.TOPICSHUB}/:topicSlug`,
    component: TopicsTopicalHub,
    exact: true,
  },
  { path: `${url.TOPICSBIGIDEA}/:topicSlug`, component: TopicsTopicalBigIdea },
  {
    path: `${url.TOPICSHUB}/:topicSlug/page/:stubSlug`,
    component: TopicsStub,
    exact: true,
  },
  {
    path: `${url.TOPICSHUB}/:topicSlug/page/:stubSlug/big-idea/:stubBigIdeaId`,
    component: TopicsStubBigIdea,
  },
  { path: '/shop', component: ManagePremiumAccess },
  { path: '/upgrade-premium', component: UpgradePremium },
  { path: '/add-child-profile', component: AddChildProfile },
  { path: '/lobby-area/:errortypes', component: WaitingScreen },
  { component: NotFound },
];

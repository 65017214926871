import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { facetColor } from 'shared/const';
import { ReactComponent as ArrowRightIcon } from 'assets/home/arrow_right.svg';

const Card = ({ tips, selectedTips, setSelectedTips, facet }) => {
  const { title, description, category, is_saved } = tips.body;
  const [isSaved, setIsSaved] = useState(is_saved);
  const labelColor = facetColor(facet);

  const isDomainPurpose =
    facet === 'Preparedness' ||
    facet === 'Organisation' ||
    facet === 'Dutifulness' ||
    facet === 'Success Driven' ||
    facet === 'Focus';

  const selectTips = () => {
    const tempTips = { ...tips };
    tempTips.body.is_saved = isSaved;
    setSelectedTips(tempTips);
  };

  const readTips = () => {
    selectTips();
  };

  useEffect(() => {
    if (selectedTips && selectedTips.body.id === tips.body.id) {
      const { is_saved } = selectedTips.body;
      setIsSaved(is_saved);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTips]);

  return (
    <>
      <div className={`single-card `} onClick={readTips}>
        <div className="top-content">
          <div className="card-header">
            <div className="left">
              <p>{title}</p>
            </div>
          </div>
          <div className="card-content">{description}</div>
        </div>

        <div
          className="footer-content"
          style={{
            backgroundColor: isDomainPurpose ? '#fee0ca' : `${labelColor}45`,
          }}>
          {category}

          <div className="read-more-container">
            Read More <ArrowRightIcon className="read-more-icon" />
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Card);

import React, { useState, createContext } from 'react';

export const NoAccessContext = createContext([]);

export const NoAccessProvider = ({ children }) => {
  const [noAccessOpen, setNoAccessOpen] = useState(false);

  const value = {
    noAccessOpen,
    setNoAccessOpen,
  };

  return (
    <NoAccessContext.Provider value={value}>
      {children}
    </NoAccessContext.Provider>
  );
};

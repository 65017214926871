import axios from 'axios';

export const getSpotlightString = () => axios.get('/spotlight-string');

export const getRecentSpotlightString = () =>
  axios.get('/spotlight-string/recents');

export const getInfiniteSpotlightString = ({ queryParams }) =>
  axios.get(`/spotlight-string/infinite`, {
    params: { ...queryParams },
  });

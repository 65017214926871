import usePayNowPayment from './usePayNowPayment';
import './PayNowForm.scss';

const PayNowForm = ({
  paymentData,
  setPaymentMethod,
  discountCode,
  isDiscountCodeValid,
  discountInfo,
}) => {
  const { showPayNowScreen } = usePayNowPayment({
    paymentData,
    discountCode,
    isDiscountCodeValid,
    discountInfo,
  });

  if (showPayNowScreen) {
    return showPayNowScreen;
  } else {
    setPaymentMethod('');
  }

  return null;
};

export default PayNowForm;

import React from "react";
import Head from "shared/components/Head.js";
import { Background } from "./AuthComponents";
import LandingScreen from "routes/Auth/LandingScreen";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import SignupCheckout from "./SignupCheckout";
import SignupVerification from "./SignupVerification";
import Signup from "./Signup";
import logoImage from "assets/logo-with-text.png";
import "./Auth.scss";

const Auth = ({ match }) => {
  const pageContent = getPageContent(match.path);
  const screenType = getScreenType(match.path);

  return (
    <div
      id="auth-screen"
      className={`${match.path === "/sign-up" && "signup"}`}
    >
      <Head
        title="Connected"
        description="Connected is a first-of-its-kind digital playbook for parents. Providing contextualised insights into your child's personality-driven behaviour. Bringing out the best in one another through understanding."
      />

      <div
        className={`auth-outer-container ${match.path === "/sign-up" && "signup"
          }`}
      >
        <div className="auth-inner-container">
          <Background type={screenType} />
          {pageContent}
        </div>
      </div>
    </div>
  );
};

const getPageContent = (path) => {
  switch (path) {
    case "/":
      return <LandingScreen logo={logoImage} />;
    case "/login":
      return <Login />;
    case "/forgot-password":
      return <ForgotPassword />;
    case "/reset-password":
      return <ResetPassword />;
    case "/signup":
      return <SignupCheckout />;
    case "/signup-verification":
      return <SignupVerification />;
    case "/sign-up":
      return <Signup />;
    default:
      return null;
  }
};

const getScreenType = (path) => {
  if (
    path === "/login" ||
    path === "/forgot-password" ||
    path === "/reset-password"
  ) {
    return "login";
  }

  return "signup";
};

export default Auth;

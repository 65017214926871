import { useState, useContext, useEffect } from 'react';
import { UserContext } from 'context/UserContext';
import _ from 'lodash';
import { calculateDayDifference } from 'shared/function/calculateDateDifference';

const useUserPremiumAccess = () => {
  const { user } = useContext(UserContext);

  const [isUserPremiumAccess, setIsUserPremiumAccess] = useState(false);
  const [isUserPremiumExpired, setIsUserPremiumExpired] = useState(false);

  const isUserDemo = _.isEqual(user.is_demo, 1) && _.isEqual(user.is_legacy, 0);
  const isUserPremiumPermanent = _.isEqual(user.is_legacy, 1);

  const userPremiumStartDate = user?.premium_start_date;
  const userPremiumEndDate = user?.premium_end_date;

  const diffInDays = calculateDayDifference(
    userPremiumStartDate,
    userPremiumEndDate,
  );

  const userPremiumRemainingDays = calculateDayDifference(
    new Date(),
    userPremiumEndDate,
  );

  useEffect(() => {
    let isUserPremiumAccess = false;

    // Check if the user has permanent premium access
    if (isUserPremiumPermanent) {
      isUserPremiumAccess = true;
    } else {
      // Check if current date is between the premium start and end dates
      if (!isUserDemo && diffInDays >= 0) {
        isUserPremiumAccess = true;
      }
    }

    // Check if the user's premium access has expired
    const isUserPremiumExpired = isUserDemo && !isUserPremiumPermanent;

    if (isUserPremiumAccess) {
      setIsUserPremiumAccess(isUserPremiumAccess);
    }

    if (isUserPremiumExpired) {
      setIsUserPremiumExpired(isUserPremiumExpired);
    }
  }, [isUserDemo, isUserPremiumPermanent, diffInDays]);

  return {
    isUserDemo,
    isUserPremiumAccess,
    isUserPremiumPermanent,
    isUserPremiumExpired,
    userPremiumRemainingDays,
    userPremiumEndDate,
  };
};

export default useUserPremiumAccess;

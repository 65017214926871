import { url } from 'config/urlConfig';
import { ButtonSolid, ButtonText } from 'shared/components/Button/Button';
import './TestComponents.scss';

export const EndTestPrompt = ({
  history,
  type,
  isAddChild,
  setTempUser,
  setShowEndTestPopup,
}) => {
  const handleProceed = () => {
    setShowEndTestPopup(false);
  };

  const handleExit = () => {
    setShowEndTestPopup(false);

    if (type === 'parent' || type === 'parent-result') {
      setTempUser({});
      history.push(`${url.PARENT_TEST_PROFILE}`);
    } else {
      if (isAddChild) {
        history.push(`${url.ADD_CHILD_TEST_PROFILE}`);
      } else {
        history.push(`${url.CHILD_TEST_PROFILE}`);
      }
    }
  };

  return (
    <div className="end-popup-overlay">
      <div className="end-popup-container">
        <div className="end-popup-content">
          <h1>Your profile is not complete:</h1>
          <br />
          <div>Test that have not been completed will not be saved.</div>
          <br />
          <br />
        </div>
        <div className="end-popup-buttons">
          <ButtonSolid
            className="proceed-btn"
            onClick={() => handleProceed()}
            children="Continue"
          />
          <ButtonText
            className="exit-btn"
            onClick={() => handleExit()}
            children="Exit profile creation"
          />
        </div>
      </div>
    </div>
  );
};

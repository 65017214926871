export const url = {
  HOME: '/home',
  PLAYBOOK: '/playbook',
  PLAYBOOKSECTION: '/playbook/section',
  PLAYBOOKSNAPSHOT: '/playbook/snapshot',
  TOPICS: '/topics',
  TOPICSHUB: '/topics/hub',
  TOPICSBIGIDEA: '/topics/topical-big-idea',
  SETTINGS: '/settings',
  UPGRADE_PREMIUM: '/settings/upgrade-premium',
  ADD_CHILD: '/settings/add-child-profile',
  ADD_CHILD_TEST_PROFILE: '/profile-test-child?addchild=true',
  PARENT_TEST_PROFILE: '/profile-test',
  CHILD_TEST_PROFILE: '/profile-test-child',
};

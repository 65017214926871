import { url } from 'config/urlConfig';
import {
  trackingAction,
  TRACKING_GENERAL,
  TRACKING_PURCHASES,
  GA_EVENT_CATOGARY,
  GA_PURCHASE_JOURNEY_TYPE,
} from 'shared/function/Tracking';
import { useDetectPreviousPath } from 'routes/SettingsModule/SettingModuleUtils';
import { MANAGE_PREMIUM_TYPE } from 'routes/ManagePremiumAccess/ManagePremiumUtils';
import useProductList from 'routes/Checkout/useProductList';
import Popup from 'shared/components/Popup/Popup';
import { ReactComponent as ChildProfileIcon } from 'assets/manage-premium/child-profile-icon.svg';
import './EmptyChildAlertPopup.scss';
import { useContext } from 'react';
import { UserContext } from 'context/UserContext';

const EmptyChildAlertPopup = ({ history, location, setIsOpen }) => {
  const { user } = useContext(UserContext);
  const path = location?.pathname;
  const currentPath = path?.substring(1);

  const isPathSetting = currentPath.includes('settings');

  const { previousPath } = useDetectPreviousPath({
    location,
  });

  const { selectedProduct } = useProductList({
    type: MANAGE_PREMIUM_TYPE.add_child_profile,
  });

  const handleBuyChild = () => {
    if (!isPathSetting) {
      trackingAction({
        category: GA_EVENT_CATOGARY.ecommerce,
        action: TRACKING_GENERAL.navigate_settings,
        ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.session_start,
        ecommerceEventData: { productData: selectedProduct },
        extraData: {
          user_id: user?.id,
        },
      });
    }

    trackingAction({
      category: GA_EVENT_CATOGARY.ecommerce,
      action: TRACKING_PURCHASES.view_child_profile,
      ecommerceEventType: GA_PURCHASE_JOURNEY_TYPE.view_item,
      ecommerceEventData: { productData: selectedProduct },
      extraData: {
        user_id: user?.id,
      },
    });

    history.push(
      `${url.ADD_CHILD}?from=${isPathSetting ? previousPath : currentPath}`,
    );
    setIsOpen(false);
  };

  const handleAddProfile = () => {
    history.push(`${url.ADD_CHILD_TEST_PROFILE}`);
  };

  const handleClose = e => {
    e.stopPropagation(); // Prevent the click event from bubbling up
    setIsOpen(false);
  };

  return (
    <Popup
      className="empty-child-alert-popup"
      icon={
        <ChildProfileIcon
          style={{
            width: '150px',
            height: '100%',
          }}
        />
      }
      header="Wait! Your child&rsquo;s Playbook is incomplete."
      description="Before you buy another Playbook, consider setting up your existing
          one. Return to your child&rsquo;s profile to do so."
      callToActions={[
        {
          type: 'solid',
          children: 'Buy',
          onClick: handleBuyChild,
        },
        {
          type: 'outline',
          children: 'Profile',
          onClick: handleAddProfile,
        },
      ]}
      setShowModal={setIsOpen}
      handleCloseCondition={handleClose}
    />
  );
};

export default EmptyChildAlertPopup;

import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { UserContext } from 'context/UserContext';
import { shortenName } from 'shared/helper/helper';
import { getDomainName } from 'shared/const';
import { trackingAction, TRACKING_SPOTLIGHT } from 'shared/function/Tracking';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import useInfiniteScroll from 'shared/hooks/useInfiniteScroll';
import useScrollDetector from 'shared/hooks/useScrollDetector';
import useChildPicker from 'shared/hooks/useChildPicker';
import { getInfiniteSpotlightString } from './spotlightUtils';
import {
  setReferenceComponents,
  setViewPortReference,
} from 'shared/components/OnboardingTourV1.js/OnboardingTourUtils';
import { navigatePlaybooks } from 'routes/Books/BooksUtils';

// components/assets
import Head from 'shared/components/Head.js';
import BackToTopButton from 'shared/components/Button/BackToTopButton';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import LoadingSpinner from 'shared/components/Loading/LoadingSpinner';
import ChildPicker from 'shared/components/ChildPicker/ChildPicker';
import NoAccessModal from 'routes/ManagePremiumAccess/NoAccessModal';
import SpotlightCard from './SpotlightCard';
import { ReferralNotification } from 'routes/ReferralSystem/ReferralComponents';
import { ReactComponent as BellIcon } from 'assets/home/bell_icon.svg';
import { ReactComponent as EmptyIcon } from 'assets/home/spotlight-empty-icon.svg';
import EditIcon from 'assets/home/spotlight-edit-icon.svg';
import './Spotlight.scss';

const Spotlight = ({ history, location }) => {
  const path = location.pathname;

  const { user, setSelectedRelative } = useContext(UserContext);

  const { isUserPremiumAccess } = useUserPremiumAccess();

  const { isScrolledDown } = useScrollDetector();

  const { selectedChildOption, childPickerData } = useChildPicker();

  const childId = selectedChildOption?.id;
  const childName = shortenName(selectedChildOption?.name);

  const getInfiniteSpotlightData = async page => {
    try {
      const queryParams = {
        page,
        per_page: 7,
        relative_id: childId,
      };

      const res = await getInfiniteSpotlightString({ queryParams });

      const allItemData = res.data?.data;
      const itemsData = res?.data?.data?.data;

      const newCurrentPage = allItemData?.current_page;
      const newLastPage = allItemData?.last_page;

      return { res, itemsData, newCurrentPage, newLastPage };
    } catch (err) {
      console.log('🚀 ~ getInfiniteSpotlightData ~ err:', err?.response);
    }
  };

  const {
    items,
    initialLoad: initialInfiniteLoad,
    isLoading: isInfiniteLoading,
    setIsLoading: setIsInfiniteLoading,
    isLoadingMore: isInfiniteLoadingMore,
  } = useInfiniteScroll(getInfiniteSpotlightData);

  const dailySpotlight = items && items[0];
  const recentSpotlights = items?.slice(1);

  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(
    user?.send_spotlight === 0 && true,
  );
  const [showBackToTopBtn, setShowBackToTopBtn] = useState(false);
  const [noAccessOpen, setNoAccessOpen] = useState(false);

  const handleExploreSpotlight = ({ type, recentSpotlightItem }) => {
    const isDaily = _.isEqual(type, 'daily');
    const isRecent = _.isEqual(type, 'recent');

    const data =
      (isDaily && dailySpotlight) || (isRecent && recentSpotlightItem);

    const facetName = data?.facet_name;
    const domainCode = data?.code;
    const bookId = data?.book_id;
    const chapterId = data?.chapter_id;
    const relative = data?.relative;
    const domainName = getDomainName(domainCode?.charAt(0));
    const relativeId = data?.relative_id;
    const spotlightStringId = data?.id;

    if (isUserPremiumAccess || _.isEqual(facetName, 'Preparedness')) {
      navigatePlaybooks({
        navigate: history.push,
        type: 'snapshot',
        bookId,
        chapterId,
        domainSlug: domainName,
      });

      setSelectedRelative(relative);
      sessionStorage.setItem('selectedRelative', JSON.stringify(relative));

      trackingAction({
        action: TRACKING_SPOTLIGHT.spotlight_explore,
        extraData: {
          book_id: bookId,
          chapter_id: chapterId,
          spotlight_string_id: spotlightStringId,
          relative_id: relativeId,
          user_id: user?.id,
        },
      });
    } else {
      setNoAccessOpen(true);
    }
  };

  const handleNavigateManageSpotlight = () => {
    const data = dailySpotlight;

    const bookId = data?.book_id;
    const chapterId = data?.chapter_id;
    const relativeId = data?.relative_id;
    const spotlightStringId = data?.id;

    history.push(`/settings/spotlight?from=${path?.substring(1)}`);

    trackingAction({
      action: TRACKING_SPOTLIGHT.spotlight_manage,
      extraData: {
        book_id: bookId,
        chapter_id: chapterId,
        spotlight_string_id: spotlightStringId,
        relative_id: relativeId,
        user_id: user?.id,
      },
    });
  };

  useEffect(() => {
    if (selectedChildOption) {
      setIsLoading(true);

      getInfiniteSpotlightData().finally(() => {
        setIsLoading(false);
      });

      setIsInfiniteLoading(true);
      initialInfiniteLoad();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChildOption]);

  useEffect(() => {
    if (isScrolledDown) {
      setShowBackToTopBtn(true);
    } else {
      setShowBackToTopBtn(false);
    }
  }, [isScrolledDown]);

  if (isLoading || isInfiniteLoading) return <LoadingIndicator />;

  return (
    <div id="spotlight-page">
      <Head title="Connected App - Home" preventIndex />

      <div className="container">
        <div className="spotlight-header">
          <ChildPicker childPickerData={childPickerData} />
        </div>

        <ReferralNotification />

        <div
          className="column-container-wrapper"
          ref={ref => {
            if (ref) {
              const { top, height, width, left } = ref.getBoundingClientRect();
              setViewPortReference({ top, height, width, left });
            }
          }}>
          {!isLoading && (
            <div id="column-container" className="column-container">
              <div style={{ flexDirection: 'column' }}>
                <div id="anchor-spotlight-position" />

                <div id="home-spotlight-card" className="home-spotlight-card">
                  <div className="spotlight-block">
                    <div
                      className="column-header"
                      onClick={() => handleNavigateManageSpotlight()}>
                      <h1 className="spotlight-title">
                        Your feed with {[childName]}
                      </h1>

                      <img
                        className="edit-icon"
                        src={EditIcon}
                        alt="Edit"
                        data-test-id="settings-spotlight"
                      />
                    </div>

                    {/* Daily Spotlight */}
                    {dailySpotlight && (
                      <div
                        // style={{ ...spotlightImgSize }}
                        ref={ref => {
                          setReferenceComponents({
                            id: 'home-spotlight-card',
                            component: ref,
                          });
                        }}>
                        <SpotlightCard
                          type="today"
                          data={dailySpotlight}
                          handleExplore={() => {
                            handleExploreSpotlight({ type: 'daily' });
                          }}
                        />
                      </div>
                    )}

                    {showAlert && (
                      <div className="send-spotlight-alert">
                        <div
                          className="left"
                          onClick={() => {
                            history.push(
                              `/settings/spotlight?tab=delivery&from=${path?.substring(
                                1,
                              )}`,
                            );
                          }}>
                          <BellIcon width={12} height={12} />
                          <p>Send me this daily</p>
                        </div>
                        <div
                          className="right"
                          onClick={() => {
                            setShowAlert(false);
                          }}>
                          <p>Not Now</p>
                        </div>
                      </div>
                    )}

                    {/* Recent Spotlight */}
                    <>
                      <div className="recent-spotlight-container">
                        {recentSpotlights &&
                          recentSpotlights?.length > 0 &&
                          recentSpotlights?.map((item, key) => {
                            return (
                              <SpotlightCard
                                key={key}
                                type="recent"
                                data={item}
                                DaysAgo
                                handleExplore={() => {
                                  handleExploreSpotlight({
                                    type: 'recent',
                                    recentSpotlightItem: item,
                                  });
                                }}
                              />
                            );
                          })}

                        {recentSpotlights && recentSpotlights?.length === 0 && (
                          <div className="empty-spotlight-content">
                            <EmptyIcon className="empty-spotlight-icon" />
                            <h3 className="empty-spotlight-text">
                              Check back tomorrow to see your recent spotlights!
                            </h3>
                          </div>
                        )}
                      </div>

                      {isInfiniteLoadingMore && (
                        <LoadingSpinner position="center" isAnimation />
                      )}
                    </>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {showBackToTopBtn && (
          <div className="back-to-top-btn-wrapper">
            <BackToTopButton position="end" />
          </div>
        )}
      </div>

      {noAccessOpen && (
        <NoAccessModal
          history={history}
          isPreviewBtn={true}
          handleClose={() => setNoAccessOpen(false)}
        />
      )}
    </div>
  );
};

export default withRouter(Spotlight);

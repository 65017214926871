import React, { useState, createContext } from 'react';

export const ToastContext = createContext([]);

export const ToastProvider = ({ children }) => {
    let initialValue = {};

    const [toast, setToast] = useState(initialValue);

    const value = {
        toast, setToast
    }

    return (
        <ToastContext.Provider value={value}>
            {children}
        </ToastContext.Provider>
    )
}
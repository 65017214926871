import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const getDeeplinkChapter = ({ queryParams }) =>
  axios.get(`/deep-link-helper/chapter`, {
    params: { ...queryParams },
  });

const getDeeplinkTopic = ({ queryParams }) =>
  axios.get(`/deep-link-helper/topic`, {
    params: { ...queryParams },
  });

const getDeeplinkStub = ({ queryParams }) =>
  axios.get(`/deep-link-helper/stub`, {
    params: { ...queryParams },
  });

const DATATYPE = { chapter: 'chapter', topic: 'topic', stub: 'stub' };

const useTrackDeeplink = ({
  domainSlug,
  chapterSlug,
  topicSlug,
  stubSlug,
  childId,
}) => {
  const history = useHistory();

  const [chapterData, setChapterData] = useState(null);
  const [topicData, setTopicData] = useState(null);
  const [stubData, setStubData] = useState(null);

  const [dataType, setDataType] = useState(null);
  const [error, setError] = useState(null);

  const domainId = chapterData?.domain?.id;
  const chapterId = chapterData?.chapter?.id;
  const bookId = chapterData?.book?.id;
  const sectionId = chapterData?.section?.id;

  const topicId = stubData?.topic?.id || topicData?.id;
  const stubId = stubData?.stub?.id;

  const getData = () => {
    switch (dataType) {
      case DATATYPE.chapter:
        return { domainId, chapterId, bookId, sectionId, childId };
      case DATATYPE.topic:
        return { topicId };
      case DATATYPE.stub:
        return { topicId, stubId };
      default:
        return { topicId, stubId, domainId, chapterId, bookId, sectionId };
    }
  };

  const getDeeplinkChapterData = async () => {
    try {
      // Skip fetching data if there is no childId
      if (!childId) {
        return;
      }

      const queryParams = {
        domain: domainSlug,
        chapter: chapterSlug,
        child_id: childId,
      };

      const res = await getDeeplinkChapter({ queryParams });

      if (res) {
        const chapterData = res?.data?.data;
        setChapterData(chapterData);
      }
    } catch (err) {
      console.log('🚀 ~ getDeeplinkChapterData ~ err:', err?.response);

      if (err) {
        const errorMessage = err?.response?.data?.message;

        setError({
          type: DATATYPE.chapter,
          message: errorMessage,
        });
      }
    }
  };

  const getDeeplinkTopicData = async () => {
    try {
      const queryParams = { topic: topicSlug };
      const res = await getDeeplinkTopic({ queryParams });
      const topicData = res?.data?.data;

      if (res) setTopicData(topicData);
    } catch (err) {
      console.log('🚀 ~ getDeeplinkTopicData ~ err:', err?.response);

      if (err) {
        const errorMessage = err?.response?.data?.message;

        setError({
          type: DATATYPE.topic,
          message: errorMessage,
        });
      }
    }
  };

  const getDeeplinkStubData = async () => {
    // Skip fetching data if there is no childId
    if (!childId) {
      return;
    }

    try {
      const queryParams = {
        topic: topicSlug,
        stub: stubSlug,
        child_id: childId,
      };
      const res = await getDeeplinkStub({ queryParams });
      const stubData = res?.data?.data;

      if (res) setStubData(stubData);
    } catch (err) {
      console.log('🚀 ~ getDeeplinkStubData ~ err:', err?.response);

      if (err) {
        const errorMessage = err?.response?.data?.message;

        setError({
          type: DATATYPE.stub,
          message: errorMessage,
        });
      }
    }
  };

  useEffect(() => {
    if (topicSlug) {
      if (stubSlug) {
        getDeeplinkStubData();
        setDataType(DATATYPE.stub);
      } else {
        getDeeplinkTopicData();
        setDataType(DATATYPE.topic);
      }

      if (!topicId) {
        getDeeplinkTopicData();
      } else if ((!topicId && !stubId) || !childId) {
        getDeeplinkStubData();
      }
    } else {
      if (
        domainSlug &&
        chapterSlug &&
        (!childId || !domainId || !chapterId || !bookId || !sectionId)
      ) {
        getDeeplinkChapterData();
        setDataType(DATATYPE.chapter);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    topicSlug,
    stubSlug,
    topicId,
    stubId,
    domainId,
    chapterId,
    bookId,
    sectionId,
    childId,
  ]);

  if (error) {
    if (error.type === DATATYPE.chapter) {
      history.push('/playbooks');
    }

    if (error.type === DATATYPE.topic || error.type === DATATYPE.stub) {
      history.push('/topics');
    }
  }

  return getData();
};

export default useTrackDeeplink;

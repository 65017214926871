import React, { useState, useContext, useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { UserContext } from 'context/UserContext';
import _ from 'lodash';
import { handleSettingBack } from '../SettingModuleUtils';
import useChildPicker from 'shared/hooks/useChildPicker';

// components/assets
import { ButtonSolid } from 'shared/components/Button/Button';
import UserSettings from './UserSettings';
import ChildSettings from './ChildSettings';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import { BackButton } from './SettingsComponent';
import UserIcon from 'shared/components/UserIcon';
import { ReactComponent as ArrowDownIcon } from 'assets/general/arrow-down-black-icon.svg';
import './Profiles.scss';

const Profiles = ({ history }) => {
  const location = useLocation();

  const { user } = useContext(UserContext);

  const { childBooks, updateChildBooks } = useChildPicker();

  const isMobile = useMediaQuery({ query: '(max-width: 992px)' });

  const isProfilesShow = user && childBooks?.length !== 0;

  const profiles = [user, ...childBooks];
  const updatedProfiles = profiles.map(profile => ({
    ...profile,
    isContentShow: false, // Add the new property with the desired initial value
  }));

  return (
    <div id="settings-profiles">
      <div className="user-form">
        {isMobile && (
          <BackButton
            title="Settings"
            handleClick={() =>
              handleSettingBack({ history, location, type: 'tab', isMobile })
            }
          />
        )}
        <h1 className="header">Manage Profiles</h1>

        {isProfilesShow ? (
          <ProfileTabs
            profiles={updatedProfiles}
            updateChildBooks={updateChildBooks}
          />
        ) : (
          <LoadingIndicator isLoaderOnly />
        )}
      </div>
    </div>
  );
};

const ProfileTabs = ({ profiles, updateChildBooks }) => {
  const [profileItems, setProfileItems] = useState(profiles);
  const [contentSuccessMessage, setContentSuccessMessage] = useState(null);
  const [contentErrorMessage, setContentErrorMessage] = useState(null);

  useEffect(() => {
    const profilesWithContentShow = profiles.map(profile => {
      const handleCompareProfile = profileItems.find(
        data => data.id === profile.id,
      );
      return {
        ...profile,
        isContentShow: handleCompareProfile.isContentShow,
      };
    });

    setProfileItems(profilesWithContentShow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profiles]);

  const handleShowTabContent = ({ item }) => {
    const updatedProfiles = profileItems.map(profile => {
      if (profile.id === item.id) {
        return { ...profile, isContentShow: !profile.isContentShow };
      } else if (profile.isContentShow) {
        return { ...profile, isContentShow: false };
      }
      return profile;
    });

    setProfileItems(updatedProfiles);
    setContentSuccessMessage(null);
    setContentErrorMessage(null);
  };

  return (
    <div className="settings-tab-group">
      {profileItems?.map((item, idx) => {
        const isShowTabContent = _.isEqual(item?.isContentShow, true);
        const isParentTab = _.isEqual(idx, 0);
        const name = isParentTab ? 'You' : item?.name;

        const itemId = item?.id;

        return (
          <ButtonSolid
            key={itemId}
            className={`settings-tab-btn ${isShowTabContent && 'active'}`}
            children={
              <>
                <div
                  className={`settings-tab-item ${
                    isShowTabContent && 'active'
                  }`}
                  onClick={() => handleShowTabContent({ item })}>
                  <div className="settings-tab-content">
                    <UserIcon relative={item} />
                    <div className="settings-tab-title">{name}</div>
                  </div>

                  <ArrowDownIcon
                    className={`more-content-icon ${
                      isShowTabContent && 'active'
                    }`}
                  />
                </div>

                {isShowTabContent && (
                  <>
                    {isParentTab ? (
                      <UserSettings
                        successMessage={contentSuccessMessage}
                        setSuccessMessage={setContentSuccessMessage}
                        errorMessage={contentErrorMessage}
                        setErrorMessage={setContentErrorMessage}
                      />
                    ) : (
                      <ChildSettings
                        childId={itemId}
                        successMessage={contentSuccessMessage}
                        setSuccessMessage={setContentSuccessMessage}
                        errorMessage={contentErrorMessage}
                        setErrorMessage={setContentErrorMessage}
                        updateChildBooks={updateChildBooks}
                      />
                    )}
                  </>
                )}
              </>
            }
          />
        );
      })}
    </div>
  );
};

export default withRouter(Profiles);

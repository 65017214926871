import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import parse from 'html-react-parser';
import _, { debounce } from 'lodash';
import useScrollDetector from 'shared/hooks/useScrollDetector';
import useTrackDeeplink from 'shared/hooks/useTrackDeeplink';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import useCloudinaryImageLoader from 'shared/hooks/useCloudinaryImageLoader';
import useChildPicker from 'shared/hooks/useChildPicker';
import { facetColor } from 'shared/const';
import { goAnchorTop } from 'shared/function/goAnchor';
import { trackingAction, TRACKING_TOPICS } from 'shared/function/Tracking';
import {
  getTopic,
  getTopicalBigIdea,
  getStubs,
  useHandleTopicsBack,
  navigateTopics,
} from './TopicsUtils';

// components/assets
import Head from 'shared/components/Head';
import { ButtonSolid } from 'shared/components/Button/Button';
import { TopicsWrapper } from './TopicsComponent';
import LoadingIndicator from 'shared/components/Loading/LoadingIndicator';
import NoAccessModal from 'routes/ManagePremiumAccess/NoAccessModal';
import { KeyboardBackspace } from '@material-ui/icons';
import UserIcon from 'shared/components/UserIcon';
import readStatusNewImg from 'assets/journey/read-status-new.png';
import readStatusActiveImg from 'assets/journey/read-status-active.png';
import readStatusInactiveImg from 'assets/journey/read-status-inactive.png';
import { Search } from '@material-ui/icons';
import './TopicsTopicalHub.scss';

const TopicsTopicalHub = () => {
  const history = useHistory();

  const { topicSlug } = useParams();

  const { isUserPremiumAccess } = useUserPremiumAccess();

  const { selectedChildOption, user } = useChildPicker();

  const { topicId } = useTrackDeeplink({ topicSlug });

  const handleBack = useHandleTopicsBack({
    history,
    topicSlug,
  });

  const { isAtBottom } = useScrollDetector();

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const [topicData, setTopicData] = useState(null);
  const [topicalBigIdeaData, setTopicalBigIdeaData] = useState(null);

  const [stubsData, setStubsData] = useState(null);
  const [stubs, setStubs] = useState(null);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [noAccessOpen, setNoAccessOpen] = useState(false);

  const relativeId = selectedChildOption?.id;

  const isUserAdmin = _.isEqual(user?.is_admin, 1);

  const topicTitle = topicData?.title;
  const topicImage = isMobileView ? topicData?.image_mob : topicData?.image;
  const topicStatus = topicData?.status;
  const isTopicDraft = _.isEqual(topicStatus, 'draft');

  const bigIdeaTitle = topicalBigIdeaData?.big_idea_title;
  const bigIdeaDescription =
    topicalBigIdeaData?.big_idea_short_description &&
    parse(topicalBigIdeaData?.big_idea_short_description);

  const getTopicData = async () => {
    try {
      const relativeId = selectedChildOption?.id;
      const queryParams = { relativeId: relativeId };

      const res = await getTopic({ queryParams, topicId });
      const topicData = res?.data?.data;

      if (topicData) {
        return topicData; // Return the fetched data
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: Journey.js:39 ~ getTopicsData ~ err:',
        err?.response,
      );
    }
  };

  const getTopicalBigIdeaData = async () => {
    try {
      const relativeId = selectedChildOption?.id;
      const queryParams = { relativeId: relativeId };

      const res = await getTopicalBigIdea({ queryParams, topicId });
      const topicalBigIdeaData = res?.data?.data;

      if (topicalBigIdeaData) {
        return topicalBigIdeaData; // Return the fetched data
      }
    } catch (err) {
      console.log(
        '🚀 ~ file: JourneyTopicalHub.js:80 ~ getTopicalBigIdeaData ~ err:',
        err?.response,
      );
    }
  };

  const getStubsData = async () => {
    try {
      const queryParams = {
        relativeId: relativeId,
        topicId: topicId,
        per_page: 10,
        page: nextPage,
      };

      const res = await getStubs({ queryParams });
      const stubsData = res.data?.data;

      if (stubsData) {
        return stubsData;
      }
    } catch (err) {
      if (err)
        console.log(
          '🚀 ~ file: JourneyTopicalHub.js:122 ~ getStubsData ~ err:',
          err?.response,
        );
    }
  };

  // In the useEffect, use Promise.all to wait for all API calls to complete
  useEffect(() => {
    // Function to fetch data and handle scroll behavior
    const fetchDataAndScroll = async () => {
      setIsLoading(true);

      try {
        if (topicId) {
          // Call all three functions asynchronously and wait for their completion
          const [topicData, topicalBigIdeaData, stubsData] = await Promise.all([
            getTopicData(),
            getTopicalBigIdeaData(),
            getStubsData(),
          ]);

          const stubs = stubsData?.data;

          // Now all the API calls and image loading are completed
          // Update the state with the fetched data
          setTopicData(topicData);
          setTopicalBigIdeaData(topicalBigIdeaData);
          setStubsData(stubsData);

          if (stubs && stubs?.length > 0) {
            if (nextPage > 1) {
              // Append the new data to the existing stubs
              setStubs(prevStubs => [...prevStubs, ...stubs]);
            } else {
              setStubs(stubs);
            }

            // Check if there are more pages to load
            if (stubsData.current_page < stubsData.last_page) {
              setHasMoreData(true);
            } else {
              setHasMoreData(false);
            }
          } else {
            // If there is no new data, set hasMoreData to false
            setHasMoreData(false);
          }

          if (nextPage > 1) {
            // After data is fetched and rendered, scroll back to the previous position
            setTimeout(() => {
              window.scrollTo(0, scrollPosition);
            }, 20);
          }

          setIsLoading(false);
        }
      } catch (err) {
        console.error('Error fetching data:', err?.response);
        setIsLoading(false);
      }
    };

    // Check if there is more data to load before setting isLoading to true
    if (hasMoreData) {
      fetchDataAndScroll();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextPage, topicId]);

  // Load more stubs
  useEffect(() => {
    setStubs([]); // Reset the stubs when selectedRelative changes
    setNextPage(1); // Reset the nextPage to 1
  }, [selectedChildOption]);

  useEffect(() => {
    if (stubsData && stubsData?.length > 0) {
      const currentPage = stubsData.current_page; // Use stubsData.current_page
      setCurrentPage(currentPage);
    }
  }, [stubsData]);

  useEffect(() => {
    if (isAtBottom) {
      setNextPage(currentPage + 1);
      setScrollPosition(window.pageYOffset);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAtBottom]);

  if (isLoading) return <LoadingIndicator />;

  return (
    <TopicsWrapper
      backBtnText="Topics"
      backBtnNavigation={() => handleBack()}
      hideMainNavigation={isMobileView && true}>
      <Head title="Connected App - Topical Hub" preventIndex />

      <div id="journey-topical-hub">
        <div className="hub-main-content">
          <h1 className="hub-header">{topicTitle}</h1>

          <div className="hub-card-wrapper">
            {topicImage && (
              <div
                className="card-image-container"
                style={{ backgroundImage: `url(${topicImage})` }}
              />
            )}

            <div className="description-container">
              <h4 className="description-text">{bigIdeaTitle}</h4>
              <p className="description-text">{bigIdeaDescription}</p>

              {/* <h4 className="read-more-btn" onClick={() => handleShowBigIdea()}>
                Read more
              </h4> */}
            </div>
          </div>
        </div>

        {stubs?.length !== 0 ? (
          <div className="hub-sub-content">
            <h2 className="hub-sub-header">Explore curated insights:</h2>

            <div className="insight-card-wrapper">
              {stubs?.map((stub, idx) => {
                const readAt = stub?.read_at;
                const publishedAt = stub?.published_at;

                const today = new Date();
                const publishedDate = new Date(publishedAt);
                // To calculate the time difference of two dates
                var timeDifference = today.getTime() - publishedDate.getTime();
                // To calculate the no. of days between two dates
                // 1000 => 1 second = 1000 milliseconds
                // 3600 => 1 hour = 3600 seconds
                // 24 => 1 day = 24 hours
                var dayDifference = parseInt(
                  timeDifference / (1000 * 3600 * 24),
                  10,
                );

                const isNew = dayDifference <= 7 && !readAt;
                const isNotRead = !readAt;
                const isRead = readAt;
                const readStatus =
                  (isNew && 'new') ||
                  (isNotRead && 'not_read') ||
                  (isRead && 'read');

                const handleExploreStub = () => {
                  const stubId = stub?.id;
                  const stubSlug = stub?.deeplink_slug;

                  if (!isUserPremiumAccess && !isUserAdmin) {
                    setNoAccessOpen(true);
                  } else {
                    navigateTopics({
                      navigate: history.push,
                      type: 'stub',
                      topicSlug,
                      stubSlug,
                    });

                    trackingAction({
                      action: TRACKING_TOPICS.stub_explore,
                      extraData: {
                        topic_id: topicId,
                        stub_id: stubId,
                        relative_id: relativeId,
                        user_id: user?.id,
                      },
                    });
                  }
                };

                return (
                  <StubCard
                    key={idx}
                    idx={idx}
                    stub={stub}
                    readStatus={readStatus}
                    user={user}
                    selectedRelative={selectedChildOption}
                    handleExploreStub={handleExploreStub}
                    isTopicDraft={isTopicDraft}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div className="hub-sub-content empty" />
        )}

        {isMobileView && <AnchorTopButton />}
      </div>

      {noAccessOpen && (
        <NoAccessModal
          history={history}
          handleClose={() => setNoAccessOpen(false)}
        />
      )}
    </TopicsWrapper>
  );
};

const StubCard = ({
  idx,
  stub,
  readStatus,
  user,
  selectedRelative,
  handleExploreStub,
  isTopicDraft,
}) => {
  const isUserAdmin = _.isEqual(user?.is_admin, 1);

  const isReadStatusNew = _.isEqual(readStatus, 'new');
  const isReadStatusRead = _.isEqual(readStatus, 'read');
  const isReadStatusNotRead = _.isEqual(readStatus, 'not_read');

  const stubType = stub?.type;
  const stubImage = stub?.cloudinaryUrl;
  const facetName = stub?.facet_name;
  const status = stub?.status;

  const isTypeParent = _.isEqual(stubType, 'parent');
  const headerValue = isTypeParent ? user : selectedRelative;
  const headerName = isTypeParent ? 'You' : selectedRelative?.name;
  const isNameLong = headerName?.length + facetName?.length > 18;
  const isNameSuperLong = headerName?.length + facetName?.length > 22;

  const isStatusPublished = _.isEqual(status, 'published');
  const isExploreDisabled = !isUserAdmin && !isStatusPublished;

  const isImageLoaded = useCloudinaryImageLoader(stubImage);

  return (
    <div id="insight-card">
      <div className="header-container">
        <div className="header-content">
          <div
            className={`header-user ${isNameLong ? 'long-name' : ''} ${
              isNameSuperLong ? 'super-long-name' : ''
            }`}>
            {headerValue && <UserIcon relative={headerValue} />}
            {headerName}
          </div>

          <span
            className={`${isNameLong ? 'long-name' : ''} ${
              isNameSuperLong ? 'super-long-name' : ''
            }`}
            style={{
              borderColor: facetColor(facetName),
              color: facetColor(facetName),
            }}>
            {facetName}
          </span>
        </div>

        {isTopicDraft && <div className="draft-container">Draft</div>}
      </div>

      {isImageLoaded ? (
        // Render the Cloudinary image when it has loaded
        <div className="image-container">
          <img src={stubImage} alt="Stub" />
        </div>
      ) : (
        // Render a loading indicator while the Cloudinary image is loading
        <div className="image-container loading">
          <LoadingIndicator isLoaderOnly={true} />
        </div>
      )}

      {isReadStatusNew && (
        <img className="read-status" src={readStatusNewImg} alt="New" />
      )}
      {isReadStatusRead && (
        <img className="read-status" src={readStatusActiveImg} alt="Read" />
      )}
      {isReadStatusNotRead && (
        <img
          className="read-status"
          src={readStatusInactiveImg}
          alt="Not Read"
        />
      )}

      <div className="button-container">
        <ButtonSolid
          onClick={() => handleExploreStub({ stub })}
          children={
            <>
              <Search className="search-icon" />
              Explore
            </>
          }
          disabled={isExploreDisabled}
          data-test-id={`stub-explore-btn-${idx + 1}`}
        />
      </div>
    </div>
  );
};

const AnchorTopButton = () => {
  const { isAtBottom } = useScrollDetector();

  const handleGoAnchorTop = debounce(() => {
    goAnchorTop({
      contentElementId: '#journey-topical-hub',
    });
  }, 100);

  if (!isAtBottom) return null;

  return (
    <div className="anchor-top-btn-container" onClick={handleGoAnchorTop}>
      <KeyboardBackspace className="anchor-top-icon" />
    </div>
  );
};

export default TopicsTopicalHub;

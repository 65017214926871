import React, { useState, useCallback, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import useDisableScreenScrollable from 'shared/hooks/useDisableScreenScrollable';

// components/assets
import ExpandIcon from 'assets/general/expand-icon.png';
import SwipeLeftIcon from 'assets/general/swipe-left-icon.png';
import SwipeRightIcon from 'assets/general/swipe-right-icon.png';
import { ReactComponent as Close } from 'assets/general/close-normal-icon.svg';
import { ReactComponent as ArrowLeft } from 'assets/general/chevron-left.svg';
import { ReactComponent as ArrowRight } from 'assets/general/chevron-right.svg';
import { ReactComponent as LeftArrow } from 'assets/about/left-arrow-icon.svg';
import { ReactComponent as RightArrow } from 'assets/about/right-arrow-icon.svg';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './ComicModule.scss';

const CAROUSELTYPE = { Default: 'default', Modal: 'modal' };

export const DEFAULT_ASPECT_RATIO = 1112 / 1920;

const ComicModule = ({ title, body }) => {
  const { mob_content: mobContent } = body;

  const [pageNumber, setPageNumber] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [aspectRatio, setAspectRatio] = useState(DEFAULT_ASPECT_RATIO);

  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const isShowModal = _.isEqual(showModal, true);

  useDisableScreenScrollable({ isDisable: isShowModal });

  const handleDragStart = e => e.preventDefault();

  const handleCloseModal = () => {
    setShowModal(false);

    // Mobile has an extra page
    const processedMobilePageNumber =
      pageNumber > 1 ? pageNumber - 1 : pageNumber;
    isMobile && setPageNumber(processedMobilePageNumber);
  };

  const changePageNumber = number => {
    setPageNumber(number);
  };

  const getMeta = (url, cb) => {
    const img = new Image();
    img.onload = () => cb(null, img);
    img.onerror = err => cb(err);
    img.src = url;
  };

  const getTrueAspectRatio = () => {
    if (mobContent && mobContent.length > 0) {
      const { image_uri: imageUri } = mobContent[0];
      getMeta(imageUri, (err, img) => {
        if (img) {
          const newAspectRatio = img?.naturalWidth / img?.naturalHeight;
          setAspectRatio(newAspectRatio);
        }
      });
    }
  };

  useEffect(() => {
    if (body) {
      getTrueAspectRatio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [body]);

  const renderComicCarousel = useCallback(() => {
    return (
      <>
        <section className="module comic default-action">
          <h1 className="comic-title">{title}</h1>

          <ComicCarousel
            aspectRatio={aspectRatio}
            type={CAROUSELTYPE.Default}
            comicItems={mobContent}
            setShowModal={setShowModal}
            handleDragStart={handleDragStart}
            pageNumber={pageNumber}
            setPageNumber={changePageNumber}
            showModal={showModal}
          />
        </section>

        {showModal && (
          <ComicModal
            aspectRatio={aspectRatio}
            comicItems={mobContent}
            handleClose={handleCloseModal}
            handleDragStart={handleDragStart}
            pageNumber={pageNumber}
            setPageNumber={changePageNumber}
            showModal={showModal}
          />
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, showModal]);

  return <>{renderComicCarousel()}</>;
};

const ComicModal = ({
  aspectRatio,
  comicItems,
  handleClose,
  handleDragStart,
  pageNumber,
  setPageNumber,
  showModal,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  return (
    <div id="comic-module-modal">
      <div className="modal-outer-container">
        <div
          className="modal-inner-container"
          style={{
            ...(isMobile
              ? {
                  height: window.innerHeight,
                }
              : {}),
          }}>
          {!isMobile && (
            <div className="modal-close-container">
              <Close className="modal-close-btn" onClick={handleClose} />
            </div>
          )}

          <ComicCarousel
            aspectRatio={aspectRatio}
            type={CAROUSELTYPE.Modal}
            comicItems={comicItems}
            handleDragStart={handleDragStart}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            showModal={showModal}
          />

          {isMobile && (
            <div className="modal-close-container">
              <Close className="modal-close-btn" onClick={handleClose} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const ComicCarousel = ({
  aspectRatio,
  type,
  comicItems,
  setShowModal,
  handleDragStart,
  pageNumber,
  setPageNumber,
  showModal,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const activeIndex = pageNumber - 1;
  const totalPages = comicItems?.length;

  const isMobileDefault = isMobile && type === CAROUSELTYPE.Default;
  const isMobileModal = isMobile && type === CAROUSELTYPE.Modal;

  const handleOpenModal = () => {
    setShowModal(true);

    // Mobile has an extra page
    const processedMobilePageNumber =
      pageNumber > 1 ? pageNumber + 1 : pageNumber;

    isMobile && setPageNumber(processedMobilePageNumber);
  };

  const items =
    comicItems &&
    comicItems.reduce((acc, item, idx) => {
      const DefaultItem = () => {
        return (
          <>
            {type === CAROUSELTYPE.Default && (
              <div
                className={`expand-icon-overlay ${
                  !isMobileDefault && 'no-overlay'
                }`}
                style={{ aspectRatio }}>
                <img
                  className="expand-icon"
                  src={ExpandIcon}
                  alt="expand"
                  onClick={handleOpenModal}
                />
              </div>
            )}

            <img
              className={`comic-image`}
              style={{
                aspectRatio,
                ...(isMobileModal
                  ? { height: (window.innerHeight * 86) / 100 }
                  : {}),
              }}
              src={item.image_uri}
              alt="comic preview"
              onDragStart={handleDragStart}
            />
          </>
        );
      };

      const MobileModalOverlay = () => {
        return (
          <div id="mobile-modal-landing-overlay">
            <div onClick={() => console.log('scroll')} class="left-container">
              <p>Previous</p>
              <img src={SwipeLeftIcon} alt="Swipe Left" />
            </div>

            <div class="right-container">
              <p>Next</p>
              <img src={SwipeRightIcon} alt="Swipe Right" />
            </div>
          </div>
        );
      };

      const MobileDefaultOverlay = () => {
        return <div id="mobile-default-overlay" />;
      };

      if (idx === 0 && isMobileModal) {
        acc.push(
          <div className={`comic-card-container`}>
            <DefaultItem />
            <MobileModalOverlay />
          </div>,
        );
      }

      if (idx === 0 && isMobileDefault) {
        acc.push(
          <div className={`comic-card-container`}>
            <DefaultItem />
            <MobileDefaultOverlay />
          </div>,
        );
      }

      acc.push(
        <div className={`comic-card-container ${!isMobile && 'web'}`}>
          <DefaultItem />
        </div>,
      );

      return acc;
    }, []);

  const handlePageChange = item => {
    const isObject = _.isObject(item);
    const pageNumber = isObject ? item?.slide + 1 : item;
    setPageNumber(pageNumber);
  };

  const getDisplayShowWhenUnexpanded = () => {
    if (pageNumber === 1) {
      return pageNumber - 1;
    }

    return pageNumber;
  };

  return (
    <div className="comic-card">
      {comicItems && (
        <>
          {isMobile && !showModal ? (
            items[getDisplayShowWhenUnexpanded()]
          ) : (
            <AliceCarousel
              mouseTracking
              items={items}
              disableDotsControls
              // autoWidth={true}
              activeIndex={pageNumber - 1}
              onSlideChanged={handlePageChange}
              renderPrevButton={() => {
                if (pageNumber > 1) return <LeftArrow />;
              }}
              renderNextButton={() => {
                if (pageNumber < totalPages) return <RightArrow />;
              }}
            />
          )}

          <ComicPagination
            type={type}
            totalPages={totalPages}
            pageNumber={pageNumber}
            handlePageChange={handlePageChange}
            activeIndex={activeIndex}
          />
        </>
      )}
    </div>
  );
};

const ComicPagination = ({
  type,
  totalPages,
  pageNumber,
  handlePageChange,
  activeIndex,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 500px)' });

  const isWebDefault = !isMobile && type === CAROUSELTYPE.Default;
  const isModal = type === CAROUSELTYPE.Modal;

  const pages =
    (isModal &&
      (activeIndex === 0
        ? `1/${totalPages}`
        : isMobile
        ? `${pageNumber - 1}/${totalPages}`
        : `${pageNumber}/${totalPages}`)) ||
    `${pageNumber}/${totalPages}`;

  return (
    <div className={`comic-pagination-container ${isModal && 'modal'}`}>
      {isWebDefault && (
        <div
          className={`arrow-content ${pageNumber > 1 ? 'visible' : 'hidden'}`}
          onClick={() => handlePageChange(pageNumber - 1)}>
          <ArrowLeft className="arrow" />
        </div>
      )}

      <div className={`pagination ${isModal && 'modal'}`}>
        <span>{pages}</span>
      </div>

      {isWebDefault && (
        <div
          className={`arrow-content ${
            pageNumber < totalPages ? 'visible' : 'hidden'
          }`}
          onClick={() => handlePageChange(pageNumber + 1)}>
          <ArrowRight className="arrow" />
        </div>
      )}
    </div>
  );
};

// import { facetColor } from 'shared/const'
// import { capitalize } from 'shared/helper/helper'
// import { useMediaQuery } from "react-responsive";
// import { useKeenSlider } from 'keen-slider/react'
// import 'keen-slider/keen-slider.min.css'

// icon
// import { ReactComponent as Close } from 'assets/general-icon/close.svg'
// import { ReactComponent as ArrowLeft } from "assets/chevron-left.svg";
// import { ReactComponent as ArrowRight } from "assets/chevron-right.svg";

// image
// import PlayIcon from 'assets/play-icon-colored.png'

// const ComicModule = ({ title, body, domain, switchActiveModule }) => {
//   const { content, mob_content: mobContent } = body;
//   const items = [];
//   const labelColor = facetColor(domain);
//   const [isOpen, setIsOpen] = useState(false);
//   const [currentSlide, setCurrentSlide] = useState(0);

//   const [sliderRef, slider] = useKeenSlider({
//     slidesPerView: 1,
//     slideChanged(s) {
//       setCurrentSlide(s.details().relativeSlide);
//     },
//   });

//   const mobileSize = useMediaQuery({ query: "(max-width: 500px)" });

//   const handleDragStart = (e) => e.preventDefault();

//   mobContent &&
//     mobContent.map((singleComic, index) =>
//       items.push(
//         <img
//           style={{ width: "99.9%" }}
//           src={singleComic.image_uri}
//           alt="comic preview"
//           onDragStart={handleDragStart}
//         />
//       )
//     );

//   const handleClick = () => {
//     setIsOpen(true);
//   };

//   const handleClose = () => {
//     setIsOpen(false);
//   };

//   const handleNext = () => {
//     switchActiveModule("next");
//     setIsOpen(false);
//   };

//   return (
//     <>
//       <section>
//         <h1 className="comic-title">{title}</h1>

//         {content && (
//           <div className="preview-container">
//             <img src={content[0].image_uri} alt="comic preview2" />
//           </div>
//         )}
//         {mobileSize && mobContent && (
//           <div className="content">
//             <div ref={sliderRef} className="keen-slider">
//               {mobContent.map((singleComic, index) => (
//                 <div key={index} className="keen-slider__slide">
//                   <img src={singleComic.image_uri} alt="comic preview" />
//                 </div>
//               ))}
//             </div>
//             {slider && (
//               <>
//                 <ArrowLeft
//                   className={`left-arrow ${
//                     currentSlide === 0 ? "disabled" : ""
//                   }`}
//                   onClick={(e) => e.stopPropagation() || slider.prev()}
//                 />
//                 <ArrowRight
//                   className={`right-arrow ${
//                     currentSlide === slider.details().size - 1 ? "disabled" : ""
//                   }`}
//                   onClick={(e) => e.stopPropagation() || slider.next()}
//                 />
//               </>
//             )}
//           </div>
//         )}
//       </section>

//       {
//         <div className={`comic-overlay ${isOpen ? "active" : ""}`}>
//           <div className="content-container">
//             <div className="head">
//               <div className="left">
//                 <div
//                   className="label"
//                   style={{ color: labelColor, borderColor: labelColor }}
//                 >
//                   {capitalize(domain)}
//                 </div>
//                 <div className="title">{parse(title)}</div>
//               </div>
//               <div className="right">
//                 <Close onClick={handleClose} />
//               </div>
//             </div>
//             <div className="content">
//               <div ref={sliderRef} className="keen-slider">
//                 {content.map((singleComic, index) => (
//                   <div key={index} className="keen-slider__slide">
//                     <img src={singleComic.image_uri} alt="comic preview" />
//                   </div>
//                 ))}
//               </div>
//               {slider && (
//                 <>
//                   <ArrowLeft
//                     className={`left-arrow ${
//                       currentSlide === 0 ? "disabled" : ""
//                     }`}
//                     onClick={(e) => e.stopPropagation() || slider.prev()}
//                   />
//                   <ArrowRight
//                     className={`right-arrow ${
//                       currentSlide === slider.details().size - 1
//                         ? "disabled"
//                         : ""
//                     }`}
//                     onClick={(e) => e.stopPropagation() || slider.next()}
//                   />
//                 </>
//               )}
//             </div>
//             {currentSlide + 1 !== content.length ? (
//               <div className="counter">
//                 {currentSlide + 1 + "/" + content.length}
//               </div>
//             ) : (
//               <button onClick={handleNext}>Next</button>
//             )}
//           </div>
//         </div>
//       }
//     </>
//   );
// };

export default withRouter(ComicModule);

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import { ButtonSolid } from '../Button/Button';
import { ReactComponent as CloseNormal } from 'assets/general/close-normal-icon.svg';
import { ReactComponent as CloseStrong } from 'assets/general/close-strong-icon.svg';
import './Popup.scss';

const Popup = ({
  className,
  icon,
  header,
  description,
  callToActions,
  setShowModal,
  handleCloseCondition,
  ...otherProps
}) => {
  const isMobileView = useMediaQuery({ query: '(max-width: 500px)' });

  const handleClose = e => {
    if (handleCloseCondition) {
      handleCloseCondition(e);
    } else {
      setShowModal(false);
    }
  };

  return (
    <div id="popup" className={className}>
      <div
        className="popup-wrapper"
        onClick={e => handleClose(e)}
        {...otherProps}>
        <div className="popup-close-container">
          {isMobileView && (
            <CloseStrong
              className="popup-close-btn"
              onClick={e => handleClose(e)}
            />
          )}

          {!isMobileView && (
            <CloseNormal
              className="popup-close-btn"
              onClick={e => handleClose(e)}
            />
          )}

          <div
            className="popup-content-container"
            onClick={e => e.stopPropagation()}>
            <div className="popup-icon-container">{icon}</div>

            <div className="popup-subcontent-container">
              {header && <h2 className="popup-subcontent-header">{header}</h2>}
              {description && <p>{description}</p>}

              {callToActions && (
                <div className="popup-button-container">
                  {callToActions?.map(action => {
                    const actionType = action?.type;
                    const isTypeSolid = _.isEqual(actionType, 'solid');
                    const isTypeOutline = _.isEqual(actionType, 'outline');
                    const isTypeText = _.isEqual(actionType, 'text');

                    return (
                      <ButtonSolid
                        className={
                          (isTypeSolid && 'solid') ||
                          (isTypeOutline && 'outline') ||
                          (isTypeText && 'text')
                        }
                        onClick={action?.onClick}
                        children={action?.children}
                        data-test-id={action?.testingAutoId}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;

import React, { useState, useContext, useRef } from 'react';
// import { version } from '../../../package.json';
import { withRouter } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import { UserContext } from 'context/UserContext';
import { ButtonSolid } from 'shared/components/Button/Button';
import { ArrowBack } from '@material-ui/icons';
// import { ReactComponent as AccountIcon } from 'assets/sidebar-icon/account.svg'
// import { ReactComponent as NotificationIcon } from 'assets/sidebar-icon/notifications.svg'
// import { ReactComponent as TutorialIcon } from 'assets/sidebar-icon/tutorial.svg'
// import { ReactComponent as TermsIcon } from 'assets/sidebar-icon/terms.svg'
// import { ReactComponent as PolicyIcon } from 'assets/sidebar-icon/policy.svg'
// import CTAImage from 'assets/settings-cta-img.png'
// import logo from 'assets/logo-alt-2.png'
// import { ReactComponent as FeedbackIcon } from 'assets/sidebar-icon/feedback.svg'
import useOutsideClick from './useOutsideClick';
// import { useIntercom } from 'react-use-intercom';
import _ from 'lodash';

const SideNav = ({ history, showOnlyLogo }) => {
  const { user, setUser } = useContext(UserContext);
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);
  // const { shutdown } = useIntercom();

  const ref = useRef();

  useOutsideClick(ref, () => {
    setIsSideNavOpen(false);
  });

  const logout = () => {
    history.push('/login');
    setUser({});

    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    setIsSideNavOpen(false);
    // shutdown()
  };

  return (
    <div id="hamburger-menu-wrapper" ref={ref}>
      <div
        className="hamburger-nav-btn"
        onClick={() => setIsSideNavOpen(!isSideNavOpen)}>
        <span />
        <span />
        <span />
      </div>
      {(user.is_temp === 1 || _.isEmpty(user)) && (
        <div
          id="sidebar-nav"
          className={`secondary ${isSideNavOpen ? 'is-open' : ''}`}>
          <ArrowBack className="back" onClick={() => setIsSideNavOpen(false)} />
          {/* <NavLink
            onClick={() => setIsSideNavOpen(false)}
            to="/"
            exact
            activeClassName="active"
          >
            Home
          </NavLink> */}

          {/* Hide sitemap */}
          {/* {(window.location.pathname === "/" ||
            window.location.pathname === "/about" ||
            window.location.pathname === "/science" ||
            window.location.pathname === "/products" ||
            window.location.pathname === "/testimonials") && (
            <>
              <NavLink
                onClick={() => setIsSideNavOpen(false)}
                to="/about"
                activeClassName="active"
              >
                Our Story
              </NavLink>
              <NavLink
                onClick={() => setIsSideNavOpen(false)}
                to="/science"
                activeClassName="active"
              >
                Our Science
              </NavLink>
              <NavLink
                onClick={() => setIsSideNavOpen(false)}
                to="/products"
                activeClassName="active"
              >
                Playbook
              </NavLink>
              <NavLink
                onClick={() => setIsSideNavOpen(false)}
                to="/testimonials"
                activeClassName="active"
              >
                Testimonials
              </NavLink>
            </>
          )} */}

          {/* <NavLink
            onClick={() => setIsSideNavOpen(false)}
            to="/Gift"
            activeClassName="active"
          >
            Gift
          </NavLink> */}

          <div className="auth-button-container">
            {user.is_temp === 1 ? (
              <button className="logout" onClick={logout}>
                Logout
              </button>
            ) : (
              <>
                <ButtonSolid
                  className="signup"
                  onClick={() => {
                    setIsSideNavOpen(false);
                    history.push('/signup');
                  }}
                  children="Sign Up"
                />

                <ButtonSolid
                  className="login"
                  onClick={() => {
                    setIsSideNavOpen(false);
                    history.push('/login');
                  }}
                  children="Log-in"
                />
              </>
              // <>
              //   <div
              //     className="signup"
              //     // onClick={() => {
              //     //   setIsSideNavOpen(false);
              //     //   history.push('./products');
              //     // }}
              //   >
              //     Sign Up
              //     <ul>
              //       <li>
              //         <Link
              //           to="sign-up"
              //           onClick={() => setIsSideNavOpen(false)}
              //         >
              //           Try for free
              //         </Link>
              //       </li>
              //       <li>
              //         <Link
              //           to="/redeem-gift"
              //           onClick={() => setIsSideNavOpen(false)}
              //         >
              //           Redeem Gift Code
              //         </Link>
              //       </li>
              //     </ul>
              //   </div>
              //   <button
              //     onClick={() => {
              //       setIsSideNavOpen(false);
              //       history.push("/login");
              //     }}
              //     className="login"
              //   >
              //     Log-in
              //   </button>
              // </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(SideNav);

import ReactGA from 'react-ga4';

export const GAInit = () => {
  const GA_TRACKING_ID = process.env.REACT_APP_GA_MEASUREMENT_ID_WEB;

  ReactGA.initialize(GA_TRACKING_ID, {
    gaOptions: {
      debug_mode: true,
    },
    gtagOptions: {
      debug_mode: true,
    },
  });
};

export const GAEvent = ({ category, action, extraData }) => {
  ReactGA.event(action, {
    category,
    action,
    ...extraData,
  });
};

export const GAEcommerceEvent = ({
  ecommerceEventType,
  ecommerceEventData,
}) => {
  const productData = ecommerceEventData?.productData;

  const orderId = ecommerceEventData?.orderId;
  const productId = productData?.product_id;
  const productCurrency = productData?.currency;

  const productName = productData?.name;
  const productType = productData?.type;
  const selectedProductPrice = productData?.price?.find(price => {
    if (price?.currency?.toLowerCase() === productCurrency?.toLowerCase())
      return true;
    return false;
  });
  const productPrice = selectedProductPrice?.amount;

  const purchaseData = !productData
    ? {}
    : orderId
    ? {
        transaction_id: orderId,
        value: productPrice,
        currency: productCurrency,
        items: [
          {
            id: productId,
            name: productName,
            category: productType,
            price: productPrice,
            quantity: 1,
          },
        ],
      }
    : {
        value: productPrice,
        currency: productCurrency,
        items: [
          {
            id: productId,
            name: productName,
            category: productType,
            price: productPrice,
            quantity: 1,
          },
        ],
      };

  ReactGA.gtag('event', ecommerceEventType, purchaseData);
};

export const GAUser = ({ type, user }) => {
  const userId = user?.id;

  switch (type) {
    case 'login':
      return ReactGA.set({ userId });
    case 'logout':
      return ReactGA.set({ userId: undefined });
    default:
      return;
  }
};

export const GA_EVENT_CATOGARY = {
  user: 'user',
  navigation: 'navigation',
  ecommerce: 'ecommerce',
  purchase: 'purchase',
};

export const GA_PURCHASE_JOURNEY_TYPE = {
  session_start: 'session_start',
  view_item: 'view_item',
  add_to_cart: 'add_to_cart',
  begin_checkout: 'begin_checkout',
  purchase: 'purchase',
};

export const GA_CHECKOUT_JOURNEY_TYPE = {
  add_shipping_info: 'add_shipping_info',
  add_payment_info: 'add_payment_info',
};

import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { UserContext } from 'context/UserContext';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import { CODE_TYPES } from 'shared/const';
import { claimPremiumAccess, validateGiftCode, claimGiftCode } from './api';
import './RedemptionCodePopUp.scss';

// components/assets
import { ButtonSolid } from 'shared/components/Button/Button';
import { ReactComponent as CloseIcon } from 'assets/general/close-normal-icon.svg';
import { ReactComponent as CloseThickIcon } from 'assets/general/close-code.svg';
import { validatePremiumAccessCode } from 'routes/Auth/authUtils';

const RedemptionCodePopUp = ({ type, isOpen, setIsOpen, onSuccess }) => {
  const { user, setUser } = useContext(UserContext);

  const { isUserDemo } = useUserPremiumAccess();

  const [formValue, setFormValue] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const isMobileView = useMediaQuery({ query: '(max-width: 500px)' });

  const isTypePremiumAccess = type?.includes(
    CODE_TYPES.MULTI_USE_PREMIUM_ACCESS,
  );

  const handleNext2 = async () => {
    setIsOpen(false);
    setError('');
    // history.push('/home')
  };

  const handleChange = e => {
    const { value } = e.target;
    setFormValue(value);
    setError('');
  };

  const redeemChild = async () => {
    try {
      const allowedTypes = [
        CODE_TYPES.CHILD,
        CODE_TYPES.PREMIUM_ACCESS_AND_CHILD,
      ];
      // Validate first
      const { data: validationRes } = await validateGiftCode({
        code: formValue,
      });

      const { data: validationResData } = validationRes;
      const { campaign_type: campaignType } = validationResData;

      // If a demo user tries to claim a child only code
      if (isUserDemo) {
        throw new Error(
          'You need to have premium access to claim a child profile.',
        );
      }

      if (allowedTypes.indexOf(campaignType) === -1) {
        throw new Error('This code cannot be used to redeem a child profile');
      }

      // Claim gift code
      const { data: claimRes } = await claimGiftCode({
        code: formValue,
        type: campaignType,
      });

      const { data: claimResData } = claimRes;

      const {
        is_demo,
        premium_start_date,
        premium_end_date,
        is_temp,
        all_books_occupied: allBooksOccupied,
      } = claimResData;

      if (allBooksOccupied === 0) {
        setUser({
          ...user,
          is_temp,
          is_demo,
          all_books_occupied: 0,
          premium_start_date,
          premium_end_date,
        });
      } else {
        setUser({
          ...user,
          is_temp,
          is_demo,
          premium_start_date,
          premium_end_date,
        });
      }
      setSuccess(`You've successfully redeemed!`);
      setTimeout(() => {
        onSuccess && onSuccess();
      }, 1000);
    } catch (err) {
      setError(err.message);
    }
  };

  const redeemPremiumAccess = async () => {
    try {
      const allowedTypes = [
        CODE_TYPES.SINGLE_USE_PREMIUM_ACCESS,
        CODE_TYPES.PREMIUM_ACCESS_AND_CHILD,
      ];

      const { data: premiumAccessCodeResponse } =
        await validatePremiumAccessCode({
          premium_access_code: formValue,
        });

      const { status: premiumAccessCodeStatus } =
        premiumAccessCodeResponse?.data;

      // This means that the premium access code exist.
      if (premiumAccessCodeStatus) {
        const { data: claimPremiumAccessRes } = await claimPremiumAccess({
          premium_access_code: formValue,
        });
        // This returns user data
        const { data: claimPremiumAccessData } = claimPremiumAccessRes;

        const {
          is_demo,
          premium_start_date,
          premium_end_date,
          is_temp,
          all_books_occupied: allBooksOccupied,
        } = claimPremiumAccessData;

        if (allBooksOccupied === 0) {
          setUser({
            ...user,
            is_temp,
            is_demo,
            all_books_occupied: 0,
            premium_start_date,
            premium_end_date,
          });
        } else {
          setUser({
            ...user,
            is_temp,
            is_demo,
            premium_start_date,
            premium_end_date,
          });
        }
      }
      // This means that the code is probably a gift code
      else {
        // Validate first
        const { data: validationRes } = await validateGiftCode({
          code: formValue,
        });

        const { data: validationResData } = validationRes;
        const { campaign_type: campaignType } = validationResData;

        if (allowedTypes.indexOf(campaignType) === -1) {
          throw new Error('This code cannot be used to redeem premium access');
        }

        // Claim gift code
        const { data: claimRes } = await claimGiftCode({
          code: formValue,
          type: campaignType,
        });

        // This also returns user data
        const { data: claimResData } = claimRes;

        const {
          is_demo,
          premium_start_date,
          premium_end_date,
          is_temp,
          all_books_occupied: allBooksOccupied,
        } = claimResData;

        if (allBooksOccupied === 0) {
          setUser({
            ...user,
            is_temp,
            is_demo,
            all_books_occupied: 0,
            premium_start_date,
            premium_end_date,
          });
        } else {
          setUser({
            ...user,
            is_temp,
            is_demo,
            premium_start_date,
            premium_end_date,
          });
        }
      }

      setSuccess(`You've successfully redeemed!`);
      setTimeout(() => {
        onSuccess && onSuccess();
      }, 1000);
    } catch (err) {
      setError(err.message);
    }
  };

  const submit = async () => {
    if (isTypePremiumAccess) {
      await redeemPremiumAccess();
    } else {
      await redeemChild();
    }
  };

  const placeholder = 'Enter gift code';

  return (
    <>
      <div
        className={`redemption-code-content-overlay ${isOpen ? 'active' : ''}`}>
        <div className="redemption-code-wrapper">
          {!isMobileView && (
            <CloseIcon
              fill={'#FFF'}
              style={{
                width: '50px',
                height: '50px',
                alignSelf: 'flex-end',
                margin: '0 12% 0 0',
                cursor: 'pointer',
              }}
              onClick={handleNext2}
            />
          )}

          <div className="redemption-code-content-container">
            {isOpen && (
              <div className="content">
                <div className="header">
                  <h1>Have a code?</h1>
                </div>

                <div className="description-wrapper">
                  <div className="form-group">
                    <input
                      className="redemption-code-input"
                      name="code"
                      type="text"
                      onChange={handleChange}
                      placeholder={placeholder}
                    />

                    <ButtonSolid
                      onClick={submit}
                      className="submit2"
                      disabled={formValue === ''}
                      children="Apply"
                      style={{
                        paddingHorizontal: 5,
                        paddingVertical: 10,
                        width: isMobileView && 75,
                      }}
                    />
                  </div>
                </div>
                {error && error.length > 0 && (
                  // Not too sure whether if to use error from API
                  // <div className="error-subtitle">{error}</div>
                  <div className="error-subtitle">Invalid code</div>
                )}
              </div>
            )}
          </div>

          {isMobileView && (
            <CloseThickIcon
              fill={'#FFF'}
              style={{
                width: '30px',
                alignSelf: 'center',
                marginTop: 16,
                cursor: 'pointer',
              }}
              onClick={handleNext2}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(RedemptionCodePopUp);

import React from 'react';
import Lottie from 'react-lottie';
import celebrationsGif from 'assets/auth/celebrations.json';
import './AccountCreationSuccess.scss';

const AccountCreationSuccess = () => {
  const env = process.env.REACT_APP_STAGE;
  const mobileProChoiceLink =
    (env === 'dev' && 'https://app-dev.getconnected.sg/login') ||
    (env === 'staging' && 'https://app-stg.getconnected.sg/login') ||
    (env === 'production' && 'https://app.getconnected.sg/login');

  return (
    <div id="account-success">
      <div
        className="account-success-container"
        onClick={e => e.stopPropagation()}>
        <div className="inner-container">
          <div className="content-container image-container">
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
                animationData: celebrationsGif,
              }}
              width={300}
              height={250}
            />
          </div>

          <div className="sub-content-container">
            <h1>Success! Your account's been created</h1>
            <p>
              Almost there! Begin your Connected journey by setting up profiles
              for you and your child.
            </p>
            <br />

            <a className="link-button-text" href={mobileProChoiceLink}>
              <div className="link-button-container">Continue</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountCreationSuccess;

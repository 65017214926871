import { useMediaQuery } from 'react-responsive';
import { url } from 'config/urlConfig';
import { trackingAction, TRACKING_PURCHASES } from 'shared/function/Tracking';
import useProductList from 'routes/Checkout/useProductList';
import { ButtonSolid } from 'shared/components/Button/Button';
import { ReactComponent as Close } from 'assets/general/close-normal-icon.svg';
import NoAccessIcon from 'assets/general/no-access-icon.png';
import './AddChildDisclaimerModal.scss';
import { useContext } from 'react';
import { UserContext } from 'context/UserContext';

const AddChildDisclaimerModal = ({
  history,
  previousPath,
  setIsOpen,
  ...otherProps
}) => {
  const { user } = useContext(UserContext);

  const { isSelectedProductInactive, selectedProduct, getProductList } =
    useProductList();

  const productId = selectedProduct?.id;

  const isMobileView = useMediaQuery({ query: '(max-width: 992px)' });

  const mainTitle = 'Your Premium access expires in less than 30 days!';
  const subTitle = (
    <>
      <p className="subtitle-top">
        Basic users do not have access to the Playbook or multiple children
        profiles. Extend your Premium access in order to continue using Premium
        features of Connected.
      </p>
    </>
  );

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleExtendPremium = () => {
    history.push(`${url.UPGRADE_PREMIUM}?from=${previousPath}`);
    handleClose();
  };

  const handleAddChild = () => {
    getProductList().then(() => {
      if (!isSelectedProductInactive) {
        history.push(`/checkout?addchild=true&from=${previousPath}`);
      }

      handleClose();

      trackingAction({
        action:
          TRACKING_PURCHASES.web_purchase_child_profile_purchase_attempt_with_limited_premium,
        extraData: { product_id: productId, user_id: user?.id },
      });
    });
  };

  return (
    <div id="add-child-disclaimer">
      <div className="add-child-disclaimer-modal" {...otherProps}>
        <div className="disclaimer-close-container">
          {!isMobileView && (
            <div className="disclaimer-close-container">
              <Close
                className="disclaimer-close-btn"
                onClick={() => handleClose()}
              />
            </div>
          )}

          <div
            className="disclaimer-modal-container"
            onClick={e => e.stopPropagation()}>
            <div className="disclaimer-popup-container">
              <div className="disclaimer-popup-content-container image-container">
                <img src={NoAccessIcon} alt="Lock" />
              </div>
            </div>

            <div className="disclaimer-sub-content-container">
              <h2>{mainTitle}</h2>
              {subTitle}

              <div className="disclaimer-modal-button-container">
                <ButtonSolid
                  className="disclaimer-extend-premium-btn"
                  onClick={handleExtendPremium}
                  children="Extend"
                />
                <ButtonSolid
                  className="disclaimer-add-child-btn"
                  onClick={handleAddChild}
                  children="Add child"
                />
              </div>
            </div>
          </div>

          {isMobileView && (
            <div className="disclaimer-close-container">
              <Close
                className="disclaimer-close-btn"
                onClick={() => handleClose()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AddChildDisclaimerModal.defaultProps = {
  handleProceed: () => {},
  handleBack: () => {},
  handleClose: () => {},
};

export default AddChildDisclaimerModal;

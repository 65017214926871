export const TESTING_CHILD_PICKER_ID = {
  CHILD_PICKER: 'child-picker',
  CHILD_PICKER_SELECTED_VALUE: 'child-picker-selected-value',
};

export const TESTING_PLAYBOOK_ID = {
  SECTION_SNAPSHOT_CARD: 'section-snapshot-card',
  SECTION_INTRO_VIDEO_CARD: 'section-intro-video-card',
  SECTION_INTRO_VIDEO_CLOSE_BUTTON: 'section-intro-video-close-button',
  SECTION_DOMAIN_CARD: 'section-domain',
  SECTION_ENDING_VIDEO_CARD: 'section-ending-video-card',
  CHAPTER_INTRO_VIDEO_CARD: 'chapter-intro-video-card',
  CHAPTER_FACET_CARD: 'chapter-facet',
  CHAPTER_ENDING_VIDEO_CARD: 'chapter-ending-video-card',
  SNAPSHOT_BACK_BUTTON: 'snapshot-back-button',
};

export const TESTING_GENERAL_ID = {
  SMARTBANNER_CLOSE_BUTTON: 'smartbanner-close-button',
  NO_ACCESS_POPUP_CLOSE_BUTTON: 'no-access-popup-close-button',
};

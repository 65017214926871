import { ButtonBack } from 'shared/components/Button/Button';
import './SettingsComponent.scss';
import * as Scroll from 'react-scroll';
var scroll = Scroll.animateScroll;

export const BackButton = ({ history, title, handleClick }) => {
  const handleBack = () => {
    history.goBack();
    scroll.scrollToTop();
  };

  return (
    <ButtonBack
      title={title}
      onClick={handleClick ? handleClick : () => handleBack()}
    />
  );
};

export const TabBar = ({ items, handleShowTabContent }) => {
  return (
    <div id="settings-tab-bar">
      {items?.map((item, idx) => {
        const { type, isSelected, label } = item;
        const isTypeLeft = type === 'left';

        const tabClasses = [
          'settings-tab-btn',
          isTypeLeft ? 'left-tab' : 'right-tab',
          isSelected ? 'active' : '',
        ]
          .filter(Boolean)
          .join(' ');

        return (
          <button
            key={idx}
            className={tabClasses}
            onClick={() => handleShowTabContent({ item })}>
            {label}
          </button>
        );
      })}
    </div>
  );
};

export const SettingsContentContainer = ({ children }) => {
  return <div id="settings-content-container">{children}</div>;
};

import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { url } from 'config/urlConfig';
import useUserPremiumAccess from 'shared/hooks/useUserPremiumAccess';
import './ManagePremiumRibbon.scss';

const ManagePremiumRibbon = ({ history, isChapterModule }) => {
  const { isUserPremiumAccess } = useUserPremiumAccess();

  const isDesktopView = useMediaQuery({ query: '(min-width: 992px)' });

  const path = history.location.pathname;

  const handleClick = () => {
    history.push(`${url.UPGRADE_PREMIUM}?from=${path?.substring(1)}`);
  };

  if (isDesktopView && !isChapterModule) {
    return null;
  }

  if (isUserPremiumAccess) {
    return null;
  }

  return (
    <div id="manage-premium-ribbon" onClick={() => handleClick()}>
      <p>You are currently previewing your Playbook.</p>
      <h3>Manage Premium Access</h3>
    </div>
  );
};

export default ManagePremiumRibbon;
